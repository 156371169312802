import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import vectorImage from '../../../../../app/assets/img/signup/Vector.png'

// import CheckIcon from '@material-ui/icons/Check'

const ChooseProgramCard = ({
  name,
  color,
  heading,
  bestForHeading,
  benefitsArray,
  activeCard,
  setActiveCard,
  dataTestId,
  icon
}) => {
  const isActive = activeCard === name
  const noActive = activeCard === null
  const showColor = isActive || noActive
  const classes = useStyles({ color, isActive, showColor })

  const onCardClickHandler = () => {
    setActiveCard(name)
  }

  return (
    <div
      className={classes.container}
      onClick={onCardClickHandler}
      data-testid={dataTestId}
    >

      <div>
        <div className={classes.heading}>
          <span className={classes.cardSubHeading}>{heading}</span>
        </div>
        <div className={classes.cardContent}>
          <div className={classes.suggestionBox}>
            <p style={{marginTop: '37px'}}>
              Best for&nbsp;
              <b className={classes.bestForHeading}>{bestForHeading}</b>
            </p>
          </div>
          <div style={{marginTop: '15px'}}>
            {benefitsArray.map((benefit, i) => {
              return (
                <div className={classes.benefitRow} key={i}>
                  <img src={vectorImage} style={{height: '17px', width: '17px'}}/>
                  &nbsp; &nbsp;<span>{benefit}</span>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '1rem',
    marginTop: 0,
    color: ({ showColor }) => (showColor ? 'black' : 'grey'),
    maxWidth: ({ showColor }) => (showColor ? '300px' : '250px'),
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut
    }),
    transform: ({ isActive }) => (isActive ? 'scale(1.1)' : 'scale(1)')
  },
  benefitRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    ' & svg': {
      color: ({ color, showColor }) => (showColor ? color : '#EBEBEB'),
      marginRight: '.4rem'
    },
    margin: '.25rem 0'
  },
  cardContent: {
    borderRadius: '5px',
    border: ({ color, showColor }) =>
      showColor ? `` : `3px solid #EBEBEB`,
    padding: '1rem',
    // maxWidth: '250px',
    minWidth: '255px',
    // minHeight: '250px',
    minHeight: '389px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1.2rem',
    backgroundColor: '#ffffff',
    boxShadow: '0px 1px 3px #999',
    fontFamily: 'Poppins',
    fontSize: '22px'
  },
  heading: {
    fontFamily: 'HandWritten',
    fontSize: '70px'
  },
  bestForHeading: {
    // color: ({ color, showColor }) => (showColor ? color : 'grey')
  },
  iconDiv: {
    position: 'absolute',
    width: '3.8rem',
    height: '3.8rem',
    backgroundColor: ({ color, showColor }) => (showColor ? color : '#EBEBEB'),
    top: '-1.9rem',
    borderRadius: '50%',
    '& svg': {
      width: '70%'
    },
    ...theme.CSS.center
  },
  suggestionBox: {
    backgroundColor: '#FBFDFF',
    marginTop: '-16px',
    width: '250px'
  },
  [theme.breakpoints.down('sm')]: {
    cardContent: {
      fontSize: '18px',
      minHeight: '295px'
    },
    heading: {
      fontSize: '45px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      maxWidth: ({ showColor }) => (showColor ? '400px' : '350px')
    },
    cardContent: {
      maxWidth: '350px',
      width: '350px'
    },
    suggestionBox: {
      width: '350px'
    }
  },
  cardSubHeading: {
    position: 'relative',
    top: '45px',
    zIndex: '999',
    backgroundColor: '#E1F0FD',
    display: 'inline-block',
    width: '195px'
  },
  [theme.breakpoints.down(400)]: {
    container: {
      maxWidth: ({ showColor }) => (showColor ? '280px' : '270px')
    },
    cardContent: {
      maxWidth: ({ showColor }) => (showColor ? '250px' : '240px')
    },
    suggestionBox: {
      width: ({ showColor }) => (showColor ? '250px' : '240px')
    }
  }
}))

ChooseProgramCard.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  bestForHeading: PropTypes.string.isRequired,
  benefitsArray: PropTypes.array.isRequired,
  activeCard: PropTypes.string,
  setActiveCard: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  icon: PropTypes.object
}

export default ChooseProgramCard
