import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, Grow } from '@material-ui/core'

import Backdrop from './Backdrop'

const ModalOverlay = ({ title, show, noBorderRadius, children }) => {
  const classes = useStyles({ noBorderRadius })
  return (
    <Grow in={show} mountOnEnter unmountOnExit>
      <div className={classes.modal}>
        <div className={classes.modalBody} data-testid='modal'>
          {children}
        </div>
      </div>
    </Grow>
  )
}

const Modal = ({
  show,
  onCancel,
  onSubmit,
  noBorderRadius,
  children,
  ...props
}) => {
  const theme = useTheme()
  const [modalVisible, setModalVisible] = useState(true)

  useEffect(() => {
    if (show) setModalVisible(true)
  }, [show])

  const backdropClickHandler = () => {
    setModalVisible(false)
    setTimeout(function () {
      onCancel()
    }, theme.transitions.duration.standard)
  }
  return (
    <Fragment>
      {show && (
        <Backdrop onClick={backdropClickHandler}>
          <ModalOverlay
            show={modalVisible}
            noBorderRadius={noBorderRadius}
            {...props}
          >
            {children}
          </ModalOverlay>
        </Backdrop>
      )}
    </Fragment>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    minHeight: '17vh',
    background: 'white',
    boxShadow: '0 8px 8px rgba(0,0,0,0.9)',
    border: '1px solid grey',
    borderRadius: (props) => (props.noBorderRadius ? '' : '15px'),
    maxWidth: '100%',
    backgroundColor: 'white',
    zIndex: 108,
    margin: 'auto',
    minWidth: '28rem',
    overflowY: 'initial!important'
  },
  [theme.breakpoints.down('sm')]: {
    modal: {
      minWidth: '28rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    modal: {
      minWidth: '24rem'
    }
  },
  [theme.breakpoints.down(430)]: {
    modal: {
      minWidth: '20rem'
    }
  },
  [theme.breakpoints.down(380)]: {
    modal: {
      minWidth: '18rem'
    }
  }
}))

ModalOverlay.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool,
  noBorderRadius: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ])
}

Modal.propTypes = {
  show: PropTypes.bool,
  noBorderRadius: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ])
}

export default Modal
