import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import skippingRope from '../../../../assets/img/pre_season/guides/infographics/skippingRope.png'
import swimGoggles from '../../../../assets/img/pre_season/guides/infographics/swimGoggles.png'
import runners from '../../../../assets/img/pre_season/guides/infographics/runners.png'
import sneakers from '../../../../assets/img/pre_season/guides/infographics/sneakers.png'
import pinkArrow from '../../../../assets/img/pre_season/guides/infographics/pinkArrow.png'
import mostBurn from '../../../../assets/img/pre_season/guides/infographics/mostBurn.png'

const HealthyEatingExercises = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <span className={classes.fancyText}>Calories burnt in 10 minutes</span>
      <Grid container className={classes.exerciseRow}>
        <Grid item xs={6} sm={3}>
          <div className={classes.exerciseContainer}>
            <img src={sneakers} alt='Sneakers' />
            <span className={classes.title}>Walk</span>
            <span>35 Cal</span>
          </div>
        </Grid>
        <Grid item xs={6} sm={3}>
          <div className={classes.exerciseContainer}>
            <img src={swimGoggles} alt='Swimming goggles' />
            <span className={classes.title}>Swim</span>
            <span>75 Cal</span>
          </div>
        </Grid>
        <Grid item xs={6} sm={3}>
          <div className={classes.exerciseContainer}>
            <img src={runners} alt='Runners' />
            <span className={classes.title}>Run</span>
            <span>95 Cal</span>
          </div>
        </Grid>
        <Grid item xs={6} sm={3}>
          <div className={`${classes.exerciseContainer} ${classes.skippingRopeContainer}`}>
            <img src={skippingRope} alt='Skipping rope' />
            <span className={classes.title}>Skip</span>
            <span>105 Cal</span>
            <div className={classes.messageDiv}>
              <div className={classes.message}>
                <img src={mostBurn} alt='Most Burn' />
              </div>
              <div className={classes.arrowDiv}>
                <img src={pinkArrow} alt='Arrow pointing to skipping rope' />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.CSS.center,
    flexDirection: 'column'
  },
  exerciseContainer: {
    ...theme.CSS.center,
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    justifyContent: 'flex-end',
    '& img': {
      width: '8rem'
    }
  },
  fancyText: {
    fontFamily: 'KokomoBreeze',
    fontSize: '3rem',
    color: theme.palette.rgb.guide.pink
  },
  exerciseRow: {
    marginBottom: '6rem'
  },
  title: {
    color: theme.palette.rgb.guide.pink,
    fontWeight: 800
  },
  skippingRopeContainer: {
    position: 'relative'
  },
  messageDiv: {
    position: 'absolute',
    bottom: '-5rem',
    display: 'flex',
    marginLeft: '-18rem'
  },
  arrowDiv: {
    marginTop: '2rem',
    width: '6rem',
    '& img': {
      width: '100%'
    }
  },
  message: {
    marginTop: '4rem'
  },
  [theme.breakpoints.down('sm')]: {
    exerciseRow: {
      marginTop: '1rem'
    },
    messageDiv: {
      marginLeft: '-17rem',
      bottom: '-6rem'
    },
    message: {
      fontSize: '.8rem',
      marginRight: '-2rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    messageDiv: {
      marginLeft: '-14rem'
    },
    message: {
      maxWidth: '10rem'
    }
  }
}))

export default HealthyEatingExercises
