import { createMuiTheme } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const boxShadowBordWidth = 1
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1900
    }
  },
  paddings: {
    md: '50px',
    xl: '96px'
  },
  borderRadius: {
    sm: '6px'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: [
            'ARSMaquettePro',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif'
          ],
          padding: '0!important',
          backgroundColor: 'white'
        }
      }
    }
  },
  palette: {
    primary: {
      light: '#61acff',
      main: '#007DE0',
      dark: '#0052ad',
      contrastText: '#fff',
      transparent: 'rgba(97, 172, 255, 0.3)'
    },
    error: {
      light: '#EF9A9A',
      main: '#EF5353',
      dark: '#F44336',
      contrastText: '#FFF',
      transparent: 'rgba(234, 93, 93, 0.2)'
    },
    signup: {
      primary: '#4ba4ff',
      secondary: '#50c7e4',
      secondaryBright: '#00ffff',
      round: '#39CED9',
      purple: '#4f5fec',
      red: '#fb5c4f',
      darkBlue: '#003b70',
      lightBlue: '#00aaef',
      orange: '#f49b39',
      green: '#35D181',
      softBlue: '#e3e9ef',
      grey: '#f7f7f7',
      newButton: '#81BDE7',
      borderColor: '#d5ebff',
      signupFaq: '#B6DCF6'
    },
    blokesSignup: {
      primary: '#9da090',
      secondary: '#000000',
      tertiary: '#808080',
      white: '#FFFFFF'
    },
    main: {
      containerBackground: 'white'
    },
    greys: {
      dark: grey[600],
      main: grey[500],
      medium: grey[400],
      mediumLight: grey[300],
      light: grey[200],
      lightest: grey[100]
    },
    rgb: {
      primary: {
        light: 'rgb(97, 172, 255)',
        main: 'rgb(0, 125, 224)',
        dark: 'rgb(0, 82, 173)'
      },
      signup: {
        purple: 'rgb(79, 95, 236)',
        darkBlue: 'rgb(0, 59, 112)',
        secondary: 'rgb(80, 199, 228)'
      },
      guide: {
        orange: 'rgb(255, 158, 48)',
        green: 'rgb(0,196,97)',
        pink: 'rgb(245,20,180)'
      }
    }
  },
  CSS: {
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    cardShadow: '0px 5px 12px rgba(0,0,0,0.2)',
    cardShadowBlue: `${boxShadowBordWidth}px ${boxShadowBordWidth}px rgb(0, 125, 224), -${boxShadowBordWidth}px -${boxShadowBordWidth}px rgb(0, 125, 224), ${boxShadowBordWidth}px -${boxShadowBordWidth}px rgb(0, 125,224), -${boxShadowBordWidth}px ${boxShadowBordWidth}px rgb(0, 125, 224)`
  }
})
export default theme
