import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import TaskContentContainer from '../../tasks/TaskContentContainer'
import happyFamily from '../../../../assets/img/pre_season/guides/infographics/familyInfographic.png'
import HealthyEatingBanner from './HealthyEatingBanner'
import HealthyEatingPortionsVsServings from './HealthyEatingPortionsVsServings'
import HealthyEatingBallRow from './HealthyEatingBallRow'
import HealthyEatingTurtleAndRabbit from './HealthyEatingTurtleAndRabbit'
import HealthyEatingExercises from './HealthyEatingExercises'

const GuideBasicsOfEatingHealthy = () => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <div className={classes.headerSection}>
          <p className={classes.header}>Nutrition Truths</p>
          <span>What’s the difference between a portion and a serving?</span>
          <img src={happyFamily} alt='Happy family' className={classes.happyFam} />
        </div>
        <HealthyEatingPortionsVsServings />
        <HealthyEatingBanner
          title='How much fruit should I eat'
          description='We recommend you eat at least 2 serves of fruit a day. But how big is a serve of fruit?'
          color={theme.palette.rgb.guide.orange}
        />
        <HealthyEatingBallRow />
        <HealthyEatingBanner
          title={`What's the difference between low GI and high GI food?`}
          description='Eating food gives you energy, but what you eat affects how your body receives this energy'
          color={theme.palette.rgb.guide.green}
        />
        <p className={classes.center}>
          Low GI foods give you more sustained energy so you feel less hungry throughout the day.
        </p>
        <HealthyEatingTurtleAndRabbit />
        <HealthyEatingBanner
          title='Where does exercise fit in?'
          description='Regular exercise alongside a balanced diet is incredibly important for both our mental and physical health. Different exercises yield different benefits for example:'
          color={theme.palette.rgb.guide.pink}
        />
        <HealthyEatingExercises />
        <p className={classes.center}>
          Other exercises like pushups and burpees may feel hard but they’re better for building muscle than burning
          calories
        </p>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& img': {
      maxWidth: '100%'
    }
  },
  center: {
    textAlign: 'center'
  },
  headerSection: {
    ...theme.CSS.center,
    flexDirection: 'column'
  },
  header: {
    fontSize: '1.8rem',
    fontWeight: 900
  },
  happyFam: {
    margin: 'auto',
    width: '20rem'
  }
}))

export default GuideBasicsOfEatingHealthy
