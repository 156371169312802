import React, { useEffect, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
// import { getRecommendedPrograms } from '../../../../redux/actions/WelcomeSteps.actions'
import { programStats } from '../welcome_step_two/WelcomeStepTwoData'
import { setBuildANewPlan, getLastSubscription, updateSubscription } from '../../../../redux/actions/Member.actions'
import WelcomeReturnProgramCard from './WelcomeReturnProgramCard'
import WelcomeReturnStepOneContinue from './WelcomeReturnStepOneContinue'

const WelcomeReturnSelectPlan = () => {
  const {
    loading,
    // isReturnMember,
    currentSubscriptionId,
    // currentRoundId,
    lastSubscription
  } = useSelector((state) => state.member)
  const memberSummary = useSelector((state) => state.memberSummary?.memberSummary)
  // const recommendedPrograms = useSelector((state) => state.welcomeStepTwo.recommendedPrograms)
  const dispatch = useDispatch()
  const BUILD_A_NEW_PLAN = 'Build a New Plan'
  const [chosenPlan, setChosenPlan] = useState(null)
  const [lastRecordedPlan, setLastRecordedPlan] = useState(null)
  const [lastPlan, setLastPlan] = useState({
    title: '',
    value: '',
    calories: null,
    description: ''
  })

  // Look through member's subscriptions to get last subscription program type:
  useEffect(() => {
    if (memberSummary && !loading && !lastRecordedPlan) {
      const lastSubscriptionPlan = memberSummary?.subscriptions[memberSummary.subscriptions.length - 2]
      setLastRecordedPlan(lastSubscriptionPlan)
      setChosenPlan(programStats[lastSubscriptionPlan?.exercise_plan_level]?.title)
    }
  }, [memberSummary, lastRecordedPlan])

  // Once last member subscription is found, save the program data in state:
  useEffect(() => {
    if (lastRecordedPlan) {
      setLastPlan({
        ...lastPlan,
        title: programStats[lastRecordedPlan?.exercise_plan_level]?.title,
        value: lastRecordedPlan?.exercise_plan_level,
        calories: lastRecordedPlan?.verbose_recipe_calorie_level?.cals_per_day,
        description: programStats[lastRecordedPlan.exercise_plan_level]?.description
      })
    }
  }, [lastRecordedPlan])

  // I couldnt make any practical use of recommendedPrograms result:
  // useEffect(() => {
  //   if (currentSubscriptionId && currentRoundId && isReturnMember && memberSummary && !loading) {
  //     dispatch(getRecommendedPrograms(currentRoundId, memberSummary?.id, currentSubscriptionId))
  //   }
  // }, [currentSubscriptionId, currentRoundId, memberSummary, isReturnMember])

  // Call endpoint to get last subscription details (nutrition plan):
  useEffect(() => {
    if (!lastSubscription && !loading && memberSummary && lastRecordedPlan)
      dispatch(getLastSubscription(memberSummary.id, lastRecordedPlan.id))
  }, [lastSubscription, memberSummary, lastRecordedPlan])

  // On successfully retrieved nutrition plan details, save in local state:
  useEffect(() => {
    if (lastSubscription) {
      setLastPlan({
        ...lastPlan,
        defaultNutritionPlan: lastSubscription.default_nutrition_plan,
        recipeCalorieLevel: lastSubscription.recipe_calorie_level,
        nutritionProgramId: lastSubscription.nutrition_program_id
      })
    }
  }, [lastSubscription])

  const classes = useStyles()
  const theme = useTheme()

  const programCardClickHandler = (plan) => {
    setChosenPlan(plan)
  }

  const onContinueClickHandler = () => {
    // Continue with normal welcome steps sequence as if first time:
    if (chosenPlan === BUILD_A_NEW_PLAN) {
      // Update preseason progress:
      dispatch(setBuildANewPlan())
    } else {
      // User selected to continue with previous plan, update current subscription with values from previous subscription:
      const values = {
        exercise_plan_level: lastPlan.value,
        exercise_plan_name: lastPlan.title,
        default_nutrition_plan: lastPlan.defaultNutritionPlan,
        recipe_calorie_level: lastPlan.recipeCalorieLevel,
        nutrition_program_id: lastPlan.nutritionProgramId
      }
      dispatch(updateSubscription(memberSummary.id, currentSubscriptionId, values))
    }
  }
  return (
    <>
      <div className={classes.selectPlan}>
        <WelcomeReturnProgramCard
          color={theme.palette.signup.primary}
          header='CONTINUE WITH EXISTING'
          isChecked={chosenPlan === lastPlan.title}
          title={lastPlan.title}
          calories={lastPlan.calories}
          description={lastPlan.description}
          onClick={programCardClickHandler}
          index={0}
        />
        {/* <WelcomeReturnProgramCard
        color={theme.palette.signup.secondary}
        header='READY FOR CHANGE?'
        isChecked={chosenPlan === 'Recommended'}
        title={'Recommended'}
        onClick={programCardClickHandler}
        index={1}
      /> */}
        <WelcomeReturnProgramCard
          color={theme.palette.greys.mediumLight}
          header='OR'
          title={BUILD_A_NEW_PLAN}
          isChecked={chosenPlan === BUILD_A_NEW_PLAN}
          description={`Choose from over 10 different plans. We'll guide you through selecting a plan that is just right for you.`}
          onClick={programCardClickHandler}
          index={2}
        />
      </div>
      <WelcomeReturnStepOneContinue onContinueClick={onContinueClickHandler} loading={loading} />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  selectPlan: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem auto',
    width: '90%',
    minHeight: '20rem'
  }
}))

export default WelcomeReturnSelectPlan
