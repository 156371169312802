import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const TaskTabItem = ({ value, activeTab, setActiveTab }) => {
  const classes = useStyles()
  const tabItemClass = value === activeTab ? `${classes.tabItem} ${classes.activeTab}` : classes.tabItem
  const tabClickHandler = () => {
    setActiveTab(value)
  }
  return (
    <div className={tabItemClass} onClick={tabClickHandler}>
      {value}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  tabItem: {
    fontSize: '20px',
    ...theme.CSS.center,
    width: '50%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeTab: {
    borderBottom: `3px solid ${theme.palette.signup.purple}`,
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('sm')]: {
    tabItem: {
      fontSize: '18px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    tabItem: {
      fontSize: '16px'
    }
  }
}))

TaskTabItem.propTypes = {
  value: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
}

export default TaskTabItem
