import {
  GET_NEW_PRE_SEASON_DATA_REQUEST,
  GET_NEW_PRE_SEASON_DATA_SUCCESS,
  GET_NEW_PRE_SEASON_DATA_FAIL,
  SUBMIT_PRE_SEASON_TASK_REQUEST,
  SUBMIT_PRE_SEASON_TASK_SUCCESS,
  SUBMIT_PRE_SEASON_TASK_FAIL,
  DELETE_PRE_SEASON_TASK_REQUEST,
  DELETE_PRE_SEASON_TASK_SUCCESS,
  DELETE_PRE_SEASON_TASK_FAIL,
  UPDATE_PRE_SEASON_TASK_REQUEST,
  UPDATE_PRE_SEASON_TASK_SUCCESS,
  UPDATE_PRE_SEASON_TASK_FAIL,
  SUBMIT_PRE_SEASON_ACTIVITY_REQUEST,
  UPDATE_PRE_SEASON_ACTIVITY_REQUEST,
  DELETE_PRE_SEASON_ACTIVITY_REQUEST,
  UPDATE_PRE_SEASON_ACTIVITY_SUCCESS,
  DELETE_PRE_SEASON_ACTIVITY_SUCCESS,
  SUBMIT_PRE_SEASON_ACTIVITY_SUCCESS,
  SUBMIT_PRE_SEASON_ACTIVITY_FAIL,
  UPDATE_PRE_SEASON_ACTIVITY_FAIL,
  DELETE_PRE_SEASON_ACTIVITY_FAIL,
  GET_PRE_SEASON_ACTIVITY_COUNTER_FAIL,
  GET_PRE_SEASON_ACTIVITY_COUNTER_REQUEST,
  GET_PRE_SEASON_ACTIVITY_COUNTER_SUCCESS,
  GET_FITNESS_SCORE_IMPROVEMENTS_REQUEST,
  GET_FITNESS_SCORE_IMPROVEMENTS_SUCCESS,
  GET_FITNESS_SCORE_IMPROVEMENTS_FAIL,
  GET_FITNESS_SCORE_RESULT_REQUEST,
  GET_FITNESS_SCORE_RESULT_SUCCESS,
  GET_FITNESS_SCORE_RESULT_FAIL,
  UPDATE_DIFFICULTY_TYPE_REQUEST,
  UPDATE_DIFFICULTY_TYPE_SUCCESS,
  UPDATE_DIFFICULTY_TYPE_FAIL
} from '../constants/PreSeason.constants'

export const newPreSeasonReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_NEW_PRE_SEASON_DATA_REQUEST:
    case SUBMIT_PRE_SEASON_TASK_REQUEST:
    case DELETE_PRE_SEASON_TASK_REQUEST:
    case UPDATE_PRE_SEASON_TASK_REQUEST:
    case SUBMIT_PRE_SEASON_ACTIVITY_REQUEST:
    case UPDATE_PRE_SEASON_ACTIVITY_REQUEST:
    case DELETE_PRE_SEASON_ACTIVITY_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_NEW_PRE_SEASON_DATA_SUCCESS:
    case DELETE_PRE_SEASON_TASK_SUCCESS:
    case UPDATE_PRE_SEASON_TASK_SUCCESS:
    case UPDATE_PRE_SEASON_ACTIVITY_SUCCESS:
    case DELETE_PRE_SEASON_ACTIVITY_SUCCESS: {
      return {
        ...state,
        preseason: payload,
        loading: false
      }
    }
    case SUBMIT_PRE_SEASON_TASK_SUCCESS:
    case SUBMIT_PRE_SEASON_ACTIVITY_SUCCESS: {
      return {
        ...state,
        preseason: payload,
        taskSuccess: true,
        loading: false
      }
    }
    case GET_NEW_PRE_SEASON_DATA_FAIL:
    case SUBMIT_PRE_SEASON_TASK_FAIL:
    case SUBMIT_PRE_SEASON_ACTIVITY_FAIL:
    case UPDATE_PRE_SEASON_ACTIVITY_FAIL:
    case DELETE_PRE_SEASON_ACTIVITY_FAIL:
    case DELETE_PRE_SEASON_TASK_FAIL:
    case UPDATE_PRE_SEASON_TASK_FAIL: {
      return {
        ...state,
        taskSuccess: false,
        taskFailed: true,
        loading: false
      }
    }
    default:
      return state
  }
}

export const newPreSeasonActivityReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PRE_SEASON_ACTIVITY_COUNTER_REQUEST: {
      return { loading: true }
    }
    case GET_PRE_SEASON_ACTIVITY_COUNTER_SUCCESS: {
      return { loading: false, activityCount: payload }
    }
    case GET_PRE_SEASON_ACTIVITY_COUNTER_FAIL: {
      return {
        loading: false
      }
    }
    default:
      return state
  }
}

export const fitnessTestReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_FITNESS_SCORE_IMPROVEMENTS_REQUEST:
    case UPDATE_DIFFICULTY_TYPE_REQUEST:
    case GET_FITNESS_SCORE_RESULT_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_FITNESS_SCORE_IMPROVEMENTS_SUCCESS: {
      return { ...state, loading: false, fitnessTestImprovements: payload }
    }
    case GET_FITNESS_SCORE_RESULT_SUCCESS: {
      return { ...state, loading: false, fitnessTestScoreResult: payload }
    }
    case UPDATE_DIFFICULTY_TYPE_SUCCESS: {
      return { ...state, loading: false }
    }
    case UPDATE_DIFFICULTY_TYPE_FAIL:
    case GET_FITNESS_SCORE_IMPROVEMENTS_FAIL:
    case GET_FITNESS_SCORE_RESULT_FAIL: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
}
