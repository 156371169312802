import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../components/ContentContainer'
import { tasks, FITNESS_TEST_AND_MEASURE_UP } from './TaskData'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useSelector } from 'react-redux'

const TaskFooter = ({ moreTasks }) => {
  const { roundInfo } = useSelector((state) => state.round)
  const [fitnessTaskLocked, setFitnessTaskLocked] = useState(true)
  const classes = useStyles()
  if (!moreTasks) return null

  // Fitness Task & measure up should only be viewable 1 week before round start:
  useEffect(() => {
    const duration = moment.duration(moment(roundInfo?.starts_at).diff(moment()))
    const days = Math.floor(duration.asDays())
    if (days <= 7 && roundInfo) {
      setFitnessTaskLocked(false)
    }
  }, [])

  return (
    <div className={classes.taskFooterContainer}>
      <ContentContainer maxWidth={'lg'}>
        <div className={classes.linkContainer}>
          <span className={classes.taskHeader}>More Tasks</span>
          {moreTasks.map((task, i) => {
            // Fitness Task & measure up should only be viewable 1 week before round start:
            if (task === FITNESS_TEST_AND_MEASURE_UP && fitnessTaskLocked) return null
            return (
              <Link to={task} key={i} className={classes.link}>
                <span>{tasks[task]?.header}</span>
              </Link>
            )
          })}
        </div>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  taskFooterContainer: {
    height: '388px',
    backgroundColor: '#F9FAFB'
  },
  link: {
    textDecoration: 'none',
    color: '#2563EB',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    margin: '7px 0'
  },
  taskHeader: {
    fontWeight: 800,
    fontSize: '30px',
    marginBottom: '16px'
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '731px',
    paddingTop: '96px'
  },
  [theme.breakpoints.down('md')]: {
    taskFooterContainer: {
      padding: '1rem'
    },
    linkContainer: {
      width: '100%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    taskFooterContainer: {
      height: '300px'
    },
    linkContainer: {
      paddingTop: '40px'
    },
    taskHeader: {
      fontSize: '25px'
    },
    link: {
      fontSize: '18px'
    }
  }
}))

TaskFooter.propTypes = {
  moreTasks: PropTypes.array
}

export default TaskFooter
