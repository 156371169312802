import React from 'react'

export const faqData = [
  {
    header: 'Are my details safe?',
    content: (
      <p>
        Yes, your details are safe. All information is encrypted using SSL to provide you with the safest, most secure
        purchasing experience.
      </p>
    )
  },
  {
    header: 'What happens after I order?',
    content: (
      <p>
        First, your payment is processed. As soon as your payment is approved you will have access to 12WBT and receive
        an email welcoming you aboard.
      </p>
    )
  },
  {
    header: 'When will I have access to the site?',
    content: <p>You will have access to 12WBT as soon as your payment is processed.</p>
  },
  {
    header: 'I forgot my password',
    content: (
      <p>
        Not to worry. You can always&nbsp;
        <b>
          <a href={`${process.env.REACT_APP_GO_SITE}password/reset`}>reset your password.</a>
        </b>
      </p>
    )
  },
  {
    header: `What is the 12WBT's cancellation policy?`,
    content: (
      <p>
        As per our terms and conditions we do not accept cancellations or issue refunds however, if there is a
        legitimate reason or an extenuating circumstance for not being able to continue with your membership then please
        contact the support crew at &nbsp;
        <br />
        <b>
          <a>support@12wbtsupport.com.</a>
        </b>
      </p>
    )
  },
  {
    header: `Can I access all content with my device/browser?`,
    content: <p>We try to support as many browsers and operating systems as possible.</p>
  }
]

export default faqData
