import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { activityCardsData } from './PreSeasonData'
import ActivityCard from './ActivityCard'
import ContentContainer from '../../../components/ContentContainer'
import PreSeasonHeader from './PreSeasonHeader'

const PreSeasonActivities = () => {
  const classes = useStyles()

  return (
    <div className={classes.background}>
      <ContentContainer maxPixels={1400}>
        <PreSeasonHeader
          title='Activities'
          description='Throughout Pre-Season we want you to be as prepared as possible so that your start to the round is seamless! 
          Completing these activities will help you get warmed up and in the zone for the start of the round. '
        />
        <div></div>
        <div className={classes.gridContainer}>
          <Grid container spacing={3}>
            {activityCardsData.map((activity, i) => {
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <ActivityCard
                    title={activity.title}
                    description={activity.description}
                    icon={activity.icon}
                    link={activity.link}
                  />
                </Grid>
              )
            })}
          </Grid>
        </div>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: '#E4E7EA'
  },
  gridContainer: {
    marginTop: theme.paddings.md,
    margin: 'auto'
  }
}))

export default PreSeasonActivities
