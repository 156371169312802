import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const HelperText = ({ message }) => {
  const classes = useStyles()
  return <span className={classes.text}>{message}</span>
}

const useStyles = makeStyles((theme) => ({
  text: { color: '#EF5353', marginTop: '14px', fontSize: '.75rem', textAlign: 'left', marginLeft: '14px' }
}))

HelperText.propTypes = {
  message: PropTypes.string.isRequired
}

export default HelperText
