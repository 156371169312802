import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const TaskSectionHeader = ({ title }) => {
  const classes = useStyles()
  return (
    <div className={classes.sectionHeaderContainer}>
      <span className={classes.sectionHeader}>{title}</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionHeaderContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  sectionHeader: {
    fontSize: '36px',
    fontWeight: 800,
    margin: '2rem auto'
  },
  [theme.breakpoints.down('sm')]: {
    sectionHeader: {
      margin: '1rem auto',

      fontSize: '30px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    sectionHeader: {
      fontSize: '25px',
      margin: '.5rem auto',
      marginTop: '1rem'
    }
  }
}))

TaskSectionHeader.propTypes = {
  title: PropTypes.string.isRequired
}

export default TaskSectionHeader
