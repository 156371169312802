import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Link } from 'react-router-dom'

const ActivityCard = ({ title, description, icon, link }) => {
  const classes = useStyles()
  return (
    <div className={classes.cardContainer}>
      <div className={classes.iconContainer}>{icon}</div>
      <div className={classes.cardContent}>
        <span className={classes.title}>{title}</span>
        <span className={classes.description}>{description}</span>
      </div>
      <Link className={classes.cardFooter} to={`/pre-season/activities/${link}`}>
        <span>Take part</span>
        <ArrowForwardIcon />
      </Link>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: 'relative',
    backgroundColor: 'white',
    marginBottom: theme.paddings.md
  },
  iconContainer: {
    position: 'absolute',
    backgroundColor: '#CC5FED',
    width: '88px',
    height: '88px',
    top: '-44px',
    left: '24px',
    borderRadius: '50%',
    ...theme.CSS.center,
    border: '5px solid white',
    '& svg': {
      width: '50px',
      height: '50px',
      color: 'white'
    }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '210px',
    padding: '0 24px'
  },
  title: {
    color: '#111827',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    marginTop: '64px'
  },
  description: {
    marginTop: '4px',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#4B5563'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    bottom: 0,
    backgroundColor: '#F9FAFB',
    textDecoration: 'none',
    '& span': {
      color: theme.palette.signup.purple,
      fontSize: '16px',
      lineHieght: '24px'
    },
    '& svg': {
      marginLeft: '.5rem',
      color: theme.palette.signup.purple
    },
    height: '72px',
    ...theme.CSS.center,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  [theme.breakpoints.down('lg')]: {
    cardContent: {
      height: '230px'
    }
  },
  [theme.breakpoints.down('md')]: {
    title: {
      fontSize: '20px',
      lineHeight: '24px',
      marginTop: '50px'
    },
    cardContent: {
      height: '230px'
    },
    description: {
      fontSize: '16px'
    },
    cardFooter: {
      height: '60px'
    },
    iconContainer: {
      width: '60px',
      height: '60px',
      top: '-30px',
      '& svg': {
        width: '40px',
        height: '40px'
      }
    }
  },
  [theme.breakpoints.down(830)]: {
    cardContent: {
      height: '250px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    cardContent: {
      height: '300px'
    },
    description: {
      fontSize: '15px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    cardContainer: {
      margin: '1rem'
    },
    cardContent: {
      height: '220px'
    }
  },
  [theme.breakpoints.down(410)]: {
    cardContent: {
      height: '290px'
    },
    description: {
      fontSize: '.9rem'
    }
  }
}))

ActivityCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired
}

export default ActivityCard
