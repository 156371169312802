export const overallGoalOptions = [
  { label: 'Increase strength and tone', value: 'Increase strength and tone' },
  { label: 'Increase cardio fitness', value: 'Increase cardio fitness' },
  { label: 'Lose 30+ kg', value: 'Lose 30+ kg' },
  { label: 'Lose 15-30 kg', value: 'Lose 15-30 kg' },
  { label: 'Lose 5-15 kg', value: 'Lose 5-15 kg' },
  { label: 'Lose less than 5kg', value: 'Lose less than 5kg' }
]

// export const goalSettingFormValidation = () => {
//   return yup.object({
//     internalExcuses: yup.string(),
//     internalExcusesSolutions: yup.string(),
//     externalExcusesWithMyControl: yup.string(),
//     externalExcusesWithMyControlSolutions: yup.string(),
//     externalExcusesOutsideMyControl: yup.string(),
//     externalExcusesOutsideMyControlSolutions: yup.string(),
//     whatsYourWhy: yup.string().required(),
//     whatsYourOverallGoal: yup.string().required(),
//     week4Goals: yup.string().required(),
//     week4GoalsHowWillIGetThere: yup.string().required(),
//     week4GoalsReward: yup.string().required(),
//     week8Goals: yup.string().required(),
//     week8GoalsHowWillIGetThere: yup.string().required(),
//     week8GoalsReward: yup.string().required(),
//     week12Goals: yup.string().required(),
//     week12GoalsHowWillIGetThere: yup.string().required(),
//     week12GoalsReward: yup.string().required(),
//     sixMonthsGoals: yup.string(),
//     sixMonthsGoalsHowWillIGetThere: yup.string(),
//     sixMonthsGoalsReward: yup.string(),
//     twelveMonthsGoals: yup.string(),
//     twelveMonthsGoalsHowWillIGetThere: yup.string(),
//     twelveMonthsGoalsReward: yup.string()
//   })
// }
