import { useEffect } from 'react'
import useQuery from '../../../hooks/UseQuery'
import {
  getPricePoints,
  validateCoupon
} from '../../../../redux/actions/Signup.actions'
import { useDispatch, useSelector } from 'react-redux'

const PricePointsHandler = () => {
  const dispatch = useDispatch()
  const { utm_coupon: couponCode } = useQuery(['utm_coupon'])
  const { programs, loading } = useSelector((state) => state.signupPrograms)

  const onValidCoupon = () => dispatch(getPricePoints(couponCode))
  const onInvalidCoupon = () => dispatch(getPricePoints())

  // Get price points on component load:
  useEffect(() => {
    if (!loading && !programs) {
      // Check if coupon code exists:
      if (couponCode)
        dispatch(validateCoupon(couponCode, onValidCoupon, onInvalidCoupon))
      else {
        dispatch(getPricePoints())
      }
    }
  }, [couponCode])
}

export default PricePointsHandler
