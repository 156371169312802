import React from 'react'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../../components/ContentContainer'
import GoalSettingForm from './GoalSettingForm'
import TaskContentSubHeader from '../TaskContentSubHeader'
import TaskContentContainer from '../TaskContentContainer'
import { tasks, SET_YOURSELF_UP_FOR_SUCCESS } from '../TaskData'
import { getPreSeasonTask } from '../../../../helpers/GetPreSeasonTask'
import TaskCompletedBanner from '../TaskCompletedBanner'

import { useDispatch, useSelector } from 'react-redux'
import { deletePreSeasonTask } from '../../../../../redux/actions/PreSeason.actions'

const SetYourselfUpForSuccessDoTask = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { currentSubscriptionId } = useSelector((state) => state.member)
  const { loading } = useSelector((state) => state.newPreSeason)
  const taskTitle = tasks[SET_YOURSELF_UP_FOR_SUCCESS].header
  const task = getPreSeasonTask(taskTitle)
  const isComplete = task

  const onUndoClickHandler = () => {
    if (!loading) dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  return (
    <ContentContainer maxWidth='xl'>
      <div className={classes.container}>
        <TaskContentContainer>
          {isComplete && <TaskCompletedBanner onUndoClick={onUndoClickHandler} />}
          <span className={classes.header}>Part 1: My Excuses and Their Solutions</span>
          <TaskContentSubHeader title='How this works' />
          <ol>
            <li>
              <p>
                List your excuses below and write down solutions for each one. For example-for
                <em>&quot;I&apos;m unfit&quot;</em>, a solution would be
                <em>
                  &quot;aim to do the best that I can and work at my own pace. If I’m consistent, I will improve and I
                  have to start somewhere&quot;.
                </em>
              </p>
            </li>
            <li>
              <p>
                Think about <em>&apos;what got you here and what&apos;s stopping you from making progress?&apos;</em>
              </p>
            </li>
            <li>
              <p>
                From these excuses, list the common ones and know that you have full accountability and complete control
                of the decisions you make in all situations.
              </p>
            </li>
          </ol>
          <GoalSettingForm />
        </TaskContentContainer>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '60px auto'
  },
  header: {
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('md')]: {
    header: {
      fontSize: '1.3rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    header: {
      fontSize: '1.2rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      fontSize: '1.15rem'
    }
  }
}))

export default SetYourselfUpForSuccessDoTask
