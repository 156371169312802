import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const UseQuery = (paramsArray) => {
  const query = new URLSearchParams(useLocation().search)
  const queryObj = {}

  paramsArray.forEach((param) => {
    queryObj[param] = query.get(param)
  })
  return queryObj
}

UseQuery.propTypes = {
  paramsArray: PropTypes.array
}

export default UseQuery
