import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Divider, makeStyles } from '@material-ui/core'
import WhiteCard from '../../../components/WhiteCard'
import { getMealPlans } from './WelcomeStepTwoData'
import WelcomeSelect from './WelcomeSelect'
import {useSelector} from "react-redux";
import moment from "moment/moment";

const WelcomeStepTwoSelectMealPlan = ({ formik, collapsed }) => {
  const classes = useStyles()
  const [chosenMealPlan, setChosenMealPlan] = useState('Classic')
  const [isVegetarian, setIsVegetarian] = useState(false)
  const [cookingForOne, setCookingForOne] = useState(false)
  const { profile } = useSelector((state) => state.profile)

  useEffect(() => {
    let mealPlan = chosenMealPlan
    let amountCookingFor = ' - Cooking for 2'
    if (isVegetarian) mealPlan += ' Vegetarian'
    if (cookingForOne) amountCookingFor = ' - Cooking for 1'
    mealPlan += amountCookingFor
    formik.setFieldValue('default_nutrition_plan', mealPlan, false)
  }, [chosenMealPlan, isVegetarian, cookingForOne])

  const chosenProgram = formik.values.exercise_plan_level
  const isFemale = profile?.gender === 'F' || localStorage.getItem('gender') === 'Female'
  const birthday = profile?.birthday || moment(localStorage.getItem('dob'), 'YYYY-M-D')
  const age = moment().diff(birthday, 'years', false)
  // const over40s = moment().diff(birthday, 'years', false) >= 40

  return (
    <WhiteCard margin='1rem auto' withShadow={true}>
      <div className={classes.container}>
        <div className={classes.title}>
          <span>Meal Preference</span>
        </div>
        <div className={classes.mealPlanSection}>
          {getMealPlans(age, isFemale, chosenProgram).map((plan, i) => {
            // return !plan.program || plan.program === chosenProgram ? (
            return (
                <div key={i} className={classes.selectRow}>
                  {i !== 0 && <Divider className={classes.divider} />}
                  <WelcomeSelect
                      onClick={() => {
                        setChosenMealPlan(
                            chosenMealPlan === plan.value ? 'Classic' : plan.value
                        )
                      }}
                      label={plan.title}
                      description={plan.description}
                      checked={plan.value === chosenMealPlan}
                  />
                </div>
            )
          })}
        </div>
        <div className={classes.selectRow}>
          <Divider className={classes.divider} />
          <WelcomeSelect
            onClick={() => {
              setIsVegetarian(!isVegetarian)
            }}
            label="I'm a vegetarian"
            description='All recipes and snacks are vegetarian.'
            checked={isVegetarian}
          />
        </div>
        <div className={classes.selectRow}>
          <Divider className={classes.divider} />
          <WelcomeSelect
            onClick={() => {
              setCookingForOne(!cookingForOne)
            }}
            label="I'm cooking for one"
            description='Recipes are adjusted to single serve to reduce food waste.'
            checked={cookingForOne}
          />
        </div>
      </div>
    </WhiteCard>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  mealPlanSection: {
    marginTop: '1.5rem'
  },
  checkboxRow: {
    margin: '1rem auto'
  },
  selectRow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  divider: {
    margin: '1rem 1rem',
    backgroundColor: theme.palette.greys.medium
  }
}))

WelcomeStepTwoSelectMealPlan.propTypes = {
  formik: PropTypes.object.isRequired,
  collapsed: PropTypes.bool
}

export default WelcomeStepTwoSelectMealPlan
