import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ContentContainer from '../../../components/ContentContainer'
import ActivityHeaderActions from './ActivityHeaderActions'
import { getPreSeasonActivityParticipants } from '../../../helpers/GetPreSeasonActivityParticipants'
import { useSelector } from 'react-redux'

const ActivityHeader = ({ header, intro, slug }) => {
  const preseasonLoading = useSelector((state) => state.newPreSeasonActivity?.loading)
  const classes = useStyles()
  const activity = getPreSeasonActivityParticipants(header)
  return (
    <div className={classes.container}>
      <Link className={classes.backButton} to='/pre-season'>
        <ArrowBackIosIcon />
        <span className={classes.buttonText}>Pre-season home</span>
      </Link>
      <ContentContainer maxPixels={1400}>
        <div className={classes.headerContent}>
          <div className={classes.headerDiv}>
            <span className={classes.activity}>ACTIVITY</span>
            <h2 className={classes.title}>{header}</h2>
            <div className={classes.descriptionContent}>
              <span className={classes.description}>{intro}</span>
            </div>
          </div>
          <div className={classes.countMeInDiv}>
            <Grid container className={classes.countMeInDivInner}>
              <Grid item xs={12} sm={4}>
                <div className={classes.infoGraphic}>
                  {preseasonLoading ? (
                    <CircularProgress className={classes.headerSpinner} size={30} />
                  ) : (
                    <span className={classes.boldNumber}>{activity?.participants || 0}</span>
                  )}
                  <span className={classes.info}>Participants</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={classes.infoGraphic}>
                  {preseasonLoading ? (
                    <CircularProgress className={classes.headerSpinner} size={30} />
                  ) : (
                    <span className={classes.boldNumber}>{activity?.completed || 0}</span>
                  )}
                  <span className={classes.info}>Completed</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div style={{ height: '100%' }}>
                  <ActivityHeaderActions header={header} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#CC5FED',
    paddingBottom: '6rem'
  },
  backButton: {
    display: 'flex',
    ...theme.CSS.center,
    position: 'absolute',
    left: '16px',
    top: '16px',
    color: 'white',
    textDecoration: 'none'
  },
  buttonText: {
    marginLeft: '8px',
    fontSize: '14px',
    fontWeight: 500
  },
  headerDiv: {
    textAlign: 'center',
    color: 'white',
    paddingTop: '96px'
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
    lineHeight: '60px',
    margin: '5px auto',
    marginBottom: '20px'
  },
  description: {
    fontSize: '20px',
    linHeight: '28px',
    fontWeight: 400
  },
  descriptionContent: {
    maxWidth: '60%',
    margin: 'auto'
  },
  activity: {
    fontSize: '24px',
    linHeight: '24px',
    fontWeight: 600
  },
  countMeInDiv: {
    position: 'absolute',
    bottom: '-63px',
    minHeight: '90px',
    padding: '.5rem',
    width: '800px',
    margin: 'auto',
    backgroundColor: 'white',
    borderRadius: '5px',
    filter: `drop-shadow(0 0 0.1rem ${theme.palette.greys.main})`
  },
  countMeInDivInner: {
    width: '100%',
    minHeight: '113px',
    ...theme.CSS.center
  },
  headerContent: {
    ...theme.CSS.center,
    margin: 'auto'
  },
  infoGraphic: {
    ...theme.CSS.center,
    flexDirection: 'column'
  },
  boldNumber: {
    fontSize: '36px',
    lineHeight: '40px',
    fontWeight: 800,
    color: '#CC5FED'
  },
  info: {
    color: '#6B7280',
    fontWeight: 500,
    fontSize: '18px'
  },
  headerSpinner: {
    color: '#CC5FED',
    marginBottom: '1rem'
  },
  [theme.breakpoints.down('md')]: {
    descriptionContent: {
      maxWidth: '100%'
    },
    headerDiv: {
      paddingTop: '75px',
      maxWidth: '80%'
    },
    title: {
      fontSize: '50px'
    },
    countMeInDiv: {
      width: '700px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '40px',
      lineHeight: '38px',
      margin: '1rem auto'
    },
    headerDiv: {
      maxWidth: '75%'
    },
    countMeInDiv: {
      width: '550px'
    },
    activity: {
      fontSize: '20px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    headerDiv: {
      maxWidth: '70%'
    },
    title: {
      fontSize: '35px',
      margin: '1rem auto'
    },
    description: {
      fontSize: '1rem'
    },
    countMeInDiv: {
      width: '90%',
      minHeight: '200px',
      bottom: '-135px'
    },
    countMeInDivInner: {
      minHeight: '200px'
    },
    boldNumber: {
      fontSize: '30px'
    },
    info: {
      fontSize: '16px'
    }
  },
  [theme.breakpoints.down(480)]: {
    headerDiv: {
      maxWidth: '90%'
    }
  },
  [theme.breakpoints.down(380)]: {
    title: {
      fontSize: '30px'
    },
    headerDiv: {
      maxWidth: '95%'
    }
  }
}))

ActivityHeader.propTypes = {
  header: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired
}

export default ActivityHeader
