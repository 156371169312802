import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'

const SectionTabs = ({ tabsArray, activeTab, setTabActive }) => {
  const classes = useStyles()

  const tabClass = (tab) => {
    if (tab === activeTab) return `${classes.tab} ${classes.activeTab}`
    return classes.tab
  }
  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {tabsArray.map((tab, i) => {
          return (
            <Grid item xs={12} sm={4} key={i} onClick={() => setTabActive(tab)}>
              <div className={tabClass(tab)}>
                <span>
                  &nbsp;&nbsp;{i + 1}. {tab}
                </span>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '4rem'
  },
  tab: {
    width: '100%',
    backgroundColor: '#5164E9',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 700,
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeTab: {
    color: '#5164E9',
    fontWeight: 900,
    backgroundColor: theme.palette.greys.light
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      padding: '.5rem',
      marginBottom: 0
    }
  }
}))

SectionTabs.propTypes = {
  tabsArray: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  setTabActive: PropTypes.func.isRequired
}

export default SectionTabs
