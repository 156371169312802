import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import TaskContentContainer from '../../TaskContentContainer'
import TaskVideo from '../../TaskVideo'
import NextTaskLink from '../NextTaskLink'

import navigateToGoSite from '../../../../../helpers/NavigateToGoSite'

const FitnessTestCoreStrength = ({ nextTaskClick, nextTask }) => {
  return (
    <TaskContentContainer>
      <TaskContentSubHeader title='Core Strength' />
      <TaskVideo videoId='3082265606001' needsCenterAdjust={true} />
      <p>
        What does this measure?
        <br />
        The Plank Test is a measurement of your core strength and endurance. In this test you will hold a plank for as
        long as possible, it is important you maintain correct posture the whole time for safety and accuracy. If you
        lose correct form the test is concluded at that point. Use a mirror or another set of eyes to ensure you
        maintain perfect form!
      </p>
      <p>
        Prep Yourself
        <br />
        To plank correctly, start laying face down on the floor and place your elbows directly under your shoulders
        avoiding hunching them towards your ears. Draw your belly button in towards your spine and maintain a steady
        breathing pattern. When you’re ready to begin timing, raise your body off the ground and pull your chin in to
        ensure a neutral position, keep your gaze on your hands to maintain this. Your body should be straight like a
        plank so your hips should be just slightly below your shoulders.
      </p>
      <span>Choose your intensity:</span>
      <ol>
        <li>
          For a low intensity option, perform an elevated plank with arms on a chair or bench. Once you can hold this
          for 4 minutes you should move to planks on your knees.
        </li>
        <li>
          For a medium intensity option, plank from your knees. Similarly once you can hold this for 4 minutes you
          should move to planks on your toes.
        </li>
        <li>For the most advanced option, plank on your toes.</li>
      </ol>
      <p>
        Make sure you enter your results against the RIGHT test! Click on the highlighted blue test names in the results
        section below to select the intensity option you chose.
      </p>
      <p>Ready, Set…Go!</p>
      <p>
        Tuck your tailbone under very slightly by clenching your butt cheeks together and tilting your hips forward
        slightly. Once you’ve done all this, start the stopwatch and hold on!
      </p>
      <p>The test ends when you can’t hold perfect form or you stand up.</p>
      <span>For demo&apos;s on how to perform these exercises, take a look here;</span>
      <ol>
        <li>
          <a href={navigateToGoSite('exercises/278-elevated-plank')}>Plank (Elevated)</a>
        </li>
        <li>
          <a href={navigateToGoSite('exercises/98-plank-on-knees-or-toes')}>Plank (Knees) and Plank (Toes)</a>
        </li>
      </ol>
      <NextTaskLink link='Aerobic Capacity' onClick={() => nextTaskClick(nextTask)} />
    </TaskContentContainer>
  )
}

FitnessTestCoreStrength.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default FitnessTestCoreStrength
