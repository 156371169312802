export const GET_MOVING = 'get-moving'
export const BUILDING_YOUR_INNER_CORE_STRENGTH = 'building-your-inner-core-strength'
export const MAKING_MORE_ME_TIME = `making-more-me-time`
export const BETTER_THAN_YESTERDAY = 'better-than-yesterday'

export const activities = {
  [GET_MOVING]: {
    header: 'Get Moving',
    intro:
      'This activity is all about getting you used to moving, whether it be incidental like taking the stairs instead of the lift or committing to a solid walk each day.'
  },
  [BUILDING_YOUR_INNER_CORE_STRENGTH]: {
    header: 'Building Your Inner Core Strength',
    intro:
      'A structure is only as strong as its foundations, so building your deep core and pelvic floor muscles is essential. This activity will take you through a core routine you can practice before the round starts.'
  },
  [MAKING_MORE_ME_TIME]: {
    header: 'Making More ‘Me’ Time',
    intro:
      'This activity is all about self-assessment to determine what you actually need the most to be kind to your body and function at your very best.'
  },
  [BETTER_THAN_YESTERDAY]: {
    header: 'Better than yesterday',
    intro:
      'This activity is all about celebrating consistent progress throughout all of the exercise and nutrition that you tackle through your Round.'
  }
}
