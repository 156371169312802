import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import PreSeasonHeader from './PreSeasonHeader'
import ContentContainer from '../../../components/ContentContainer'
import { guideTips } from './PreSeasonData'
import GuideTip from './GuideTip'

const PreSeasonGuide = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ContentContainer maxPixels={1400}>
        <PreSeasonHeader
          title='Your 12WBT Guides'
          description='Need a little help getting up to speed with all things 12WBT? These useful guides will help you!'
        />
        <div className={classes.guideTipsSection}>
          <Grid container>
            {guideTips.map((tip, i) => {
              return (
                <Grid item xs={12} sm={6} key={i}>
                  <GuideTip title={tip.title} description={tip.description} icon={tip.icon} link={tip.link} />
                </Grid>
              )
            })}
          </Grid>
        </div>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F9FAFB'
  },
  guideTipsSection: {
    marginBottom: '1rem'
  },
  [theme.breakpoints.down('sm')]: {
    guideTipsSection: {
      margin: '0 1rem'
    }
  }
}))

export default PreSeasonGuide
