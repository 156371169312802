import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import blueLogo from '../../assets/img/logos/12WBT-Blue.png'
// import { Link } from 'react-router-dom'
import appleBadge from "../../assets/img/logos/apple-store-badge.png";
import googleBadge from "../../assets/img/logos/google-play-badge-changed.png";
import FB from "../../assets/img/logos/FB.png";
import Insta from "../../assets/img/logos/Insta.png";
// import Tiktok from "../../assets/img/logos/TikTok.png";


const Footer = () => {
  const classes = useStyles();
  const [is775pxScreen, setIs775pxScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIs775pxScreen(window.innerWidth < 775);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
      <div className={classes.footerContainer}>
        <Grid container className={classes.grid} style={{ marginTop: '17px', width: 'auto' }}>
          {is775pxScreen ? (
              <>
                <Grid item md={12} className={classes.gridItem}>
                  <div className={classes.footerLogo}><img src={blueLogo} alt='12WBT Logo' className={classes.logo} /></div>
                  <div className={classes.socialContainer}>
                    <span><a href='https://www.facebook.com/12wbt'><img src={FB} alt='FB Logo' className={classes.sociaLogo} /></a></span>
                    <span><a href='https://www.instagram.com/12wbt'><img src={Insta} alt='Insta Logo' className={classes.sociaLogo} /></a></span>
                  </div>
                </Grid>
                <Grid item md={12} className={classes.gridItem + ' ' + classes.storeLogoContainer}>
                  <div className={classes.storeDiv}>
                    <a href={process.env.REACT_APP_APPLE_STORE}>
                      <img className={classes.badge + ' ' + classes.appStoreImg} src={appleBadge} alt='Apple store' />
                    </a>
                  </div>
                  <div className={classes.storeDivApp}>
                    <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE}>
                      <img className={classes.badge + ' ' + classes.playStoreImg} src={googleBadge} alt='Google store' />
                    </a>
                  </div>
                </Grid>
                <Grid item md={12} className={classes.gridItem + ' ' + classes.contentStyling} style={{marginTop: '15px'}}>
                  <div className={classes.footerContent}>
                <span>
                  Consult your healthcare professional before beginning any diet or fitness regime.
                  <br />
                  Copyright © {new Date().getFullYear()} 12WBT Trading Pty. Ltd.
                </span>
                    <div className={classes.siteLinks}>
                      <a href='https://www.12wbt.com/blog'>Blog</a>|
                      <a href='https://www.12wbt.com/faqs'>FAQs</a>|
                      <a href='https://help.12wbt.com/hc/en-us/requests/new?_ga=2.34620065.1221875568.1711061104-1950777304.1711061104'>Contact Us</a>|
                      <a href='https://www.12wbt.com/terms'>Terms</a>|<a href='https://www.12wbt.com/privacy'>Privacy Policy</a>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                      *Results may vary. Exercise and proper diet are necessary to achieve and maintain weight loss.
                    </div>
                  </div>
                </Grid>
              </>
          ) : (
              <>
                <Grid item sm={12} md={2} className={classes.gridItem + ' ' + classes.logoContainer} style={{width: '650px'}}>
                  <div><img src={blueLogo} alt='12WBT Logo' className={classes.logo} /></div>
                  <div className={classes.socialContainer}>
                    <span><a href='https://www.facebook.com/12wbt'><img src={FB} alt='FB Logo' className={classes.sociaLogo} /></a></span>
                    <span><a href='https://www.instagram.com/12wbt'><img src={Insta} alt='Insta Logo' className={classes.sociaLogo} /></a></span>
                  </div>
                </Grid>
                <Grid item sm={12} md={8} className={classes.gridItem + ' ' + classes.contentStyling}>
                  <div className={classes.footerContent} style={{ width: '93%' }}>
                <span>
                  Consult your healthcare professional before beginning any diet or fitness regime.
                  <br />
                  Copyright © {new Date().getFullYear()} 12WBT Trading Pty. Ltd.
                </span>
                    <div className={classes.siteLinks}>
                      <a href='https://www.12wbt.com/blog'>Blog</a>|
                      <a href='https://www.12wbt.com/faqs'>FAQs</a>|
                      <a href='https://help.12wbt.com/hc/en-us/requests/new?_ga=2.34620065.1221875568.1711061104-1950777304.1711061104'>Contact Us</a>|
                      <a href='https://www.12wbt.com/terms'>Terms</a>|<a href='https://www.12wbt.com/privacy'>Privacy Policy</a>
                    </div>
                    <div style={{ marginTop: '7px' }}>
                      *Results may vary. Exercise and proper diet are necessary to achieve and maintain weight loss.
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={2} className={classes.gridItem + ' ' + classes.storeContainer}>
                  <div className={classes.storeDiv}>
                    <a href={process.env.REACT_APP_GOOGLE_PLAY_STORE}>
                      <img className={classes.badge + ' ' + classes.playStoreImg} src={googleBadge} alt='Google store' />
                    </a>
                  </div>
                  <div className={classes.storeDiv}>
                    <a href={process.env.REACT_APP_APPLE_STORE}>
                      <img className={classes.badge + ' ' + classes.appStoreImg} src={appleBadge} alt='Apple store' />
                    </a>
                  </div>
                </Grid>
              </>
          )}
        </Grid>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  footerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '75px'
  },
  contentStyling: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '1.5em'
  },
  grid: {
    margin: 'auto',
    // width: '90%'
  },
  footerContainer: {
    bottom: 0,
    minHeight: '8rem',
    // width: '80%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    color: '#999',
    textAlign: 'left',
    backgroundColor: '#FFFFFF',
    marginTop: '35px',
    '& a': {
      color: '#007bff',
      margin: '0x 5px'
    },
    '& p': {
      marginBottom: 0
    },
    ...theme.CSS.center
  },
  logo: {
    width: '187px',
    height: '45px'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      marginRight: '15px'
    },
    ...theme.CSS.center
  },
  siteLinks: {
    marginTop: '7px',
    '& :first-child': {
      marginLeft: '0px'
    },
    '& a': {
      margin: '0px 5px',
      textDecoration: 'none'
    },
    position: 'relative',
    display: 'inline-block'
  },
  badge: {
    // height: '45%',
    // width: '55%',
    margin: '0 1rem'
  },
  sociaLogo: {
    // height: '21px'
  },
  socialContainer: {
    marginTop: '17px',
    width: '196px'
  },
  storeDiv: {
    marginLeft: '31px'
  },
  playStoreImg: {
    width: '155px',
    height: '45px'
  },
  appStoreImg: {
    width: '155px',
    height: '51px'
  },
  storeLogoContainer: {
    marginTop: '9px'
  },
  [theme.breakpoints.down(1135)]: {
    grid: {
      width: '95% !important'
    }
  },
  [theme.breakpoints.down(1102)]: {
    logoContainer: {
      marginTop: '-30px'
    },
    storeContainer: {
      marginTop: '-30px'
    }
  },
  [theme.breakpoints.down(1038)]: {
    logoContainer: {
      marginTop: '-55px'
    },
    storeContainer: {
      marginTop: '-55px'
    }
  },
  [theme.breakpoints.down(960)]: {
    storeDiv: {
      marginLeft: '37px'
    },
    grid: {
      width: '90% !important'
    }
  },
  [theme.breakpoints.down(913)]: {
    grid: {
      width: '85%'
    },
    logo: {
      width: '165px',
      height: '40px'
    },
    socialContainer: {
      width: '175px'
    },
    gridItem: {
      '& span': {
        marginRight: '15px'
      }
    },
    playStoreImg: {
      width: '131px',
      height: '38px'
    },
    appStoreImg: {
      width: '131px',
      height: '40px'
    },
    logoContainer: {
      marginTop: '-60px'
    },
    storeContainer: {
      marginTop: '-75px'
    }
  },
  [theme.breakpoints.down(855)]: {
    grid: {
      // width: '75%'
    }
  },
  [theme.breakpoints.down('lg')]: {
    logo: {
      // width: '8rem'
    }
  },
  [theme.breakpoints.down(775)]: {
    footerLogo: {
      // position: 'absolute',
      // marginTop: '-63px'
    },
    grid: {
      width: '90%'
    },
    gridItem: {
      display: 'block'
    },
    contentStyling: {
      fontSize: '13px'
    },
    socialContainer: {
      marginLeft: '540px',
      marginTop: '-45px',
      textAlign: 'right'
    },
    footerContent: {
      marginLeft: '0'
    },
    storeDiv: {
      marginLeft: '-17px'
    },
    storeDivApp: {
      marginLeft: '515px',
      marginTop: '-57px'
    },
    appStoreImg: {
      width: '159px',
      height: '52px'
    },
    playStoreImg: {
      width: '169px',
      height: '52px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    contentStyling: {
      fontSize: '13px'
    },
    footerContainer: {
      // width: '95%',
      fontSize: '.8rem'
    },
    appStoreImg: {
      width: '110px',
      height: '36.81px'
    },
    playStoreImg: {
      width: '130px',
      height: '38.72px'
    },
    siteLinks: {
      '& a': {
        margin: '0 5px'
      }
    },
    logo: {
      width: '120px',
      height: '29.7px'
    },
    badge: {
      // width: '27%'
    },
    storeLogoContainer: {
      marginTop: '21px'
    },
    storeDivApp: {
      marginTop: '-42px',
      marginLeft: '545px'
    },
    socialContainer: {
      textAlign: 'right',
      marginLeft: '532px'
    }
  },
  [theme.breakpoints.down(740)]: {
    socialContainer: {
      marginLeft: '515px'
    },
    storeDivApp: {
      marginLeft: '529px'
    }
  },
  [theme.breakpoints.down(715)]: {
    socialContainer: {
      marginLeft: '478px'
    },
    storeDivApp: {
      marginLeft: '492px'
    }
  },
  [theme.breakpoints.down(680)]: {
    socialContainer: {
      marginLeft: '450px'
    },
    storeDivApp: {
      marginLeft: '465px'
    },
    gridItem: {
      width: '100%'
    }
  },
  [theme.breakpoints.down(655)]: {
    socialContainer: {
      marginLeft: '435px'
    },
    storeDivApp: {
      marginLeft: '450px'
    }
  },
  [theme.breakpoints.down(637)]: {
    socialContainer: {
      marginLeft: '411px'
    },
    storeDivApp: {
      marginLeft: '427px'
    }
  },
  [theme.breakpoints.down(613)]: {
    socialContainer: {
      marginLeft: '399px'
    },
    storeDivApp: {
      marginLeft: '415px'
    }
  },
  [theme.breakpoints.down(604)]: {
    socialContainer: {
      marginLeft: '382px'
    },
    storeDivApp: {
      marginLeft: '397px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    footerContainer: { padding: '1rem 0' },
    logo: {
      margin: '1rem auto',
      // width: '7rem'
    },
    siteLinks: {
      '& a': {
        margin: '0px 2px'
      }
    }
  },
  [theme.breakpoints.down(580)]: {
    socialContainer: {
      marginLeft: '363px'
    },
    storeDivApp: {
      marginLeft: '379px'
    }
  },
  [theme.breakpoints.down(576)]: {
    socialContainer: {
      marginTop: '-43px'
    }
  },
  [theme.breakpoints.down(552)]: {
    socialContainer: {
      marginLeft: '335px'
    },
    storeDivApp: {
      marginLeft: '351px'
    }
  },
  [theme.breakpoints.down(527)]: {
    socialContainer: {
      marginLeft: '313px'
    },
    storeDivApp: {
      marginLeft: '329px'
    }
  },
  [theme.breakpoints.down(504)]: {
    socialContainer: {
      marginLeft: '287px'
    },
    storeDivApp: {
      marginLeft: '303px'
    }
  },
  [theme.breakpoints.down(482)]: {
    socialContainer: {
      marginLeft: '269px'
    },
    storeDivApp: {
      marginLeft: '285px'
    }
  },
  [theme.breakpoints.down(457)]: {
    socialContainer: {
      marginLeft: '247px'
    },
    storeDivApp: {
      marginLeft: '261px'
    }
  },
  [theme.breakpoints.down(441)]: {
    socialContainer: {
      marginLeft: '235px'
    },
    storeDivApp: {
      marginLeft: '250px'
    }
  },
  [theme.breakpoints.down(429)]: {
    socialContainer: {
      marginLeft: '220px'
    },
    storeDivApp: {
      marginLeft: '235px'
    }
  },
  [theme.breakpoints.down(415)]: {
    socialContainer: {
      marginLeft: '210px'
    },
    storeDivApp: {
      marginLeft: '225px'
    },
    playStoreImg: {
      // width: '160px',
      // height: '51px'
    },
    appStoreImg: {
      // width: '157px',
      // height: '54px'
    }
  },
  [theme.breakpoints.down(403)]: {
    socialContainer: {
      marginLeft: '199px'
    },
    storeDivApp: {
      marginLeft: '215px'
    }
  },
  [theme.breakpoints.down(387)]: {
    socialContainer: {
      marginLeft: '185px'
    },
    storeDivApp: {
      marginLeft: '200px'
    }
  },
  [theme.breakpoints.down(370)]: {
    socialContainer: {
      marginLeft: '170px'
    },
    storeDivApp: {
      marginLeft: '185px'
    }
  },
  [theme.breakpoints.down(357)]: {
    socialContainer: {
      marginLeft: '159px'
    },
    storeDivApp: {
      marginLeft: '175px'
    }
  },
  [theme.breakpoints.down(345)]: {
    socialContainer: {
      marginLeft: '155px'
    },
    storeDivApp: {
      marginLeft: '170px'
    }
  },
  [theme.breakpoints.down(339)]: {
    socialContainer: {
      marginLeft: '150px'
    },
    storeDivApp: {
      marginLeft: '165px'
    }
  },
  [theme.breakpoints.down(331)]: {
    socialContainer: {
      marginLeft: '141px'
    },
    storeDivApp: {
      marginLeft: '157px'
    }
  },
  [theme.breakpoints.down(321)]: {
    socialContainer: {
      marginLeft: '131px'
    },
    storeDivApp: {
      marginLeft: '147px'
    }
  },
  [theme.breakpoints.down(314)]: {
    socialContainer: {
      marginLeft: '127px'
    },
    storeDivApp: {
      marginLeft: '142px'
    }
  }
}))

export default Footer
