import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import TaskContentContainer from '../tasks/TaskContentContainer'
import TaskVideo from '../tasks/TaskVideo'
import GuideBasicsOfEatingHealthy from './basics_of_healthy_eating/GuideBasicsOfEatingHealthy'
import { Link } from 'react-router-dom'

import navigateToGoSite from '../../../helpers/NavigateToGoSite'

const GuideNutritionTruths = () => {
  const classes = useStyles()
  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <p>
          Your success in this program will come down to making the most INFORMED choices when it comes to your eating.
          But don’t worry… I am in this with you, not only will I be educating you along the way, but my team and I will
          also be SUPPORTING you regularly as you put your new habits into practice.
        </p>
        <p>
          Let’s face it, most of us know the kind of foods that we should be eating BUT we kind of get a little mixed up
          on the serving sizes OR we know the sorts of foods and serving sizes but sticking to it long enough to create
          habit seems to be our hurdle. That’s why my 12WBT is for 12 weeks, so that TOGETHER we can get these new
          habits ingrained, and my mindset lessons cement them for LIFE!
        </p>
        <GuideBasicsOfEatingHealthy />
        <span>
          <b>My takeaway message on Nutrition Truths:</b>
        </span>
        <ol>
          <li>
            Carbohydrates are not the enemy. I’ll be showing you the best kinds of carbohydrates to fuel your body.
          </li>
          <li>
            Protein is an important part of your diet, but yes, <b>you can overdo it.</b>
          </li>
          <li>
            Portion distortion anyone? Portion sizes have significantly increased over the years leading us to eat like
            lumberjacks. By the end of this Round, you’ll have the know-how to reset your appetite and understand
            completely what correct portions look like.
          </li>
          <li>
            Variety is the spice of life. I understand you may not be into all the recipes I give you but that’s why I
            have given you hundreds of others to choose from in our Recipe Index . Simply select a Plan that best suits
            your lifestyle from our extensive{' '}
            <a href={navigateToGoSite('help/1862-what-are-the-12wbt-meal-plan-options')}>Meal Plan</a> options and then{' '}
            <a href={navigateToGoSite('help/1152599-how-do-i-customise-my-meal-plan')}>customise your Meal Plans</a> to
            suit your taste-buds.
          </li>
          <li>
            Got a special dietary requirement? You can filter my recipes in the Recipe Index to search for recipes to
            swap into your Plans. Filters include:{' '}
            <em>gluten free, lactose free, low carb, high protein, low FODMAP</em> as well as <em>Feed the Family</em>{' '}
            and <em>Freezable.</em>
          </li>
        </ol>
        <p>
          The other thing I get asked about which needs its very own mention is Snacks. Don’t get me wrong, I love
          snacks… but they can MAKE or BREAK a healthy nutrition plan. Let’s look at the three TTT’s - the traps, tricks
          and truths of snacks!
        </p>
        <TaskVideo videoId='2964987382001' needsCenterAdjust={true} />
        <p>
          <b>My takeaway message on snacks:</b>
        </p>
        <ol>
          <li>
            The worst kinds of snacks are those that are higher in calories with very little nutritional value (think
            chocolate and chips). They might bring some shortterm joy to your taste buds, but the rest of you will hit a
            slump.
          </li>
          <li>Be aware of your food cravings AND times you may feel vulnerable.</li>
          <li>
            Plan your day, and week, to ensure you include some healthy nutritious midmeal snacks. Not only will you be
            nourishing your body and keeping your hunger at bay BUT you will reduce cravings and those unwanted
            indulgences.
          </li>
          <li>
            Visit my <a href={navigateToGoSite('recipes?filters=4')}>Snack Tab</a> in the Recipe Index for great snack
            ideas that are simple, nutritious and delicious.
          </li>
        </ol>
        <p>
          Now that you’ve studied my Nutrition Truths infographic and watched my Snack Swap video, get ready to have
          some fun and discover if you’re a nutrition whiz or a work in progress!
        </p>
        <p>
          Remember your score and take the test again in Weeks 4, 8, and 12 and watch your nutrition knowledge grow!
        </p>
        <p>
          <b>Nutrition Truths Quiz</b>
        </p>
        <p>
          Here’s a challenge for you… Have some fun with this quiz and discover if you’re a nutrition whiz or a work in
          progress!
          <br />
          Remember your score and take the test again in Weeks 4, 8, and 12 and watch your nutrition knowledge grow!
        </p>
        <div className={classes.buttonRow}>
          <Link to='/pre-season/guides/nutrition-truths-quiz' className={classes.link}>
            <Button className={classes.button}>Take the quiz</Button>
          </Link>
        </div>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& img': {
      maxWidth: '100%'
    }
  },
  link: {
    textDecoration: 'none',
    margin: 'auto'
  },
  buttonRow: {
    width: '100%',
    margin: '50px auto',
    ...theme.CSS.center
  },
  button: {
    margin: 'auto',
    width: '18rem',
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#5164E9',
    borderRadius: '6px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    '&:hover': {
      border: `1px solid #5164E9`,
      color: '#5164E9'
    }
  }
}))

export default GuideNutritionTruths
