import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import basketball from '../../../../assets/img/pre_season/guides/infographics/basketball.png'
import tennisBall from '../../../../assets/img/pre_season/guides/infographics/tennisBall.png'
import pingPongBall from '../../../../assets/img/pre_season/guides/infographics/pingPongBall.png'
import marble from '../../../../assets/img/pre_season/guides/infographics/marble.png'
import orangeArrow from '../../../../assets/img/pre_season/guides/infographics/orangeArrow.png'

function applyOpacity(color) {
  const opacity = 0.2
  return color.substring(0, color.length - 1) + `, ${opacity})`
}

const HealthyEatingBallRow = () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} sm={3}>
        <div className={classes.ballContainer}>
          <img src={basketball} alt='Basketball' className={classes.basketBall} />
          <span className={classes.title}>Basketball</span>
          <span>60 serves!</span>
        </div>
      </Grid>
      <Grid item xs={6} sm={3}>
        <div className={`${classes.ballContainer} ${classes.tennisBallDiv}`}>
          <img src={tennisBall} alt='Tennisball' className={classes.tennisBall} />
          <span className={classes.title}>Tennis Ball</span>
          <span>1 serves</span>
          <div className={classes.messageDiv}>
            <div className={classes.arrowDiv}>
              <img src={orangeArrow} alt='Arrow pointing to tennis ball' />
            </div>
            <div className={classes.message}>
              <span>Remember you get 2 serves in tennis too!</span>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={6} sm={3}>
        <div className={classes.ballContainer}>
          <img src={pingPongBall} alt='Ping pong ball' className={classes.pingPongBall} />
          <span className={classes.title}>Ping Pong Ball</span>
          <span>1/3 serves</span>
        </div>
      </Grid>
      <Grid item xs={6} sm={3}>
        <div className={classes.ballContainer}>
          <img src={marble} alt='Marble' className={classes.marble} />
          <span className={classes.title}>Marbles</span>
          <span>Don&apos;t eat</span>
        </div>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '6rem auto',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  ballContainer: {
    ...theme.CSS.center,
    flexDirection: 'column',
    textAlign: 'center',
    height: '100%',
    justifyContent: 'flex-end'
  },
  title: {
    color: theme.palette.rgb.guide.orange,
    fontWeight: 800
  },
  tennisBallDiv: {
    position: 'relative'
  },
  basketBall: {
    width: '12rem'
  },
  tennisBall: {
    width: '4.5rem'
  },
  pingPongBall: {
    width: '3.5rem'
  },
  marble: {
    width: '2rem'
  },
  messageDiv: {
    position: 'absolute',
    top: '-1rem',
    display: 'flex',
    marginRight: '-23rem'
  },
  arrowDiv: {
    marginTop: '1rem',
    width: '6rem',
    '& img': {
      width: '100%'
    }
  },
  message: {
    backgroundColor: applyOpacity(theme.palette.rgb.guide.orange),
    color: theme.palette.rgb.guide.orange,
    fontSize: '1rem',
    padding: '.5rem',
    borderRadius: '15px',
    maxWidth: '15rem',
    height: '50%'
  },
  [theme.breakpoints.down('xs')]: {
    messageDiv: {
      marginRight: '18rem',
      top: '-4rem',
      flexDirection: 'row-reverse',
      '& img': {
        transform: 'scaleX(-1)'
      }
    },
    arrowDiv: { width: '10rem' },
    ballContainer: {
      margin: '1rem 0'
    }
  },
  [theme.breakpoints.down(400)]: {
    messageDiv: {
      marginRight: '12rem'
    }
  }
}))

export default HealthyEatingBallRow
