import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNewPreSeasonData } from '../../redux/actions/PreSeason.actions'

const loadNewPreSeasonData = () => {
  const dispatch = useDispatch()
  const { preseason, loading, taskSuccess } = useSelector((state) => state.newPreSeason)
  const currentSubscriptionId = useSelector((state) => state.member?.currentSubscriptionId)

  useEffect(() => {
    if (currentSubscriptionId && !loading) {
      dispatch(getNewPreSeasonData(currentSubscriptionId))
    }
  }, [currentSubscriptionId])

  return { preseason, loading, taskSuccess }
}

export default loadNewPreSeasonData
