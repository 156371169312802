import React, { useState } from 'react'
import { useFormik } from 'formik'
import WelcomeStepOneProfileFields from './WelcomeStepOneProfileFields'
import WelcomeBillingDetails from './WelcomeBillingDetails'
import welcomeStepOneValidationSchema from './WelcomeStepOneValidation'
import WelcomeStepOneSubmit from './WelcomeStepOneSubmit'

import { useDispatch, useSelector } from 'react-redux'
import { updateProfilePhoto, updateProfile, updateMember } from '../../../../redux/actions/Member.actions'

const WelcomeStepOneForm = () => {
  const [submissionAttempt, setSubmissionAttempt] = useState(false)
  const [fileUploaded, setFileUploaded] = useState(null)
  const dispatch = useDispatch()
  const { memberSummary } = useSelector((state) => state.memberSummary)
  const loading = useSelector((state) => state.profile.loading)
  // const { progress } = useSelector((state)=> state.preseason)
  const formik = useFormik({
    initialValues: {
      screen_name: '',
      birthday: '',
      gender: '',
      street_address: '',
      suburb: '',
      postcode: '',
      state: '',
      country: '',
      phone_number: '',
      day: '',
      month: '',
      year: ''
    },
    validationSchema: welcomeStepOneValidationSchema,
    onSubmit: (values) => {
      localStorage.setItem('gender', values.gender)
      localStorage.setItem('dob', values.birthday)
      // eslint-disable-next-line camelcase
      const { street_address, screen_name, birthday, gender, suburb, postcode, state, country, phone_number } = values
      if (fileUploaded) {
        // Dispatch file upload here: https://api.dev.cloud.12wbt.com/v1/members/490504/profile
        dispatch(updateProfilePhoto(memberSummary.id, fileUploaded))
      }

      // Some data is updated on member profile /member/${memberid}/profile:
      dispatch(
        updateProfile(memberSummary.id, {
          gender,
          phone_number,
          birthday
        })
      )

      // Other data is updated directly on member /member/${memberid}:
      dispatch(
        updateMember(memberSummary.id, {
          street_address,
          suburb,
          postcode,
          state,
          country,
          screen_name
        })
      )
    }
  })

  // Set file in state:
  const fileUploadHandler = (file) => {
    setFileUploaded(file)
  }

  return (
    <div>
      <WelcomeStepOneProfileFields
        formik={formik}
        onImageUpload={fileUploadHandler}
        submissionAttempt={submissionAttempt}
      />
      <WelcomeBillingDetails formik={formik} />
      <WelcomeStepOneSubmit formik={formik} setSubmissionAttempt={setSubmissionAttempt} loading={loading} />
    </div>
  )
}

export default WelcomeStepOneForm
