import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextareaAutosize } from '@material-ui/core'

import HelperText from '../../../../components/fields/HelperText'

const ExcusesTextAreaInput = ({ formik, label, name, isHeader }) => {
  const error = true
  const classes = useStyles({ error })
  const labelClass = isHeader ? classes.headerLabel : classes.label

  return (
    <div className={classes.container}>
      {label && <span className={labelClass}>{label}</span>}
      <TextareaAutosize
        rowsMax={12}
        rowsMin={4}
        name={name}
        aria-label='maximum height'
        onChange={formik.handleChange}
        value={formik.values[name]}
        className={classes.textArea}
      />
      {formik.errors[name] && formik.touched[name] && <HelperText message='This field is required' />}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      marginTop: '0!important'
    },
    border: '1px solid black',
    padding: '1rem'
  },
  textArea: {
    border: 'none',
    borderBottom: '1px solid black',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    '&:focus': {
      outline: 0,
      borderBottom: '2px solid blue'
    }
  },
  label: {
    fontSize: '1rem',
    color: theme.palette.greys.dark
  },
  headerLabel: {
    fontSize: '1rem',
    fontWeight: 700
  }
}))

ExcusesTextAreaInput.defaultProps = {
  isHeader: false
}

ExcusesTextAreaInput.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  isHeader: PropTypes.bool
}

export default ExcusesTextAreaInput
