import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'

const Reclaim25Terms = () => {
  const { couponApplied, couponSuccess } = useSelector((state) => state.coupon)
  const classes = useStyles()
  if (couponApplied !== 'RECLAIM25' || !couponSuccess) return <></>
  return (
    <div className={classes.termsContainer}>
      <hr />

      <h4 className={classes.title}>Offer Terms and Conditions:</h4>
      <ul>
        <li>
          Input RECLAIM25 in the coupon code section at&nbsp;
          <Link to='/signup'>https://app.12wbt.com/signup</Link> or visit&nbsp;
          <Link to='/signup?utm_coupon=reclaim25'>
            https://app.12wbt.com/signup?utm_coupon=reclaim25
          </Link>
          &nbsp; to redeem.
        </li>
        <li>Promo code is valid until Tuesday 29 Nov 2022 11:59PM AEDT.</li>
        <li>
          25% discount is available for Upfront Single Round Sign Ups only.
        </li>
        <li>Offer cannot be used in conjunction with any other discount.</li>
      </ul>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  termsContainer: {
    margin: '1rem',
    '& ul': {
      paddingLeft: '1rem'
    },
    '& hr': {
      backgroundColor: 'lightgrey',
      border: 'none',
      height: '1px'
    }
  },
  title: {
    color: theme.palette.signup.purple,
    fontWeight: 'bold'
  }
}))

export default Reclaim25Terms
