import React from 'react'
import { makeStyles } from '@material-ui/core'
import TaskContentContainer from '../tasks/TaskContentContainer'

import navigateToGoSite from '../../../helpers/NavigateToGoSite'

const GuideGearUp = () => {
  const classes = useStyles()
  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <p>
          On your journey to a fitter, leaner and much stronger you, it will make the world of difference if you have
          the right mindset, and what helps that mindset is if you feel mentally confident and physically supported,
          that’s what ‘Gear Up’ is all about.
        </p>
        <p>
          <b>What you&apos;ll Need:</b>
        </p>
        <ol>
          <li>
            Trainers - A good pair of properly fitted trainers that feel comfortable and offer you the right amount of
            support are essential, especially if you’ll be on your feet with walking, running or any or the workout
            videos.
          </li>
          <li>
            Comfortable Training Gear - Whether you’re at home, in a gym or out at the park - comfort is key. You should
            have clothes that aren’t too hot, cool or tight. I always like to do a few full squats and star jumps in the
            dressing room before I buy workout gear aka Activewear.
          </li>
          <li>
            Equipment - If you’re training at home or outdoors then you’ll need: Some light dumbbells which you can make
            from a couple of water bottles and some rice. There is other nice-to-have equipment such as: a barbell and
            resistance band but all you really need is a can-do attitude and some simple improvisation. PS A good drink
            bottle will be essential to replace all that sweat.
          </li>
        </ol>
        <p>
          <b>How often you&apos;ll be Training</b>
        </p>
        <p>So you know what to expect, your training will consist of the following...</p>
        <ul>
          <li>
            My Movers will be doing: 4 days of fitness and toning mix and 1 day of core and stretch (after Week 8).
          </li>
          <li>
            My Beginner, Intermediate and Advanced members will be doing: 3 days of fitness, 2 days of toning and 1 day
            of light fitness, core and stretch.
          </li>
          <li>
            My Lean & Strong will be doing: 4 days of strength and tone, 1 day of fitness and fat burning and 1 day of
            core and stretch.
          </li>
          <li>
            My Runners will be doing: 3-4 days of running, or learning to run, 1-2 day of toning (strength & stability)
            and 1 day of stretch and core.
          </li>
        </ul>
        <p>
          <b>Locations to train at</b>
        </p>
        <p>
          <b>Gym</b> - Before you go to the gym check out this 1 minute video -{' '}
          <a href={navigateToGoSite('videos/category/fitness/450-first-time-to-the-gym')}>Be Gym Ready</a>
        </p>
        <p>
          <b>At Home</b> - Have a couple sets of dumbbells, a mat or towel to door your floor exercises.
        </p>
        <p>
          <b>Outdoors</b> - Similar equipment to ‘At Home’ as well as a sunshine dance.
        </p>
        <p>
          <b>Bonus Training Equipment</b>
        </p>
        <p>
          &nbsp;These are not essential but if you’re at home and planning on doing to do every workout video (the most
          popular feature of the site) then here’s some extra pieces of equipment that will come in handy:
        </p>
        <ul>
          <li>Multiple training outfits</li>
          <li>A cap/visor if you’re training outside</li>
          <li>
            Resistance band - Not essential - but you’ll find it really useful when you do my ‘Resist and Persist’
            Workout Video
          </li>
          <li>
            Barbell Set - Not essential - but you’ll find it really useful when you do my ‘Raise the Bar’ Workout Video
          </li>
          <li>Heart rate monitor: we recommend you choose one that measures calories expended</li>
          <li>Workout Playlist - This can turn an ordinary workout into an awesome one!</li>
        </ul>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({}))

export default GuideGearUp
