import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Logo12WBT from '../assets/img/logos/12wbtlogo.png'

const Loader = () => {
  const classes = useStyles()

  return (
    <Grid container direction='column' justify='center' alignItems='center' style={{ height: '100vh' }}>
      <img src={Logo12WBT} alt='Logo' className={classes.logo} />
      <CircularProgress color='primary' variant='indeterminate' size={100}></CircularProgress>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: theme.spacing(5),
    maxWidth: '150px'
  }
}))

export default Loader
