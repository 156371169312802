import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const SignUpFAQItem = ({ header, content, lastElement, collapsed, index, setActiveItem }) => {
  const classes = useStyles({ collapsed, lastElement })
  const contentClass = collapsed ? classes.content : `${classes.content} ${classes.contentOpen}`
  return (
    <div className={classes.itemContainer}>
      <div className={classes.header} onClick={() => setActiveItem(index)}>
        <span>{header}</span>
      </div>
      <div className={contentClass}>{content}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    flexDirection: 'column'
  },
  header: {
    color: (props) => (props.collapsed ? theme.palette.signup.signupFaq : 'white'),
    fontWeight: 'bold',
    display: 'flex',
    height: '4rem',
    backgroundColor: (props) => (props.collapsed ? 'white' : theme.palette.signup.signupFaq),
    ...theme.CSS.center,
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.signup.signupFaq}`,
    borderBottom: (props) => (props.lastElement ? `1px solid ${theme.palette.signup.signupFaq}` : ''),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.signup.signupFaq,
      color: 'white'
    }
  },
  content: {
    transition: theme.transitions.create(['max-height'], {
      duration: theme.transitions.duration.complex
    }),
    overflow: 'hidden',
    padding: (props) => (props.collapsed ? 0 : '1rem'),
    maxHeight: 0,
    '& p': {
      visibility: (props) => (props.collapsed ? 'hidden' : 'visible')
    }
  },
  contentOpen: {
    maxHeight: '20rem'
  },
  [theme.breakpoints.down(400)]: {
    header: {
      fontSize: '.9rem',
      padding: '.3rem'
    },
    content: {
      fontSize: '.9rem'
    }
  }
}))

SignUpFAQItem.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  lastElement: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  setActiveItem: PropTypes.func.isRequired
}

export default SignUpFAQItem
