export const LOGIN_WITH_TOKEN_REQUEST = 'LOGIN_WITH_TOKEN_REQUEST'
export const LOGIN_WITH_TOKEN_SUCCESS = 'LOGIN_WITH_TOKEN_SUCCESS'
export const LOGIN_WITH_TOKEN_FAIL = 'LOGIN_WITH_TOKEN_FAIL'

export const GET_MEMBER_SUMMARY_REQUEST = 'GET_MEMBER_SUMMARY_REQUEST'
export const GET_MEMBER_SUMMARY_SUCCESS = 'GET_MEMBER_SUMMARY_SUCCESS'
export const GET_MEMBER_SUMMARY_FAIL = 'GET_MEMBER_SUMMARY_FAIL'

export const MEMBER_LOGIN_REQUEST = 'MEMBER_LOGIN_REQUEST'
export const MEMBER_LOGIN_SUCCESS = 'MEMBER_LOGIN_SUCCESS'
export const MEMBER_LOGIN_FAIL = 'MEMBER_LOGIN_FAIL'

export const MEMBER_LOGOUT_REQUEST = 'MEMBER_LOGOUT_REQUEST'
export const MEMBER_LOGOUT_SUCCESS = 'MEMBER_LOGOUT_SUCCESS'
export const MEMBER_LOGOUT_FAIL = 'MEMBER_LOGOUT_FAIL'

export const MEMBER_LOAD_REQUEST = 'MEMBER_LOAD_REQUEST'
export const MEMBER_LOAD_SUCCESS = 'MEMBER_LOAD_SUCCESS'
export const MEMBER_LOAD_FAIL = 'MEMBER_LOAD_FAIL'

export const GET_CURRENT_SUBSCRIPTION_REQUEST =
  'GET_CURRENT_SUBSCRIPTION_REQUEST'
export const GET_CURRENT_SUBSCRIPTION_SUCCESS =
  'GET_CURRENT_SUBSCRIPTION_SUCCESS'
export const GET_CURRENT_SUBSCRIPTION_FAIL = 'GET_CURRENT_SUBSCRIPTION_FAIL'

export const GET_LAST_SUBSCRIPTION_REQUEST = 'GET_LAST_SUBSCRIPTION_REQUEST'
export const GET_LAST_SUBSCRIPTION_SUCCESS = 'GET_LAST_SUBSCRIPTION_SUCCESS'
export const GET_LAST_SUBSCRIPTION_FAIL = 'GET_LAST_SUBSCRIPTION_FAIL'

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_FAIL = 'UPDATE_SUBSCRIPTION_FAIL'
export const CLEAR_UPDATE_SUBSCRIPTION_SUCCESS =
  'CLEAR_UPDATE_SUBSCRIPTION_SUCCESS'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const UPDATE_PROFILE_PHOTO_REQUEST = 'UPDATE_PROFILE_PHOTO_REQUEST'
export const UPDATE_PROFILE_PHOTO_SUCCESS = 'UPDATE_PROFILE_PHOTO_SUCCESS'
export const UPDATE_PROFILE_PHOTO_FAIL = 'UPDATE_PROFILE_PHOTO_FAIL'

export const UPDATE_PRE_SEASON_PROGRESS_REQUEST =
  'UPDATE_PRE_SEASON_PROGRESS_REQUEST'
export const UPDATE_PRE_SEASON_PROGRESS_SUCCESS =
  'UPDATE_PRE_SEASON_PROGRESS_SUCCESS'
export const UPDATE_PRE_SEASON_PROGRESS_FAIL = 'UPDATE_PRE_SEASON_PROGRESS_FAIL'

export const GET_PRE_SEASON_PROGRESS_REQUEST = 'GET_PRE_SEASON_PROGRESS_REQUEST'
export const GET_PRE_SEASON_PROGRESS_SUCCESS = 'GET_PRE_SEASON_PROGRESS_SUCCESS'
export const GET_PRE_SEASON_PROGRESS_FAIL = 'GET_PRE_SEASON_PROGRESS_FAIL'

export const GET_ROUND_MEASUREMENTS_REQUEST = 'GET_ROUND_MEASUREMENTS_REQUEST'
export const GET_ROUND_MEASUREMENTS_SUCCESS = 'GET_ROUND_MEASUREMENTS_SUCCESS'
export const GET_ROUND_MEASUREMENTS_FAIL = 'GET_ROUND_MEASUREMENTS_FAIL'

export const SUBMIT_ROUND_MEASUREMENTS_REQUEST =
  'SUBMIT_ROUND_MEASUREMENTS_REQUEST'
export const SUBMIT_ROUND_MEASUREMENTS_SUCCESS =
  'SUBMIT_ROUND_MEASUREMENTS_SUCCESS'
export const SUBMIT_ROUND_MEASUREMENTS_FAIL = 'SUBMIT_ROUND_MEASUREMENTS_FAIL'
export const CLEAR_SUBMIT_ROUND_MEASUREMENTS_SUCCESS =
  'CLEAR_SUBMIT_ROUND_MEASUREMENTS_SUCCESS'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'

export const SET_WELCOME_SUBSCRIPTION_ID = 'SET_WELCOME_SUBSCRIPTION_ID'
export const SET_IS_RETURN_MEMBER = 'SET_IS_RETURN_MEMBER'
export const SET_CURRENT_ROUND_ID = 'SET_CURRENT_ROUND_ID'

export const SET_BUILD_A_NEW_PLAN = 'SET_BUILD_A_NEW_PLAN'

export const UPLOAD_BEFORE_PHOTO_REQUEST = 'UPLOAD_BEFORE_PHOTO_REQUEST'
export const UPLOAD_BEFORE_PHOTO_SUCCESS = 'UPLOAD_BEFORE_PHOTO_SUCCESS'
export const UPLOAD_BEFORE_PHOTO_FAIL = 'UPLOAD_BEFORE_PHOTO_FAIL'

export const SET_AUTH_ROUTE_ATTEMPT = 'SET_AUTH_ROUTE_ATTEMPT'
export const CLEAR_AUTH_ROUTE_ATTEMPT = 'CLEAR_AUTH_ROUTE_ATTEMPT'
export const GET_ALL_SUBSCRIPTIONS_REQUEST = 'GET_ALL_SUBSCRIPTIONS_REQUEST'
export const GET_ALL_SUBSCRIPTIONS_SUCCESS = 'GET_ALL_SUBSCRIPTIONS_SUCCESS'
export const GET_ALL_SUBSCRIPTIONS_FAIL = 'GET_ALL_SUBSCRIPTIONS_FAIL'

export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST'
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS'
export const UPDATE_MEMBER_FAIL = 'UPDATE_MEMBER_FAIL'
