import React, { useEffect, useState } from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { calorieValuesFromPrograms } from '../../modules/welcome_steps/welcome_step_two/WelcomeStepTwoData'
import lifestyleLogo from '../../assets/img/logos/12WBTLifestyleLogo.png'
import defaultProfilePhoto from '../../assets/img/general/profile-default.png'
import navigateToGoSite from '../../helpers/NavigateToGoSite'
import { Link } from 'react-router-dom'
import TooltipSwitchRoundBtn from './SwitchRound/TooltipSwitchRoundBtn'
import loadCurrentSubscriptionAndRound from '../../helpers/LoadCurrentSubscriptionAndRound'

const TooltipContentAccount = () => {
  const [calories, setCalories] = useState(null)
  const { profile } = useSelector((state) => state.profile)
  const memberScreenName = useSelector(
    (state) => state.member?.member?.screen_name
  )
  const isAdmin = useSelector((state) => state.memberSummary?.is_admin)

  // force refresh current subscription
  loadCurrentSubscriptionAndRound(true)

  const currentSubscription = useSelector(
    (state) => state.currentSubscription?.currentSubscription
  )

  const isLifestyle = currentSubscription?.lifers
  // Pick image to show:
  let profileImage = profile?.avatar_url?.thumb || defaultProfilePhoto
  if (
    profile?.avatar_url?.thumb === '/images/layout/bg-member-photos-default.png'
  )
    profileImage = defaultProfilePhoto
  const classes = useStyles({ profileThumb: profileImage })

  useEffect(() => {
    if (currentSubscription)
      setCalories(
        calorieValuesFromPrograms[currentSubscription?.nutrition_program_id]
      )
  }, [currentSubscription])

  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <div className={classes.profileImage} />
        {isLifestyle && (
          <img
            src={lifestyleLogo}
            alt='12WBT Lifestyle Logo'
            className={classes.logo}
          />
        )}
      </div>
      <div className={classes.profileInfo}>
        <span className={classes.screenName}>{memberScreenName}</span>
        <div className={classes.description}>
          <span className={classes.programDifficulty}>
            {currentSubscription?.exercise_plan_level}
          </span>
          <span className={classes.programDifficulty}>{calories} Cal</span>
        </div>
        <TooltipSwitchRoundBtn />
        <Divider className={classes.divider} />
        <div className={classes.links}>
          <a href={navigateToGoSite('my-account')} className={classes.link}>
            My Account
          </a>
          <a
            href={navigateToGoSite('my-account/my-activity')}
            className={classes.link}
          >
            My Activity
          </a>
          <a href={navigateToGoSite('messages')} className={classes.link}>
            Messages
          </a>
          {isAdmin && (
            <a href={navigateToGoSite('admin')} className={classes.link}>
              Admin
            </a>
          )}
        </div>
        <Divider className={classes.divider} />
        <Link className={classes.link} to='/logout'>
          Logout
        </Link>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    padding: '.4rem'
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem'
  },
  profileImage: {
    width: '12rem',
    height: '12rem',
    backgroundImage: (props) =>
      props.profileThumb
        ? `url(${props.profileThumb})`
        : `url(${props.profileDefault})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  programDifficulty: {
    fontSize: '.8rem',
    margin: '.1rem 0'
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    margin: '.2rem 0'
  },
  link: {
    textDecoration: 'none',
    minWidth: '12rem',
    color: theme.palette.signup.purple,
    fontWeight: 'bold',
    margin: '.4rem 0',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: 'black',
      cursor: 'pointer'
    }
  },
  screenName: {
    fontSize: '1.4rem',
    fontWeight: 700,
    color: theme.palette.signup.purple
  },
  divider: {
    width: '100%',
    margin: '.5rem 0'
  },
  description: {
    margin: '.2rem 0',
    display: 'flex',
    flexDirection: 'column'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  logo: {
    maxWidth: '10rem',
    margin: '1rem auto'
  },
  [theme.breakpoints.down('sm')]: {
    profileImage: {
      width: '8rem',
      height: '8rem'
    },
    link: {
      width: '8rem'
    },
    logo: {
      width: '6rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    profileImage: {
      width: '6rem',
      height: '6rem'
    },
    link: {
      minWidth: '6rem'
    },
    logo: {
      width: '5rem'
    }
  }
}))

export default TooltipContentAccount
