import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const PreSeasonHeader = ({ title, description }) => {
  const classes = useStyles()
  return (
    <div className={classes.header}>
      <span className={classes.title}>{title}</span>
      <span className={classes.description}>{description}</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: theme.paddings.md,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '736px',
    margin: 'auto'
  },
  title: {
    color: '#111827',
    fontSize: '36px',
    lineHeight: '40px',
    fontWeight: 'bold'
  },
  description: {
    color: '#1F2937',
    fontSize: '20px',
    lineHeight: '28px',
    marginTop: '8px'
  },
  [theme.breakpoints.down('md')]: {
    header: {
      paddingTop: '50px',
      width: 'auto',
      maxWidth: '80%',
      height: 'auto'
    },
    title: {
      fontSize: '25px'
    },
    description: {
      fontSize: '16px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      paddingTop: '30px'
    },
    description: {
      maxWidth: '100%',
      margin: 'auto'
    }
  }
}))

PreSeasonHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default PreSeasonHeader
