import React from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import ContentContainer from '../../../components/ContentContainer'
import lifestyleLogo from '../../../assets/img/logos/lifestyle-logo.png'
import standardLogo from '../../../assets/img/logos/logo-white@2x.png'
import BrightCovePlayer from '../../../components/BrightCovePlayer'
import WelcomeBackMessage from './WelcomeBackMessage'

import { useSelector } from 'react-redux'

const welcomeMessages = {
  lifestyle: {
    message: `Thanks for joining my team! It's time to train hard, eat clean and TRANSFORM into the best version of YOURSELF! Twelve months from now I want you to look in the mirror and love what you see. Your first 12 week challenge for the year is about to begin and I can't wait to see what you achieve. Your time is NOW!`
  },
  single: {
    message: `Thanks for joining my team! It's time to train hard, eat clean and TRANSFORM into the best version of YOURSELF! Twelve weeks from now I want you to look in the mirror and love what you see. The time is NOW!`
  }
}

const WelcomeCompleteHeader = () => {
  const { isReturnMember } = useSelector((state) => state.member)
  const { memberSummary } = useSelector((state) => state.memberSummary)
  const { currentSubscription } = useSelector((state) => state.currentSubscription)
  const classes = useStyles()
  const isLifestyle = currentSubscription?.lifers
  const logo = isLifestyle ? lifestyleLogo : standardLogo
  const title = `Welcome ${memberSummary?.first_name}, you are good to go!`
  const welcomeMessage = isLifestyle ? welcomeMessages.lifestyle.message : welcomeMessages.single.message
  const videoId = '6098586222001' // TODO: set relevant video id based on program subscription
  return (
    <ContentContainer>
      <div className={classes.logoContainer}>
        <img src={logo} alt='12WBT logo' className={classes.logo} />
      </div>
      <Divider className={classes.divider} />
      {isReturnMember ? (
        <WelcomeBackMessage welcomeMessage={welcomeMessage} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.video}>
              <BrightCovePlayer videoId={videoId} />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.welcomeMessageContainer}>
            <span className={classes.welcomeTitle}>{title}</span>
            <span className={classes.welcomeMessage}>{welcomeMessage}</span>
          </Grid>
        </Grid>
      )}
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    maxWidth: '10rem',
    margin: '4rem auto'
  },
  divider: {
    marginBottom: '2rem',
    backgroundColor: theme.palette.greys.light
  },
  video: {
    minWidth: '10rem',
    height: '15rem'
  },
  welcomeMessageContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    color: 'white'
  },
  welcomeTitle: {
    textAlign: 'center',
    fontSize: '1.8rem',
    fontWeight: 600,
    maxWidth: '60%',
    margin: '.5rem auto'
  },
  welcomeMessage: {
    lineHeight: '1.3rem',
    maxWidth: '90%',
    margin: '1rem auto'
  },
  [theme.breakpoints.down('lg')]: {
    welcomeTitle: {
      maxWidth: '80%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    video: {
      height: '20rem'
    },
    welcomeTitle: {
      fontSize: '1.4rem',
      maxWidth: '90%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    video: {
      height: '15rem'
    }
  }
}))

export default WelcomeCompleteHeader
