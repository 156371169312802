import React from 'react'
import PageContainer from '../app/components/PageContainer'
import {
  activities,
  GET_MOVING,
  BUILDING_YOUR_INNER_CORE_STRENGTH,
  MAKING_MORE_ME_TIME,
  BETTER_THAN_YESTERDAY
} from '../app/modules/pre_season/activities/ActivityData'
import { Redirect, useLocation } from 'react-router-dom'
import ActivityHeader from '../app/modules/pre_season/activities/ActivityHeader'

import AcitivityMakingMoreMeTime from '../app/modules/pre_season/activities/AcitivityMakingMoreMeTime'
import ActivityBuildYourInnerCoreStrength from '../app/modules/pre_season/activities/ActivityBuildYourInnerCoreStrength'
import ActivityGetMoving from '../app/modules/pre_season/activities/ActivityGetMoving'
import ActivityBetterThanYesterday from '../app/modules/pre_season/activities/ActivityBetterThanYesterday'
import loadNewPreSeasonData from '../app/helpers/LoadNewPreSeasonData'

const PreSeasonActivity = () => {
  const location = useLocation()
  const slugArray = location.pathname.split('/')
  const slug = slugArray[slugArray?.length - 1]
  const activity = activities[slug]

  // Get task & activity info:
  loadNewPreSeasonData()

  const TaskActivityContent = () => {
    switch (slug) {
      case GET_MOVING: {
        return <ActivityGetMoving />
      }
      case BUILDING_YOUR_INNER_CORE_STRENGTH: {
        return <ActivityBuildYourInnerCoreStrength />
      }
      case MAKING_MORE_ME_TIME: {
        return <AcitivityMakingMoreMeTime />
      }
      case BETTER_THAN_YESTERDAY: {
        return <ActivityBetterThanYesterday />
      }
    }
  }

  // Validate slug & redirect if invalid:
  if (!activity) return <Redirect to='/pre-season' />

  return (
    <PageContainer>
      <ActivityHeader header={activity?.header} intro={activity?.intro} slug={slug} />
      {TaskActivityContent()}
    </PageContainer>
  )
}

export default PreSeasonActivity
