import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const MeasureUpInputCircle = ({ color }) => {
  const classes = useStyles({ color })
  return <div className={classes.circle}></div>
}

const useStyles = makeStyles((theme) => ({
  circle: {
    borderRadius: '50%',
    width: '.7rem',
    height: '.7rem',
    border: (props) => `2px solid ${props.color}`
  }
}))

MeasureUpInputCircle.propTypes = {
  color: PropTypes.string.isRequired
}

export default MeasureUpInputCircle
