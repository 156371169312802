import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import NextTaskLink from '../NextTaskLink'

const MeasureUpChestWaistHip = ({ nextTaskClick, nextTask }) => {
  return (
    <div>
      <TaskContentSubHeader title='Measure Chest/Waist/Hips' />
      <p>
        <b>Chest:</b> Using the centre of your nipple-line as a reference point, position the measuring tape around your
        upper back making sure that it is in one straight line, not twisted, or uneven. Bring the tape around the front
        in line to take the measurement.
      </p>
      <p>
        <b>Waist:</b> Using the centre of your bellybutton as a reference point, position the measuring tape around the
        middle of your body making sure as you bring the tape around to the front that it is line with your belly button
        as you take the measurement. Breathe out and let everything hang out as you take this measurement.
      </p>
      <p>
        <b>Hips:</b> Locate the widest part of your butt (standing side on to a mirror is easiest) then position the
        measuring tape so it encircles the middle of your bottom making sure that as you bring the tape around to the
        front that you are taking the measurement at the widest point.
      </p>
      <NextTaskLink link='Measure Thighs/Arms' onClick={() => nextTaskClick(nextTask)} />
    </div>
  )
}

MeasureUpChestWaistHip.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default MeasureUpChestWaistHip
