import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import TextInput from '../app/components/fields/TextInput'
import ContentContainer from '../app/components/ContentContainer'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAuthRouteAttempt,
  getMemberSummary,
  login
} from '../redux/actions/Member.actions'
import * as yup from 'yup'
import { Link, Redirect } from 'react-router-dom'
import backgroundPattern from '../app/assets/img/login/loginheaderbackground.png'
import logoWhite from '../app/assets/img/logos/12wbtlogowhite.png'
import Loader from '../app/components/Loader'

const loginFormValidationSchema = () => {
  return yup.object({
    email: yup
      .string()
      .email('Insert a valid email')
      .required('Your email is required')
      .test(
        'emailCheck',
        'Invalid email, your email must not include special characters',
        (value) =>
          value !== undefined && isValidEmail(value) && value.length > 1
      ),
    password: yup
      .string()
      .required('No password provided.')
      .min(6, 'Your password must be 6 characters minimum')
  })
}

// Emails with '+' are invalid within our ecosystem (Admin FE errors occur):
function isValidEmail(value) {
  if (value.includes('+') && process.env.REACT_APP_ENVIRONMENT === 'production')
    return false
  return true
}

const Login = () => {
  const [hrefLoading, setHrefLoading] = useState(false)
  const [redirectLink, setRedirectLink] = useState(false)
  const dispatch = useDispatch()
  const { member, memberLoginError, isAuthenticated, loading } = useSelector(
    (state) => state.member
  )
  const { memberSummary } = useSelector((state) => state.memberSummary)
  const memberSummaryLoading = useSelector(
    (state) => state.memberSummary?.loading
  )
  const { url } = useSelector((state) => state.authRouteAttempt)

  useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('auth_token')
  }, [])

  useEffect(() => {
    if (
      member &&
      isAuthenticated &&
      localStorage.token &&
      !loading &&
      !memberSummaryLoading &&
      memberSummary
    ) {
      const isCurrentRoundWeekGreaterThan11AndNextRoundCurrentlyOnPreSeason = checkIsCurrentRoundWeekGreaterThan11AndNextRoundCurrentlyOnPreSeason(
        memberSummary
      )

      // If a protected route has already attempted to be accessed, redirect to there:
      if (url) {
        setRedirectLink(url)
        dispatch(clearAuthRouteAttempt())
      } else if (
        memberSummary?.round_in_progress?.weeks &&
        !isCurrentRoundWeekGreaterThan11AndNextRoundCurrentlyOnPreSeason
      ) {
        // Get round start date from week 0
        const roundStartsDate = new Date(
          memberSummary.round_in_progress.weeks[0]?.start_date
        )
        // Set round start date time to midnight
        roundStartsDate.setHours(0, 0)

        if (new Date() >= roundStartsDate) {
          setHrefLoading(true)
          window.location.href = `${
            process.env.REACT_APP_GO_LOGIN
          }?redirectUrl=dashboard&t=${localStorage.getItem(
            'auth_token'
          )}&roundId=${memberSummary?.round_in_progress.id}`
        } else {
          setRedirectLink('/pre-season')
        }
      } else if (!redirectLink) {
        setRedirectLink('/pre-season')
      }
    }
  }, [member, memberSummary, setRedirectLink])

  const checkIsCurrentRoundWeekGreaterThan11AndNextRoundCurrentlyOnPreSeason = (
    memberSummary
  ) => {
    const nextRoundId = memberSummary.next_round?.id
    const isOnPreSeason =
      new Date() < new Date(memberSummary.next_round?.starts_at)
    const isCurrentRoundWeekGreaterThan11 =
      memberSummary?.round_in_progress?.current_week > 11

    return (
      memberSummary.subscriptions?.findIndex(
        (subscription) => subscription.round_id === nextRoundId
      ) !== -1 &&
      isOnPreSeason &&
      isCurrentRoundWeekGreaterThan11
    )
  }

  useEffect(() => {
    if (
      !memberSummary &&
      isAuthenticated &&
      localStorage.token &&
      !memberSummaryLoading
    ) {
      dispatch(getMemberSummary())
    }
  }, [memberSummary, member])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: false
    },
    validationSchema: loginFormValidationSchema(),
    onSubmit: (values) => {
      dispatch(login(values))
    }
  })
  const classes = useStyles()
  if (redirectLink) {
    return <Redirect to={redirectLink} />
  }
  if (loading || memberSummaryLoading || hrefLoading) return <Loader />
  return (
    <form className={classes.container} onSubmit={() => formik.submitForm()}>
      <div className={classes.loginHeader}>
        <img src={logoWhite} alt='12WBT Logo' />
      </div>
      <ContentContainer maxWidth='lg'>
        <div className={classes.formContainer}>
          <h2 className={classes.title}>Login</h2>
          {memberLoginError && (
            <div className={classes.errorDiv}>
              <span>
                <b>Oops - looks like something is missing.</b>&nbsp;Check the
                email and password you&apos;ve typed in, or{' '}
                <a href={`${process.env.REACT_APP_GO_SITE}password/reset`}>
                  reset here
                </a>
                .
              </span>
            </div>
          )}
          <TextInput
            formik={formik}
            name='email'
            type='text'
            placeholder='Email'
          />
          <TextInput
            formik={formik}
            name='password'
            type='password'
            placeholder='Password'
          />
          <div className={classes.loginFooter}>
            <div className={classes.footerRow}>
              <a href={`${process.env.REACT_APP_GO_SITE}password/reset`}>
                Forgot Your Password
              </a>
              <label className={classes.checkBoxRow}>
                <Checkbox
                  name='rememberMe'
                  value={formik.values.rememberMe}
                  classes={{ root: classes.checkbox }}
                  size='small'
                  inputProps={{ 'aria-label': 'Checkbox A' }}
                  color='primary'
                  onChange={formik.handleChange}
                />
                <Typography className={classes.rememberText}>
                  Remember Me
                </Typography>
              </label>
            </div>
            <Button
              className={classes.button}
              onClick={() => formik.submitForm()}
              type='submit'
            >
              {loading ? <CircularProgress size={20} /> : 'Login'}
            </Button>
            <div className={classes.signupContainer}>
              <span>Not a member yet?</span>
              <Link to='/signup' className={classes.joinLink}>
                Join my Team now!
              </Link>
            </div>
          </div>
        </div>
      </ContentContainer>
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  loginHeader: {
    height: '240px',
    width: '100%',
    backgroundImage: `url(${backgroundPattern})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    ...theme.CSS.center,
    '& img': {
      width: '17rem'
    },
    backgroundColor: theme.palette.signup.purple
  },
  title: {
    color: theme.palette.signup.purple,
    fontSize: '2rem'
  },
  formContainer: {
    maxWidth: '60%',
    textAlign: 'center',
    margin: '1rem auto',
    '& > div': {
      margin: '.2rem auto'
    }
  },
  button: {
    width: '100%',
    height: '39px',
    borderRadius: '6px',
    backgroundColor: '#5164E9',
    margin: 'auto',
    marginBottom: '1.5rem',
    color: 'white',
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 500,
    '&:hover': {
      border: `1px solid ${theme.palette.signup.purple}`,
      color: theme.palette.signup.purple
    }
  },
  footerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: '.7rem',
    margin: '1.3rem auto'
  },
  checkBoxRow: {
    fontSize: '.7rem',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  checkbox: {
    padding: 0,
    paddingRight: '.2rem'
  },
  loginFooter: {
    width: '260px',
    margin: '1rem auto',
    ...theme.CSS.center,
    flexDirection: 'column'
  },
  rememberText: {
    fontSize: '.7rem'
  },
  signupContainer: {
    ...theme.CSS.center,
    flexDirection: 'column',
    '& span': {
      fontSize: '.7rem',
      margin: '.5rem auto'
    }
  },
  joinLink: {
    color: theme.palette.signup.purple,
    textDecoration: 'underline',
    fontWeight: 600,
    fontSize: '1.1rem',
    '&:hover': {
      color: 'black'
    }
  },
  errorDiv: {
    width: '100%',
    color: 'red',
    '& a': {
      color: theme.palette.signup.purple,
      fontWeight: 'bold'
    },
    margin: '.5rem auto'
  },
  [theme.breakpoints.down('sm')]: {
    formContainer: {
      maxWidth: '80%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    formContainer: {
      maxWidth: '90%'
    }
  }
}))

export default Login
