import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const DefaultClearBtn = ({ title, onClick, href }) => {
  const classes = useStyles()
  return href ? (
    <a href={href} className={classes.defaultClearBtnStyle}>
      {title}
    </a>
  ) : (
    <span className={classes.defaultClearBtnStyle} onClick={onClick}>
      {title}
    </span>
  )
}

const useStyles = makeStyles((theme) => ({
  defaultClearBtnStyle: {
    color: theme.palette.signup.purple,
    fontWeight: 800,
    fontSize: '1.1rem',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

DefaultClearBtn.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  href: PropTypes.string
}

export default DefaultClearBtn
