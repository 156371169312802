import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextField } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
const CalculatorInput = ({ formik, name }) => {
  const classes = useStyles()
  return (
    <div className={classes.textInput}>
      <div className={classes.lock}>
        <AttachMoneyIcon />
      </div>
      <TextField
        fullWidth
        InputProps={{ className: classes.textField }}
        id={name}
        name={name}
        variant='outlined'
        type='number'
        value={formik.values[name]}
        onChange={formik.handleChange}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  textInput: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0'
  },
  lock: {
    width: '2.6rem',
    height: '2.6rem',
    backgroundColor: theme.palette.grey[400],
    marginRight: '-3px',
    zIndex: 10,
    ...theme.CSS.center,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    '& svg': {
      color: 'white',
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  textField: {
    height: '2.6rem'
  },
  [theme.breakpoints.down('sm')]: {
    textInput: {
      margin: '.2rem 0'
    }
  }
}))

CalculatorInput.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
}

export default CalculatorInput
