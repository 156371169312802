import axiosWrapper from '../axiosInstance'

import {
  GET_NEXT_AVAILABLE_ROUND_REQUEST,
  GET_NEXT_AVAILABLE_ROUND_SUCCESS,
  GET_NEXT_AVAILABLE_ROUND_FAIL,
  UPDATE_LASSO_REQUEST,
  UPDATE_LASSO_SUCCESS,
  UPDATE_LASSO_FAIL,
  VALIDATE_COUPON_REQUEST,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  CHECK_MEMBER_REQUEST,
  CHECK_MEMBER_SUCCESS,
  CHECK_MEMBER_FAIL,
  CHECK_FRIEND_MEMBER_REQUEST,
  CHECK_FRIEND_MEMBER_SUCCESS,
  CHECK_FRIEND_MEMBER_FAIL,
  GET_PRICE_POINTS_REQUEST,
  GET_PRICE_POINTS_SUCCESS,
  GET_PRICE_POINTS_FAIL,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_LOGIN_REQUEST,
  SIGNUP_LOGIN_SUCCESS,
  SIGNUP_LOGIN_FAIL,
  SIGNUP_RESET_ERROR,
  SIGNUP_LOGIN_RESET_ERROR,
  GET_SECURITY_TOKEN_REQUEST,
  GET_SECURITY_TOKEN_SUCCESS,
  GET_SECURITY_TOKEN_FAIL,
  GET_ROUND_INFO_REQUEST,
  GET_ROUND_INFO_SUCCESS,
  GET_ROUND_INFO_FAIL,
  GET_LIFERS_PRICE_POINTS_REQUEST,
  GET_LIFERS_PRICE_POINTS_SUCCESS,
  GET_LIFERS_PRICE_POINTS_FAIL,
  SET_SELECTED_PRICE,
  GET_CURRENT_AND_NEXT_ROUND_DATES_REQUEST,
  GET_CURRENT_AND_NEXT_ROUND_DATES_SUCCESS,
  GET_CURRENT_AND_NEXT_ROUND_DATES_FAIL,
  GET_FRIEND_PRICE_POINTS_REQUEST,
  GET_FRIEND_PRICE_POINTS_SUCCESS,
  GET_FRIEND_PRICE_POINTS_FAIL,
  SIGNUP_WITH_FRIEND_REQUEST,
  SIGNUP_WITH_FRIEND_SUCCESS,
  SIGNUP_WITH_FRIEND_FAIL,
  SIGNUP_RESET_SUCCESS
} from '../constants/Signup.constants'

// {URL}signup/nextAvailableRound
export const getNextAvailableRound = () => async (dispatch) => {
  try {
    dispatch({ type: GET_NEXT_AVAILABLE_ROUND_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}signup/nextAvailableRound`
    )
    dispatch({ type: GET_NEXT_AVAILABLE_ROUND_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_NEXT_AVAILABLE_ROUND_FAIL })
  }
}

// {URL}signup/get-next-available-rounds
export const getCurrentAndNextRoundDates = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CURRENT_AND_NEXT_ROUND_DATES_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}signup/rounds`
    )
    dispatch({
      type: GET_CURRENT_AND_NEXT_ROUND_DATES_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    dispatch({ type: GET_CURRENT_AND_NEXT_ROUND_DATES_FAIL })
  }
}

// {URL}signup/check-member/:email
export const checkMember = (email) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_MEMBER_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}signup/check-member/${email}`
    )
    dispatch({ type: CHECK_MEMBER_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: CHECK_MEMBER_FAIL })
  }
}

// {URL}signup/check-member/:email
export const checkFriendMember = (email) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_FRIEND_MEMBER_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}signup/check-member/${email}`
    )
    dispatch({ type: CHECK_FRIEND_MEMBER_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: CHECK_FRIEND_MEMBER_FAIL })
  }
}
// {URL}signup/lasso
export const updateLasso = (values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_LASSO_REQUEST })
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}signup/lasso`,
      values
    )
    dispatch({ type: UPDATE_LASSO_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: UPDATE_LASSO_FAIL })
  }
}

// {URL}signup/validate-coupon
export const validateCoupon = (
  coupon,
  paymentPlanId,
  onValidCoupon,
  onInvalidCoupon
) => async (dispatch) => {
  try {
    dispatch({ type: VALIDATE_COUPON_REQUEST })
    const res = await axiosWrapper.get(
      `${
        process.env.REACT_APP_API_HOST
      }signup/validate-coupon/${coupon.toUpperCase()}/${paymentPlanId}`
    )
    dispatch({ type: VALIDATE_COUPON_SUCCESS, payload: res.data })
    onValidCoupon()
  } catch (err) {
    dispatch({ type: VALIDATE_COUPON_FAIL })
    onInvalidCoupon()
  }
}

// {URL}signup/price-points
export const getPricePoints = (couponCode) => async (dispatch) => {
  try {
    const endpoint = couponCode
      ? `price-points?code=${couponCode.toUpperCase()}`
      : 'price-points'

    dispatch({ type: GET_PRICE_POINTS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}api/v1/signup/${endpoint}`
    )
    dispatch({ type: GET_PRICE_POINTS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_PRICE_POINTS_FAIL })
  }
}

// {URL}signup/price-points
export const getLifersPricePoints = (couponCode) => async (dispatch) => {
  const endpoint = couponCode
    ? `price-points?lifestyle=true&code=${couponCode.toUpperCase()}`
    : 'price-points?lifestyle=true'
  try {
    dispatch({ type: GET_LIFERS_PRICE_POINTS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}api/v1/signup/${endpoint}`
    )
    dispatch({ type: GET_LIFERS_PRICE_POINTS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_LIFERS_PRICE_POINTS_FAIL })
  }
}

// {URL}signup/price-points/friend
export const getFriendPricePoints = () => async (dispatch) => {
  try {
    dispatch({ type: GET_FRIEND_PRICE_POINTS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}signup/price-points/friend`
    )
    dispatch({ type: GET_FRIEND_PRICE_POINTS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_FRIEND_PRICE_POINTS_FAIL })
  }
}

export const getSecurityToken = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SECURITY_TOKEN_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}api/v1/signup/chargify-token`
    )
    dispatch({ type: GET_SECURITY_TOKEN_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_SECURITY_TOKEN_FAIL })
  }
}

// {URL}signup
export const signUp = (values) => async (dispatch) => {
  try {
    values.email = values.email.toLowerCase()
    dispatch({ type: SIGNUP_REQUEST })
    axiosWrapper.defaults.withCredentials = true
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}api/v1/signup`,
      values
    )
    dispatch({ type: SIGNUP_SUCCESS, payload: res.data })
  } catch (err) {
    let error = [' ']
    if (err.response) {
      error = err.response.data?.message || [err.response.data]
    }
    dispatch({ type: SIGNUP_FAIL, payload: error })
  }
}

// {URL}signup/friend
export const signUpWithFriend = (values) => async (dispatch) => {
  try {
    dispatch({ type: SIGNUP_WITH_FRIEND_REQUEST })
    axiosWrapper.defaults.withCredentials = true
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}signup/friend`,
      values
    )
    dispatch({ type: SIGNUP_WITH_FRIEND_SUCCESS, payload: res.data })
  } catch (err) {
    let error = [' ']
    if (err.response) {
      error = err.response.data?.message || [err.response.data]
    }
    dispatch({ type: SIGNUP_WITH_FRIEND_FAIL, payload: error })
  }
}

// {URL}login
export const signUpLogin = ({ email, password }) => async (dispatch) => {
  try {
    dispatch({ type: SIGNUP_LOGIN_REQUEST })
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}auth/login`,
      { email, password }
    )
    dispatch({ type: SIGNUP_LOGIN_SUCCESS, payload: res.data })
  } catch (error) {
    dispatch({ type: SIGNUP_LOGIN_FAIL })
  }
}

export const getRoundInfo = (roundId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ROUND_INFO_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/rounds/${roundId}`
    )
    dispatch({ type: GET_ROUND_INFO_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_ROUND_INFO_FAIL })
  }
}

export const resetSignUpError = () => async (dispatch) => {
  dispatch({ type: SIGNUP_RESET_ERROR })
}

export const resetSignUpLoginError = () => async (dispatch) => {
  dispatch({ type: SIGNUP_LOGIN_RESET_ERROR })
}

/**
 *
 * @param {Number} priceInDollars  (Dollars) used to display text above signup submit button (you're investing ${price} in changes for life)
 * @param {Number} GATotalCostInCents used to send signup value to Google analytics
 * @param {Boolean} isWeekly used to alter text above signup submit button
 * @param {Boolean} isDiscounted used to determine if couponCode should be re-applied in create account signup login modal
 * @returns
 */
export const setSelectedPrice = (
  priceInDollars,
  GATotalCostInCents,
  isWeekly,
  isDiscounted
) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_PRICE,
    payload: { priceInDollars, GATotalCostInCents, isWeekly, isDiscounted }
  })
}

export const resetSignUpSuccess = () => async (dispatch) => {
  dispatch({ type: SIGNUP_RESET_SUCCESS })
}
