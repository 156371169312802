import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearUpdateSubscriptionSuccess, updatePreSeasonProgress } from '../../../redux/actions/Member.actions'
import loadCurrentSubscriptionAndRound from '../../helpers//LoadCurrentSubscriptionAndRound'

const WelcomeRedirector = () => {
  const updateSubscriptionSuccess = useSelector((state) => state.member.updateSubscriptionSuccess)
  const currentSubscriptionId = useSelector((state) => state.member.currentSubscriptionId)
  const memberLoading = useSelector((state) => state.member.loading)
  const updateProfileSuccess = useSelector((state) => state.profile.updateProfileSuccess)
  const profileLoading = useSelector((state) => state.profile.loading)
  const buildANewPlan = useSelector((state) => state.profile.buildANewPlan)
  const progress = useSelector((state) => state.preseason.progress)
  const dispatch = useDispatch()
  const [redirect, setRedirect] = useState(null)

  loadCurrentSubscriptionAndRound()

  // On completed welcome step one, update pre-season progress:
  useEffect(() => {
    if (!profileLoading) {
      if ((updateProfileSuccess && currentSubscriptionId) || (buildANewPlan && currentSubscriptionId)) {
        // Get member summary again (updateProfile doesnt return updated subscription):
        // dispatch(getMemberSummary())

        dispatch(updatePreSeasonProgress(currentSubscriptionId, { welcome_steps_1: true }))
      }
    }
  }, [updateProfileSuccess, buildANewPlan])

  // On completed welcome step two, update pre-season progress:
  useEffect(() => {
    if (!memberLoading) {
      if (updateSubscriptionSuccess && currentSubscriptionId) {
        // Get member summary again (updateSubscription doesnt return updated subscription):
        // dispatch(getMemberSummary())
        dispatch(clearUpdateSubscriptionSuccess())
        // Update pre-season/welcome steps progress:
        dispatch(updatePreSeasonProgress(currentSubscriptionId, { welcome_steps_1: true, welcome_steps_2: true }))
      }
    }
  }, [updateSubscriptionSuccess])

  // Redirect to correct welcome step:
  useEffect(() => {
    if (progress) {
      if (progress.welcome_steps_2) {
        setRedirect('/pre-season')
      } else if (progress.welcome_steps_1) {
        setRedirect('/welcome/step-2')
      } else {
        setRedirect('/welcome/step-1')
      }
    }
  }, [progress])

  if (redirect) return <Redirect to={redirect} />
  return null
}

export default WelcomeRedirector
