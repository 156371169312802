import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ExcusesTextAreaInput from './ExcusesTextAreaInput'

const ExcusesTable = ({ formik }) => {
  const classes = useStyles()
  return (
    <div className={classes.tableContainer}>
      <div className={classes.tableRow}>
        <ExcusesTextAreaInput formik={formik} name='internalExcuses' label='Internal Excuses' isHeader={true} />
        <ExcusesTextAreaInput formik={formik} name='internalExcusesSolutions' label='Solutions' />
      </div>
      <div className={classes.tableRow}>
        <ExcusesTextAreaInput
          formik={formik}
          name='externalExcusesWithMyControl'
          label='External Excuses Within My Control'
          isHeader={true}
        />
        <ExcusesTextAreaInput formik={formik} name='externalExcusesWithMyControlSolutions' label='Solutions' />
      </div>
      <div className={classes.tableRow}>
        <ExcusesTextAreaInput
          formik={formik}
          name='externalExcusesOutsideMyControl'
          label='External Excuses Outside My Control'
          isHeader={true}
        />
        <ExcusesTextAreaInput formik={formik} name='externalExcusesOutsideMyControlSolutions' label='Solutions' />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginBottom: '2rem'
  },
  tableRow: {
    display: 'flex',
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    tableRow: {
      flexDirection: 'column',
      width: '90%',
      margin: 'auto'
    },
    tableContainer: {
      width: '90%'
    }
  }
}))

ExcusesTable.propTypes = {
  formik: PropTypes.object.isRequired
}

export default ExcusesTable
