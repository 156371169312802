import React from 'react'
import WelcomeStepsFooter from '../WelcomeStepsFooter'
import PropTypes from 'prop-types'

const WelcomeReturnStepOneContinue = ({ onContinueClick, loading }) => {
  return <WelcomeStepsFooter onSubmit={onContinueClick} buttonTitle='Continue' loading={loading} />
}

WelcomeReturnStepOneContinue.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default WelcomeReturnStepOneContinue
