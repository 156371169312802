import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import BrightCovePlayer from '../../../components/BrightCovePlayer'

const TaskVideo = ({ videoId, paddingTop, needsCenterAdjust }) => {
  const classes = useStyles({ paddingTop, needsCenterAdjust })
  return (
    <div className={classes.videoContainer}>
      <BrightCovePlayer videoId={videoId} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    paddingTop: (props) => props.paddingTop,
    width: '900px',
    height: '506px',
    margin: 'auto',
    marginLeft: (props) => (props.needsCenterAdjust ? '-90px' : '')
  },
  [theme.breakpoints.down('md')]: {
    videoContainer: {
      width: '712px',
      height: '400px',
      margin: 'auto!important'
    }
  },
  [theme.breakpoints.down('sm')]: {
    videoContainer: {
      width: '534px',
      height: '300px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    videoContainer: {
      width: '392px',
      height: '220px'
    }
  },
  [theme.breakpoints.down(430)]: {
    videoContainer: {
      width: '320px',
      height: '180px'
    }
  },
  [theme.breakpoints.down(370)]: {
    videoContainer: {
      width: '285px',
      height: '160px'
    }
  }
}))

TaskVideo.defaultProps = {
  needsCenterAdjust: false
}

TaskVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  paddingTop: PropTypes.string,
  needsCenterAdjust: PropTypes.bool
}

export default TaskVideo
