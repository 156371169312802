import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const WelcomeCompleteFeature = ({ day, color, title, description }) => {
  const classes = useStyles({ color })
  return (
    <div className={classes.container}>
      <div className={classes.circle}>
        <span>{day}</span>
      </div>
      <div className={classes.contentContainer}>
        <span className={classes.title}>{title}</span>
        <span className={classes.description}>{description}</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    color: 'black'
  },
  circle: {
    minWidth: '3rem',
    minHeight: '3rem',
    height: '3rem',
    borderRadius: '50%',
    backgroundColor: (props) => props.color,
    color: 'white',
    fontSize: '1rem',
    ...theme.CSS.center
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '1rem',
    textAlign: 'start',
    fontSize: '.9rem'
  },
  title: {
    fontWeight: 'bold'
  }
}))

WelcomeCompleteFeature.propTypes = {
  day: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default WelcomeCompleteFeature
