const months = [
  {
    label: '01',
    value: 1
  },
  {
    label: '02',
    value: 2
  },
  {
    label: '03',
    value: 3
  },
  {
    label: '04',
    value: 4
  },
  {
    label: '05',
    value: 5
  },
  {
    label: '06',
    value: 6
  },
  {
    label: '07',
    value: 7
  },
  {
    label: '08',
    value: 8
  },
  {
    label: '09',
    value: 9
  },
  {
    label: '10',
    value: 10
  },
  {
    label: '11',
    value: 11
  },
  {
    label: '12',
    value: 12
  }
]

const states = [
  { label: 'NSW', value: 'NSW' },
  { label: 'VIC', value: 'VIC' },
  { label: 'QLD', value: 'QLD' },
  { label: 'NT', value: 'NT' },
  { label: 'WA', value: 'WA' },
  { label: 'SA', value: 'SA' },
  { label: 'ACT', value: 'ACT' },
  { label: 'TAS', value: 'TAS' }
]

const currentYear = new Date().getFullYear()
const firstPossibleYear = currentYear - 100

const getYears = () => {
  const yearsArray = []
  for (let i = 0; i < 95; i++) {
    yearsArray.unshift({ label: (firstPossibleYear + i).toString(), value: firstPossibleYear + i })
  }
  return yearsArray
}

const getDays = () => {
  const days = []
  for (let i = 1; i < 32; i++) {
    days.push({ label: i, value: i })
  }
  return days
}

export { months, states, getYears, getDays }
