import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../../redux/actions/Member.actions'
import { Redirect } from 'react-router-dom'

const LogoutRoute = () => {
  const [logoutCalled, setLogoutCalled] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(logout())
    setLogoutCalled(true)
  }, [])

  return logoutCalled ? <Redirect to='/login' /> : null
}

export default LogoutRoute
