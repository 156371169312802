import React from 'react'
import TaskContentContainer from '../tasks/TaskContentContainer'

const GuideWeeklyPhoto = () => {
  return (
    <TaskContentContainer>
      <p>One of the best ways to track your progress and non-scale victories is to take photos;</p>
      <ol>
        <li>
          At the <b>start and end</b> of your 12 weeks,
        </li>
        <li>
          At the <b>start of every new week</b> for 12 weeks,
        </li>
        <li>
          Or staggered throughout the Round at <b>weeks 1, 4, 8 and 12</b>.
        </li>
      </ol>
      <p>
        Throughout your 12WBT, it’s easy to feel down or disheartened when the number on the scales don’t seem to budge.
        That’s why taking photos at every stage gives a great reminder of how far you have come on your journey!
      </p>
      <p>
        I know it can be really hard to take the first step! Hear me out here; your 12 weeks is about more than weight
        loss, it’s about more than numbers on the scale, it’s about learning to love and appreciate your body (including
        your flaws - we all have them!!) and creating a positive relationship with food, fitness and mindset.
      </p>
      <p>
        And so, I’ve put together a few guidelines for you to follow so you can take the best possible progress photos:
      </p>
      <ul>
        <li>
          It’s best to wear form-fitting clothing, for example; shorts, tights, crop top, singlet - whatever you’re
          comfortable in! Wear the same type and colour of clothing throughout the 12 weeks.
        </li>
        <li>
          Please be aware if you do take your photo in your underwear or swimwear (although an awesome way to track your
          transformation and great for personal use) they will not be featured on social media for privacy reasons.
        </li>
        <li>
          The best photos are taken on a plain wall or background - with minimal clutter so that you can focus on your
          transformation! Please include our 12WBT wallpapers on your phone, iPad or printed on a piece of paper.
        </li>
        <li>
          Try to take up most of the photo. Include your whole body, from head to toe. If you are a speck in the
          distance it’s harder to see your results!
        </li>
        <li>Stand in the same position in your weekly photos (so you can compare apples with apples!).</li>
        <li>I recommend that you take your photos in portrait rather than landscape.</li>
        <p>
          If you need support throughout your 12 weeks make sure you reach out to Support Crew via email or the Facebook
          Member Zone - my team are here for all of your needs so don’t be shy!
        </p>
        <p>I look forward to seeing your transformation 12 weeks from now!</p>
        <p>
          <b>Quote:</b>
        </p>
        <p>
          <em>
            “I keep my before and transformation photos WK 4,8,12 on my bathroom mirror to remind myself how far I’ve
            come and how damn good I look!”
          </em>
        </p>
        <p>
          <b>12WBTer Jaymee</b>
        </p>
      </ul>
    </TaskContentContainer>
  )
}

export default GuideWeeklyPhoto
