import React from 'react'

// Task Images:
import FitnessTestAndMeasureUp from '../../../assets/img/pre_season/FitnesTestAndMeasureTest.png'
import NutOutYourNutrition from '../../../assets/img/pre_season/NutOutYourNutrition.jpg'
import OrganiseAndDiarise from '../../../assets/img/pre_season/OrganiseAndDiarise.jpg'
import IntroduceYourself from '../../../assets/img/pre_season/IntroduceYourself.jpg'
import SetYourselfUpForSuccess from '../../../assets/img/pre_season/SetYourselfUpforSuccess.jpg'

// Activity Icons:
import { ReactComponent as GetMovingIcon } from '../../../assets/img/icons/pre_season/GetMoving.svg'
import { ReactComponent as BuildingYourInnerCoreIcon } from '../../../assets/img/icons/pre_season/BuldingYourInnerCore.svg'
import { ReactComponent as MakingMoreMeTimeIcon } from '../../../assets/img/icons/pre_season/MakeMoreMETime.svg'
// import { ReactComponent as BetterThanYesterday } from '../../../assets/img/icons/pre_season/BetterthanYesterday.svg'

// Early Access Link (to workouts / recipes) Images:
import RecipeIndex from '../../../assets/img/pre_season/RecipeIndex.jpg'
import FitnessVideos from '../../../assets/img/pre_season/FitnessVideos.jpg'
import ExpressWorkouts from '../../../assets/img/pre_season/ExpressWorkout.png'
import MindsetVideos from '../../../assets/img/pre_season/MindsetVideos.jpg'
import MemberZone from '../../../assets/img/pre_season/FBMemberZone.png'

// Guide Tips Icons:
import { ReactComponent as NutritionTruths } from '../../../assets/img/icons/pre_season/NutritionTruthsBadge.svg'
import { ReactComponent as NavigateNutrition } from '../../../assets/img/icons/pre_season/NavigateNutrition.svg'
import { ReactComponent as NavigateFitness } from '../../../assets/img/icons/pre_season/NavigateFitness.svg'
import { ReactComponent as GearUp } from '../../../assets/img/icons/pre_season/GearUp.svg'
import { ReactComponent as SupportCrew } from '../../../assets/img/icons/pre_season/CommunitySupportCrew.svg'
import { ReactComponent as How12WBTWorks } from '../../../assets/img/icons/pre_season/How12WBTWorks.svg'
import { ReactComponent as TechTips } from '../../../assets/img/icons/pre_season/TechTips.svg'
import { ReactComponent as WeeklyPhoto } from '../../../assets/img/icons/pre_season/Camera.svg'

// Guide Links:
import {
  GEAR_UP,
  HOW_12WBT_WORKS,
  NAVIGATE_FITNESS,
  NAVIGATE_NUTRITION,
  NUTRITION_TRUTHS,
  GET_TO_KNOW_SUPPORT,
  WEEKLY_PHOTO,
  TECH_TIPS
} from '../guides/GuideData'

import {
  GET_MOVING,
  BUILDING_YOUR_INNER_CORE_STRENGTH,
  MAKING_MORE_ME_TIME,
  BETTER_THAN_YESTERDAY,
  activities
} from '../activities/ActivityData'

import {
  NUT_OUT_YOUR_NUTRITION,
  ORGANISE_AND_DIARISE,
  FITNESS_TEST_AND_MEASURE_UP,
  INTRODUCE_YOURSELF,
  SET_YOURSELF_UP_FOR_SUCCESS,
  tasks
} from '../tasks/TaskData'

const taskCardsData = [
  {
    title: tasks[SET_YOURSELF_UP_FOR_SUCCESS].header,
    link: SET_YOURSELF_UP_FOR_SUCCESS,
    time: '10 min',
    description:
      'In order to strive to achieve something, you need to set a series of goals that will lead you to your ultimate goal. Let’s get real, find your why and set your SMART goals.',
    bannerImage: SetYourselfUpForSuccess,
    done: false,
    required: true
  },
  {
    title: tasks[FITNESS_TEST_AND_MEASURE_UP].header,
    link: FITNESS_TEST_AND_MEASURE_UP,
    time: '10 min',
    description:
      'If there’s only 1 task you do, make sure it’s this one! In order to know how far you’ve come, you have to know where you started...that is why this task is SO important.',
    bannerImage: FitnessTestAndMeasureUp,
    done: true,
    required: true
  },
  {
    title: tasks[NUT_OUT_YOUR_NUTRITION].header,
    link: NUT_OUT_YOUR_NUTRITION,
    time: '10 min',
    description:
      'Nutrition is everything when it comes to our health! In this task you’ll learn how to get your kitchen prepped, clear out your pantry and fridge, as well as how to shop smart at the supermarket.',
    bannerImage: NutOutYourNutrition,
    done: false,
    required: false
  },
  {
    title: tasks[ORGANISE_AND_DIARISE].header,
    link: ORGANISE_AND_DIARISE,
    time: '10 min',
    description:
      'Diarising and planning is the best way to guarantee you’ll get your workouts, meals and shopping done. In this task we’ll show you how to plan smart.',
    bannerImage: OrganiseAndDiarise,
    done: false,
    required: false
  },
  {
    title: tasks[INTRODUCE_YOURSELF].header,
    link: INTRODUCE_YOURSELF,
    time: '10 min',
    description: `It's time to get friendly with your fellow 12WBTers and my Support Crew. In this task we’ll show you how to connect and introduce yourself to the 12WBT family.`,
    bannerImage: IntroduceYourself,
    done: false,
    required: false
  }
]

const activityCardsData = [
  {
    title: activities[GET_MOVING].header,
    icon: <GetMovingIcon />,
    link: GET_MOVING,
    description:
      'It’s time to get you used to moving, whether it be incidental like taking the stairs instead of the lift or committing to a solid walk each day before the Round starts. '
  },
  {
    title: activities[BUILDING_YOUR_INNER_CORE_STRENGTH].header,
    icon: <BuildingYourInnerCoreIcon />,
    link: BUILDING_YOUR_INNER_CORE_STRENGTH,
    description:
      'A structure is only as strong as its foundations, so building your deep core and pelvic floor muscles is essential. Use this core routine you can practice before the Round starts.'
  },
  {
    title: activities[MAKING_MORE_ME_TIME].header,
    icon: <MakingMoreMeTimeIcon />,
    link: MAKING_MORE_ME_TIME,
    description:
      'This Activity is all about self-assessment to determine what you actually need the most to be kind to your body and function at your very best. '
  },
  {
    title: activities[BETTER_THAN_YESTERDAY].header,
    icon: <MakingMoreMeTimeIcon />,
    link: BETTER_THAN_YESTERDAY,
    description:
      'This Activity is all about celebrating consistent progress throughout all of the exercise and nutrition that you tackle through your Round.'
  }
]

const earlyAccessLinks = [
  {
    title: 'Recipe Index',
    backgroundImage: RecipeIndex,
    link: 'recipes'
  },
  {
    title: 'Workout Videos',
    backgroundImage: FitnessVideos,
    link: 'videos/category/workout'
  },
  {
    title: 'Express Workouts',
    backgroundImage: ExpressWorkouts,
    link: 'exercises/express-workouts'
  },
  {
    title: 'Mindset Videos',
    backgroundImage: MindsetVideos,
    link: 'videos/category/mindset'
  },
  {
    title: 'Facebook Member Zone',
    backgroundImage: MemberZone,
    link: process.env.REACT_APP_12WBT_FACEBOOK_MEMBER_ZONE,
    externalTo12WBT: true
  }
]

const guideTips = [
  {
    title: 'Nutrition Truths + Snack Swaps',
    icon: <NutritionTruths />,
    description: 'Your success will come down to making the most INFORMED choices when it comes to your eating.',
    link: NUTRITION_TRUTHS
  },
  {
    title: 'How to Navigate Nutrition',
    icon: <NavigateNutrition />,
    description:
      'To nail your nutrition, you must master your Meal Plan and know all the tools on the site that are available.',
    link: NAVIGATE_NUTRITION
  },
  {
    title: 'How to Navigate Fitness',
    icon: <NavigateFitness />,
    description:
      'To be on top of your workouts, here’s everything you need to know about where to find all fitness content.',
    link: NAVIGATE_FITNESS
  },
  {
    title: 'Gear Up',
    icon: <GearUp />,
    description:
      'To gain every success you aim to strive for, it’s best to start with the basics of what you need to help get you there. ',
    link: GEAR_UP
  },
  {
    title: 'Get to know the Support Crew',
    icon: <SupportCrew />,
    description:
      'Now that you’re a 12WBTer, it’s time for you to get acquainted with my Support Crew and put faces to the names that will help guide and support you.',
    link: GET_TO_KNOW_SUPPORT
  },
  {
    title: 'How 12WBT works',
    icon: <How12WBTWorks />,
    description:
      'Started your 12WBT but need a bit of guidance with how it all works and what you have access to? Here is everything you need to know!',
    link: HOW_12WBT_WORKS
  },
  {
    title: 'Tech Tips',
    icon: <TechTips />,
    description:
      'Need a little help in the tech department? Here is the rundown of how to connect and watch our videos on different types of devices.',
    link: TECH_TIPS
  },
  {
    title: `Take a weekly photo! Here's why!`,
    icon: <WeeklyPhoto />,
    description:
      'One of the best ways to track your progress throughout your 12WBT is to take photos, it’s a great reminder of how far you have come on your journey!',
    link: WEEKLY_PHOTO
  }
]

export { taskCardsData, activityCardsData, earlyAccessLinks, guideTips }
