import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import TabItem from './TabItem'

const TabSelector = ({ activeColor, activeHeader, userSelectedProgram }) => {
  const classes = useStyles()

  return (
    <div className={classes.valuesContainer}>
      <TabItem
        value={activeHeader.value}
        title={activeHeader.name}
        activeColor={activeColor}
        activeTab={userSelectedProgram}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  valuesContainer: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '5px',
    // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    // border: `1px solid ${theme.palette.greys.mediumLight}`
  }
}))

TabSelector.propTypes = {
  activeColor: PropTypes.string,
  activeHeader: PropTypes.object,
  userSelectedProgram: PropTypes.string
}

export default TabSelector
