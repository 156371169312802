import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'
import TaskContentSubHeader from '../../TaskContentSubHeader'
import NextTaskLink from '../NextTaskLink'

const MeasureUpHowThisWorks = ({ nextTaskClick, nextTask }) => {
  const classes = useStyles()
  return (
    <div className={classes.textContainer}>
      <TaskContentSubHeader title='How this works' />
      <ul>
        <li>Weigh yourself</li>
        <li>Take your body measurements</li>
        <li>Take your &apos;before&apos; photo</li>
        <li>Record your results and upload your before photo by the end of week 1</li>
      </ul>
      <TaskContentSubHeader title={`What you'll need to do the test:`} />
      <ul>
        <li>A set of scales</li>
        <li>A tape measure</li>
        <li>A digital camera or your smartphone with camera</li>
      </ul>
      <NextTaskLink link='Weighing Yourself' onClick={() => nextTaskClick(nextTask)} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  textContainer: {
    '& ul': {
      padding: '1rem',
      paddingTop: 0
    },
    '& ol': {
      padding: '1rem',
      paddingTop: 0
    },
    '& li': {
      marginBottom: '1rem'
    }
  }
}))

MeasureUpHowThisWorks.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default MeasureUpHowThisWorks
