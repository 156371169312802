import React, { useEffect } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import { Route, Switch, useLocation } from 'react-router-dom'
import { setAuthToken } from './app/helpers/AuthTokenHelper'
import CssBaseline from '@material-ui/core/CssBaseline'

// import SignUp from './screens/SignUp'
import WelcomeStepOne from './screens/WelcomeStepOne'
import WelcomeStepTwo from './screens/WelcomeStepTwo'
import WelcomeComplete from './screens/WelcomeComplete'
import Welcome from './screens/Welcome'
import PreSeason from './screens/PreSeason'
import PreSeasonTask from './screens/PreSeasonTask'
import PreSeasonActivity from './screens/PreSeasonActivity'
import Login from './screens/Login'

import { loadMember } from './redux/actions/Member.actions'
import { useDispatch, useSelector } from 'react-redux'
import PrivateRoute from './app/components/PrivateRoute'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import PreSeasonGuide from './screens/PreSeasonGuide'
import LogoutRoute from './app/components/LogoutRoute'
import SignUp from './screens/SignUp'
import Alert from './app/components/Alert'

if (localStorage.token) {
  setAuthToken(localStorage.token)
}

const App = () => {
  const gtmParams = {
    id: process.env.REACT_APP_GTM_ID
  }

  const dispatch = useDispatch()
  const { loading, isAuthenticated } = useSelector((state) => state.member)
  const location = useLocation()

  // Try load member on app load:
  useEffect(() => {
    if (
      !loading &&
      !isAuthenticated &&
      !location.pathname.includes('logout') &&
      !location.pathname === '/'
    ) {
      dispatch(loadMember())
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GTMProvider state={gtmParams}>
        <Alert />
        <Switch>
          <Route exact path='/' component={() => <SignUp />} />
          <Route exact path='/signup' component={() => <SignUp />} />
          <Route
            exact
            path='/signup/lifestyle'
            component={() => <SignUp lifestyle={true} />}
          />
          <Route exact path='/login' component={Login} />
          <Route exact path='/logout' component={LogoutRoute} />
          <PrivateRoute exact path='/welcome' component={Welcome} />
          <PrivateRoute
            exact
            path='/welcome/step-1'
            component={WelcomeStepOne}
          />
          <PrivateRoute
            exact
            path='/welcome/step-2'
            component={WelcomeStepTwo}
          />
          <PrivateRoute
            exact
            path='/welcome/complete'
            component={WelcomeComplete}
          />
          <PrivateRoute exact path='/pre-season' component={PreSeason} />
          <PrivateRoute path='/pre-season/tasks' component={PreSeasonTask} />
          <PrivateRoute path='/pre-season/guides' component={PreSeasonGuide} />
          <PrivateRoute
            path='/pre-season/activities'
            component={PreSeasonActivity}
          />
          <Route path='*' component={Login} />
        </Switch>
      </GTMProvider>
    </ThemeProvider>
  )
}

export default App
