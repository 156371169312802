export const GEAR_UP = 'gear-up'
export const HOW_12WBT_WORKS = 'how-12wbt-works'
export const BASICS_OF_EATING_HEALTHY = 'basics-of-eating-healthy'
export const NAVIGATE_FITNESS = 'navigate-fitness'
export const NAVIGATE_NUTRITION = 'navigate-nutrition'
export const NUTRITION_TRUTHS = 'nutrition-truths'
export const NUTRITION_TRUTHS_QUIZ = 'nutrition-truths-quiz'
export const GET_TO_KNOW_SUPPORT = 'get-to-know-support'
export const WEEKLY_PHOTO = 'weekly-photo'
export const TECH_TIPS = 'tech-tips'

export const guides = {
  [GEAR_UP]: {
    header: 'How to Gear Up!',
    intro: `To gain every success you aim to strive for, it’s best to start with the basics of what you need to help get you there.`
  },
  [HOW_12WBT_WORKS]: {
    header: 'How 12WBT works',
    intro:
      'Started your 12WBT but need a bit of guidance with how it all works and what you have access to? Here is everything you need to know!'
  },
  [BASICS_OF_EATING_HEALTHY]: {
    header: 'The Basics of Eating Healthy'
  },
  [NAVIGATE_FITNESS]: {
    header: 'How to Navigate Fitness',
    intro: `To be on top of your workouts, here's everything you need to know about where to find all fitness content.`
  },
  [NAVIGATE_NUTRITION]: {
    header: 'How to Navigate Nutrition',
    intro:
      'To nail your nutrition, you must master your Meal Plan and know all the tools on the site that are available.'
  },
  [NUTRITION_TRUTHS]: {
    header: 'Nutrition Truths + Snack Swaps'
  },
  [NUTRITION_TRUTHS_QUIZ]: {
    header: 'Nutrition Truths Quiz'
  },
  [GET_TO_KNOW_SUPPORT]: {
    header: 'Get to know the Support Crew'
  },
  [WEEKLY_PHOTO]: {
    header: `Take a weekly photo! Here's why!`,
    intro: `One of the best ways to track your progress throughout your 12WBT and non-scale victories is to take photos, it’s a great reminder of how far you have come on your journey!`
  },
  [TECH_TIPS]: {
    header: 'Tech Tips',
    intro:
      'Need a little help in the tech department? Here is the rundown of how to connect and watch our videos on different types of devices.'
  }
}
