import React from 'react'
import ContentContainer from '../../../components/ContentContainer'
import ActivityContentContainer from './ActivityContentContainer'
import { makeStyles } from '@material-ui/core'
import ActivityHeaderSpacer from './ActivityHeaderSpacer'

const ActivityGetMoving = () => {
  const classes = useStyles()
  return (
    <div className={classes.background}>
      <ActivityHeaderSpacer />
      <ContentContainer maxPixels={732}>
        <ActivityContentContainer>
          <p>
            Rather than looking for an external stimulus such as another double-shot Espresso to give you that
            &apos;Happy and Excited&apos; lift, try doing it naturally with your favourite form of exercise, or if
            that’s not available then do the most practical activity that releases those hormones naturally.
          </p>
          <p>Here are a few suggestions:</p>
          <p>
            <b>Walking - </b>A solid walk can be done by almost anyone at any time. It uses your whole body, burns a
            whole load of calories and doesn’t require a shower afterwards.
          </p>
          <p>
            <b>Tabata - </b>For those that are a little more hardcore you can set a timer for 4 mins, which is 8 rounds
            of 20 seconds all-out, followed by 10 seconds of catching your breath.
          </p>
          <p>You can do any exercise, however some Big Bang ones include:</p>
          <ol>
            <li>
              Squats - The faster and deeper you go the more calories you burn. These also have minimal impact on your
              joints.
            </li>
            <li>
              On the Spot Sprints - All you need is a 1m x 1m square and you can run like the Flash for the same 8
              rounds of 20 seconds - Sprint, 10 seconds recover.
            </li>
            <li>
              Burpees - To incorporate more upper body (as well as a potential headspin) you can do full burpees which
              include a complete toe push-up at the start of each rep soon followed by a giant vertical leap to finish.
              8 rounds of these will get you &apos;Full-Body&apos; fit!
            </li>
          </ol>
          <p>For those that want something in between these two extremes you can pump out your happy hormones by…</p>
          <p>
            <b>Stair Climbing - </b>Find yourself a set of stairs, ideally out in the fresh air, and set your timer for
            5,10 or 20 minutes. Climb to the top as quickly as you can before carefully walking back down, ideally with
            your hand around the rail just in case you need to grab it if your legs turn to jelly. If you’ve never done
            stairs before then 5 minutes should be plenty, gradually increase the time as your fitness improves. With
            consistency you’ll surprise yourself in how quickly you can improve those leaps and bounds!
          </p>
          <p>
            Share your progress by writing a&nbsp;
            <a href={process.env.REACT_APP_12WBT_FACEBOOK_MEMBER_ZONE}>Member Zone post</a>, or posting through
            Instagram. Tag your post: <b>#12WBTanywhere</b>
          </p>
        </ActivityContentContainer>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    width: '100%',
    height: '100%',
    paddingBottom: '20px'
  },
  [theme.breakpoints.down('sm')]: {
    background: {
      backgroundColor: theme.palette.greys.light
    }
  }
}))

export default ActivityGetMoving
