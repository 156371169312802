import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import moment from 'moment'

const PromotionalBanner = () => {
  const { currentAndNextRound } = useSelector((state) => state.round)
  const { couponApplied, couponSuccess } = useSelector((state) => state.coupon)

  const classes = useStyles()

  const dateToDisplay = () => {
    return currentAndNextRound?.nextRoundDate
      ? `We start ${moment(currentAndNextRound?.nextRoundDate).format(
          'MMMM Do, YYYY'
        )}`
      : 'Starting soon!'
  }

  const DefaultNextRoundBanner = () => {
    return (
      <div className={classes.roundStartBar}>
        {dateToDisplay()}
      </div>
    )
  }

  const BannerContentToDisplay = () => {
    if (!couponSuccess) return <DefaultNextRoundBanner />

    switch (couponApplied) {
      case '10OFF': {
        return (
          <div className={classes.largeText}>
            <span>SPECIAL OFFER:</span>
            <br />
            <span>Get 10% OFF Lifestyle Membership</span>
          </div>
        )
      }
      case 'GETBACK20': {
        return (
          <div className={classes.largeText}>
            <span>Enjoy 20% OFF a Single Round!</span>
            <br />
            <span>Hurry, offer ends January 2.</span>
          </div>
        )
      }
      case 'RECLAIM25': {
        return (
          <div className={classes.largeText}>
            <span>Save 25% on a Single Round when you sign up today</span>
          </div>
        )
      }
      default: {
        return <DefaultNextRoundBanner />
      }
    }
  }

  // The 'Enjoy Pre-season..' text below should be shown by default unless a coupon code is successfully applied:
  return (
    <div className={classes.container}>
      <BannerContentToDisplay />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  roundStartBar: {
    backgroundColor: '#F5F5F5',
    minHeight: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '32px',
    fontWeight: '600',
    lineHeight: '45px'
  },
  container: {
    textAlign: 'center',
    backgroundColor: '#F5F5F5',
    width: '100%',
    padding: '1.1rem .2rem',
    // marginTop: '87px'
    marginTop: '-5px'
  },
  datesContainer: {
    // fontSize: '1.2rem',
    fontSize: '35px',
    fontFamily: 'Poppins',
    fontWeight: '700',
    lineHeight: '45px',
    letterSpacing: '0%',
    textAlign: 'center'
  },
  largeText: {
    fontSize: '1.6rem',
    color: theme.palette.rgb.guide.pink,
    fontWeight: 900
  },
  [theme.breakpoints.down(769)]: {
    datesContainer: {
      // marginTop: '70px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    roundStartBar: {
      fontSize: '25px'
    },
    container: {
      padding: '.9rem 1.5rem'
    },
    datesContainer: {
      fontSize: '1.1rem'
    },
    largeText: {
      fontSize: '1.4rem'
    }
  }
}))

export default PromotionalBanner
