import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Switch } from '@material-ui/core'

const WelcomeSelect = ({ onClick, label, description, checked, hidden }) => {
  const classes = useStyles()
  return (
    <div className={classes.container} onClick={onClick} hidden={hidden}>
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        checked={checked}
      />
      <div className={classes.descriptionContainer}>
        <span className={classes.title}>{label}</span>
        <span className={classes.description}>{description}</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.greys.dark
  },
  title: {
    color: theme.palette.greys.dark,
    fontWeight: 'bold',
    fontSize: '1.1rem'
  },
  description: {
    margin: '.3rem auto',
    fontSize: '.9rem'
  },
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'visible'
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.signup.secondary,
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: theme.palette.signup.secondary,
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {},
  [theme.breakpoints.down('xs')]: {
    title: {
      fontSize: '1.05rem'
    }
  }
}))

WelcomeSelect.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  hidden: PropTypes.bool
}

export default WelcomeSelect
