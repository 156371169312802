import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import MeasureUpInput from '../measure_up/MeasureUpInput'
import Modal from '../../../../../components/Modal'
import FitnessTestModal from './FitnessTestModal'

const FitnessTestResultInput = ({ formik, testData, onTestChange, initialDifficulty }) => {
  const [showModal, setShowModal] = useState(false)
  const [activeTest, setActiveTest] = useState(testData.defaultTest)
  const classes = useStyles()

  useEffect(() => {
    if (initialDifficulty) {
      const newTest = testData.tests.find((test) => test.difficulty === initialDifficulty)
      setNewTestHandler(newTest, true)
    }
  }, [])

  // const isXsOrUnder = useMediaQuery('(max-width:550px)')
  const labelClass = testData.tests.length > 1 ? `${classes.label} ${classes.active}` : classes.label
  const labelClickHandler = () => {
    if (testData.tests.length > 1) setShowModal(true)
  }

  const setNewTestHandler = (newTest, initialRender) => {
    setShowModal(false)
    setActiveTest(newTest)
    if (!initialRender) onTestChange(testData.type, newTest.difficulty)
  }

  return (
    <div className={classes.container}>
      <span className={classes.category}>{testData.category}</span>
      <span className={labelClass} onClick={labelClickHandler}>
        {activeTest.label}
      </span>
      <span></span>
      <div className={classes.inputRow}>
        {activeTest.inputs.map((input, i) => {
          return (
            <div key={i} className={classes.inputContainer}>
              <MeasureUpInput
                key={i}
                formik={formik}
                name={input.value}
                unitOfMeasurement={input.metric}
                width='4.5rem'
              />
            </div>
          )
        })}
      </div>
      <Modal
        show={showModal}
        onCancel={() => {
          formik.resetForm()
          setShowModal(false)
        }}
      >
        <FitnessTestModal
          testData={testData}
          setNewTest={setNewTestHandler}
          closeModal={() => setShowModal(false)}
          currentTest={activeTest}
          formik={formik}
        />
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.CSS.center,
    textAlign: 'center',
    flexDirection: 'column',
    maxWidth: '12rem'
  },
  category: {
    fontWeight: 'bold',
    fontSize: '1rem'
  },
  inputRow: {
    display: 'flex',
    width: '8rem',
    justifyContent: 'center',
    '& > div': {
      margin: '.2rem'
    }
  },
  label: {
    fontSize: '1rem'
  },
  active: {
    '&:hover': {
      cursor: 'pointer'
    },
    fontWeight: 700,
    color: '#4f5fed'
  },
  [theme.breakpoints.down('xs')]: {
    category: {
      fontSize: '.8rem'
    },
    label: {
      fontSize: '.8rem'
    },
    inputRow: {
      '& > div': {
        margin: '.1rem'
      }
    }
  }
}))

FitnessTestResultInput.propTypes = {
  testData: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  onTestChange: PropTypes.func,
  initialValue: PropTypes.string,
  initialDifficulty: PropTypes.string
}

export default FitnessTestResultInput
