import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const ActivityContentContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.textContainer}>{children}</div>
}

const useStyles = makeStyles((theme) => ({
  textContainer: {
    fontSize: '16px',
    lineHeight: '32px',
    backgroundColor: 'white',
    padding: '1rem'
  },
  [theme.breakpoints.down('sm')]: {
    textContainer: {
      margin: '0rem 2rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    textContainer: {
      margin: '1rem',
      marginTop: '2rem',
      fontSize: '1rem'
    }
  }
}))

ActivityContentContainer.propTypes = {
  children: PropTypes.oneOfType([(PropTypes.object, PropTypes.array)])
}

export default ActivityContentContainer
