import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core'
import TabSelector from '../fields/TabSelector'
import { planTabs, PLAN_TAB_TYPES } from '../SignUpData'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPrice } from '../../../../redux/actions/Signup.actions'
import SelectPlanBanners from './SelectPlanBanners'
import CouponInput from './CouponInput'
import PricePointsHandler from './PricePointsHandler'
import HelperText from '../../../components/fields/HelperText'

const ViewablePlans = ({ formik, submissionAttempt, userSelectedProgram }) => {
  const dispatch = useDispatch()
  const { programs, loading, pricePointFail } = useSelector(
    (state) => state.signupPrograms
  )
  const [activeBanner, setActiveBanner] = useState(null)
  const classes = useStyles({ pricePointFail })
  const theme = useTheme()

  const activeHeader = planTabs.find((val) => val.value === userSelectedProgram)
  const activeColor =
    activeHeader?.name === 'Lifestyle'
      ? theme.palette.signup.primary
      : theme.palette.signup.round

  // Logic to retrieve getPricePoints ||  validate coupon && getCouponPrice points from query param:
  PricePointsHandler()

  // Sorted programs visible to users, depending on activeTab:
  const viewablePrograms = useMemo(() => {
    if (programs) {
      let newPrograms = programs.filter(
        (program) => program.membershipType.name === userSelectedProgram
      )

      // Reorder array for lifestyle banners:
      if (userSelectedProgram === PLAN_TAB_TYPES.LIFESTYLE.value) {
        newPrograms = newPrograms
          .slice()
          .sort((a, b) => a.billingCycles - b.billingCycles)
      }
      return newPrograms
    }
  }, [programs, userSelectedProgram])

  // On program option select, change chosen program for styling & update formik values with relevant program details:
  const programSelectHandler = (value, paymentObj) => {
    const isWeekly = value.includes('recurring')
    setActiveBanner(value)
    // Save selected price in signupPrograms reducer to display price on form submit button:
    const isDiscounted = paymentObj?.couponCode
    dispatch(
      setSelectedPrice(
        paymentObj.priceInDollars,
        paymentObj.GATotalCostInCents,
        isWeekly,
        isDiscounted
      )
    )
    // Update formik values:
    handleChange('productHandle', paymentObj.productHandle)
    handleChange('paymentPlan', paymentObj.paymentPlan)
    handleChange('couponCode', paymentObj?.couponCode)
    handleChange('billingOption', paymentObj.billingOption)
  }

  const selectProgram = (program) => {
    const GATotalCostInCents = program?.coupon
      ? program.coupon.minAmount
      : program.minAmount

    // Calculate values to show on UI and send to Google Tag Manager (GTM):
    const price = program?.coupon
      ? program.coupon.discountPriceInCents
      : program.priceInCents

    const priceInDollars = (price / 100).toFixed(2)
    programSelectHandler(program.handle, {
      productHandle: program.chargifyProductHandle,
      paymentPlan: program._id,
      billingOption: program.billingOption,
      priceInDollars,
      GATotalCostInCents,
      couponCode: program?.coupon?.code
    })
  }

  // Set first banner program as selected on tab change:
  useEffect(() => {
    if (viewablePrograms?.length > 0) {
      const selectedProgram = viewablePrograms.find(
        (e) => e.handle === activeBanner
      )
      if (!selectedProgram) {
        selectProgram(viewablePrograms[0])
      } else {
        selectProgram(selectedProgram)
      }
    }
  }, [viewablePrograms])

  const handleChange = async (name, value) => {
    await formik.setFieldValue(name, value)
    formik.setFieldTouched(name, true)
  }

  const error = formik.errors.productHandle && submissionAttempt

  if (!userSelectedProgram)
    return (
      <div>
        <HelperText message='You must select a program' />
        <CouponInput formik={formik} />
      </div>
    )

  return (
    <div className={classes.fieldsContainer}>
      <TabSelector
        activeHeader={activeHeader}
        activeColor={activeColor}
        userSelectedProgram={userSelectedProgram}
      />
      <SelectPlanBanners
        values={viewablePrograms}
        activeBanner={activeBanner}
        bannerSelectHandler={programSelectHandler}
        activeColor={activeColor}
        loading={loading}
      />

      {error && (
        <div className={classes.error}>
          <HelperText message='You must select a payment plan' />
        </div>
      )}
      <CouponInput formik={formik} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    marginTop: '24px'
  },
  error: {
    marginTop: (props) => (props.pricePointFail ? '1rem' : '-1rem')
  }
}))

ViewablePlans.propTypes = {
  formik: PropTypes.object.isRequired,
  priceChangeHandler: PropTypes.func,
  submissionAttempt: PropTypes.bool.isRequired,
  isLifestyle: PropTypes.bool,
  userSelectedProgram: PropTypes.string
}

export default ViewablePlans
