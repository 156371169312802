import React from 'react'
import TaskContentSubHeader from '../../TaskContentSubHeader'
import TaskContentContainer from '../../TaskContentContainer'
import TaskVideo from '../../TaskVideo'

const FitnessTestLowerBodyStrength = () => {
  return (
    <TaskContentContainer>
      <TaskContentSubHeader title='Lower Body Strength' />
      <TaskVideo videoId='3082265609001' needsCenterAdjust={true} />
      <p>
        What does this measure?
        <br />
        You will be doing the Wall Sit test to measure your lower body strength. What can I say – OUCH!! Who knew not
        moving could hurt so much! This test is a measurement of your lower body strength and endurance. It’s really
        important to have correct form while doing this test, so follow my instructions carefully.
      </p>
      <p>
        Prep Yourself
        <br />
        Position yourself against a solid wall and lower yourself down until your knees are at 90 degrees (this feels
        low!). If possible, use a mirror or another set of eyes to check your knees are at 90 degrees. Make sure your
        head, shoulders and lower back are pressed into the wall then start your stopwatch. Make sure your knees and
        ankles stay at 90 degree angles and that you are drawing your belly button in to brace your core.
      </p>
      <p>Ready, Set…Go!</p>
      <p>
        Start your timer and stop it when you can&apos;t hold yourself any more and you either slide down the wall or
        push away.
      </p>
    </TaskContentContainer>
  )
}

export default FitnessTestLowerBodyStrength
