import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

function pickMargin(index, isChecked) {
  let marginLeft = 0
  let marginRight = 0
  let marginTop = 0
  if (index === 0) marginRight = '-10px'
  else if (index === 2) marginLeft = '-10px'
  if (isChecked) marginTop = '-1rem'
  return `${marginTop} ${marginRight} 0 ${marginLeft}`
}

const WelcomeReturnProgramCard = ({ calories, color, isChecked, description, header, title, onClick, index }) => {
  let zIndex = 1
  if (isChecked) zIndex = 3
  if (index === 1) zIndex = 2
  const margin = pickMargin(index, isChecked)

  const size = 30
  const quarterSize = size * 0.25
  const classes = useStyles({ color, size, quarterSize, zIndex, margin })
  const programCardClickHandler = () => {
    onClick(title)
  }
  return (
    <div className={classes.programCard} onClick={programCardClickHandler}>
      <div className={classes.cardHeader}>{header}</div>
      <div className={classes.cardBody}>
        <div className={classes.checkbox} tabIndex='0'>
          {isChecked && <CheckCircleIcon />}
        </div>
        <span className={classes.title}>{title}</span>
        {calories && <span className={classes.calories}>{`${calories} Calories per day`}</span>}
        <span className={classes.description}>{description}</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  programCard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '15rem',
    height: '20rem',
    borderRadius: '15px',
    '&:hover': {
      cursor: 'pointer'
    },
    boxShadow: theme.CSS.cardShadow,
    zIndex: (props) => props.zIndex,
    margin: (props) => props.margin,
    transition: theme.transitions.create(['margin'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut
    })
  },
  cardHeader: {
    textAlign: 'center',
    backgroundColor: (props) => props.color,
    height: '3rem',
    width: '100%',
    ...theme.CSS.center,
    color: 'white',
    fontSize: '1.02rem',
    fontWeight: 'bold',
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px'
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '0 1rem'
  },
  checkbox: {
    borderRadius: '50%',
    width: (props) => props.size,
    height: (props) => props.size,
    minWidth: (props) => props.size,
    borderStyle: 'dotted',
    borderColor: (props) => (props.error ? 'red' : props.color),
    '&:hover': {
      cursor: 'pointer'
    },
    borderWidth: '1px',
    '& svg': {
      width: (props) => props.size + 2 * props.quarterSize,
      height: (props) => props.size + 2 * props.quarterSize,
      marginLeft: (props) => `-${props.quarterSize}px`,
      marginTop: (props) => `-${props.quarterSize}px`,
      color: (props) => props.color
    },
    margin: '1rem auto',
    '&:focus-visible': {
      outline: 'none'
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    marginTop: '.4rem'
  },
  description: {
    marginTop: '.6rem',
    color: theme.palette.greys.dark
  },
  calories: {
    color: theme.palette.greys.dark,
    fontWeight: 'bold',
    marginTop: '.6rem'
  }
}))

WelcomeReturnProgramCard.propTypes = {
  calories: PropTypes.oneOfType([(PropTypes.string, PropTypes.number)]),
  color: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number
}

export default WelcomeReturnProgramCard
