import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles, TextField } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import CheckIcon from '@material-ui/icons/Check'

const TextInput = ({
  formik,
  label,
  name,
  placeholder,
  onBlurHandler,
  onFocusHandler,
  withLock,
  loading,
  updateFocus,
  type,
  isDisabled,
  height,
  withDenseMargin,
  withCheck,
  isTextCentered
}) => {
  const classes = useStyles({ height, label, withDenseMargin, isTextCentered })
  const blurHandler = (e) => {
    if (onBlurHandler) onBlurHandler()
    formik.handleBlur(e)
    if (updateFocus) updateFocus(name, false)
  }

  const focusHandler = () => {
    if (onFocusHandler) onFocusHandler()
    if (updateFocus) updateFocus(name, true)
  }

  return (
    <div className={classes.container}>
      <span>{label}</span>
      <div className={classes.textInput}>
        {withLock && (
          <div className={classes.lock}>
            <LockIcon />
          </div>
        )}
        <TextField
          fullWidth
          InputProps={{ className: classes.textField }}
          placeholder={placeholder}
          id={name}
          disabled={isDisabled}
          name={name}
          onFocus={focusHandler}
          onBlur={(e) => blurHandler(e)}
          variant='outlined'
          type={type}
          margin={withDenseMargin ? 'dense' : undefined}
          value={formik.values[name]}
          onChange={formik.handleChange}
          error={formik.errors[name] && formik.touched[name]}
          helperText={formik.touched[name] && formik.errors[name]}
        />
        {loading && (
          <div className={classes.spinner}>
            <CircularProgress size={25} />
          </div>
        )}
        {!loading && withCheck && formik.values[name] && !formik?.errors[name] && (
          <div className={classes.spinner}>
            <CheckIcon size={25} />
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    color: 'grey',
    textAlign: 'left',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'c4c4c4',
        borderWidth: '1px',
        transition: theme.transitions.create(['box-shadow'], {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut
        })
      },
      '&.Mui-focused fieldset': {
        outline: 'none!important',
        border: `1px solid ${theme.palette.primary.main}`,
        // borderColor: theme.palette.primary.main,
        boxShadow: theme.CSS.cardShadowBlue
      }
    },
    '& span': {
      margin: (props) =>
        props.withDenseMargin ? '0!important' : '.2rem 0!important',
      marginTop: (props) => !props.label && '1.2rem'
    },
    // remove X
    '& input[type="number"]::-webkit-clear-button': {
      display: 'none'
    },

    // remove inside of arrows button
    '& input[type="number"]::-webkit-inner-spin-button': {
      display: 'none'
    },

    // remove outsideof arrows button
    '& input[type="number"]::-webkit-outside-spin-button': {
      display: 'none'
    }
  },
  '& .MuiFormHelperText-root': {
    color: 'red!important'
  },
  textInput: {
    display: 'flex',
    position: 'relative'
  },
  spinner: {
    position: 'absolute',
    right: '10px',
    height: '100%',
    ...theme.CSS.center,
    '& svg': {
      color: theme.palette.signup.secondary
    }
  },
  lock: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: theme.palette.grey[400],
    marginRight: '-3px',
    zIndex: 10,
    ...theme.CSS.center,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    '& svg': {
      color: 'white',
      width: '2rem',
      height: '2rem'
    }
  },
  textField: {
    height: (props) => props.height,
    '& input': {
      textAlign: (props) => (props.isTextCentered ? 'center' : 'start')
    }
  }
}))

TextInput.defaultProps = {
  placeholder: '',
  withLock: false,
  type: 'text',
  isDisabled: false,
  height: undefined,
  withDenseMargin: false
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  formik: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  withLock: PropTypes.bool,
  loading: PropTypes.bool,
  updateFocus: PropTypes.func,
  isDisabled: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object
  ]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withDenseMargin: PropTypes.bool,
  withCheck: PropTypes.bool,
  isTextCentered: PropTypes.bool
}

export default TextInput
