import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ContentContainer from '../../../components/ContentContainer'
import TaskTabItem from './TaskTabItem'
import { HEADER_TABS } from './TaskData'
import loadCurrentSubscriptionAndRound from '../../../helpers/LoadCurrentSubscriptionAndRound'

const TaskHeader = ({ title, description, activeTab, setActiveTab }) => {
  const classes = useStyles()

  // Setup current round + subscription id's in redux: (Eventually this will be removed as currentRound + currentSubscription will come from memberSummary):
  loadCurrentSubscriptionAndRound()

  return (
    <div className={classes.container}>
      <Link className={classes.backButton} to='/pre-season'>
        <ArrowBackIosIcon />
        <span className={classes.buttonText}>Pre-season home</span>
      </Link>
      <ContentContainer maxWidth='lg'>
        <div className={classes.headerContent}>
          <div className={classes.headerDiv}>
            <span className={classes.task}>TASK</span>
            <h2 className={classes.title}>{title}</h2>
            <span className={classes.description}>{description}</span>
          </div>
          <div className={classes.tabSelector}>
            <TaskTabItem value={HEADER_TABS.LEARN} activeTab={activeTab} setActiveTab={setActiveTab} />
            <TaskTabItem value={HEADER_TABS.DO_THE_TASK} activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
        </div>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#5164E9',
    position: 'relative',
    paddingBottom: '4rem'
  },
  backButton: {
    display: 'flex',
    ...theme.CSS.center,
    position: 'absolute',
    left: '16px',
    top: '16px',
    color: 'white',
    textDecoration: 'none'
  },
  buttonText: {
    marginLeft: '8px',
    fontSize: '14px',
    fontWeight: 500
  },
  headerDiv: {
    textAlign: 'center',
    color: 'white',
    paddingTop: '96px'
  },
  task: {
    fontSize: '24px',
    linHeight: '24px',
    fontWeight: 600
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
    lineHeight: '60px',
    margin: '5px auto',
    marginBottom: '20px'
  },
  description: {
    fontSize: '20px',
    linHeight: '28px',
    fontWeight: 400,
    maxWidth: '40%'
  },
  tabSelector: {
    position: 'absolute',
    bottom: '-30px',
    height: '60px',
    width: '768px',
    margin: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: '5px',
    filter: `drop-shadow(0 0 0.1rem ${theme.palette.greys.main})`
  },
  headerContent: {
    ...theme.CSS.center
  },
  [theme.breakpoints.down('md')]: {
    headerDiv: {
      paddingTop: '75px',
      maxWidth: '80%'
    },
    title: {
      fontSize: '50px'
    },
    countMeInDiv: {
      width: '700px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '40px',
      lineHeight: '38px',
      margin: '1rem auto'
    },
    headerDiv: {
      maxWidth: '75%'
    },
    tabSelector: {
      width: '550px'
    },
    countMeInButton: {
      width: '150px',
      height: '40px'
    },
    task: {
      fontSize: '20px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    headerDiv: {
      maxWidth: '70%'
    },
    title: {
      fontSize: '35px',
      margin: '1rem auto'
    },
    description: {
      fontSize: '1rem'
    },
    tabSelector: {
      width: '90%'
    },
    countMeInButton: {
      marginTop: '10px',
      width: '200px'
    }
  },
  [theme.breakpoints.down(480)]: {
    headerDiv: {
      maxWidth: '90%'
    }
  },
  [theme.breakpoints.down(380)]: {
    title: {
      fontSize: '30px'
    },
    headerDiv: {
      maxWidth: '95%'
    }
  }
}))

TaskHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
}

export default TaskHeader
