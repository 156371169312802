import React from 'react'
import { makeStyles } from '@material-ui/core'
import TaskContentContainer from '../tasks/TaskContentContainer'
import fitnessGuideScreenshot from '../../../assets/img/pre_season/guides/fitnessGuideScreenshot.png'
import fitnessDropdown from '../../../assets/img/pre_season/guides/fitnessdropdown.png'
import fitnessSideMenu from '../../../assets/img/pre_season/guides/fitnesssidemenu.png'
import staticLunge from '../../../assets/img/pre_season/guides/staticlunge.png'
import workoutfilters from '../../../assets/img/pre_season/guides/workoutfilters.png'

const GuideNavigateFitness = () => {
  const classes = useStyles()
  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <p>
          <b>Using a Desktop or Laptop?</b>
        </p>
        <p>Head to the ‘Fitness’ dropdown in the navigation bar at the top of your screen</p>
        <img src={fitnessGuideScreenshot} alt='fitnessguide' />
        <p>
          When you hover over it it will show four (or six if you’re a Lifestyle Member) links to the various Fitness
          components of the website.
        </p>
        <p>Available to Round and Lifestyle Members (Lifestyle Members get extra content in each):</p>
        <span>
          <b>12WBT Round Members:</b>
        </span>
        <ul className={classes.dashList}>
          <li>Exercise Index</li>
          <li>Fitness Videos</li>
          <li>Workout Videos</li>
          <li>Express Workouts</li>
        </ul>
        <span>
          <b>Lifestyle Extras:</b>
        </span>
        <ul>
          <li>Workout Hacks</li>
          <li>4 Week Programs</li>
        </ul>
        <p>
          <b>Fitness Content Explained:</b>
        </p>
        <p>
          <b>Exercise Index</b>
          <br />
          This Index includes over 540 different exercises and explains what each exercise is and how to do it with
          correct technique. This Index will become your go-to whilst you navigate and learn new exercises.
        </p>
        <span>They are in alphabetical order and can be viewed under their different categories, these include:</span>
        <ul className={classes.dashList}>
          <li>All Exercises</li>
          <li>Flexibility</li>
          <li>Fitness</li>
          <li>Fitness Test</li>
          <li>Toning</li>
        </ul>
        <ul>
          <li>
            If you’d like to find a specific exercise then you can go to the search function which is magnifying glass
            and type in the exercise e.g. ‘Squat’. It will then come up with all the different forms of Squats.
          </li>
          <li>
            To see them all scroll down the bottom and click on page 2 or the ‘forward button’ represented by the double
            arrow &gt; &gf;.
          </li>
          <li>
            Once you click on an exercise you will go to the page where there is an demonstration video as well as a
            detailed description that includes:
          </li>
        </ul>
        <ul className={classes.dashList}>
          <li>Start Position</li>
          <li>Execution</li>
          <li>Variations - This will tell you both the easier and harder version of this exercise.</li>
          <li>
            Reviews - This is where members can rate and comment on each exercise. E.g. I tried it and actually like it.
            PS I hate exercise.
          </li>
        </ul>
        <p>The Exercise index can also be viewed in both ‘Magazine’ and ‘List’ format.</p>
        <p>
          <b>Fitness Videos</b>
        </p>
        <p>
          The next link below ‘Exercise Index’ is ‘Fitness Videos’. Here is where over 52 videos{' '}
          <b>explaining all things fitness</b> can be found. Everything from explaining ‘Your Fitness Score’ to ‘How to
          make a Peanut’ - our most viewed video ever!
          <br />
          <br />
          These are great videos if you’re new to fitness or this website, I guarantee you will learn something new, so
          check out this part often to learn something new about fitness. It is a hidden treasure chest of gems.
        </p>
        <p>
          <b>Workout Videos</b>
        </p>
        <p>
          The Third dropdown link below the ‘Fitness’ Tab is ‘Workout Videos’ this is the most popular part of the site.
          <br />
          <br />
          Here you’ll find over 40 workout videos that range from ‘Beginners guide to stretches’ to ‘High Intensity
          Bodyweight Workouts’ and more core workouts than you can poke a stick at.
        </p>
        <p>
          If you are in need of a motivational boost to get you through a workout come visit the ever-growing library of
          workout videos.
        </p>
        <p>
          <b>Express Workouts</b>
        </p>
        <p>
          The 4th dropdown link is an even more impressive collection of shorter ‘Express Workouts’ that you can use to
          either:
        </p>
        <ol>
          <li>Start your workout - For example ‘The Ultimate Warm Up’</li>
          <li>
            Stack together - Put 3 of these Express Workouts together to suit your current goals and timeframe e.g A
            Fitness workout, Strength/Toning and finish with Core.
          </li>
          <li>
            Finish Your Workout - If you want to work your butt or abs a little harder then you can find a 5-10 minute
            workout that suits to accelerate your specific goal.
          </li>
        </ol>
        <p>Because there are over 210 Express Workouts we’ve made it easier by adding a number of filters.</p>
        <span>As you will see up the top you can search using:</span>
        <ul className={classes.dashList}>
          <li>All Workouts</li>
          <li>Core</li>
          <li>Strength/toning</li>
          <li>Fitness</li>
          <li>Flexibility</li>
          <li>Running</li>
          <li>Favourites</li>
        </ul>
        <p>
          <b>Tip:</b>&nbsp; Just like on your smartphone you can mark a ‘Favourite’ workout by tapping the love heart in
          the bottom right of the workout image. You can then click on the ‘Favourites’ box and it will have all your
          favourite workouts stored ready for easy access.
        </p>
        <img src={staticLunge} alt='static lunge exercise' className={classes.smallImg} />
        <p>You can filter the workouts even further using the options located on the left hand side:</p>
        <img src={workoutfilters} alt='workout filters dropdown' className={classes.smallImg} />
        <p>So there’s a few easy ways you can search for and add extra oomph to your workouts.</p>
        <p>
          <b>Workout Hacks</b>
        </p>
        <p>
          Exclusive to our Lifestyle members or ‘Lifers’ as we call them. These Workout Hacks have loads of fantastic
          information on all things fitness as well as specifically assigned Express Workouts.
        </p>
        <p>
          <b>4 Week Programs</b>
        </p>
        <p>
          This contains very specific 4 Week Programs that deliver 12 awesome workouts as well as specialised video tips
          and meal plans also exclusive to our Lifestyle Members. The 4 week plans focus on 1 particular aspect,
          including:
        </p>
        <p>
          ‘KickStarter’ for Beginners
          <br />
          <br />
          ‘Ease & Flow’ our yoga-based program
          <br />
          <br />
          ‘Stabilise’ Redefining your Butt and Ab strength
          <br />
          <br />
          ‘Barbell Progressions - Getting Strong and Mobile using a barbell
          <br />
          <br />
          ‘Fire Up’ - High calorie burning without the impact
          <br />
          <br />
          ‘Athletic Conditioning’ Crossfit style training with one of Australia’s Fittest Cross fit Athletes’.
        </p>
        <img src={fitnessDropdown} alt='Fitness dropdown links' />
        <p>
          <b>Using the mobile site?</b>
        </p>
        <p>
          The mobile site works just like the desktop version, the biggest difference is the position of your navigation
          bar. You will find everything mentioned above by clicking on the navigation button (three white lines) in the
          left corner of your screen.
        </p>
        <p>
          <b>Using the 12WBT App?</b>
        </p>
        <p>Navigating your Fitness needs is simple! Here’s how..</p>
        <p>Head to the Fitness icon in the bottom navigation bar, this will open the Fitness menu which includes:</p>
        <p>
          Exercise Plan - this is where you will access your Exercise Plan each week
          <br />
          Exercise Index
          <br />
          Express Workouts
          <br />
          Workout Hacks - Lifestyle members only
          <br />4 Week Programs - Lifestyle members only
        </p>
        <img src={fitnessSideMenu} alt='Mobile fitness side menu' className={classes.smallImg} />
        <p>
          To make any changes to your Fitness program head to ‘More…’ in the bottom navigation bar. Select ‘My Account’
          from the menu, under ‘Program’ tap &apos;edit!&apos; next to your current Fitness program to select your
          desired Fitness Program.
        </p>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    '& img': {
      maxWidth: '100%'
    }
  },
  smallImg: {
    width: '12rem'
  }
}))

export default GuideNavigateFitness
