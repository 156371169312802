import { useSelector } from 'react-redux'

export const getPreSeasonTask = (taskTitle) => {
  const { preseason } = useSelector((state) => state.newPreSeason)
  return preseason?.preSeason.tasks.find((t) => t.title === taskTitle)
}

export const getPreSeasonTaskBySlug = (taskSlug) => {
  const { preseason } = useSelector((state) => state.newPreSeason)
  return preseason?.preSeason.tasks.find((t) => t.slug === taskSlug)
}
