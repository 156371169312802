import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, Hidden, makeStyles } from '@material-ui/core'
import WhiteCard from '../../../components/WhiteCard'
import TextInput from '../../../components/fields/TextInput'
import CountryInput from '../../../components/fields/CountryInput'
import MobileInput from '../../../components/fields/MobileInput'
import SelectInput from '../../../components/fields/SelectInput'
import { states } from './WelcomeStepOneData'

const WelcomeBillingDetails = ({ formik }) => {
  const classes = useStyles()
  return (
    <WhiteCard margin={'2rem 0'} withShadow={true}>
      <div className={classes.container}>
        <span className={classes.cardTitle}>Billing Details:</span>
        <span className={classes.subHeader}>Address</span>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextInput
              formik={formik}
              name='street_address'
              placeholder='Street address'
              height={40}
              withDenseMargin={true}
              withCheck={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              formik={formik}
              name='suburb'
              placeholder='Suburb'
              height={40}
              withDenseMargin={true}
              withCheck={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.gridRow}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  formik={formik}
                  name='postcode'
                  placeholder='Postcode'
                  height={40}
                  withDenseMargin={true}
                  withCheck={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  name='state'
                  formik={formik}
                  options={states}
                  placeholder='State'
                  withDenseMargin={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2}>
              <Grid item sm={6} className={classes.countryInputContainer}>
                <CountryInput formik={formik} name='country' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <span className={classes.subHeader}>Mobile Number</span>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MobileInput formik={formik} name='phone_number' />
        </Grid>
        <Hidden smDown>
          <Grid item sm={6}></Grid>
        </Hidden>
      </Grid>
    </WhiteCard>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardTitle: {
    fontSize: '1.4rem',
    margin: 'auto'
  },
  countryInputContainer: {
    minWidth: '100%!important'
  },
  gridRow: {
    marginTop: '.2rem'
  },
  divider: {
    margin: '1.5rem 0',
    color: 'black'
  }
}))

WelcomeBillingDetails.propTypes = {
  formik: PropTypes.object
}

export default WelcomeBillingDetails
