import React from 'react'
import { ReactComponent as Clock } from '../../assets/img/icons/signup/clocknew2.svg'
import { ReactComponent as Heart } from '../../assets/img/icons/signup/heart.svg'
import { ReactComponent as Books } from '../../assets/img/icons/signup/books.svg'
import { ReactComponent as TapeMeasure } from '../../assets/img/icons/signup/tapeMeasure.svg'
import { ReactComponent as Tick } from '../../assets/img/icons/signup/tick.svg'
import { ReactComponent as Like } from '../../assets/img/icons/signup/like.svg'

export const featureColumn = [
  {
    icon: <Tick />,
    title: 'Simple',
    description: `Nutrition, fitness and mindset plans are customised for you every week. Plus a library of 1000+ dietitian-approved recipes, quick at-home workouts, and life-changing mindset coaching videos.`
  },
  {
    icon: <Clock />,
    title: 'Convenient',
    description:
      'Designed to fit into your life, my 12WBT program is 100% customisable. Plus, our 12WBT app means you can plan, follow and track your progress right from your phone.'
  },
  {
    icon: <Like />,
    title: 'Proven',
    description: `With over 1.5 million kilos lost, 12WBT is Australia’s most trusted online weight loss program.`
  }
]

export const featureRow = [
  {
    icon: <TapeMeasure />,
    title: '1.5 million',
    description: 'kilos lost'
  },
  {
    icon: <Heart />,
    title: '400,000+',
    description: 'lives changed'
  },
  {
    icon: <Books />,
    title: '35 years',
    description: 'experience'
  }
]

export const PLAN_TAB_TYPES = {
  ROUND: {
    name: '12 Week Round',
    value: 'SINGLE 12WBT ROUND'
  },
  LIFESTYLE: {
    name: 'Lifestyle',
    value: 'LIFESTYLE MEMBERSHIP'
  }
}

export const planTabs = [PLAN_TAB_TYPES.ROUND, PLAN_TAB_TYPES.LIFESTYLE]
