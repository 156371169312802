import React from 'react'
import { makeStyles } from '@material-ui/core'
import PreSeasonHeader from './PreSeasonHeader'
import { earlyAccessLinks } from './PreSeasonData'
import PreSeasonLinkCard from './PreSeasonLinkCard'
import ContentContainer from '../../../components/ContentContainer'

const PreSeasonEarlyAccessCards = () => {
  const classes = useStyles()
  return (
    <ContentContainer maxPixels={1400}>
      <div className={classes.container}>
        <PreSeasonHeader
          title='Eager to get started?'
          description='Completed all of your Pre-Season Tasks and Activities and still want to do more? Throughout Pre-Season the recipes and workouts are all unlocked and ready for you to start getting stuck into.'
        />
        <div className={classes.linkContainer}>
          {earlyAccessLinks.map((link, i) => {
            return (
              <PreSeasonLinkCard
                title={link.title}
                image={link.backgroundImage}
                link={link.link}
                key={i}
                externalTo12WBT={link?.externalTo12WBT}
              />
            )
          })}
        </div>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  linkContainer: {
    display: 'flex',
    margin: '1rem auto'
  },
  [theme.breakpoints.down('md')]: {
    linkContainer: {
      flexDirection: 'column'
    }
  }
}))

export default PreSeasonEarlyAccessCards
