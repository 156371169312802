import React from 'react'
import { Grid, Hidden, makeStyles, useTheme } from '@material-ui/core'
import portionHoles from '../../../../assets/img/pre_season/guides/infographics/ovalsMulti.png'
import servingsHoles from '../../../../assets/img/pre_season/guides/infographics/ovalsSameSize.png'
import vs from '../../../../assets/img/pre_season/guides/infographics/vs.png'
import HealthyEatingRatioInfo from './HealthyEatingRatioInfo'
import HealthyEatingBanner from './HealthyEatingBanner'
import bread from '../../../../assets/img/pre_season/guides/infographics/carbs.png'
import pieGraph from '../../../../assets/img/pre_season/guides/infographics/pieGraph.png'
import meat from '../../../../assets/img/pre_season/guides/infographics/protein.png'
import vegetables from '../../../../assets/img/pre_season/guides/infographics/vegetable.png'

const HealthyEatingPortionsVsServings = () => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <div>
      <Grid className={classes.portVsServingsContainer} container>
        <Grid item xs={5} className={classes.portVsServingsDiv}>
          <img src={portionHoles} alt='Portion Sizes' />
          <HealthyEatingRatioInfo
            title='Portions'
            description='Portions come in all sizes. They can be as big as you want!'
            color={theme.palette.primary.main}
          />
        </Grid>
        <Grid item xs={2} className={classes.vsContainer}>
          <img src={vs} alt='Vs' className={classes.vsImage} />
        </Grid>
        <Grid item xs={5} className={classes.portVsServingsDiv}>
          <img src={servingsHoles} alt='Serving Sizes' />
          <HealthyEatingRatioInfo
            title='Servings'
            description='Serving sizes are all standard'
            color={theme.palette.primary.main}
          />
        </Grid>
        <HealthyEatingBanner
          title='What should I put on my plate?'
          description='A balanced meal should contain roughly 25% protein, 25% carbohydrates and 50% vegetables or salad'
          color={theme.palette.rgb.primary.main}
          backgroundColor={theme.palette.rgb.signup.secondary}
        />
      </Grid>
      <Hidden smUp>
        <img src={pieGraph} alt='Pie Chart of food ratios' className={classes.pieChartMobile} />
      </Hidden>
      <Grid container className={classes.ratioRow}>
        <Grid item xs={4}>
          <HealthyEatingRatioInfo
            title='Carbohydrates'
            color={theme.palette.primary.light}
            description='Rice, grains, bread and potatoes.'
            img={bread}
          />
        </Grid>
        <Grid item xs={4} className={classes.centerGridItem}>
          <Hidden xsDown>
            <img src={pieGraph} alt='Pie Chart of food ratios' className={classes.pieChart} />
          </Hidden>
          <HealthyEatingRatioInfo
            title='Vegetables'
            description='Carrots, peas, asparagus, corn'
            img={vegetables}
            color={theme.palette.primary.dark}
          />
        </Grid>
        <Grid item xs={4}>
          <HealthyEatingRatioInfo
            title='Protein'
            description='Meat, eggs, tofu, chickpeas, legumes and lentils'
            img={meat}
            color={theme.palette.signup.purple}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  portVsServingsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    margin: '2rem auto'
  },
  portVsServingsDiv: {
    ...theme.CSS.center,
    flexDirection: 'column',
    textAlign: 'center',
    '& img': {
      width: '12rem'
    },
    height: '100%'
  },
  vsContainer: {
    ...theme.CSS.center
  },
  vsImage: {
    position: 'absolute',
    height: '75%'
  },
  centerGridItem: {
    ...theme.CSS.center,
    flexDirection: 'column'
  },
  [theme.breakpoints.down('sm')]: {
    vsImage: {
      height: '50%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    vsImage: {
      height: '40%'
    }
  }
}))

export default HealthyEatingPortionsVsServings
