import React from 'react'
import { makeStyles } from '@material-ui/core'

const ActivityHeaderSpacer = () => {
  const classes = useStyles()
  return <div className={classes.spacer}></div>
}

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: '90px',
    width: '100%'
  },
  [theme.breakpoints.down('xs')]: {
    spacer: {
      backgroundColor: theme.palette.greys.light,
      height: '110px'
    }
  }
}))

export default ActivityHeaderSpacer
