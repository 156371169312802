import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import TextInput from '../fields/TextInput'

import signUpLoginFormValidation from './SignUpLoginFormValidation'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetSignUpLoginError,
  signUpLogin
} from '../../../../redux/actions/Signup.actions'

const SignUpLoginModal = ({
  isCurrentMember,
  closeModal,
  formik,
  setReturnMemberId
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { memberLoggedIn, loading, error } = useSelector(
    (state) => state.signupLogin
  )
  const loginFormik = useFormik({
    initialValues: {
      loginEmail: '',
      loginPassword: ''
    },
    validationSchema: signUpLoginFormValidation(),
    onSubmit: (values) => {
      const body = { email: values.loginEmail, password: values.loginPassword }
      dispatch(signUpLogin(body))
    }
  })

  // On form load, assign email:
  useEffect(() => {
    loginFormik.setFieldValue('loginEmail', formik.values.email, false)
  }, [])

  useEffect(() => {
    // Redirect:
    if (memberLoggedIn && isCurrentMember) {
      window.location.href = `${process.env.REACT_APP_GO_LOGIN}?t=${memberLoggedIn.auth_token}`
    } else if (memberLoggedIn && !isCurrentMember) {
      // Save memberId in formContext for signup:
      setReturnMemberId(memberLoggedIn.member_id)

      // Set form fields:
      formik.setFieldValue('email', memberLoggedIn.member.email, false)
      formik.setFieldValue('firstName', memberLoggedIn.member.first_name, false)
      formik.setFieldValue('lastName', memberLoggedIn.member.last_name, false)
      formik.setFieldValue('password', loginFormik.values.loginPassword, false)
      formik.setFieldValue(
        'passwordReType',
        loginFormik.values.loginPassword,
        false
      )
      formik.setErrors({})
      closeModal()
    }
  }, [memberLoggedIn])

  const forgotPasswordHandler = () => {
    window.location.href = process.env.REACT_APP_GO_PASSWORD_RESET
  }

  const modalClickHandler = () => {
    if (error) dispatch(resetSignUpLoginError())
  }

  return (
    <div className={classes.container} onClick={modalClickHandler}>
      <div className={classes.header}>Sign in to 12WBT</div>
      <div className={classes.body}>
        <span className={classes.message}>
          Looks like you already have an account. Please sign in.
        </span>
        <span className={classes.fieldLabel}>Email</span>
        <TextInput formik={loginFormik} name='loginEmail' isDisabled={true} />
        <span className={classes.fieldLabel}>Password</span>
        <TextInput formik={loginFormik} name='loginPassword' type='password' />
        <Button
          data-testid='signInButton'
          className={classes.signInButton}
          variant='contained'
          onClick={() => {
            loginFormik.submitForm()
          }}
        >
          {loading ? <CircularProgress /> : 'Sign in'}
        </Button>
        {memberLoggedIn && (
          <span className={classes.successMessage}>
            Successfully logged in!
          </span>
        )}
        {error && (
          <span className={classes.error}>Invalid login credentials</span>
        )}
        <div className={classes.forgotPass} onClick={forgotPasswordHandler}>
          <span className={classes.forgotPassword}>I forgot my password</span>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '35rem'
  },
  header: {
    width: '100%',
    height: '2.8rem',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    backgroundColor: theme.palette.signup.primary,
    color: 'white',
    ...theme.CSS.center,
    fontSize: '1.3rem'
  },
  body: {
    margin: '0 2rem',
    display: 'flex',
    flexDirection: 'column'
  },
  message: {
    marginTop: '2rem',
    marginBottom: '1rem'
  },
  fieldLabel: {
    fontWeight: 'bold',
    margin: '.5rem',
    fontSize: '1.1rem',
    color: theme.palette.signup.darkBlue
  },
  signInButton: {
    width: '100%',
    borderRadius: '10px',
    margin: '1rem 0',
    backgroundColor: theme.palette.signup.purple,
    color: 'white',
    fontSize: '1.05rem'
  },
  forgotPassword: {
    color: theme.palette.signup.darkBlue,
    fontWeight: 'bold',
    '& :hover': {
      cursor: 'pointer!important'
    }
  },
  forgotPass: {
    margin: '1rem auto',
    '& :hover': {
      cursor: 'pointer  '
    }
  },
  successMessage: {
    color: 'green',
    fontWeight: 'bold',
    margin: '.2rem auto'
  },
  error: {
    color: 'red',
    margin: '.3rem auto'
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      width: '100%'
    }
  }
}))

SignUpLoginModal.propTypes = {
  isCurrentMember: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  setReturnMemberId: PropTypes.func.isRequired
}

export default SignUpLoginModal
