import React, { useEffect, useState } from 'react'
import TaskContentSubHeader from '../../../TaskContentSubHeader'
import TaskContentContainer from '../../../TaskContentContainer'
import { useFormik } from 'formik'
import { getPreSeasonTask } from '../../../../../../helpers/GetPreSeasonTask'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import {
  aerobicCapacityTests,
  coreStrengthTests,
  flexibilityTests,
  lowerBodyStrengthTests,
  upperBodyStrengthTests,
  LOW_INTENSITY,
  MEDIUM,
  ADVANCED
} from '../FitnessTestData'
import FitnessTestResultInput from '../FitnessTestResultInput'
import ActionButton from '../../../ActionButton'
import {
  getRoundMeasurements,
  submitRoundMeasurements,
  clearSubmitRoundMeasurmentsSuccess,
  loadMember
} from '../../../../../../../redux/actions/Member.actions'
import {
  getFitnessScoreImprovements,
  getFitnessScoreResult,
  submitPreSeasonTask,
  updateDifficultyType,
  updatePreSeasonTask
} from '../../../../../../../redux/actions/PreSeason.actions'
import { useDispatch, useSelector } from 'react-redux'
import { FITNESS_TEST } from '../../../TaskData'
import DefaultClearBtn from '../../../../../../components/buttons/DefaultClearBtn'
import FitnessScorePanel from './components/FitnessScorePanel'
import WhichProgramShouldYouDo from './components/WhichProgramShouldYouDo'
import ImprovingYourFitnessScore from './components/ImprovingYourFitnessScore'
import { EXERCISE_TYPES_CONST } from './components/const/ExerciseTypesConst'
import TaskFailedBanner from '../../../TaskFailedBanner'

function checkTaskValue(taskValue, task) {
  return task && task.answer[taskValue] ? task.answer[taskValue] : null
}

function convertDifficulty(difficulty) {
  const newDifficulty = {
    [LOW_INTENSITY]: 'easy',
    [MEDIUM]: 'normal',
    [ADVANCED]: 'hard'
  }
  return newDifficulty[difficulty]
}

function convertDifficultyBack(difficulty) {
  const newDifficulty = {
    easy: 'Low Intensity',
    normal: 'Medium',
    hard: 'Advanced'
  }
  return newDifficulty[difficulty]
}

const FitnessTestResults = () => {
  const dispatch = useDispatch()
  const [measurement, setMeasurement] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  const [typeDifficulties, setTypeDifficulties] = useState({
    core: coreStrengthTests.defaultTest.difficulty,
    upper_body: upperBodyStrengthTests.defaultTest.difficulty,
    aerobic: aerobicCapacityTests.defaultTest.difficulty
  })
  const { currentRoundId, currentSubscriptionId } = useSelector(
    (state) => state.member
  )
  // TODO: Please review this logic to get the member ID
  const { member } = useSelector((state) => state.member)
  const { measurements, success, loading } = useSelector(
    (state) => state.measurements
  )
  const {
    loading: loadingPreseason,
    taskSuccess,
    taskFailed
  } = useSelector((state) => state.newPreSeason)
  const {
    fitnessTestImprovements,
    loading: fitnessTestResultsLoading,
    fitnessTestScoreResult
  } = useSelector((state) => state.fitnessTest)

  const classes = useStyles()
  const task = getPreSeasonTask(FITNESS_TEST)

  const isComplete = task
  const actionButtonTitle = isComplete ? 'Update' : 'Finish'

  useEffect(() => {
    if (!member?.id) {
      dispatch(loadMember())
    }
  }, [member])

  useEffect(() => {
    if (!loading && member?.id && !fitnessTestImprovements && currentRoundId) {
      dispatch(getRoundMeasurements(currentRoundId))
      dispatch(getFitnessScoreImprovements(currentRoundId, member.id, 0))
      dispatch(getFitnessScoreResult(currentRoundId, member.id, 0))
      setInitialRender(false)
    }
  }, [member, isComplete, currentRoundId])

  // On successfully submit round measurements call these:
  useEffect(() => {
    if (
      !loading &&
      member?.id &&
      isComplete &&
      success &&
      !initialRender &&
      currentRoundId
    ) {
      dispatch(clearSubmitRoundMeasurmentsSuccess())
      dispatch(getRoundMeasurements(currentRoundId))
      dispatch(getFitnessScoreImprovements(currentRoundId, member.id, 0))
      dispatch(getFitnessScoreResult(currentRoundId, member.id, 0))
    }
  }, [success])

  useEffect(() => {
    if (measurements && !measurement) {
      setMeasurement(measurements[0])
    }
  }, [measurements])

  const emptyStringChecker = (variable) => {
    if (variable === '') return null
    return variable
  }

  const formik = useFormik({
    initialValues: {
      pushUpsKnees: checkTaskValue('pushUpsKnees', task),
      pushUpsAgainstWall: checkTaskValue('pushUpsAgainstWall', task),
      pushUpsToes: checkTaskValue('pushUpsToes', task),
      sitAndReach: checkTaskValue('sitAndReach', task),
      plankElevatedMin: checkTaskValue('plankElevatedMin', task),
      plankElevatedSec: checkTaskValue('plankElevatedSec', task),
      plankKneesMin: checkTaskValue('plankKneesMin', task),
      plankKneesSec: checkTaskValue('plankKneesSec', task),
      plankToesMin: checkTaskValue('plankToesMin', task),
      plankToesSec: checkTaskValue('plankToesSec', task),
      wallSitMin: checkTaskValue('wallSitMin', task),
      wallSitSec: checkTaskValue('wallSitSec', task),
      threeKmCycleTimeTrialMin: checkTaskValue(
        'threeKmCycleTimeTrialMin',
        task
      ),
      threeKmCycleTimeTrialSec: checkTaskValue(
        'threeKmCycleTimeTrialSec',
        task
      ),
      oneKmTimeTrialMin: checkTaskValue('oneKmTimeTrialMin', task),
      oneKmTimeTrialSec: checkTaskValue('oneKmTimeTrialSec', task),
      twelveMinuteRunTestMeters: checkTaskValue(
        'twelveMinuteRunTestMeters',
        task
      )
    },
    onSubmit: (values) => {
      if (!measurement) {
        console.log('Measure up submission failed')
        return
      }
      if (isComplete)
        dispatch(
          updatePreSeasonTask(currentSubscriptionId, {
            title: 'Fitness Test',
            answer: { ...values },
            _id: task._id
          })
        )
      else
        dispatch(
          submitPreSeasonTask(currentSubscriptionId, {
            title: 'Fitness Test',
            answer: { ...values }
          })
        )

      // Update Round Measurements:
      const roundMeasurements = {
        measurements: { ...measurement }
      }

      // Update new values from form:
      roundMeasurements.measurements.knee_push_ups = emptyStringChecker(
        values.pushUpsKnees
      )
      roundMeasurements.measurements.push_ups_wall = emptyStringChecker(
        values.pushUpsAgainstWall
      )
      roundMeasurements.measurements.push_ups = emptyStringChecker(
        values.pushUpsToes
      )
      roundMeasurements.measurements.sit_and_reach = emptyStringChecker(
        values.sitAndReach
      )
      roundMeasurements.measurements.elevated_plank_minutes =
        emptyStringChecker(values.plankElevatedMin)
      roundMeasurements.measurements.elevated_plank_seconds =
        emptyStringChecker(values.plankElevatedSec)
      roundMeasurements.measurements.ab_stage_minutes = emptyStringChecker(
        values.plankKneesMin
      )
      roundMeasurements.measurements.ab_stage_seconds = emptyStringChecker(
        values.plankKneesSec
      )
      roundMeasurements.measurements.plank_toes_minutes = emptyStringChecker(
        values.plankToesMin
      )
      roundMeasurements.measurements.plank_toes_seconds = emptyStringChecker(
        values.plankToesSec
      )
      roundMeasurements.measurements.wall_sit_minutes = emptyStringChecker(
        values.wallSitMin
      )
      roundMeasurements.measurements.wall_sit_seconds = emptyStringChecker(
        values.wallSitSec
      )
      roundMeasurements.measurements.three_km_cycle_minutes =
        emptyStringChecker(values.threeKmCycleTimeTrialMin)
      roundMeasurements.measurements.three_km_cycle_seconds =
        emptyStringChecker(values.threeKmCycleTimeTrialSec)
      roundMeasurements.measurements.time_trial_minutes = emptyStringChecker(
        values.oneKmTimeTrialMin
      )
      roundMeasurements.measurements.time_trial_seconds = emptyStringChecker(
        values.oneKmTimeTrialSec
      )
      roundMeasurements.measurements.twelve_minute_run_test =
        emptyStringChecker(values.twelveMinuteRunTestMeters)
      roundMeasurements.measurements.aerobic = convertDifficulty(
        typeDifficulties.aerobic
      )
      roundMeasurements.measurements.core = convertDifficulty(
        typeDifficulties.core
      )
      roundMeasurements.measurements.upper_body = convertDifficulty(
        typeDifficulties.upper_body
      )

      // In order to get the right fitness score from API with have to update measurements twice (check later whys that)
      // TODO: Fix submiting twice data to get the correct fitness score
      dispatch(
        submitRoundMeasurements(
          currentRoundId,
          measurement.id,
          roundMeasurements
        )
      )
      setIsEdit(false)
    }
  })

  // When task loads (if not null), assign values to formik:
  useEffect(() => {
    if (task) {
      const keys = Object.keys(task?.answer)
      if (keys) {
        keys.forEach((key) => {
          formik.setFieldValue(key, checkTaskValue(key, task))
        })
      }
    }
  }, [task])

  const typeDifficultyChangeHandler = (type, difficulty) => {
    const exerciseInfo = EXERCISE_TYPES_CONST.find(
      (exerciseType) =>
        exerciseType.exerciseTypeVariable === type &&
        exerciseType.exerciseTypeDifficulty === difficulty
    )

    const data = {
      exercise: exerciseInfo.exerciseVariable,
      group: exerciseInfo.exerciseTypeVariable,
      level:
        exerciseInfo.exerciseTypeDifficulty === 'Low Intensity'
          ? 'easy'
          : exerciseInfo.exerciseTypeDifficulty === 'Medium'
          ? 'normal'
          : 'hard',
      round_id: currentRoundId,
      subscription_id: currentSubscriptionId,
      week: 0
    }

    // Update difficulty type on API
    dispatch(updateDifficultyType(currentSubscriptionId, 0, data))

    setTypeDifficulties({ ...typeDifficulties, [type]: difficulty })
  }

  const scoreExists = fitnessTestScoreResult && isComplete

  return (
    <TaskContentContainer>
      <TaskContentSubHeader
        title='Fitness Test Results'
        btnTitle={isEdit ? 'View results' : 'Edit'}
        btnFunction={
          isEdit
            ? scoreExists
              ? () => setIsEdit(false)
              : null
            : () => setIsEdit(true)
        }
      />
      {!isEdit &&
      isComplete &&
      !fitnessTestResultsLoading &&
      !loading &&
      fitnessTestImprovements &&
      fitnessTestScoreResult ? (
        <>
          <p>
            Maximise your fitness score by customising your fitness test.{' '}
            <DefaultClearBtn
              title='Learn More'
              href={`${process.env.REACT_APP_GO_SITE}help/1422829-what-is-the-fitness-score`}
            />
          </p>
          <FitnessScorePanel />
          <WhichProgramShouldYouDo />
          <ImprovingYourFitnessScore />
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <FitnessTestResultInput
                formik={formik}
                testData={upperBodyStrengthTests}
                onTestChange={typeDifficultyChangeHandler}
                initialDifficulty={
                  measurements &&
                  convertDifficultyBack(measurements[0]?.upper_body)
                }
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <div className={classes.test}></div>
              <FitnessTestResultInput
                formik={formik}
                testData={coreStrengthTests}
                onTestChange={typeDifficultyChangeHandler}
                initialDifficulty={
                  measurements && convertDifficultyBack(measurements[0]?.core)
                }
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FitnessTestResultInput
                formik={formik}
                testData={aerobicCapacityTests}
                onTestChange={typeDifficultyChangeHandler}
                initialDifficulty={
                  measurements &&
                  convertDifficultyBack(measurements[0]?.aerobic)
                }
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FitnessTestResultInput
                formik={formik}
                testData={flexibilityTests}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FitnessTestResultInput
                formik={formik}
                testData={lowerBodyStrengthTests}
              />
            </Grid>
          </Grid>
          <Divider />
          {!loadingPreseason && !taskSuccess && taskFailed && (
            <TaskFailedBanner />
          )}

          <div className={classes.buttonContainer}>
            <ActionButton
              title={actionButtonTitle}
              onButtonClick={() => formik.submitForm()}
              loading={
                fitnessTestResultsLoading || loadingPreseason || !measurement
              }
            />
          </div>
        </>
      )}
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    margin: '2rem 0'
  }
}))

export default FitnessTestResults
