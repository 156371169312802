import React, { useEffect, useState } from 'react'
import { Button, Divider, makeStyles, useTheme } from '@material-ui/core'
import WhiteCard from '../../../components/WhiteCard'
import PropTypes from 'prop-types'
import {
  allCalorieOptions,
  CALORIE_OPTIONS,
  programStats
} from './WelcomeStepTwoData'
import WelcomeCheckbox from '../welcome_step_one/WelcomeCheckbox'
import { useDispatch, useSelector } from 'react-redux'
import { regressStepTwo } from '../../../../redux/actions/WelcomeSteps.actions'

const WelcomeStepTwoSelectCalories = ({ formik, collapsed }) => {
  const { profile } = useSelector((state) => state.profile)
  const gender = (profile?.gender === 'F' || localStorage.getItem('gender') === 'Female') ? 'female' : 'male'
  const recommendedOption =
    programStats[formik.values.exercise_plan_level].recommendedCalorie[gender]
  const totalCalorieOptions = allCalorieOptions[gender]
  const dispatch = useDispatch()
  const classes = useStyles()
  const [chosenCalorieLevel, setChosenCalorieLevel] = useState(
    recommendedOption
  )
  const theme = useTheme()

  useEffect(() => {
    formik.setFieldValue(
      'nutrition_program_id',
      CALORIE_OPTIONS[gender][chosenCalorieLevel].nutritionProgramId
    )
    formik.setFieldValue(
      'recipe_calorie_level',
      CALORIE_OPTIONS[gender][chosenCalorieLevel].recipeCalorieLevel
    )
  }, [chosenCalorieLevel])

  const backButtonClickHandler = () => {
    dispatch(regressStepTwo())
  }

  return (
    <WhiteCard margin='1rem auto'>
      <div className={classes.container}>
        <div className={classes.title}>
          <span>Calorie Selection</span>
        </div>
        {collapsed ? (
          <div className={classes.pickedGoalRow}>
            <WelcomeCheckbox
              color={theme.palette.signup.secondary}
              margin='.5rem 1rem .5rem 0'
              size={25}
              isChecked={true}
              label={chosenCalorieLevel}
              description={
                chosenCalorieLevel
                  ? CALORIE_OPTIONS[gender][chosenCalorieLevel].description
                  : null
              }
              onClick={() => {}}
              error={false}
              titleUppercase={true}
            />
            <Button
              className={classes.changeButton}
              onClick={backButtonClickHandler}
            >
              Change
            </Button>
          </div>
        ) : (
          <div className={classes.checkboxSection}>
            {totalCalorieOptions?.map((option, i) => {
              const currentOption = CALORIE_OPTIONS[gender][option]
              return (
                <div className={classes.checkboxRow} key={i}>
                  {i !== 0 && <Divider className={classes.divider} />}
                  <WelcomeCheckbox
                    color={theme.palette.signup.secondary}
                    margin='.5rem 1rem .5rem 0'
                    size={25}
                    isChecked={chosenCalorieLevel === currentOption?.calories}
                    label={currentOption?.calories}
                    description={currentOption?.description}
                    onClick={() => {
                      setChosenCalorieLevel(currentOption?.calories)
                    }}
                    error={false}
                    recommended={currentOption.calories === recommendedOption}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    </WhiteCard>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 1rem',
    overflow: 'hidden'
  },
  title: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  checkboxSection: {
    marginTop: '1.5rem'
  },
  checkboxRow: {
    margin: '1rem auto'
  },
  divider: {
    margin: '1rem 1rem',
    backgroundColor: theme.palette.greys.medium
  },
  programButton: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: theme.palette.signup.purple,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.signup.primary
    },
    width: '19rem',
    borderRadius: '12px'
  },
  buttonContainer: {
    ...theme.CSS.center
  },
  changeButton: {
    color: 'white',
    backgroundColor: theme.palette.signup.purple,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.signup.primary
    },
    width: '6rem',
    borderRadius: '12px',
    height: '2.5rem'
  },
  pickedGoalRow: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      padding: '0 .2rem'
    },
    changeButton: {
      marginBottom: '0',
      width: '18rem',
      fontWeight: 'bold'
    }
  }
}))

WelcomeStepTwoSelectCalories.propTypes = {
  formik: PropTypes.object.isRequired,
  collapsed: PropTypes.bool
}

export default WelcomeStepTwoSelectCalories
