import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'

const YourWhySection = () => {
  const [isVisible, setIsVisible] = useState(false)
  const classes = useStyles({ isVisible })
  const buttonTitle = isVisible ? 'Show less' : 'Show more'
  return (
    <>
      {isVisible && (
        <div className={classes.whyContainer}>
          <p>
            If weight loss is your goal, keep asking yourself why you want to lose the weight. When we really explore
            this, we eventually get to the deep seated pain you want to avoid and/or pleasure you want to seek.
          </p>
          <p>
            Your first answer to &apos;why&apos; losing weight is important could be
            <em>&quot;I want to exercise three times per week to lose weight so I can feel fit and healthy&quot;.</em>
            This is not strong enough. Why do you want to be fit and healthy?
          </p>
          <p>
            <em>
              &quot;I want to lose weight because when I was facing a health issue a few weeks ago, I was terrified that
              my kids might grow up without a Mum. I felt terrible that I would play a role in that outcome by failing
              to look after my health&quot;.
            </em>
            Do you see how much more emotion and feeling is behind that original goal?
          </p>
          <p>
            Why is health and fitness important to you? Why is goal setting important to you? Spend some time exploring
            your &quot;why&quot; and revisit this on a regular basis.
          </p>
        </div>
      )}
      <Button className={classes.showButton} onClick={() => setIsVisible(!isVisible)}>
        {buttonTitle}
      </Button>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  showButton: {
    textTransform: 'none',
    color: theme.palette.signup.purple,
    backgroundColor: theme.palette.greys.light,
    width: '138px',
    height: '50px',
    fontSize: '1.1rem'
  },
  whyContainer: {
    height: '100%',
    transition: theme.transitions.create(['max-height'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut
    }),
    maxHeight: (props) => (props.isVisible ? '100rem' : '0')
  }
}))

export default YourWhySection
