import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const HealthyEatingRatioInfo = ({ title, color, description, img }) => {
  const classes = useStyles({ color })
  return (
    <div className={classes.container}>
      <span className={classes.title}>{title}</span>
      <span className={classes.description}>{description}</span>
      {img && <img src={img} alt={title} />}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& img': {
      maxWidth: '90%',
      margin: 'auto',
      marginTop: '-14px'
    },
    textAlign: 'center'
  },
  title: {
    fontFamily: 'KokomoBreeze',
    color: (props) => props.color,
    fontSize: '4rem',
    marginBottom: '.5rem'
  }
}))

HealthyEatingRatioInfo.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  img: PropTypes.string
}

export default HealthyEatingRatioInfo
