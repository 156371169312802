import React from 'react'
import { makeStyles } from '@material-ui/core'
import mainLogo from '../../../assets/img/logos/logo-white@2x.png'
import lifestyleLogo from '../../../assets/img/logos/lifestyle-logo.png'
import { useSelector } from 'react-redux'

const WelcomeReturnHeader = () => {
  const currentSubscription = useSelector((state) => state.currentSubscription?.currentSubscription)
  const classes = useStyles()
  const logo = currentSubscription?.lifers ? lifestyleLogo : mainLogo
  return (
    <div className={classes.header}>
      <img src={logo} alt='white 12wbt logo' className={classes.logo} />
      <span className={classes.title}>Select a new plan below:</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto'
  },
  logo: {
    maxWidth: '10rem',
    margin: '1rem auto',
    marginTop: '3rem'
  },
  title: {
    fontSize: '1.8rem',
    margin: '2rem auto',
    color: 'white'
  },
  [theme.breakpoints.down('xs')]: {
    title: {
      marginBottom: '3rem'
    }
  }
}))

export default WelcomeReturnHeader
