import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../../components/ContentContainer'
import TaskLearnButtonRow from '../TaskLearnButtonRow'
import TaskContentSubHeader from '../TaskContentSubHeader'
import TaskContentContainer from '../TaskContentContainer'

const OrganiseAndDiariseLearn = ({ doTaskButtonClick }) => {
  const classes = useStyles()
  return (
    <ContentContainer maxWidth='xl'>
      <div className={classes.container}>
        <TaskContentContainer>
          <TaskContentSubHeader title="We're getting close to kick off!" />
          <p>
            Diarising and planning is the best way to guarantee you&apos;ll get your workouts, meals and shopping done.
          </p>
          <ul>
            <li>Organise your training and nutrition around ‘red flag’ disruptions.</li>
            <li>Allow one major shopping expedition and one top-up shop during the week.</li>
            <li>
              While we want you to work out at a time that suits you best, early morning training is best - it gets it
              out of the way and there&apos;s less chance of interruptions. Set out your workout clothes that night so
              you&apos;re all ready to go in the morning.
            </li>
            <li>
              Be prepared for your workouts by familiarising yourself with these the night before. Click on any
              exercises that you&apos;ve never seen and watch their demo videos so you know how they need to be
              completed. This can make a considerable difference to your workout as you can move through things
              seamlessly, safely and with a good level of intensity.
            </li>
            <li>
              If you&apos;re training at home, set up your area if possible or improvise if you have others at home to
              consider at that time.
            </li>
          </ul>
          <TaskLearnButtonRow doTaskButtonClick={doTaskButtonClick} />
        </TaskContentContainer>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '60px auto',
    '& li': {
      marginBottom: '2rem'
    }
  },
  [theme.breakpoints.down('md')]: {
    container: {
      '& li': {
        marginBottom: '1rem'
      }
    }
  }
}))

OrganiseAndDiariseLearn.propTypes = {
  doTaskButtonClick: PropTypes.func.isRequired
}

export default OrganiseAndDiariseLearn
