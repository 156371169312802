import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const WelcomeStepTwoProgressIcon = ({ icon, active }) => {
  const classes = useStyles({ active })
  return <div className={classes.iconContainer}>{icon}</div>
}

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    backgroundColor: (props) => (props.active ? 'white' : theme.palette.signup.lightBlue),
    border: '2px solid white',
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    ...theme.CSS.center,
    zIndex: 3,
    '& svg': {
      color: (props) => (props.active ? theme.palette.signup.lightBlue : 'white'),
      width: '1.5rem',
      height: '1.5rem',
      fill: (props) => (props.active ? theme.palette.signup.lightBlue : 'white')
    }
  }
}))

WelcomeStepTwoProgressIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  active: PropTypes.bool.isRequired
}

export default WelcomeStepTwoProgressIcon
