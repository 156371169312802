import React from 'react'
import { makeStyles } from '@material-ui/core'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const TaskFailedBanner = () => {
  const classes = useStyles()
  return (
    <div className={classes.bannerContainer}>
      <div className={classes.completeContainer}>
        <HighlightOffIcon />
        <span className={classes.mainText}>
          Something went wrong! Try again or contact our support at support@12wbtsupport.com
        </span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem 0',
    backgroundColor: '#FFd2d2',
    border: `1px solid #C41A1A`,
    borderRadius: '6px',
    margin: '1rem auto',
    marginBottom: '2rem'
  },
  completeContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: '#C41A1A',
      marginRight: '6px'
    },
    padding: '0 .8rem'
  },
  mainText: {
    color: '#C41A1A',
    fontWeight: 800,
    fontSize: '1.1rem'
  },
  [theme.breakpoints.down('md')]: {
    bannerContainer: {
      marginBottom: '1rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    bannerContainer: {
      width: '90%',
      flexDirection: 'column',
      padding: 0
    },
    mainText: {
      fontSize: '.9rem'
    }
  }
}))

export default TaskFailedBanner
