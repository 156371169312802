import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import BannerItem from '../fields/BannerItem'
import ErrorLoadingPrograms from './ErrorLoadingPrograms'
import { useSelector } from 'react-redux'

const SelectPlanBanners = ({
  values,
  activeBanner,
  bannerSelectHandler,
  activeColor
}) => {
  const classes = useStyles()
  const { programs, loading, pricePointFail } = useSelector(
    (state) => state.signupPrograms
  )

  return (
    <div className={classes.container}>
      {values &&
        values.map((program, i) => {
          const GATotalCostInCents = program?.coupon
            ? program.coupon.minAmount
            : program.minAmount

          // Calculate values to show on UI and send to Google Tag Manager (GTM):
          const price = program?.coupon
            ? program.coupon.discountPriceInCents
            : program.priceInCents
          const priceInDollars = (price / 100).toFixed(2)
          const minTotalCostInDollars = (GATotalCostInCents / 100).toFixed(2)
          const originalPriceInCents = program.priceInCents
          return (
            <BannerItem
              key={i}
              value={program.handle}
              activeBanner={activeBanner}
              activeColor={activeColor}
              setActiveBanner={bannerSelectHandler}
              paymentObj={{
                productHandle: program.chargifyProductHandle,
                paymentPlan: program._id,
                billingOption: program.billingOption,
                priceInDollars,
                GATotalCostInCents,
                couponCode: program?.coupon?.code
              }}
              title={program.title}
              price={priceInDollars}
              minTotalCostInDollars={minTotalCostInDollars}
              paymentDescription={program.paymentDescription}
              description={program.description}
              terms={program?.notes}
              billingCycles={program.billingCycles}
              discount={program?.coupon}
              originalPriceInCents={originalPriceInCents}
            />
          )
        })}
      {!programs && !loading && pricePointFail && <ErrorLoadingPrograms />}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({}))

SelectPlanBanners.defaultProps = {
  values: []
}

SelectPlanBanners.propTypes = {
  values: PropTypes.array,
  activeBanner: PropTypes.string,
  activeColor: PropTypes.string,
  bannerSelectHandler: PropTypes.func.isRequired
}

export default SelectPlanBanners
