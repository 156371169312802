import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const WhiteCard = ({ children, margin, padding, withShadow }) => {
  const classes = useStyles({ margin, padding, withShadow })
  return <div className={classes.card}>{children}</div>
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '20px',
    backgroundColor: 'white',
    padding: (props) => props.padding,
    margin: (props) => props.margin,
    boxShadow: (props) => (props.withShadow ? theme.CSS.cardShadow : undefined)
  },
  [theme.breakpoints.down('xs')]: {
    card: {
      margin: '.8rem .5rem!important',
      boxShadow: (props) => (props.withShadow ? theme.CSS.cardShadow : undefined)
    }
  }
}))

WhiteCard.defaultProps = {
  padding: '1rem',
  margin: '1.8rem'
}

WhiteCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.string,
  withShadow: PropTypes.bool
}

export default WhiteCard
