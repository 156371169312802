export const GET_NEXT_AVAILABLE_ROUND_REQUEST =
  'GET_NEXT_AVAILABLE_ROUND_REQUEST'
export const GET_NEXT_AVAILABLE_ROUND_SUCCESS =
  'GET_NEXT_AVAILABLE_ROUND_SUCCESS'
export const GET_NEXT_AVAILABLE_ROUND_FAIL = 'GET_NEXT_AVAILABLE_ROUND_FAIL'

export const UPDATE_LASSO_REQUEST = 'UPDATE_LASSO_REQUEST'
export const UPDATE_LASSO_SUCCESS = 'UPDATE_LASSO_SUCCESS'
export const UPDATE_LASSO_FAIL = 'UPDATE_LASSO_FAIL'

export const VALIDATE_COUPON_REQUEST = 'VALIDATE_COUPON_REQUEST'
export const VALIDATE_COUPON_SUCCESS = 'VALIDATE_COUPON_SUCCESS'
export const VALIDATE_COUPON_FAIL = 'VALIDATE_COUPON_FAIL'

export const CHECK_MEMBER_REQUEST = 'CHECK_MEMBER_REQUEST'
export const CHECK_MEMBER_SUCCESS = 'CHECK_MEMBER_SUCCESS'
export const CHECK_MEMBER_FAIL = 'CHECK_MEMBER_FAIL'

export const CHECK_FRIEND_MEMBER_REQUEST = 'CHECK_FRIEND_MEMBER_REQUEST'
export const CHECK_FRIEND_MEMBER_SUCCESS = 'CHECK_FRIEND_MEMBER_SUCCESS'
export const CHECK_FRIEND_MEMBER_FAIL = 'CHECK_FRIEND_MEMBER_FAIL'

export const GET_PRICE_POINTS_REQUEST = 'GET_PRICE_POINTS_REQUEST'
export const GET_PRICE_POINTS_SUCCESS = 'GET_PRICE_POINTS_SUCCESS'
export const GET_PRICE_POINTS_FAIL = 'GET_PRICE_POINTS_FAIL`'

export const GET_LIFERS_PRICE_POINTS_REQUEST = 'GET_LIFERS_PRICE_POINTS_REQUEST'
export const GET_LIFERS_PRICE_POINTS_SUCCESS = 'GET_LIFERS_PRICE_POINTS_SUCCESS'
export const GET_LIFERS_PRICE_POINTS_FAIL = 'GET_LIFERS_PRICE_POINTS_FAIL'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'

export const SIGNUP_WITH_FRIEND_REQUEST = 'SIGNUP_WITH_FRIEND_REQUEST'
export const SIGNUP_WITH_FRIEND_SUCCESS = 'SIGNUP_WITH_FRIEND_SUCCESS'
export const SIGNUP_WITH_FRIEND_FAIL = 'SIGNUP_WITH_FRIEND_FAIL'

export const SIGNUP_LOGIN_REQUEST = 'SIGNUP_LOGIN_REQUEST'
export const SIGNUP_LOGIN_SUCCESS = 'SIGNUP_LOGIN_SUCCESS'
export const SIGNUP_LOGIN_FAIL = 'SIGNUP_LOGIN_FAIL'

export const SIGNUP_RESET_ERROR = 'SIGNUP_RESET_ERROR'
export const SIGNUP_LOGIN_RESET_ERROR = 'SIGNUP_LOGIN_RESET_ERROR'
export const SIGNUP_RESET_SUCCESS = 'SIGNUP_RESET_SUCCESS'

export const GET_SECURITY_TOKEN_REQUEST = 'GET_SECURITY_TOKEN_REQUEST'
export const GET_SECURITY_TOKEN_SUCCESS = 'GET_SECURITY_TOKEN_SUCCESS'
export const GET_SECURITY_TOKEN_FAIL = 'GET_SECURITY_TOKEN_FAIL'

export const GET_FRIEND_PRICE_POINTS_REQUEST = 'GET_FRIEND_PRICE_POINTS_REQUEST'
export const GET_FRIEND_PRICE_POINTS_SUCCESS = 'GET_FRIEND_PRICE_POINTS_SUCCESS'
export const GET_FRIEND_PRICE_POINTS_FAIL = 'GET_FRIEND_PRICE_POINTS_FAIL'

export const GET_ROUND_INFO_REQUEST = 'GET_ROUND_INFO_REQUEST'
export const GET_ROUND_INFO_SUCCESS = 'GET_ROUND_INFO_SUCCESS'
export const GET_ROUND_INFO_FAIL = 'GET_ROUND_INFO_FAIL'

export const GET_CURRENT_AND_NEXT_ROUND_DATES_REQUEST =
  'GET_CURRENT_AND_NEXT_ROUND_DATES_REQUEST'
export const GET_CURRENT_AND_NEXT_ROUND_DATES_SUCCESS =
  'GET_CURRENT_AND_NEXT_ROUND_DATES_SUCCESS'
export const GET_CURRENT_AND_NEXT_ROUND_DATES_FAIL =
  'GET_CURRENT_AND_NEXT_ROUND_DATES_FAIL'

export const SET_SELECTED_PRICE = 'SET_SELECTED_PRICE'
