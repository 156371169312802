import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../../components/ContentContainer'
import ActionButton from '../ActionButton'
import TaskContentSubHeader from '../TaskContentSubHeader'
import TaskContentContainer from '../TaskContentContainer'

import { useDispatch, useSelector } from 'react-redux'
import { deletePreSeasonTask, submitPreSeasonTask } from '../../../../../redux/actions/PreSeason.actions'
import { tasks, INTRODUCE_YOURSELF } from '../TaskData'
import { getPreSeasonTask } from '../../../../helpers/GetPreSeasonTask'
import TaskCompletedBanner from '../TaskCompletedBanner'
import navigateToGoSite from '../../../../helpers/NavigateToGoSite'

const IntroduceYourselfDoTask = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.newPreSeason)
  const { currentSubscriptionId } = useSelector((state) => state.member)
  const classes = useStyles()

  const taskTitle = tasks[INTRODUCE_YOURSELF].header
  const task = getPreSeasonTask(taskTitle)
  const isComplete = task
  const actionButtonTitle = isComplete ? 'Undo' : 'Finish'

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [isComplete])

  const actionButtonClickHandler = () => {
    if (!isComplete) dispatch(submitPreSeasonTask(currentSubscriptionId, { title: taskTitle }))
    else dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  const onUndoClickHandler = () => {
    if (!loading) dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  return (
    <ContentContainer maxWidth='xl'>
      <div className={classes.container}>
        <TaskContentContainer>
          {isComplete && <TaskCompletedBanner onUndoClick={onUndoClickHandler} />}
          <TaskContentSubHeader title='How this works' />
          <ol>
            <li>
              It&apos;s time to get friendly and personable with your fellow 12WBTers and share your commitment and
              goals; and the best way to start is by putting some time into your{' '}
              <a href={navigateToGoSite('my-account/profile')}>12WBT Profile!</a> Think about when you introduce
              yourself to someone in person and how much more comfortable you feel because you are meeting them
              face-to-face and discussing things you have in common. That&apos;s why adding a picture to your 12WBT
              Profile and being open about your goals is a great way to help create that safe and trusting environment
              in the <a href={process.env.REACT_APP_12WBT_FACEBOOK_MEMBER_ZONE}>Member Zone.</a> You can even add your
              location and a short bio too!
            </li>
            <li>
              Introduce Yourself! Once you have completed your profile, head to the&nbsp;
              <a href={process.env.REACT_APP_12WBT_FACEBOOK_MEMBER_ZONE}>Facebook Member Zone</a> and take a browse
              through the posts. The whole idea is to connect with 12WBTers doing the same program as you.
            </li>
            <li>
              Post something! If you&apos;re feeling confident, we encourage you to start off by posting an intro about
              you and your goals by writing: <em>&quot;My commitment is...&quot;</em> — and clearly outline your goals.
              Finish it with, <em>&quot;I am committed to do the work to get me there&quot;</em>.
            </li>
            <li>
              After putting your goals and commitment out there, you can post updates, questions, replies or comments,
              or even start to like some of the posts that resonate with you. Check out some post examples at the bottom
              of this Task page.
            </li>
            <li>Tell your loved ones. Shake on it. Now be your word and don&apos;t let anything get in your way!!!</li>
            <li>
              Why not acknowledge 5 other fellow teammates’ commitment posts? Supporting each other is how we roll in
              12WBT!
            </li>
            <li>
              Don&apos;t forget to come back to this page and select ‘Finish’ (bottom of page) to tick this task off!
            </li>
          </ol>
          <span className={classes.memberZoneHeader}>Member Zone Tips</span>
          <div className={classes.memberZoneTips}>
            <ul>
              <li>End your posts with questions – this will encourage more responses.</li>
              <li>Take the time to reply to other topics, the more you put in, the more you&apos;ll get out.</li>
            </ul>
          </div>
          <TaskContentSubHeader title='Check out some Member Zone post examples:' />
          <span className={classes.subHeader}>New and Nervous</span>
          <p className={classes.memberPost}>
            Hello 12WBTers, my name is Sarah and this is my very first time doing 12WBT! I have spent the last 20 years
            of my life putting my family first and not giving time to myself. I really want to learn to put time and
            energy into MY exercise and MY eating. My goal is to lose 20 kilos but I am nervous about failing. Any
            advice would be greatly appreciated!
          </p>
          <span className={classes.subHeader}>Fighting Evening Binges</span>
          <p className={classes.memberPost}>
            Does anyone else have trouble ignoring the cries of the snack food from the pantry in the evenings? My name
            is Stephanie and I really don&apos;t know how to break the night time binge habits. This is my second round
            of 12WBT and my new goal is to break this habit. I work full time, have 3 kids and love chocolate. Hands up
            if you&apos;re in the same boat with these night time temptations?
          </p>
          <span className={classes.subHeader}>Learning to love moving</span>
          <p className={classes.memberPost}>
            Hello! My name is Andrew, this is my first time doing 12WBT. I haven&apos;t exercised properly for about 6
            years. I have joined the Beginners program to get control of my life again and get myself moving. I have a
            knee injury and need to start this journey gradually. Slowly but surely! Does anyone have any advice for
            completing the 12WBT with a knee injury?
          </p>
          <ActionButton title={actionButtonTitle} onButtonClick={actionButtonClickHandler} loading={loading} />
        </TaskContentContainer>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '60px auto',
    '& ol': {
      '& li': {
        marginBottom: '2rem'
      }
    }
  },
  subHeader: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    margin: 0,
    padding: 0
  },
  memberZoneTips: {
    borderLeft: `5px solid ${theme.palette.signup.purple}`,
    width: '100%',
    margin: 'auto',
    marginBottom: '2rem',
    marginTop: '-10px',
    height: '5rem',
    '& ul': {
      listStyle: 'none'
    }
  },
  memberZoneHeader: {
    fontSize: '1.3rem',
    fontWeight: 900,
    color: theme.palette.signup.purple
  },
  memberPost: {
    marginTop: 0
  },
  [theme.breakpoints.down('md')]: {
    memberZoneTips: {
      marginBottom: '1rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    memberZoneTips: {
      marginBottom: '2rem',
      lineHeight: '25px',
      height: '6rem'
    },
    container: {
      '& ol': {
        '& li': {
          marginBottom: '1rem'
        }
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    memberZoneTips: {
      marginBottom: '4rem'
    }
  }
}))

export default IntroduceYourselfDoTask
