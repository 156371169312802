export const GET_NEW_PRE_SEASON_DATA_REQUEST = 'GET_NEW_PRE_SEASON_DATA_REQUEST'
export const GET_NEW_PRE_SEASON_DATA_SUCCESS = 'GET_NEW_PRE_SEASON_DATA_SUCCESS'
export const GET_NEW_PRE_SEASON_DATA_FAIL = 'GET_NEW_PRE_SEASON_DATA_FAIL'

export const SUBMIT_PRE_SEASON_TASK_REQUEST = 'SUBMIT_PRE_SEASON_TASK_REQUEST'
export const SUBMIT_PRE_SEASON_TASK_SUCCESS = 'SUBMIT_PRE_SEASON_TASK_SUCCESS'
export const SUBMIT_PRE_SEASON_TASK_FAIL = 'SUBMIT_PRE_SEASON_TASK_FAIL'

export const UPDATE_PRE_SEASON_TASK_REQUEST = 'UPDATE_PRE_SEASON_TASK_REQUEST'
export const UPDATE_PRE_SEASON_TASK_SUCCESS = 'UPDATE_PRE_SEASON_TASK_SUCCESS'
export const UPDATE_PRE_SEASON_TASK_FAIL = 'UPDATE_PRE_SEASON_TASK_FAIL'

export const DELETE_PRE_SEASON_TASK_REQUEST = 'DELETE_PRE_SEASON_TASK_REQUEST'
export const DELETE_PRE_SEASON_TASK_SUCCESS = 'DELETE_PRE_SEASON_TASK_SUCCESS'
export const DELETE_PRE_SEASON_TASK_FAIL = 'DELETE_PRE_SEASON_TASK_FAIL'

export const SUBMIT_PRE_SEASON_ACTIVITY_REQUEST = 'SUBMIT_PRE_SEASON_ACTIVITY_REQUEST'
export const SUBMIT_PRE_SEASON_ACTIVITY_SUCCESS = 'SUBMIT_PRE_SEASON_ACTIVITY_SUCCESS'
export const SUBMIT_PRE_SEASON_ACTIVITY_FAIL = 'SUBMIT_PRE_SEASON_ACTIVITY_FAIL'

export const UPDATE_PRE_SEASON_ACTIVITY_REQUEST = 'UPDATE_PRE_SEASON_ACTIVITY_REQUEST'
export const UPDATE_PRE_SEASON_ACTIVITY_SUCCESS = 'UPDATE_PRE_SEASON_ACTIVITY_SUCCESS'
export const UPDATE_PRE_SEASON_ACTIVITY_FAIL = 'UPDATE_PRE_SEASON_ACTIVITY_FAIL'

export const DELETE_PRE_SEASON_ACTIVITY_REQUEST = 'DELETE_PRE_SEASON_ACTIVITY_REQUEST'
export const DELETE_PRE_SEASON_ACTIVITY_SUCCESS = 'DELETE_PRE_SEASON_ACTIVITY_SUCCESS'
export const DELETE_PRE_SEASON_ACTIVITY_FAIL = 'DELETE_PRE_SEASON_ACTIVITY_FAIL'

export const GET_PRE_SEASON_ACTIVITY_COUNTER_REQUEST = 'GET_PRE_SEASON_ACTIVITY_COUNTER_REQUEST'
export const GET_PRE_SEASON_ACTIVITY_COUNTER_SUCCESS = 'GET_PRE_SEASON_ACTIVITY_COUNTER_SUCCESS'
export const GET_PRE_SEASON_ACTIVITY_COUNTER_FAIL = 'GET_PRE_SEASON_ACTIVITY_COUNTER_FAIL'

export const GET_FITNESS_SCORE_IMPROVEMENTS_REQUEST = 'GET_FITNESS_SCORE_IMPROVEMENTS_REQUEST'
export const GET_FITNESS_SCORE_IMPROVEMENTS_SUCCESS = 'GET_FITNESS_SCORE_IMPROVEMENTS_SUCCESS'
export const GET_FITNESS_SCORE_IMPROVEMENTS_FAIL = 'GET_FITNESS_SCORE_IMPROVEMENTS_FAIL'

export const GET_FITNESS_SCORE_RESULT_REQUEST = 'GET_FITNESS_SCORE_RESULT_REQUEST'
export const GET_FITNESS_SCORE_RESULT_SUCCESS = 'GET_FITNESS_SCORE_RESULT_SUCCESS'
export const GET_FITNESS_SCORE_RESULT_FAIL = 'GET_FITNESS_SCORE_RESULT_FAIL'

export const UPDATE_DIFFICULTY_TYPE_REQUEST = 'UPDATE_DIFFICULTY_TYPE_REQUEST'
export const UPDATE_DIFFICULTY_TYPE_SUCCESS = 'UPDATE_DIFFICULTY_TYPE_SUCCESS'
export const UPDATE_DIFFICULTY_TYPE_FAIL = 'UPDATE_DIFFICULTY_TYPE_FAIL'
