import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import SignUpFAQDrawer from './SignUpFAQDrawer'

const SignUpFAQButton = () => {
  const classes = useStyles()
  const [drawerIsVisible, setDrawerIsVisible] = useState(false)
  return (
    <>
      <div className={classes.faqButton} onClick={() => setDrawerIsVisible(!drawerIsVisible)}>
        <ChevronLeftIcon />
        <span className={classes.faqText}>FAQ</span>
      </div>
      <SignUpFAQDrawer show={drawerIsVisible} closeHandler={() => setDrawerIsVisible(false)} />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  faqButton: {
    position: 'fixed',
    right: 0,
    width: '3rem',
    height: '6rem',
    top: '42%',
    zIndex: 100,
    backgroundColor: theme.palette.signup.signupFaq,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    borderBottomLeftRadius: '15px',
    borderTopLeftRadius: '15px',
    '& svg': {
      color: 'white'
    }
  },
  faqText: {
    color: 'white',
    transform: 'rotate(-90deg)',
    fontWeight: 900,
    padding: '1rem',
    paddingRight: '.5rem'
  },
  [theme.breakpoints.down('sm')]: {
    faqButton: {
      width: '2.5rem',
      height: '5rem'
    }
  }
}))

export default SignUpFAQButton
