import React, { useEffect, useState } from 'react'
import PageContainer from '../app/components/PageContainer'
import TaskHeader from '../app/modules/pre_season/tasks/TaskHeader'
import {
  tasks,
  HEADER_TABS,
  NUT_OUT_YOUR_NUTRITION,
  ORGANISE_AND_DIARISE,
  INTRODUCE_YOURSELF,
  SET_YOURSELF_UP_FOR_SUCCESS,
  FITNESS_TEST_AND_MEASURE_UP,
  FITNESS_TEST,
  MEASURE_UP
} from '../app/modules/pre_season/tasks/TaskData'

import { Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRoundMeasurements } from '../redux/actions/Member.actions'
import TaskFooter from '../app/modules/pre_season/tasks/TaskFooter'

import NutOutYourNutritionDoTask from '../app/modules/pre_season/tasks/nut_out_your_nutrition/NutOutYourNutritionDoTask'
import NutOutYourNutritionLearn from '../app/modules/pre_season/tasks/nut_out_your_nutrition/NutOutYourNutritionLearn'

import OrganiseAndDiariseDoTask from '../app/modules/pre_season/tasks/organise_and_diarise/OrganiseAndDiariseDoTask'
import OrganiseAndDiariseLearn from '../app/modules/pre_season/tasks/organise_and_diarise/OrganiseAndDiariseLearn'

import IntroduceYourselfDoTask from '../app/modules/pre_season/tasks/introduce_yourself/IntroduceYourselfDoTask'
import IntroduceYourselfLearn from '../app/modules/pre_season/tasks/introduce_yourself/IntroduceYourselfLearn'

import SetYourselfUpForSuccessLearn from '../app/modules/pre_season/tasks/set_yourself_up_for_success/SetYourselfUpForSuccessLearn'
import SetYourselfUpForSuccessDoTask from '../app/modules/pre_season/tasks/set_yourself_up_for_success/SetYourselfUpForSuccessDoTask'

import FitnessTestAndMeasureUpLearn from '../app/modules/pre_season/tasks/fitness_test_and_measure_up/FitnessTestAndMeasureUpLearn'
import FitnessTestAndMeasureUpDoTask from '../app/modules/pre_season/tasks/fitness_test_and_measure_up/FitnessTestAndMeasureUpDoTask'
import loadNewPreSeasonData from '../app/helpers/LoadNewPreSeasonData'
import { getPreSeasonTask, getPreSeasonTaskBySlug } from '../app/helpers/GetPreSeasonTask'

const PreSeasonTask = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const currentRoundId = useSelector((state) => state.member.currentRoundId)
  const measurementsLoading = useSelector((state) => state.measurements.loading)
  const measurements = useSelector((state) => state.measurements.measurements)
  const member = useSelector((state) => state.member.member)
  const [activeTab, setActiveTab] = useState(HEADER_TABS.LEARN)
  const slugArray = location.pathname.split('/')
  const slug = slugArray[slugArray?.length - 1]

  const isFitnessTestTaskDone = getPreSeasonTask(FITNESS_TEST)?.status === 'done'
  const isMeasureUpTaskDone = getPreSeasonTask(MEASURE_UP)?.status === 'done'
  const isOtherTaskDone = getPreSeasonTaskBySlug(slug)?.status === 'done'

  // Get task & activity info:
  loadNewPreSeasonData()

  // Reset tab to learn on page change:
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
    if (slug === 'fitness-test-and-measure-up' && (isFitnessTestTaskDone || isMeasureUpTaskDone))
      setActiveTab(HEADER_TABS.DO_THE_TASK)
    else if (isOtherTaskDone) setActiveTab(HEADER_TABS.DO_THE_TASK)
    else setActiveTab(HEADER_TABS.LEARN)
  }, [location.pathname, setActiveTab])

  // On do task button click, scroll to top:
  const doTaskButtonClickHandler = () => {
    // window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveTab(HEADER_TABS.DO_THE_TASK)
  }

  const task = tasks[slug]

  useEffect(() => {
    if (!measurements && slug === FITNESS_TEST_AND_MEASURE_UP && !measurementsLoading && member && currentRoundId) {
      dispatch(getRoundMeasurements(currentRoundId))
    }
  }, [member, currentRoundId])

  // Validate slug & redirect if invalid:
  if (!task) return <Redirect to='/pre-season' />

  const TaskContent = () => {
    const learnTabActive = activeTab === HEADER_TABS.LEARN
    switch (slug) {
      case NUT_OUT_YOUR_NUTRITION: {
        if (learnTabActive) return <NutOutYourNutritionLearn doTaskButtonClick={doTaskButtonClickHandler} />
        return <NutOutYourNutritionDoTask />
      }
      case ORGANISE_AND_DIARISE: {
        if (learnTabActive) return <OrganiseAndDiariseLearn doTaskButtonClick={doTaskButtonClickHandler} />
        return <OrganiseAndDiariseDoTask />
      }
      case INTRODUCE_YOURSELF: {
        if (learnTabActive) return <IntroduceYourselfLearn doTaskButtonClick={doTaskButtonClickHandler} />
        return <IntroduceYourselfDoTask />
      }
      case SET_YOURSELF_UP_FOR_SUCCESS: {
        if (learnTabActive) return <SetYourselfUpForSuccessLearn doTaskButtonClick={doTaskButtonClickHandler} />
        return <SetYourselfUpForSuccessDoTask />
      }
      case FITNESS_TEST_AND_MEASURE_UP: {
        if (learnTabActive) return <FitnessTestAndMeasureUpLearn doTaskButtonClick={doTaskButtonClickHandler} />
        return <FitnessTestAndMeasureUpDoTask />
      }
      default:
        return null
    }
  }

  return (
    <PageContainer>
      <TaskHeader title={task?.header} description={task?.intro} activeTab={activeTab} setActiveTab={setActiveTab} />
      {TaskContent()}
      <TaskFooter moreTasks={task?.moreTasks} />
    </PageContainer>
  )
}

PreSeasonTask.propTypes = {}

export default PreSeasonTask
