import React from 'react'
import { FormHelperText, makeStyles } from '@material-ui/core'

const ErrorLoadingPrograms = () => {
  const classes = useStyles()
  return (
    <FormHelperText className={classes.error}>
      Whoops, a problem occurred while retrieving available plans. Please contact us at support@12wbtsupport.com
    </FormHelperText>
  )
}

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: '1rem',
    color: '#EF5353'
  }
}))

export default ErrorLoadingPrograms
