import axiosWrapper from '../../redux/axiosInstance'

export const setAuthToken = (token) => {
  axiosWrapper.defaults.withCredentials = true
  if (token) {
    axiosWrapper.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axiosWrapper.defaults.headers.common.Authorization
  }
}
