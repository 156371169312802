import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import ContentContainer from '../../../components/ContentContainer'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

const GuideHeader = ({ title, description }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Link className={classes.backButton} to='/pre-season'>
        <ArrowBackIosIcon />
        <span className={classes.buttonText}>Pre-season home</span>
      </Link>
      <ContentContainer maxWidth='lg'>
        <div className={classes.headerContent}>
          <div className={classes.headerDiv}>
            <span className={classes.task}>How to get started with 12WBT</span>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.descriptionContent}>
              <span className={classes.description}>{description}</span>
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#5164E9',
    position: 'relative',
    paddingBottom: '2rem'
  },
  backButton: {
    display: 'flex',
    ...theme.CSS.center,
    position: 'absolute',
    left: '16px',
    top: '16px',
    color: 'white',
    textDecoration: 'none'
  },
  buttonText: {
    marginLeft: '8px',
    fontSize: '14px',
    fontWeight: 500
  },
  headerDiv: {
    textAlign: 'center',
    color: 'white',
    paddingTop: '96px'
  },
  task: {
    fontSize: '24px',
    linHeight: '24px',
    fontWeight: 600
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
    lineHeight: '60px',
    margin: '5px auto',
    marginBottom: '20px'
  },
  description: {
    fontSize: '20px',
    linHeight: '28px',
    fontWeight: 400,
    maxWidth: '40%'
  },
  descriptionContent: {
    maxWidth: '60%',
    margin: 'auto'
  },
  headerContent: {
    ...theme.CSS.center
  },
  [theme.breakpoints.down('md')]: {
    headerDiv: {
      paddingTop: '75px',
      maxWidth: '80%'
    },
    title: {
      fontSize: '50px',
      maxWidth: '90%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '40px',
      lineHeight: '38px',
      margin: '1rem auto'
    },
    headerDiv: {
      maxWidth: '75%'
    },
    task: {
      fontSize: '20px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      paddingBottom: '1rem'
    },
    headerDiv: {
      maxWidth: '70%'
    },
    title: {
      fontSize: '35px',
      margin: '1rem auto'
    },
    description: {
      fontSize: '1rem'
    }
  },
  [theme.breakpoints.down(480)]: {
    headerDiv: {
      maxWidth: '90%'
    }
  },
  [theme.breakpoints.down(380)]: {
    title: {
      fontSize: '30px'
    },
    headerDiv: {
      maxWidth: '95%'
    }
  }
}))
GuideHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default GuideHeader
