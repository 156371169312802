import axiosWrapper from '../axiosInstance'
import {
  PROGRESS_STEP_TWO,
  REGRESS_STEP_TWO,
  GET_RECOMMENDED_PROGRAMS_REQUEST,
  GET_RECOMMENDED_PROGRAMS_SUCCESS,
  GET_RECOMMENDED_PROGRAMS_FAIL
} from '../constants/WelcomeSteps.constants'

export const progressStepTwo = () => async (dispatch) => {
  dispatch({ type: PROGRESS_STEP_TWO })
}

export const regressStepTwo = () => async (dispatch) => {
  dispatch({ type: REGRESS_STEP_TWO })
}

export const getRecommendedPrograms = (
  roundId,
  memberId,
  subscriptionId
) => async (dispatch) => {
  try {
    dispatch({ type: GET_RECOMMENDED_PROGRAMS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v2/rounds/${roundId}/members/${memberId}/fitness_scores/recommended_programs_for_round_and_member?subscription_id=${subscriptionId}`
    )
    dispatch({ type: GET_RECOMMENDED_PROGRAMS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_RECOMMENDED_PROGRAMS_FAIL })
  }
}
