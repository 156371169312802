import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const TooltipPreSeasonReleaseDate = ({ icon, title, dateAvailable, link, subTitle, subTitleLink, isActive }) => {
  const classes = useStyles({ isActive, subTitle })
  return isActive ? (
    <a href={link} className={classes.container}>
      <div className={classes.iconContainer}>{icon}</div>
      <div className={classes.planDiv}>
        <span className={classes.title}>{title}</span>
        {subTitle ? (
          <a href={subTitleLink} className={classes.container}>
            <span className={classes.availableDate}>{subTitle}</span>
          </a>
        ) : (
          <></>
        )}
      </div>
    </a>
  ) : (
    <div className={classes.container}>
      <div className={classes.iconContainer}>{icon}</div>
      <div className={classes.planDiv}>
        <span className={classes.title}>{title}</span>
        <span className={classes.availableDate}>Available {dateAvailable}</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: (props) => (props.isActive && !props.subTitle ? 'center' : 'start'),
    margin: (props) => (props.subTitle ? '0 0 .3rem 0' : '.7rem 0'),
    textDecorationWidth: 'none',
    color: (props) => (props.isActive ? theme.palette.signup.purple : 'grey'),
    textDecoration: 'none'
  },
  planDiv: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '.5rem'
  },
  iconContainer: {
    color: 'red',
    padding: '0 .1rem 0 .5rem',
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      fill: (props) => (props.isActive ? theme.palette.signup.purple : 'grey'),
      marginTop: (props) => (props.isActive ? 0 : '-2px')
    }
  },
  title: {
    fontWeight: 'bold',
    fontSize: '.9rem'
  },
  availableDate: {
    fontSize: '.9rem',
    fontWeight: 400,
    paddingTop: '.5rem'
  },
  [theme.breakpoints.down('md')]: {
    container: {
      margin: '.4rem 0'
    }
  }
}))

TooltipPreSeasonReleaseDate.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  dateAvailable: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  subTitleLink: PropTypes.string,
  isActive: PropTypes.bool.isRequired
}

export default TooltipPreSeasonReleaseDate
