import * as yup from 'yup'

const signUpLoginFormValidation = () => {
  return yup.object({
    loginEmail: yup
      .string()
      .email('Insert a valid email')
      .required('Your email is required')
      .test(
        'emailCheck',
        'Invalid email, your email must not include special characters',
        (value) => value !== undefined && isValidEmail(value) && value.length > 1
      ),
    loginPassword: yup.string().required('No password provided.').min(6, 'Your password must be 6 characters minimum')
  })
}

// Emails with '+' are invalid within our ecosystem (Admin FE errors occur):
function isValidEmail(value) {
  if (value.includes('+') && process.env.REACT_APP_ENVIRONMENT === 'production') return false
  return true
}

export default signUpLoginFormValidation
