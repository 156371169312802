import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import TaskContentContainer from '../../TaskContentContainer'
import TaskVideo from '../../TaskVideo'
import { makeStyles } from '@material-ui/core'
import NextTaskLink from '../NextTaskLink'
import navigateToGoSite from '../../../../../helpers/NavigateToGoSite'

const FitnessTestUpperBodyStrength = ({ nextTaskClick, nextTask }) => {
  const classes = useStyles()
  return (
    <TaskContentContainer>
      <TaskContentSubHeader title='Upper Body Strength' />
      <TaskVideo videoId='3088679498001' needsCenterAdjust={true} />
      <p>
        What does this measure?
        <br />
        This is a measurement of your upper body strength and endurance. The test requires you to see how many
        continuous Push Ups you can complete! That means no pausing, no resting and JFDI!!
      </p>
      <p>
        Prep Yourself
        <br />
        You have three options for this test; push ups against a wall, push ups on your knees or push ups on your toes.
        Whatever option you choose, REMEMBER, that perfect form is the most important aspect of this test. Your hands
        should be placed slightly wider than shoulder width apart with elbows pointing out to the sides. Expand your
        chest up between your arms, pull your belly button through your spine to engage your core and lower the
        shoulders away from your ears.
      </p>
      <span>Choose Your Intensity</span>
      <ol className={classes.intensityList}>
        <li>
          For a low intensity option, perform the push ups against a wall. Once you can do 30 wall push ups you should
          move to your knees.
        </li>
        <li>
          For a medium intensity option, perform these from your knees. Once you can confidently complete 33 knee push
          ups, you should move to your toes.
        </li>
        <li>For the most advanced option, do them on your toes!</li>
      </ol>
      <p>
        Make sure you enter your results against the RIGHT test! Click on the highlighted blue test names in the results
        section below to select the intensity option you chose.
      </p>
      <p>
        Ready, Set...Go!
        <br />
        Keeping your abs pulled in, bend the arms with the elbows travelling slightly backwards and lower the upper body
        so that your chest almost touches the ground. A good way to make sure each rep is the same depth is to place a
        drink bottle on it&apos;s side below your chest and make sure you touch this on each rep. Straighten your arms
        and return to start position, that counts as one rep!
      </p>
      <p>
        Remember to breath! Inhale on the way down, exhale as you come up. For demo’s on how to perform these exercises,
        take a look here;
      </p>
      <ol>
        <li>
          <a href={navigateToGoSite('exercises/255-push-ups-against-wall')}>Push Ups (Against Wall)</a>
        </li>
        <li>
          <a href={navigateToGoSite('exercises/100-pushups-on-knees')}>Push Ups (Knees)</a>
        </li>
        <li>
          <a href={navigateToGoSite('exercises/81-tricep-pushups-on-toes')}>Push Ups (Toes)</a>
        </li>
      </ol>
      <NextTaskLink link='Core Strength' onClick={() => nextTaskClick(nextTask)} />
    </TaskContentContainer>
  )
}

const useStyles = makeStyles(() => ({
  intensityList: {
    marginTop: 0
  }
}))

FitnessTestUpperBodyStrength.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default FitnessTestUpperBodyStrength
