import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles, useTheme, Box } from '@material-ui/core'
import WhiteCard from '../../../components/WhiteCard'
import TextInput from '../../../components/fields/TextInput'
import SelectInput from '../../../components/fields/SelectInput'
import WelcomeCheckbox from './WelcomeCheckbox'
import { months, getDays, getYears } from './WelcomeStepOneData'
import WelcomeStepOneImageUpload from './WelcomeStepOneImageUpload'
import HelperText from '../../../components/fields/HelperText'
import { useSelector } from 'react-redux'

const checkboxType = {
  FEMALE: 'Female',
  MALE: 'Male'
}

const WelcomeStepOneProfileFields = ({
  formik,
  onImageUpload,
  submissionAttempt
}) => {
  const { memberSummary } = useSelector((state) => state.memberSummary)
  const [activeCheckbox, setActiveCheckbox] = useState(false)
  const [genderError, setGenderError] = useState(false)
  const [dateError, setDateError] = useState(false)
  const theme = useTheme()
  const classes = useStyles({ genderError })

  useEffect(() => {
    if (memberSummary) {
      formik.setFieldValue('screen_name', memberSummary.screen_name, true)
    }
  }, [memberSummary])

  useEffect(() => {
    if (formik.errors?.gender && submissionAttempt) {
      setGenderError(formik.errors.gender)
    } else {
      setGenderError(null)
    }
  }, [formik.errors?.gender, submissionAttempt])

  useEffect(() => {
    if (formik.errors?.birthDate && submissionAttempt) {
      setDateError(formik.errors.birthDate)
    } else {
      setDateError(null)
    }
  }, [formik.errors?.birthDate, submissionAttempt])

  // Listen to select inputs for date of birth. When valid, change formik field:
  useEffect(() => {
    if (formik.values.day && formik.values.month && formik.values.year) {
      const dateOfBirth = `${formik.values.year}-${formik.values.month}-${formik.values.day}`
      formik.setFieldValue('birthday', dateOfBirth, true)
    }
  }, [formik.values.day, formik.values.month, formik.values.year])

  const checkboxClickHandler = (value) => {
    setActiveCheckbox(value)
    formik.setFieldValue('gender', value, true)
  }

  return (
    <WhiteCard className={classes.container} margin={0} withShadow={true}>
      <Grid container>
        <Grid item xs={12} sm={4} className={classes.imageSection}>
          <WelcomeStepOneImageUpload onImageUpload={onImageUpload} />
          <TextInput
            formik={formik}
            name='screen_name'
            placeholder='Screen Name'
            height={40}
            withDenseMargin={true}
            withCheck={true}
            isTextCentered={true}
          />
          <span className={classes.profileText}>
            This is the name displayed in the 12WBT Member Zone.
          </span>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.genderSection}>
          <Grid container>
            <Grid item>
              <span className={classes.subHeader}>Gender</span>
              <div className={classes.checkboxRow}>
                <WelcomeCheckbox
                  size={25}
                  color={theme.palette.signup.secondary}
                  isChecked={activeCheckbox === checkboxType.FEMALE}
                  label={checkboxType.FEMALE}
                  onClick={checkboxClickHandler}
                  error={genderError}
                  margin='0.5rem 0.5rem 0.5rem 0'
                />
                <WelcomeCheckbox
                  size={25}
                  color={theme.palette.signup.secondary}
                  isChecked={activeCheckbox === checkboxType.MALE}
                  label={checkboxType.MALE}
                  onClick={checkboxClickHandler}
                  error={genderError}
                  margin='0.5rem 0.5rem 0.5rem 0'
                />
              </div>
              <div className={classes.errorContainer}>
                {genderError && <HelperText message={genderError} />}
              </div>
            </Grid>
          </Grid>
          <Box m='2rem' />
          <span className={classes.subHeader}>Birthday</span>
          <div className={classes.birthDateRow}>
            <SelectInput
              name='day'
              formik={formik}
              options={getDays()}
              placeholder='Day'
              withDenseMargin={true}
              showError={false}
            />
            <SelectInput
              name='month'
              formik={formik}
              options={months}
              placeholder='Month'
              withDenseMargin={true}
              showError={false}
            />
            <SelectInput
              name='year'
              formik={formik}
              options={getYears()}
              placeholder='Year'
              withDenseMargin={true}
              showError={false}
            />
          </div>
          {dateError && <HelperText message={dateError} />}
        </Grid>
      </Grid>
    </WhiteCard>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem'
  },
  imageSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 2rem'
  },
  profileText: {
    margin: '.5rem auto',
    maxWidth: '80%',
    textAlign: 'center',
    color: 'grey',
    fontSize: '.7rem'
  },
  genderTitle: {
    fontSize: '1.1rem',
    color: 'grey',
    fontWeight: 'bold'
  },
  checkboxRow: {
    display: 'flex',
    '& > :nth-child(2)': {
      marginLeft: '1rem'
    }
  },
  birthDateRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '16rem'
  },
  errorContainer: {
    margin: '.5rem 0 1rem 0'
  },
  genderSection: {
    marginTop: '1rem'
  },
  [theme.breakpoints.down('lg')]: {
    profileText: {
      maxWidth: '95%'
    }
  },
  [theme.breakpoints.down('md')]: {
    imageSection: {
      padding: '1rem'
    },
    profileText: {
      maxWidth: '100%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    imageSection: {
      marginTop: '-5rem',
      alignItems: 'left',
      minWidth: '100%'
    },
    birthDateRow: {
      flexDirection: 'column',
      width: '100%',
      minWidth: '100%',
      '& .MuiOutlinedInput-root': {
        minWidth: '100%',
        width: '100%'
      }
    }
  }
}))

WelcomeStepOneProfileFields.propTypes = {
  formik: PropTypes.object.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  submissionAttempt: PropTypes.bool.isRequired
}

export default WelcomeStepOneProfileFields
