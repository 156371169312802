import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import TaskContentContainer from '../../TaskContentContainer'
import NextTaskLink from '../NextTaskLink'

const FitnessTestWarmUp = ({ nextTaskClick, nextTask }) => {
  return (
    <TaskContentContainer>
      <TaskContentSubHeader title='Warm Up' />
      <p>
        Walk or Jog
        <br />
        Do a 10 minute walk or jog and perform the following stretches, holding each for 30 seconds.
      </p>
      <p>
        Hamstring Stretch
        <br />
        Keeping one leg bent, gather the other one towards you and clasping your hands behind your knee lift it up and
        point it to the ceiling. Keep it slightly bent and flex your foot and you should feel the stretch in the
        &quot;belly&quot; of your hamstring. You can use a towel, wrap it around your leg or shoe and gently pull your
        leg up towards you. Increase the stretch effect by straightening the base leg out until you feel the change.
        Repeat on the opposite side.
      </p>
      <p>
        Quadriceps Stretch
        <br />
        Stand up, and curl your leg up behind you and grab it with one hand, keep your knees together. Gently pull your
        foot into your butt. Stand tall, draw your abs in and pull your shoulders back and down, elevating your chest
        Think about tucking your tailbone under to get more of a stretch. If your balance is good, take both hands
        behind you and hold your foot, and fully expand your chest and pull your shoulders back. Repeat on the opposite
        side.
      </p>
      <p>
        Calf Stretch
        <br />
        Stand with one foot behind you with a straight leg, and bend into the front leg. Keep the back leg straight to
        feel the stretch in the calf muscle. After holding for 30 seconds, keep the stretch going but bring the leg in a
        little closer and bend the knee of the calf that&apos;s stretching to take the stretch further down into the
        Achilles tendon. Repeat on the opposite side.
      </p>
      <p>
        Chest Stretch
        <br />
        While standing, clasp hands together behind your back and lift your chest (be proud of what you&apos;ve got!).
        Stand tall, drop your shoulders down and exhale into the stretch. If you can get your arms straight, try raising
        them a little higher up (towards the sky) without letting your shoulders collapse forward.
      </p>
      <p>
        Another option is to place the palm of your hand on to a wall or pole, and gently turn yourself away opening up
        one side of your chest. Stand tall, drop your shoulders down and exhale into the stretch. Repeat on the opposite
        side.
      </p>
      <p>
        Shoulder Stretch
        <br />
        Take one arm across the body at shoulder height and hold it in place above the elbow using the other arm. Gently
        pull it in towards you. Try to &apos;drop&apos; your shoulder down and lengthen neck and spine. Repeat on the
        opposite side.
      </p>
      <p>
        Tricep Stretch
        <br />
        Take one arm up and over behind your head. Using the other arm, hold the elbow and gently assist the stretch by
        pushing down. Keep chest lifted and repeat on the opposite side.
      </p>
      <NextTaskLink link='Upper Body Strength' onClick={() => nextTaskClick(nextTask)} />
    </TaskContentContainer>
  )
}

FitnessTestWarmUp.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default FitnessTestWarmUp
