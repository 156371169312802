import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { makeStyles } from '@material-ui/core'
import HelperText from './HelperText'

const MobileInput = ({ formik, phoneValue, name }) => {
  const [phoneInputFocussed, setPhoneInputFocussed] = useState(false)
  const [error, setError] = useState(false)
  const classes = useStyles({ phoneInputFocussed, error })

  useEffect(() => {
    if (formik.errors[name] && formik.touched[name]) {
      setError(formik.errors[name])
    } else setError(undefined)
  }, [formik.errors[name], formik.touched[name]])

  return (
    <>
      <PhoneInput
        country={'au'}
        value={phoneValue}
        onChange={(e) => formik.setFieldValue(name, e)}
        containerClass={classes.phoneInput}
        inputClass={classes.inputClass}
        data-testid='phone'
        inputProps={{
          name: 'phone'
        }}
        onBlur={() => {
          setPhoneInputFocussed(false)
        }}
        onFocus={() => {
          setPhoneInputFocussed(true)
        }}
      />
      {error && <HelperText message={error} />}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    margin: '0.5rem 0',
    '& .form-control': {
      backgroundColor: 'transparent',
      fontSize: '1.15rem!important',
      width: '100%!important',
      borderColor: (props) => (props.error ? 'red' : 'rgba(0, 0, 0, 0.23'),
      '&:focus': {
        border: `1.3px solid ${theme.palette.primary.main}`
      }
    },
    '& .special-label': {
      display: 'none!important'
    }
  },
  inputClass: {
    height: 40
  }
}))

MobileInput.propTypes = {
  phoneValue: PropTypes.string,
  name: PropTypes.string,
  formik: PropTypes.object
}

export default MobileInput
