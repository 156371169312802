import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import TextInput from '../fields/TextInput'
import useEffectWithTimeout from '../../../hooks/UseEffectWithTimeout'
import { useDispatch, useSelector } from 'react-redux'
import {
  checkMember,
  updateLasso
} from '../../../../redux/actions/Signup.actions'
import SignUpLoginModal from '../login_modal/SignUpLoginFormModal'
import Modal from '../../../components/Modal'

const notNullObject = (value) => {
  if (value) return true
  return false
}

const notNull = (value) => {
  if (value.length > 1) return true
  return false
}

const CreateAccountFields = ({ formik, setReturnMemberId }) => {
  const [showModal, setShowModal] = useState(false)
  const { memberLoggedIn } = useSelector((state) => state.signupLogin)
  const { isDiscounted } = useSelector((state) => state.signupPrograms)
  const { member } = useSelector((state) => state.checkMember)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [activeField, setActiveField] = useState()

  // on email input blur, if memberCheck returns true (member already exists), show modal to redirect to login:
  useEffect(() => {
    // If current member, show login modal and redirect to dashboard on success:
    if (member?.currentMember || member?.alreadyMember) {
      setShowModal(true)
    }
  }, [member])

  useEffect(() => {
    if (formik.errors.email === undefined) {
      if (formik.values.email.length > 1) {
        dispatch(checkMember(formik.values.email))
      }
    }
  }, [])

  // Condition to check if all member info is filled in & valid:
  const memberInfoCorrect =
    activeField === '' &&
    notNull(formik.values.email) &&
    notNull(formik.values.firstName) &&
    notNull(formik.values.lastName) &&
    notNull(formik.values.password)

  // Lasso endpoint calls on stale inputs (no relevant input has been focussed for > 1000 seconds):
  useEffectWithTimeout({
    timeout: 1000,
    dependencies: [activeField !== ''],
    condition: memberInfoCorrect,
    successMethod: () => {
      // Lasso to save member details:
      dispatch(
        updateLasso({
          email: formik.values.email,
          firstName: formik.values.firstName,
          lastName: formik.values.lastName
        })
      )
    }
  })

  // Keep track of currently focussed field to avoid unnecessary endpoint calls:
  const updateFieldsFocussedHandler = (name, value) => {
    if (value) setActiveField(name)
    else setActiveField('')
  }

  const emailBlurHandler = async () => {
    // Call endpoint to check if already a member if email is valid:
    formik.validateField('email').then(() => {
      if (formik.errors.email === undefined) {
        if (formik.values.email.length > 1) {
          dispatch(checkMember(formik.values.email))
        }
      }
    })
  }

  const resetCreateAccountFields = () => {
    const savedFormikValues = { ...formik.values }
    // Save product handle:
    const productHandle = savedFormikValues.productHandle
    const paymentPlan = savedFormikValues.paymentPlan
    const billingOption = savedFormikValues.billingOption
    const couponCode = savedFormikValues?.couponCode

    // Reset form:
    formik.resetForm()

    // Reapply product handle:
    handleChange('productHandle', productHandle)
    handleChange('paymentPlan', paymentPlan)
    handleChange('billingOption', billingOption)
    // Reapply coupon code if coupon can be applied to selected program:
    if (couponCode && isDiscounted) {
      handleChange('couponCode', couponCode)
    } else {
      handleChange('couponCode', null)
    }
  }

  const handleChange = async (name, value) => {
    await formik.setFieldValue(name, value)
    formik.setFieldTouched(name, true)
  }

  const closeModalHandler = () => {
    setShowModal(false)
  }

  // const chromeEmailAutoCompleteHandler = (initialEmailValue) => {
  //   if (formik.errors.email === undefined && initialEmailValue.length > 3) {
  //     dispatch(checkMember(initialEmailValue))
  //   }
  // }

  return (
    <>
      <div className={classes.fieldsContainer}>
        <TextInput
          name='email'
          label='Email'
          formik={formik}
          isDisabled={notNullObject(memberLoggedIn)}
          updateFocus={updateFieldsFocussedHandler}
          onBlurHandler={emailBlurHandler}
          currentFocus={activeField}
          // autoCompleteHandler={chromeEmailAutoCompleteHandler}
        />
        <TextInput
          name='firstName'
          label='First name'
          formik={formik}
          isDisabled={notNullObject(memberLoggedIn)}
          updateFocus={updateFieldsFocussedHandler}
          currentFocus={activeField}
        />
        <TextInput
          name='lastName'
          label='Last Name'
          formik={formik}
          isDisabled={notNullObject(memberLoggedIn)}
          updateFocus={updateFieldsFocussedHandler}
          currentFocus={activeField}
        />
        <TextInput
          name='password'
          label='Password'
          formik={formik}
          isDisabled={notNullObject(memberLoggedIn)}
          updateFocus={updateFieldsFocussedHandler}
          currentFocus={activeField}
          type='password'
        />
        <TextInput
          name='passwordReType'
          label='Confirm Password'
          formik={formik}
          isDisabled={notNullObject(memberLoggedIn)}
          updateFocus={updateFieldsFocussedHandler}
          currentFocus={activeField}
          type='password'
        />
      </div>
      <Modal
        show={showModal}
        onCancel={() => {
          resetCreateAccountFields()
          setShowModal(false)
        }}
      >
        <SignUpLoginModal
          isCurrentMember={member?.currentMember}
          closeModal={closeModalHandler}
          formik={formik}
          setReturnMemberId={setReturnMemberId}
        />
      </Modal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {}
}))

CreateAccountFields.propTypes = {
  formik: PropTypes.object,
  setReturnMemberId: PropTypes.func.isRequired
}

export default CreateAccountFields
