import React from 'react'
import PropTypes from 'prop-types'
import { Radio, makeStyles } from '@material-ui/core'

const FitnessTestRadio = ({ test, activeTest, setActive }) => {
  const isChecked = test.label === activeTest?.label
  const classes = useStyles({ isChecked })
  return (
    <label className={classes.container} onClick={() => setActive(test)}>
      <Radio checked={isChecked} classes={{ root: classes.radio }} color='inherit' />
      <span className={classes.bold}>{test.difficulty}</span>
      <span className={classes.light}>- {test.label}</span>
    </label>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  bold: {
    fontWeight: 800
  },
  light: {
    paddingLeft: '.5rem'
  },
  radio: {
    color: (props) => (props.isChecked ? theme.palette.signup.purple : theme.palette.primary.light)
  }
}))

FitnessTestRadio.propTypes = {
  test: PropTypes.object.isRequired,
  setActive: PropTypes.func.isRequired,
  activeTest: PropTypes.object.isRequired
}

export default FitnessTestRadio
