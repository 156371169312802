import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const WelcomeCheckbox = ({
  color,
  size,
  margin,
  isChecked,
  label,
  onClick,
  error,
  description,
  titleUppercase,
  recommended
}) => {
  const quarterSize = size * 0.25
  const classes = useStyles({
    color,
    size,
    margin,
    quarterSize,
    error,
    description
  })
  const clickHandler = () => {
    onClick(label)
  }
  const descriptionRef = useRef(null)

  // By setting innerHTML the html elements are rendered as html, not a string:
  useEffect(() => {
    if (description) descriptionRef.current.innerHTML = description
  }, [description, descriptionRef])

  const checkboxTitle = () => {
    if (recommended) {
      return (
        <div className={classes.titleRow}>
          <span className={classes.title}>
            {titleUppercase ? label.toUpperCase() : label}
          </span>
          <span className={classes.recommended}>
            <em>Recommended</em>
          </span>
        </div>
      )
    }
    return (
      <span className={classes.title}>
        {titleUppercase ? label.toUpperCase() : label}
      </span>
    )
  }

  return (
    <label className={classes.checkboxContainer} onClick={clickHandler}>
      <div className={classes.checkbox} tabIndex='0'>
        {isChecked && <CheckCircleIcon />}
      </div>
      {description ? (
        <div className={classes.descriptionContainer}>
          {checkboxTitle()}
          <span className={classes.description} ref={descriptionRef}></span>
        </div>
      ) : (
        label
      )}
    </label>
  )
}

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  checkbox: {
    borderRadius: '50%',
    width: (props) => props.size,
    height: (props) => props.size,
    minWidth: (props) => props.size,
    borderStyle: 'dotted',
    borderColor: (props) => (props.error ? 'red' : props.color),
    '&:hover': {
      cursor: 'pointer'
    },
    borderWidth: '1px',
    '& svg': {
      width: (props) => props.size + 2 * props.quarterSize,
      height: (props) => props.size + 2 * props.quarterSize,
      marginLeft: (props) => `-${props.quarterSize}px`,
      marginTop: (props) => `-${props.quarterSize}px`,
      color: (props) => props.color
    },
    margin: (props) => props.margin,
    '&:focus-visible': {
      outline: 'none'
    }
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.greys.dark
  },
  title: {
    color: theme.palette.greys.dark,
    fontWeight: 'bold',
    fontSize: '1.1rem'
  },
  titleRow: {
    display: 'flex'
  },
  description: {
    margin: '.3rem auto',
    fontSize: '.9rem'
  },
  recommended: {
    fontWeight: 'bold',
    color: theme.palette.signup.secondary,
    marginLeft: '1rem'
  },
  [theme.breakpoints.down('xs')]: {
    titleRow: {
      flexDirection: 'column'
    },
    title: {
      fontSize: '1.05rem'
    },
    recommended: {
      margin: '.5rem auto',
      marginLeft: '0'
    }
  }
}))

WelcomeCheckbox.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.string,
  recommended: PropTypes.bool,
  titleUppercase: PropTypes.bool
}

WelcomeCheckbox.defaultProps = {
  margin: '.5rem'
}

export default WelcomeCheckbox
