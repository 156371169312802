import { combineReducers } from 'redux'
import { MEMBER_LOGOUT_REQUEST } from '../constants/Member.constants'
import alert from './Alert.reducers'
import { userReducer } from './User.reducers'
import {
  checkMemberReducer,
  couponReducer,
  roundReducer,
  signUpReducer,
  programsReducer,
  securityTokenReducer,
  lassoReducer,
  signUpLoginReducer
} from './Signup.reducers'
import { stepTwoReducer } from './WelcomeSteps.reducers'
import {
  memberReducer,
  preSeasonReducer,
  profileReducer,
  measurementsReducer,
  currentSubscriptionReducer,
  memberSummaryReducer,
  authRouteReducer,
  getAllSubscriptionsReducer
} from './Member.reducers'
import {
  fitnessTestReducer,
  newPreSeasonActivityReducer,
  newPreSeasonReducer
} from './PreSeason.reducers'

const appReducer = combineReducers({
  alert,
  user: userReducer,
  authRouteAttempt: authRouteReducer,
  currentSubscription: currentSubscriptionReducer,
  signup: signUpReducer,
  checkMember: checkMemberReducer,
  lasso: lassoReducer,
  member: memberReducer,
  memberSummary: memberSummaryReducer,
  measurements: measurementsReducer,
  preseason: preSeasonReducer,
  newPreSeason: newPreSeasonReducer,
  newPreSeasonActivity: newPreSeasonActivityReducer,
  profile: profileReducer,
  round: roundReducer,
  signupPrograms: programsReducer,
  signupSecurityToken: securityTokenReducer,
  coupon: couponReducer,
  signupLogin: signUpLoginReducer,
  subscriptions: getAllSubscriptionsReducer,
  welcomeStepTwo: stepTwoReducer,
  fitnessTest: fitnessTestReducer
})

// Reducers return the initial state when they are called with undefined as first argument:
// When RESET_STORE is called, all reducers are initialized anew:
const rootReducer = (state, action) => {
  if (action.type === MEMBER_LOGOUT_REQUEST) {
    state = undefined
    localStorage.removeItem('token')
    localStorage.removeItem('auth_token')
  }
  return appReducer(state, action)
}

export default rootReducer
