import React from 'react'
import { makeStyles } from '@material-ui/core'
import navigateToGoSite from '../../../../helpers/NavigateToGoSite'

const ClearOutTable = () => {
  const classes = useStyles()
  return (
    <table className={classes.table} cellSpacing={0}>
      <tr>
        <td className={classes.tableData}>
          <span>
            <b>
              Clear out: Sugary cereals!
              <br />
            </b>
          </span>
          <span>
            Breakfast cereals should have a maximum of 10g of sugar per 100g unless it has added dried fruit, then it
            will be more.
          </span>
        </td>
        <td className={classes.tableData}>
          <span>
            <b>Opt instead for:&nbsp;</b>
          </span>
          <span>oats / porridge, untoasted muesli, bran based cereals or wheat biscuits.</span>
        </td>
      </tr>
      <tr>
        <td className={classes.tableData}>
          <span>
            <b>Clear out: Processed packaged foods!</b>
            <br />
          </span>
          <span>
            Particularly snacks: eg biscuits, chips, muesli bars, chocolate, lollies, cakes, white bread, etc.
          </span>
        </td>
        <td className={classes.tableData}>
          <span>
            <b>Opt instead for:</b>&nbsp; Yogurt, fruit, cut up vegetables with hummus or home-made snacks made from
            whole ingredients.
          </span>
          <br />
          <br />
          <span>
            Check out our snack FAQ for ideas <a href={navigateToGoSite('help/448-snacks')}>here</a>&nbsp; OR our snack
            recipes <a href={navigateToGoSite('recipes?filters=4')}>here</a>.
          </span>
        </td>
      </tr>
      <tr>
        <td className={classes.tableData}>
          <span>
            <b>Clear out:</b> Soft drinks, cordials and sugary juices.
          </span>
        </td>
        <td className={classes.tableData}>
          <span>
            <b>Opt instead for:</b> Water, sparkling water, infused water, herbal tea.
          </span>
        </td>
      </tr>
    </table>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    border: '1px solid black'
  },
  tableData: {
    border: '1px solid black',
    padding: '1rem',
    verticalAlign: 'top'
  },
  [theme.breakpoints.down('xs')]: {
    tableData: {
      padding: '.5rem'
    }
  }
}))

export default ClearOutTable
