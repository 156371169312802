import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextField } from '@material-ui/core'

const MeasureUpInput = ({ formik, name, unitOfMeasurement, width }) => {
  const classes = useStyles({ width })
  return (
    <div className={classes.textInput}>
      <TextField
        fullWidth
        InputProps={{ className: classes.textField }}
        id={name}
        name={name}
        variant='outlined'
        type='number'
        value={formik.values[name]}
        onChange={formik.handleChange}
      />
      <div className={classes.lock}>{unitOfMeasurement}</div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  textInput: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1rem 0',
    color: theme.palette.greys.dark,
    width: (props) => (props.width ? props.width : '100%')
  },
  lock: {
    width: '2.6rem',
    height: '2.2rem',
    backgroundColor: theme.palette.grey[300],
    marginLeft: '-3px',
    zIndex: 10,
    ...theme.CSS.center,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    '& svg': {
      color: 'white',
      width: '1.5rem',
      height: '1.5rem'
    },
    padding: '0 .1rem',
    fontSize: '.8rem'
  },
  textField: {
    height: '2.2rem',
    '& input': {
      padding: '.2rem',
      paddingLeft: '.3rem',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    textInput: {
      margin: '.2rem 0'
    }
  }
}))

MeasureUpInput.defaultProps = {
  unitOfMeasurement: 'cm'
}

MeasureUpInput.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  unitOfMeasurement: PropTypes.string.isRequired,
  width: PropTypes.string
}

export default MeasureUpInput
