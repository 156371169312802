import React from 'react'

import { Redirect, useLocation } from 'react-router-dom'
import {
  GEAR_UP,
  GET_TO_KNOW_SUPPORT,
  guides,
  HOW_12WBT_WORKS,
  NAVIGATE_NUTRITION,
  NAVIGATE_FITNESS,
  NUTRITION_TRUTHS,
  WEEKLY_PHOTO,
  TECH_TIPS,
  NUTRITION_TRUTHS_QUIZ
} from '../app/modules/pre_season/guides/GuideData'
import PageContainer from '../app/components/PageContainer'
import GuideHeader from '../app/modules/pre_season/guides/GuideHeader'

import GuideNavigateNutrition from '../app/modules/pre_season/guides/GuideNavigateNutrition'
import GuideGearUp from '../app/modules/pre_season/guides/GuideGearUp'
import GuideNutritionTruths from '../app/modules/pre_season/guides/GuideNutritionTruths'
import GuideHow12WBTWorks from '../app/modules/pre_season/guides/GuideHow12WBTWorks'
import GuideSupportCrew from '../app/modules/pre_season/guides/GuideSupportCrew'
import GuideWeeklyPhoto from '../app/modules/pre_season/guides/GuideWeeklyPhoto'
import GuideNavigateFitness from '../app/modules/pre_season/guides/GuideNavigateFitness'
import GuideTechTips from '../app/modules/pre_season/guides/GuideTechTips'
import GuideNutritionTruthsQuiz from '../app/modules/pre_season/guides/nutrition_truths_quiz/GuideNutritionTruthsQuiz'

const PreSeasonGuide = () => {
  const location = useLocation()
  const slugArray = location.pathname.split('/')
  const slug = slugArray[slugArray?.length - 1]
  const guide = guides[slug]

  const GuideContent = () => {
    switch (slug) {
      case NAVIGATE_NUTRITION: {
        return <GuideNavigateNutrition />
      }
      case NAVIGATE_FITNESS: {
        return <GuideNavigateFitness />
      }
      case TECH_TIPS: {
        return <GuideTechTips />
      }
      case GEAR_UP: {
        return <GuideGearUp />
      }
      case NUTRITION_TRUTHS: {
        return <GuideNutritionTruths />
      }
      case NUTRITION_TRUTHS_QUIZ: {
        return <GuideNutritionTruthsQuiz />
      }
      case HOW_12WBT_WORKS: {
        return <GuideHow12WBTWorks />
      }
      case GET_TO_KNOW_SUPPORT: {
        return <GuideSupportCrew />
      }
      case WEEKLY_PHOTO: {
        return <GuideWeeklyPhoto />
      }
      default:
        return null
    }
  }

  // Validate slug & redirect if invalid:
  if (!guide) return <Redirect to='/pre-season' />

  return (
    <PageContainer>
      <GuideHeader title={guide?.header} description={guide?.intro} />
      {GuideContent()}
    </PageContainer>
  )
}

export default PreSeasonGuide
