import q1 from '../../../../assets/img/pre_season/guides/quiz/Q1.jpg'
import q2 from '../../../../assets/img/pre_season/guides/quiz/Q2.jpg'
import q3 from '../../../../assets/img/pre_season/guides/quiz/Q3.jpg'
import q4 from '../../../../assets/img/pre_season/guides/quiz/Q4.jpg'
import airPoppedPopcorn from '../../../../assets/img/pre_season/guides/quiz/airPoppedPopcorn.jpg'
import almonds from '../../../../assets/img/pre_season/guides/quiz/almonds.jpg'
import anzacBiscuits from '../../../../assets/img/pre_season/guides/quiz/anzacBiscuits.jpg'
import appleJuice from '../../../../assets/img/pre_season/guides/quiz/appleJuice.jpg'
import bananaAndStrawberries from '../../../../assets/img/pre_season/guides/quiz/bananaAndStrawberries.jpg'
import cola from '../../../../assets/img/pre_season/guides/quiz/cola.jpeg'
import chocChipBiscuits from '../../../../assets/img/pre_season/guides/quiz/chocChipBiscuits.jpg'
import chocolateMudMuffin from '../../../../assets/img/pre_season/guides/quiz/chocolateMudMuffin.jpeg'
import darkChocolate from '../../../../assets/img/pre_season/guides/quiz/darkChocolate.jpg'
import driedFruit from '../../../../assets/img/pre_season/guides/quiz/driedFruit.jpg'
import fruitFilledBiscuits from '../../../../assets/img/pre_season/guides/quiz/fruitFilledBiscuits.jpg'
import iceCream from '../../../../assets/img/pre_season/guides/quiz/iceCream.jpg'
import lowFatNaturalYoghurt from '../../../../assets/img/pre_season/guides/quiz/lowFatNaturalYoghurt.jpg'
import milkChocolate from '../../../../assets/img/pre_season/guides/quiz/milkChocolate.jpg'
import milkshake from '../../../../assets/img/pre_season/guides/quiz/milkshake.jpg'
import muesliBar from '../../../../assets/img/pre_season/guides/quiz/muesliBar.jpg'
import orangeJuice from '../../../../assets/img/pre_season/guides/quiz/orangeJuice.jpg'
import partyLollies from '../../../../assets/img/pre_season/guides/quiz/partyMixLollies.jpeg'
import potatoChips from '../../../../assets/img/pre_season/guides/quiz/potatoChips.jpg'
import raspberryMuffin from '../../../../assets/img/pre_season/guides/quiz/raspberryMuffin.jpg'
import riceCrackers from '../../../../assets/img/pre_season/guides/quiz/riceCrackers.jpg'
import savouryBiscuits from '../../../../assets/img/pre_season/guides/quiz/savouryBiscuits.jpeg'
import shortbreadBiscuits from '../../../../assets/img/pre_season/guides/quiz/shortbreadBiscuits.jpeg'
import skimMilkChocolatePowder from '../../../../assets/img/pre_season/guides/quiz/skimMilkChocolatePowder.jpg'
import sorbet from '../../../../assets/img/pre_season/guides/quiz/sorbet.jpg'
import sweetBiscuits from '../../../../assets/img/pre_season/guides/quiz/sweetBiscuits.jpg'
import waterCrackers from '../../../../assets/img/pre_season/guides/quiz/waterCrackers.jpg'
import watermelonRoseWaterSalad from '../../../../assets/img/pre_season/guides/quiz/watermelonRoseWaterSalad.jpg'
import waterWithLemon from '../../../../assets/img/pre_season/guides/quiz/waterWithLemon.jpg'

export const QUESTION_TYPE = {
  RADIO: 'RADIO',
  IMAGE_PICKER: 'IMAGE_PICKER'
}

const ALMONDS = {
  image: almonds,
  title: 'Almonds',
  serving: '25g',
  calories: 149,
  benefits: 'A good source of healthy fats and fibre'
}

const AIR_POPPED_POPCORN = {
  image: airPoppedPopcorn,
  title: 'Air-popped popcorn',
  serving: '20g',
  calories: 68,
  benefits: 'A good source of fibre.'
}

const LOW_FAT_YOGHURT = {
  image: lowFatNaturalYoghurt,
  title: 'Low-fat natural yoghurt',
  serving: '200g',
  calories: 100,
  benefits: 'A great source of calcium.'
}

const COLA = {
  image: cola,
  title: 'Cola',
  serving: '600ml',
  calories: 258
}

const CHOCOLATE_MUD_MUFFIN = {
  image: chocolateMudMuffin,
  title: 'Chocolate mud muffin',
  serving: '1 muffin',
  calories: 700
}

const SORBET = {
  image: sorbet,
  title: 'Sorbet',
  serving: '3 scoops',
  calories: 186
}

const MILKSHAKE = {
  image: milkshake,
  title: 'Milkshake',
  serving: '360ml',
  calories: 238
}

const ICECREAM = {
  image: iceCream,
  title: 'Ice cream',
  serving: '3 scoops',
  calories: 300
}

const BANANA_AND_STRAWBERRIES = {
  image: bananaAndStrawberries,
  title: 'Banana and strawberries',
  serving: '1 banana and 250g strawberries',
  calories: 156,
  benefits: 'High in fibre and rich in vitamins and minerals.'
}

const MILK_CHOCOLATE = {
  image: milkChocolate,
  title: 'Milk chocolate',
  serving: '4 rows (16 small squares)',
  calories: 530
}

const APPLE_JUICE = {
  image: appleJuice,
  title: 'Apple juice',
  serving: '1 cup',
  calories: 115
}

const ANZAC_BISCUITS = {
  image: anzacBiscuits,
  title: 'Anzac biscuits',
  serving: '2 biscuits',
  calories: 236
}

const DARK_CHOCOLATE = {
  image: darkChocolate,
  title: 'Dark chocolate',
  serving: '1 row (4 small squares)',
  calories: 127
}

const DRIED_FRUIT = {
  image: driedFruit,
  title: 'Dried fruit mix',
  serving: '50g',
  calories: 168
}

const MUESLI_BAR = {
  image: muesliBar,
  title: 'Muesli bar',
  serving: '40g',
  calories: 250
}

const CHOC_CHIP_BISCUIT = {
  image: chocChipBiscuits,
  title: 'Choc chip biscuits',
  serving: '4 biscuits',
  calories: 232
}

const FRUIT_FILLED_BISCUITS = {
  image: fruitFilledBiscuits,
  title: 'Fruit-filled biscuits',
  serving: '3 biscuits',
  calories: 193
}

const RASPBERRY_MUFFIN = {
  image: raspberryMuffin,
  title: '12WBT Raspberry muffin',
  serving: '163g',
  calories: 130,
  benefits: 'A small treat without the guilt.'
}

const RICE_CRACKERS = {
  image: riceCrackers,
  title: 'Rice crackers',
  serving: '20 crackers (34g)',
  calories: 135
}

const SKIM_MILK_CHOCOLATE_POWDER = {
  image: skimMilkChocolatePowder,
  title: 'Skim milk with chocolate powder',
  serving: '200ml',
  calories: 110,
  benefits: 'A great source of calcium.'
}

const ORANGE_JUICE = {
  image: orangeJuice,
  title: 'Orange juice',
  serving: '1 cup',
  calories: 100
}

const PARTY_MIX_LOLLIES = {
  image: partyLollies,
  title: 'Party mix lollies',
  serving: '70g',
  calories: 251
}

const POTATO_CHIPS = {
  image: potatoChips,
  title: 'Potato chips',
  serving: '100g',
  calories: 522
}

const PLAIN_SWEET_BISCUITS = {
  image: sweetBiscuits,
  title: 'Plain sweet biscuits',
  serving: '5 biscuits',
  calories: 218
}

const SAVOURY_BISCUITS = {
  image: savouryBiscuits,
  title: 'Savoury biscuits',
  serving: '15 biscuits',
  calories: 293
}

const SHORT_BREAD_BISCUITS = {
  image: shortbreadBiscuits,
  title: 'Shortbread cream biscuits',
  serving: '5 biscuits',
  calories: 422
}

const WATER_CRACKERS = {
  image: waterCrackers,
  title: 'Water crackers',
  serving: '20 crackers (60g)',
  calories: 244
}

const WATER_WITH_LEMON = {
  image: waterWithLemon,
  title: 'Water with lemon or lime',
  serving: '1 cup',
  calories: 0,
  benefits: 'The best source of hydration for your body.'
}

const WATERMELON_ROSE_WATER_SALAD = {
  image: watermelonRoseWaterSalad,
  title: 'Watermelon, strawberry & rosewater salad',
  serving: '230g',
  calories: 102,
  benefits: 'A good source of fibre and calcium.'
}

export const quizData = {
  1: {
    question: 'A portion is the total amount of food eaten in one sitting. A serve is a measured amount of food.',
    questionAnswer: true,
    questionType: QUESTION_TYPE.RADIO,
    answerText:
      'Portion sizes can be as small or big as you want! The key is to try and eat healthy portion sizes. Servings sizes, on the other hand, specify an exact weight of food.',
    image: q1
  },
  2: {
    question:
      'Glycemic Index (GI) is a measure of how quickly protein is broken down and absorbed into the bloodstream.',
    questionAnswer: false,
    questionType: QUESTION_TYPE.RADIO,
    answerText:
      'GI is a measure of how quickly carbohydrates are broken down and absorbed in the bloodstream. The lower the GI of a food the slower it is broken down, giving you energy for longer.',
    image: q2
  },
  3: {
    question: 'You should aim to have two serves of fruit everyday. One serve of fruit is the size of:',
    answerArray: ['Marble', 'Ping-Pong ball', 'Tennis ball', 'Basketball'],
    questionAnswer: 'Tennis ball',
    questionType: QUESTION_TYPE.RADIO,
    answerText: 'A serve of fruit is the size of a tennis ball.',
    image: q3
  },
  4: {
    question: 'Your plate should be made up of 1/4 protein, 1/4 carbohydrates and 1/2 vegetables.',
    questionAnswer: true,
    questionType: QUESTION_TYPE.RADIO,
    answerText:
      'Serving your portions this way ensures you are getting the right amounts of carbohydrates, protein and vegetables in each meal.',
    image: q4
  },
  5: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    unhealthySnack: ICECREAM,
    questionAnswer: LOW_FAT_YOGHURT,
    answerArray: [LOW_FAT_YOGHURT, SORBET, MILKSHAKE]
  },
  6: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    unhealthySnack: MILK_CHOCOLATE,
    questionAnswer: SKIM_MILK_CHOCOLATE_POWDER,
    answerArray: [DARK_CHOCOLATE, CHOC_CHIP_BISCUIT, SKIM_MILK_CHOCOLATE_POWDER]
  },
  7: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    unhealthySnack: SHORT_BREAD_BISCUITS,
    questionAnswer: BANANA_AND_STRAWBERRIES,
    answerArray: [BANANA_AND_STRAWBERRIES, ANZAC_BISCUITS, MUESLI_BAR]
  },
  8: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    questionAnswer: WATER_WITH_LEMON,
    unhealthySnack: COLA,
    answerArray: [WATER_WITH_LEMON, APPLE_JUICE, ORANGE_JUICE]
  },
  9: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    questionAnswer: WATERMELON_ROSE_WATER_SALAD,
    unhealthySnack: PARTY_MIX_LOLLIES,
    answerArray: [FRUIT_FILLED_BISCUITS, WATERMELON_ROSE_WATER_SALAD, DRIED_FRUIT]
  },
  10: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    questionAnswer: AIR_POPPED_POPCORN,
    unhealthySnack: POTATO_CHIPS,
    answerArray: [RICE_CRACKERS, WATER_CRACKERS, AIR_POPPED_POPCORN]
  },
  11: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    questionAnswer: ALMONDS,
    unhealthySnack: SAVOURY_BISCUITS,
    answerArray: [ALMONDS, POTATO_CHIPS, RICE_CRACKERS]
  },
  12: {
    questionType: QUESTION_TYPE.IMAGE_PICKER,
    questionAnswer: RASPBERRY_MUFFIN,
    unhealthySnack: CHOCOLATE_MUD_MUFFIN,
    answerArray: [PLAIN_SWEET_BISCUITS, MUESLI_BAR, RASPBERRY_MUFFIN]
  }
}
