import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import UseWindowDimensions from '../../hooks/UseWindowDimensions'

const HeaderTooltip = ({ withRadius, targetEl, children, withArrowOffset }) => {
  const tooltipRef = useRef(null)
  const [tooltipBodyLeft, setTooltipBodyLeft] = useState(null)
  const [arrowOffsetLeft, setArrowOffsetLeft] = useState(null)
  const [tooltipPosX, setTooltipPosX] = useState(
    targetEl?.current?.getBoundingClientRect().x + targetEl?.current?.getBoundingClientRect().width
  )
  const [tooltipRefPos, setTooltipRefPos] = useState(tooltipRef?.current?.getBoundingClientRect())
  const { width } = UseWindowDimensions()
  HeaderTooltip.displayName = 'HeaderTooltip'

  useEffect(() => {
    const rect = targetEl?.current.getBoundingClientRect()
    setTooltipPosX(rect.x + rect.width)
    if (withArrowOffset) setArrowOffsetLeft(rect.width / 2)
  }, [targetEl, window.innerWidth, tooltipRefPos])

  // On window resize && tooltipRef set, get dimensions of tooltip body:
  useEffect(() => {
    setTooltipRefPos(tooltipRef?.current?.getBoundingClientRect())
  }, [window.innerWidth])

  // On receiving tool tip body dimensions, adjust tooltip to be in center of screen if overlapping edge of screen:
  useEffect(() => {
    let tooltipBodyLeftTemp = 0
    // Close to right side of window:
    if (tooltipPosX + tooltipRefPos?.width > width - 50) {
      setTooltipBodyLeft(0)
      const diff = tooltipPosX + tooltipRefPos.width
      tooltipBodyLeftTemp = -Math.abs(diff - width)
      setTooltipBodyLeft(tooltipBodyLeftTemp + 10)
    }
  }, [tooltipRefPos, tooltipPosX])

  const arrowSize = 15
  const classes = useStyles({
    arrowOffsetLeft,
    arrowSize,
    tooltipBodyLeft,
    withRadius
  })
  return (
    <div className={classes.tooltipContainer} ref={tooltipRef}>
      <div className={classes.hoverSaver} />
      <div className={classes.tooltipBody}>
        <div className={classes.tooltipArrowBorder} />
        <div className={classes.tooltipArrow} />
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    backgroundColor: 'white',
    position: 'absolute',
    marginTop: '1.5rem',
    boxShadow: theme.CSS.cardShadow,
    borderRadius: '6px',
    marginLeft: (props) => props.tooltipBodyLeft
  },
  hoverSaver: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '-1.5rem'
  },
  tooltipBody: {
    position: 'relative',
    border: `1px solid ${theme.palette.greys.medium}`,
    minWidth: '15rem',
    '&:hover': {
      cursor: 'default'
    },
    borderRadius: (props) => (props.withRadius ? '6px' : 0)
  },
  tooltipArrow: {
    position: 'absolute',
    top: (props) => `-${Math.floor(props.arrowSize * 0.95)}px`,
    margin: 'auto',
    marginLeft: (props) => -props.tooltipBodyLeft,
    left: (props) => props.arrowOffsetLeft,
    width: 0,
    height: 0,
    borderLeft: (props) => `${props.arrowSize}px solid transparent`,
    borderRight: (props) => `${props.arrowSize}px solid transparent`,
    borderBottom: (props) => `${props.arrowSize}px solid white`
  },
  tooltipArrowBorder: {
    position: 'absolute',
    top: (props) => `-${props.arrowSize}px`,
    margin: 'auto',
    marginLeft: (props) => -props.tooltipBodyLeft,
    left: (props) => props.arrowOffsetLeft,
    width: 0,
    height: 0,
    borderLeft: (props) => `${props.arrowSize}px solid transparent`,
    borderRight: (props) => `${props.arrowSize}px solid transparent`,
    borderBottom: (props) => `${props.arrowSize}px solid ${theme.palette.greys.medium}`
  }
}))

HeaderTooltip.defaultProps = {
  withRadius: true,
  withArrowOffset: true
}

HeaderTooltip.propTypes = {
  targetEl: PropTypes.oneOfType([(PropTypes.element, PropTypes.object)]),
  withRadius: PropTypes.bool,
  children: PropTypes.oneOfType([(PropTypes.object, PropTypes.array)]),
  withArrowOffset: PropTypes.bool
}

export default HeaderTooltip
