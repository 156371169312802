import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setAuthRouteAttempt } from '../../redux/actions/Member.actions'
import Loader from './Loader'

const blokesRounds = [107, 108, 109, 110, 111, 112, 113, 114, 115, 116]

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { isAuthenticated, loading } = useSelector((state) => state.member)
  const { memberSummary, loading: loadingMemberSummary } = useSelector((state) => state.memberSummary)
  const { currentSubscription } = useSelector((state) => state.currentSubscription)

  if (!isAuthenticated && !loading && !localStorage.getItem('token')) {
    dispatch(setAuthRouteAttempt(location.pathname))
    return <Redirect to='/login' />
  }

  if (memberSummary?.subscriptions) {
    const subscription = memberSummary?.subscriptions[memberSummary.subscriptions.length - 1]
    if (subscription.status === 'Suspended') {
      window.location.href = `${
        process.env.REACT_APP_GO_LOGIN
      }?redirectUrl=account/billing/update-credit-card/suspended&t=${localStorage.getItem('auth_token')}`
    }
    if (subscription.round_id < 103 || blokesRounds.includes(subscription.round_id)) {
      window.location.href = `${process.env.REACT_APP_GO_LOGIN}?redirectUrl=dashboard&t=${localStorage.getItem(
        'auth_token'
      )}`
    }
  }

  if (currentSubscription?.round_id < 103 || blokesRounds.includes(currentSubscription?.round_id)) {
    window.location.href = `${process.env.REACT_APP_GO_LOGIN}?redirectUrl=dashboard&t=${localStorage.getItem(
      'auth_token'
    )}`
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        loading || loadingMemberSummary ? (
          <Loader />
        ) : !isAuthenticated && !loading && !localStorage.getItem('token') ? (
          <Redirect to='/login' />
        ) : !loading ? (
          <Component {...props}> {props.children} </Component>
        ) : null
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  children: PropTypes.object
}

export default PrivateRoute
