import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import backgroundPattern from '../app/assets/img/welcome_steps/BG-ProgramConfirmationPage.svg'
import WelcomeStepOneHeader from '../app/modules/welcome_steps/welcome_step_one/WelcomeStepOneHeader'
import ContentContainer from '../app/components/ContentContainer'
import WelcomeStepOneForm from '../app/modules/welcome_steps/welcome_step_one/WelcomeStepOneForm'
import WelcomeRedirector from '../app/modules/welcome_steps/WelcomeRedirector'
import WelcomeReturnHeader from '../app/modules/welcome_steps/welcome_return/WelcomeReturnHeader'
import WelcomeReturnSelectPlan from '../app/modules/welcome_steps/welcome_return/WelcomeReturnSelectPlan'

import { useDispatch, useSelector } from 'react-redux'
import { updatePreSeasonProgress } from '../redux/actions/Member.actions'

const WelcomeStepOne = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { isReturnMember } = useSelector((state) => state.member)
  const currentSubscriptionId = useSelector((state) => state.member.currentSubscriptionId)
  const { progress, loading } = useSelector((state) => state.preseason)

  // Welcome_step_0 used to be password input screen, but has since been removed:
  useEffect(() => {
    if (progress && currentSubscriptionId) {
      if (!progress.welcome_steps_0 && !loading)
        dispatch(updatePreSeasonProgress(currentSubscriptionId, { welcome_steps_0: true }))
    }
  }, [progress, currentSubscriptionId])

  return (
    <div className={classes.background}>
      <WelcomeRedirector />
      {isReturnMember ? (
        <>
          <WelcomeReturnHeader />
          <WelcomeReturnSelectPlan />
        </>
      ) : (
        <ContentContainer>
          <WelcomeStepOneHeader />
          <WelcomeStepOneForm />
        </ContentContainer>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundPattern})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    backgroundAttachment: 'fixed',
    minWidth: '100%',
    minHeight: '100vh'
  },
  innerContent: {
    maxWidth: '70vw',
    margin: 'auto'
  },
  [theme.breakpoints.down('md')]: {
    innerContent: {
      maxWidth: '80%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    innerContent: {
      maxWidth: '90%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    innerContent: {
      maxWidth: '95%'
    }
  }
}))

export default WelcomeStepOne
