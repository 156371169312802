import React, { forwardRef } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const ChargifyPaymentFields = forwardRef(({ securityToken, fail }, ref) => {
  const classes = useStyles()

  if (fail)
    return (
      <div className={classes.error}>
        <span>
          An Error occurred, Please contact us at{' '}
          <a href='mailto: support@12wbtsupport.com'>
            support@12wbtsupport.com
          </a>
        </span>
      </div>
    )
  // In our setup Security token is required to submit the payment fields to Chargify:
  if (!securityToken)
    return (
      <div className={classes.spinnerDiv}>
        <CircularProgress size={25} />
      </div>
    )
  return (
    <div id='chargify_form' className={classes.chargifyForm} ref={ref}>
      <div id='cc_number' className={classes.cardNumber}></div>
      <div className={classes.cardHolder}>
        <div id='cc_first_name'></div>
        <div id='cc_last_name'></div>
      </div>

      <div className={classes.cardExpiry}>
        <div id='cc_month'></div>
        <div id='cc_year'></div>
        <div id='cc_cvv'></div>
      </div>
    </div>
  )
})

ChargifyPaymentFields.displayName = 'ChargifyPaymentFields'

const useStyles = makeStyles((theme) => ({
  spinnerDiv: {
    height: '150px',
    ...theme.CSS.center
  },
  chargifyForm: {
    width: '90%'
  },
  cardNumber: {
    maxWidth: '430px',
    '& iframe': {
      width: '100%'
    }
  },
  cardHolder: {
    display: 'flex',
    '& > div': {
      width: '28%',
      marginLeft: '.5rem',
      '& iframe': {
        width: '100%'
      }
    },
    '& > div:nth-child(1)': {
      marginLeft: 0
    }
  },
  cardExpiry: {
    display: 'flex',
    '& > div': {
      width: '28%',
      marginLeft: '.5rem',
      '& iframe': {
        width: '100%'
      }
    },
    '& > div:nth-child(1)': {
      marginLeft: 0
    }
  },
  error: {
    margin: '1rem 0',
    color: theme.palette.error.main,
    '& a': {
      color: theme.palette.error.main
    }
  },
  [theme.breakpoints.down('sm')]: {
    cardNumber: {
      maxWidth: '90%'
    }
  }
}))

ChargifyPaymentFields.propTypes = {
  securityToken: PropTypes.string,
  fail: PropTypes.bool
}

export default ChargifyPaymentFields
