import React from 'react'
import { makeStyles } from '@material-ui/core'
import backgroundPattern from '../app/assets/img/welcome_steps/BG-ProgramConfirmationPage.svg'
import WelcomeCompleteHeader from '../app/modules/welcome_steps/welcome_complete/WelcomeCompleteHeader'
import WelcomeCompletePreSeasonBanner from '../app/modules/welcome_steps/welcome_complete/WelcomeCompletePreSeasonBanner'
import WelcomeCompleteProgramInfo from '../app/modules/welcome_steps/welcome_complete/WelcomeCompleteProgramInfo'
import WelcomeRedirector from '../app/modules/welcome_steps/WelcomeRedirector'

const WelcomeComplete = () => {
  const classes = useStyles()
  return (
    <div className={classes.background}>
      <WelcomeRedirector />
      <WelcomeCompleteHeader />
      <WelcomeCompletePreSeasonBanner />
      <WelcomeCompleteProgramInfo />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundPattern})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    backgroundAttachment: 'fixed',
    minWidth: '100%',
    minHeight: '100vh'
  }
}))

export default WelcomeComplete
