import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextareaAutosize } from '@material-ui/core'

import HelperText from './HelperText'
const TextAreaInput = ({ formik, label, name, placeholder }) => {
  const [error, setError] = useState(false)
  const classes = useStyles({ error })

  useEffect(() => {
    if (formik.errors[name] && formik.touched[name]) {
      setError(true)
    } else setError(undefined)
  }, [formik.errors[name], formik.touched[name]])

  return (
    <div className={classes.container}>
      {label && <span className={classes.label}>{label}</span>}
      <TextareaAutosize
        rowsMax={12}
        rowsMin={4}
        name={name}
        aria-label='maximum height'
        onChange={formik.handleChange}
        value={formik.values[name]}
        className={classes.textArea}
        placeholder={placeholder}
      />
      {error && <HelperText message='This field is required' />}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      marginTop: '0!important'
    }
  },
  textArea: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    border: (props) => (props.error ? `1px solid red` : `1px solid black`)
  },
  [theme.breakpoints.down('md')]: {
    container: {
      '& span': {
        fontSize: '1rem'
      }
    }
  }
}))

TextAreaInput.propTypes = {
  formik: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string
}

export default TextAreaInput
