import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentSubscription,
  setCurrentSubscriptionId,
  setCurrentRoundId,
  getPreSeasonProgress,
  getMemberSummary,
  setIsReturnMember,
  getAllSubscriptions
} from '../../redux/actions/Member.actions'

const loadCurrentSubscriptionAndRound = (force = false) => {
  const dispatch = useDispatch()
  const { memberSummary, loading } = useSelector((state) => state.memberSummary)
  const currentSubscription = useSelector(
    (state) => state.currentSubscription?.currentSubscription
  )
  const currentSubscriptionLoading = useSelector(
    (state) => state.currentSubscription?.loading
  )
  const { progress } = useSelector((state) => state.preseason)
  const preseasonLoading = useSelector((state) => state.preseason?.loading)
  const currentSubscriptionId = useSelector(
    (state) => state.member?.currentSubscriptionId
  )
  const currentRoundId = useSelector((state) => state.member?.currentRoundId)
  const loadingAllSubscriptions = useSelector(
    (state) => state.subscriptions?.loading
  )
  const subscriptions = useSelector(
    (state) => state.subscriptions?.subscriptions
  )

  useEffect(() => {
    if (memberSummary && !loading) {
      // if (!memberSummary.round_in_progress) {
      // Get current subscription id:
      const roundId =
        memberSummary.visible_rounds[0]?.id ||
        memberSummary.subscriptions[memberSummary.subscriptions.length - 1]
          .round_id

      const subscription = memberSummary.subscriptions.find((sub) => {
        return sub.round_id === roundId
      })
      if (!progress && !preseasonLoading) {
        // Get pre-season/welcome-steps progress:
        dispatch(getPreSeasonProgress(subscription.id))
      }

      // Set current subscription id in redux:
      if (!currentSubscriptionId)
        dispatch(setCurrentSubscriptionId(subscription.id))

      // Get current subscription:
      if ((!currentSubscription && !currentSubscriptionLoading) || force)
        dispatch(getCurrentSubscription(memberSummary.id, subscription.id))

      // Get all subscriptions
      if (!subscriptions && !loadingAllSubscriptions)
        dispatch(getAllSubscriptions(memberSummary.id))

      // Set current round id in redux:
      if (!currentRoundId) dispatch(setCurrentRoundId(roundId))
      // }

      // Set return member bool in redux state:
      if (memberSummary.subscriptions.length > 1) {
        dispatch(setIsReturnMember())
      }
    }
    if (!memberSummary && !loading) {
      dispatch(getMemberSummary())
    }
  }, [memberSummary])
}

export default loadCurrentSubscriptionAndRound
