import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Radio } from '@material-ui/core'

const RadioAnswerOption = ({
  currentAnswer,
  radioAnswer,
  setAnswer,
  answerSubmitted,
  currentAnswerCorrect,
  correctAnswerChosen
}) => {
  const classes = useStyles()
  const radioButtonClickHandler = () => {
    setAnswer(radioAnswer)
  }

  let radioText
  if (radioAnswer === true) radioText = 'True'
  else if (radioAnswer === false) radioText = 'False'
  else radioText = radioAnswer

  // Class management:
  let labelClass = classes.labelRow
  let radioClass = classes.radio
  if (answerSubmitted) {
    labelClass =
      correctAnswerChosen || currentAnswerCorrect
        ? `${classes.labelRow} ${classes.successLabelRow}`
        : currentAnswer === radioAnswer
        ? classes.failLabelRow
        : `${classes.labelRow}`
    radioClass =
      correctAnswerChosen || currentAnswerCorrect
        ? `${classes.radio} ${classes.successRadio}`
        : currentAnswer === radioAnswer
        ? classes.failRadio
        : classes.radio
  }

  return (
    <label onClick={() => radioButtonClickHandler(true)} className={labelClass}>
      <Radio
        classes={{
          checked: classes.checked,
          root: radioClass
        }}
        color='primary'
        checked={
          radioAnswer === currentAnswer ||
          (answerSubmitted && correctAnswerChosen) ||
          (answerSubmitted && currentAnswerCorrect)
        }
      />
      <span>{radioText}</span>
    </label>
  )
}

const useStyles = makeStyles((theme) => ({
  labelRow: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.greys.light,
    width: '100%',
    margin: '.5rem 0',
    height: '3rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  labelRowSubmitted: {
    height: '2.5rem',
    borderRadius: '6px'
  },
  successLabelRow: {
    border: '1px solid rgb(50,205,50)',
    backgroundColor: applyOpacity('rgb(50,205,50)', 0.5)
  },
  failLabelRow: {
    border: '1px solid rgb(220,20,60)',
    backgroundColor: applyOpacity('rgb(220,20,60)', 0.5),
    color: 'rgb(220,20,60)',
    fontWeight: 'bold'
  },
  successRadio: {
    '&$checked': {
      color: 'rgb(50,205,50)'
    }
  },
  checked: {},
  failRadio: {
    '&$checked': {
      color: 'red'
    }
  }
}))

function applyOpacity(color) {
  const opacity = 0.2
  return color.substring(0, color.length - 1) + `, ${opacity})`
}

RadioAnswerOption.propTypes = {
  currentAnswer: PropTypes.string.isRequired,
  radioAnswer: PropTypes.string.isRequired,
  answerSubmitted: PropTypes.string.isRequired,
  setAnswer: PropTypes.func.isRequired,
  correctAnswerChosen: PropTypes.bool.isRequired,
  currentAnswerCorrect: PropTypes.bool.isRequired
}

export default RadioAnswerOption
