import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const ChooseRound = ({ onRoundChange }) => {
  const classes = useStyles()
  const [activeRound, setActiveRound] = useState(null)
  const { currentAndNextRound } = useSelector((state) => state.round)

  // Pick current round on component load:
  useEffect(() => {
    if (!activeRound && currentAndNextRound) {
      roundBannerClickHandler(currentAndNextRound.currentRoundId)
      // roundBannerClickHandler(currentAndNextRound.nextRoundId)
    }
  }, [currentAndNextRound])

  const bannerClass = (roundId) => {
    return activeRound === roundId
      ? `${classes.bannerContainer} ${classes.activeContainer}`
      : classes.bannerContainer
  }

  const roundBannerClickHandler = (roundId) => {
    setActiveRound(roundId)
    onRoundChange(roundId)
  }

  return (
    <div className={classes.container}>
      <div
          style={{backgroundColor: '#F5F5F5'}}
        className={bannerClass(currentAndNextRound?.currentRoundId)}
        onClick={() =>
          roundBannerClickHandler(currentAndNextRound?.currentRoundId)
        }
      >
        <span className={classes.title}>
          {currentAndNextRound?.currentRoundName}
        </span>
        <span className={classes.description}>
          Are you ready to jump straight in?
        </span>
        <span className={classes.description}>Week 1 has just started.</span>
      </div>
      <div
          style={{backgroundColor: '#F5F5F5'}}
        className={bannerClass(currentAndNextRound?.nextRoundId)}
        onClick={() =>
          roundBannerClickHandler(currentAndNextRound?.nextRoundId)
        }
      >
        <span className={classes.title}>
          {currentAndNextRound?.nextRoundName}
        </span>
        <span className={classes.description}>
          Need more time to prepare or new to my program?
        </span>
        <span className={classes.description}>
          Pre-Season has just started.
        </span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  formHeader: {
    fontWeight: 700,
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '.5rem'
  },
  formSubHeader: {
    fontSize: '20px',
    fontWeight: 400,
    margin: '.5rem 0'
  },
  bannerContainer: {
    width: '100%',
    margin: '.5rem 0',
    borderRadius: '5px',
    border: '1px solid #D1D5DB',
    padding: '6px 12px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeContainer: {
    border: (props) =>
        `3px solid ${theme.palette.signup.borderColor}`
      // props.activeColor
      //   ? `3px solid ${props.activeColor}`
      //   : `3px solid ${theme.palette.signup.borderColor}`
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 900,
    fontSize: '17px',
    marginBottom: '.2rem'
  },
  description: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#6B7280',
    lineHeight: '16px'
  },
  [theme.breakpoints.down('md')]: {
    formHeader: {
      fontSize: '20px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    formHeader: {
      fontSize: '22px'
    }
  }
}))

ChooseRound.propTypes = {
  onRoundChange: PropTypes.func.isRequired
}

export default ChooseRound
