import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormHelperText, Select, makeStyles, MenuItem } from '@material-ui/core'

const SelectInput = ({ formik, placeholder, name, label, options, defaultValue, withDenseMargin, showError }) => {
  const classes = useStyles({ label, withDenseMargin })
  const [value, setValue] = useState('')
  const handleChange = (e) => {
    setValue(e.target.value)
    formik.setFieldValue(name, e.target.value, true)
  }
  return (
    <div className={classes.container}>
      <span className={classes.label}>{label}</span>
      <FormControl
        variant='outlined'
        className={classes.formControl}
        error={formik.errors[name] && formik.touched[name]}
        margin={withDenseMargin && 'dense'}
      >
        <Select
          value={!value && defaultValue ? defaultValue : value}
          onChange={handleChange}
          displayEmpty
          name={name}
          className={classes.select}
        >
          <MenuItem value='' disabled>
            {placeholder}
          </MenuItem>
          {options.map((option, index) => {
            return (
              <MenuItem value={option.value} key={index}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
        {showError && <FormHelperText>{formik.touched[name] && formik.errors[name]}</FormHelperText>}
      </FormControl>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    color: 'grey',
    textAlign: 'left',
    '& span': {
      margin: (props) => (props.label ? '.2rem 0' : undefined)
    },
    overflow: 'visible'
  },
  formControl: {
    marginTop: (props) => (props.label ? '0rem' : props.withDenseMargin ? '7px' : '1rem')
  },
  label: {
    overflow: 'visible',
    whiteSpace: 'nowrap'
  },
  select: {
    height: 40,
    backgroundColor: 'white'
  }
}))

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  formik: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  withDenseMargin: PropTypes.bool,
  showError: PropTypes.bool
}

SelectInput.defaultProps = {
  showError: true
}

export default SelectInput
