import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const GuideTip = ({ icon, title, description, link }) => {
  const classes = useStyles()
  return (
    <Link className={classes.container} to={`pre-season/guides/${link}`}>
      <div className={classes.iconDiv}>{icon}</div>
      <div className={classes.textContainer}>
        <span className={classes.title}>{title}</span>
        <span className={classes.description}>{description}</span>
      </div>
    </Link>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: '64px',
    textDecoration: 'none'
  },
  iconDiv: {
    backgroundColor: '#2563EB',
    minWidth: '48px',
    height: '48px',
    borderRadius: '6px',
    ...theme.CSS.center,
    '& svg': {
      color: 'white',
      width: '40px',
      height: '40px'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '13px'
  },
  title: {
    color: '#111827',
    fontSize: '18px',
    fontWeight: 400
  },
  description: {
    marginTop: '8px',
    color: '#4B5563',
    fontSize: '16px',
    fontWeight: 400,
    paddingRight: '1rem'
  }
}))

GuideTip.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

export default GuideTip
