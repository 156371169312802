import React from 'react'
import { makeStyles } from '@material-ui/core'
import turtle from '../../../../assets/img/pre_season/guides/infographics/turtle.png'
import rabbit from '../../../../assets/img/pre_season/guides/infographics/rabbit.png'
import rabbitHole from '../../../../assets/img/pre_season/guides/infographics/rabbitHole.png'

const HealthyEatingTurtleAndRabbit = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <span className={classes.fancyTitle}>Low GI</span>
      <div className={classes.turtleRow}>
        <img src={turtle} alt='Turtle' />
        <img src={turtle} alt='Turtle' />
      </div>
      <div className={classes.turtleDivider}>
        <hr className={classes.dottedLine} />
        <span className={classes.fancyTitle}>Finish</span>
      </div>
      <div className={classes.rabbitSection}>
        <span className={classes.fancyTitle}>High GI</span>
        <div className={classes.rabbitDiv}>
          <img src={rabbit} alt='rabbit' />
          <img src={rabbitHole} alt='rabbitHole' />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  fancyTitle: {
    color: theme.palette.rgb.guide.green,
    fontFamily: 'KokomoBreeze',
    fontSize: '3.5rem'
  },
  turtleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& img': {
      width: '12rem'
    },
    width: '100%'
  },
  turtleDivider: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  dottedLine: {
    top: '-5px',
    width: '100%',
    height: '10px',
    border: 'none',
    backgroundImage: `linear-gradient(to right, ${theme.palette.rgb.guide.green} 33%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: 'center',
    backgroundSize: '10px 2px',
    backgroundRepeat: 'repeat-x'
  },
  rabbitDiv: {
    display: 'flex',
    maxWidth: '100%',
    '& img': {
      width: '12rem'
    }
  },
  rabbitSection: {
    margin: '1rem 0'
  },
  [theme.breakpoints.down('sm')]: {
    turtleRow: {
      '& img': {
        width: '10rem'
      }
    },
    rabbitDiv: {
      '& img': {
        width: '10rem'
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    turtleRow: {
      '& img': {
        width: '8rem'
      }
    },
    rabbitDiv: {
      '& img': {
        width: '8rem'
      }
    }
  }
}))

export default HealthyEatingTurtleAndRabbit
