import { useEffect, useState } from 'react'

const UseWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

  const handleWindowResize = () => setDimensions({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return { width: dimensions.width, height: dimensions.height }
}

export default UseWindowDimensions
