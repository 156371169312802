import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import DoneIcon from '@material-ui/icons/Done'
import { Link } from 'react-router-dom'

const TaskCard = ({ bannerImage, description, done, title, link, required, locked, unlockDate }) => {
  const classes = useStyles({ bannerImage, done })

  const badgeTitle = () => {
    if (done)
      return (
        <div className={classes.badgeTitle}>
          <DoneIcon /> <span>Done</span>
        </div>
      )

    return (
      <div className={classes.badgeTitle}>
        <span>Must Do</span>
      </div>
    )
  }

  const shouldShowBadge = done || required
  const badgeClass = required ? `${classes.badge} ${classes.badgeRequired}` : classes.badge
  return (
    <div className={classes.container}>
      <div className={classes.cardHeader}>
        {locked && (
          <div className={classes.lockedHeaderOverlay}>
            <span>Unlocks {unlockDate}</span>
          </div>
        )}
        {shouldShowBadge && <div className={badgeClass}>{badgeTitle()}</div>}
      </div>
      <div className={classes.cardBody}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <span className={classes.description}>{description}</span>
      </div>
      {locked ? (
        <div className={classes.cardFooter}>
          <span>Get started</span> <ArrowForwardIcon />
        </div>
      ) : (
        <Link className={classes.cardFooter} to={`/pre-season/tasks/${link}`}>
          <span>Get started</span>
          <ArrowForwardIcon />
        </Link>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '8px',
    boxShadow: theme.CSS.cardShadow
  },
  cardHeader: {
    height: '192px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    position: 'relative',
    backgroundImage: (props) => `url(${props.bannerImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  cardTitle: {
    margin: '.5rem 0',
    fontSize: '24px'
  },
  cardBody: {
    height: '190px',
    padding: '24px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    bottom: 0,
    backgroundColor: '#F9FAFB',
    textDecoration: 'none',
    '& span': {
      color: theme.palette.primary.main,
      fontSize: '16px',
      lineHieght: '24px'
    },
    '& svg': {
      marginLeft: '.5rem',
      color: theme.palette.primary.main
    },
    height: '72px',
    ...theme.CSS.center,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  badge: {
    borderRadius: '10px',
    width: '76px',
    height: '36px',
    position: 'absolute',
    padding: '2px 10px',
    left: '16px',
    top: '10px',
    backgroundColor: (props) => (props.done ? '#D1FAE5' : '#FEE2E2'),
    ...theme.CSS.center
  },
  badgeRequired: {
    padding: '2px 5px'
  },
  badgeTitle: {
    ...theme.CSS.center,
    color: (props) => (props.done ? '#065F46' : '#991B1B'),
    '& svg': {
      color: '#065F46'
    }
  },
  description: {
    marginTop: '1rem',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#4B5563'
  },
  lockedHeaderOverlay: {
    backgroundColor: 'rgba(0,0,0, 0.5)',
    ...theme.CSS.center,
    color: 'white',
    height: '100%',
    fontSize: '24px',
    fontWeight: 'bold',
    borderRadius: '8px'
  },
  [theme.breakpoints.down('lg')]: {
    cardBody: {
      height: '250px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    cardBody: {
      height: '270px',
      padding: '18px'
    },
    cardTitle: {
      fontSize: '20px',
      margin: '0',
      marginBottom: '5px'
    },
    description: {
      marginTop: '5px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    cardBody: {
      height: '180px'
    }
  },
  [theme.breakpoints.down(350)]: {
    cardBody: {
      height: '200px'
    },
    description: {
      fontSize: '.9rem'
    }
  }
}))

TaskCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  bannerImage: PropTypes.string.isRequired,
  done: PropTypes.oneOfType([(PropTypes.bool, PropTypes.object)]),
  required: PropTypes.bool.isRequired,
  locked: PropTypes.bool.isRequired,
  unlockDate: PropTypes.string
}

export default TaskCard
