import React from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import TooltipPreSeasonReleaseDate from './TooltipPreSeasonReleaseDate'
import { ReactComponent as MealPlan } from '../../assets/img/icons/pre_season/MealPlan.svg'
import { ReactComponent as ExercisePlan } from '../../assets/img/icons/pre_season/ExercisePlan.svg'
import { ReactComponent as Mindset } from '../../assets/img/icons/pre_season/Mindset.svg'
import { ReactComponent as MyStats } from '../../assets/img/icons/pre_season/MyStats.svg'
import { ReactComponent as MyTracker } from '../../assets/img/icons/pre_season/MyTracker.svg'
import { ReactComponent as WeeklyChallenge } from '../../assets/img/icons/pre_season/WeeklyChallenge.svg'

import moment from 'moment'
import preseasonProgress from '../../assets/img/pre_season/preseasongraph.png'
import SettingsIcon from '@material-ui/icons/Settings'
import navigateToGoSite from '../../helpers/NavigateToGoSite'
import TooltipSwitchRoundBtn from './SwitchRound/TooltipSwitchRoundBtn'
import DefaultBtn from '../buttons/DefaultBtn'

import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'

const TooltipContentPreSeason = () => {
  const roundInfo = useSelector((state) => state.round.roundInfo)
  const sixDaysBefore = moment(roundInfo?.starts_at)
    .subtract(6, 'days')
    .format('DD MMMM')
  const dayBefore = moment(roundInfo?.starts_at)
    .subtract(1, 'days')
    .format('DD MMMM')
  const dayOf = moment(roundInfo?.starts_at).format('DD MMMM')
  const classes = useStyles()
  const currentSubscription = useSelector(
    (state) => state.currentSubscription?.currentSubscription
  )
  const roundCurrentWeek = useSelector(
    (state) => state.round?.roundInfo?.current_week
  )
  const sixDaysBeforeDate = new Date(roundInfo?.starts_at)
  // Exercise and meal plans and Weekly challenge should be released 6 days before release at 10 am
  sixDaysBeforeDate.setDate(sixDaysBeforeDate.getDate() - 6)
  sixDaysBeforeDate.setHours(10);
  
  const oneDayBeforeDate = new Date(roundInfo?.starts_at)
  // Mindset should be released 1 day before release at 5 pm
  oneDayBeforeDate.setDate(oneDayBeforeDate.getDate() - 1)
  oneDayBeforeDate.setHours(17);

  const navigateToHeaderLink = (redirectUrl) => {
    return `${process.env.REACT_APP_GO_LOGIN}?t=${localStorage.getItem(
      'auth_token'
    )}&roundId=${currentSubscription?.round_id}&redirectUrl=${redirectUrl}`
  }

  return (
    <div className={classes.container}>
      <div className={classes.contentBody}>
        <div className={classes.releaseDatesContainer}>
          <TooltipPreSeasonReleaseDate
            icon={<MealPlan />}
            title='My Meal Plan'
            dateAvailable={sixDaysBefore}
            link={navigateToHeaderLink(
              `week/${roundCurrentWeek < 1 ? 1 : roundCurrentWeek}/meal-plans`
            )}
            subTitle='Shopping List'
            subTitleLink={navigateToHeaderLink(
              `week/${
                roundCurrentWeek < 1 ? 1 : roundCurrentWeek
              }/meal-plans/shopping-list`
            )}
            isActive={new Date() >= sixDaysBeforeDate}
          />
          <Divider className={classes.divider} />
          <TooltipPreSeasonReleaseDate
            icon={<ExercisePlan />}
            title='My Exercise Plan'
            dateAvailable={sixDaysBefore}
            link={navigateToHeaderLink(
              `week/${
                roundCurrentWeek < 1 ? 1 : roundCurrentWeek
              }/exercise-plan`
            )}
            isActive={new Date() > sixDaysBeforeDate}
          />
          <Divider className={classes.divider} />
          <TooltipPreSeasonReleaseDate
            icon={<Mindset />}
            title='Mindset Video'
            dateAvailable={dayBefore}
            link={navigateToHeaderLink(
              `week/${roundCurrentWeek < 1 ? 1 : roundCurrentWeek}`
            )}
            isActive={new Date() >= oneDayBeforeDate}
          />
          <Divider className={classes.divider} />
          <TooltipPreSeasonReleaseDate
            icon={<MyStats />}
            title='My Stats'
            dateAvailable={dayOf}
            link={navigateToHeaderLink('week/my-stats')}
            isActive={new Date() >= new Date(roundInfo?.starts_at)}
          />
          <Divider className={classes.divider} />
          <TooltipPreSeasonReleaseDate
            icon={<MyTracker />}
            title='My Tracker'
            dateAvailable={dayOf}
            link={navigateToHeaderLink(
              `week/${roundCurrentWeek < 1 ? 1 : roundCurrentWeek}/my-tracker`
            )}
            isActive={new Date() >= new Date(roundInfo?.starts_at)}
          />
          <Divider className={classes.divider} />
          <TooltipPreSeasonReleaseDate
            icon={<WeeklyChallenge />}
            title='Weekly Challenge'
            dateAvailable={sixDaysBefore}
            link={navigateToHeaderLink(
              `week/${roundCurrentWeek < 1 ? 1 : roundCurrentWeek}/challenge`
            )}
            isActive={new Date() >= sixDaysBeforeDate}
          />
        </div>
        <div className={classes.preSeasonImageContainer}>
          <div className={classes.roundText}>
            <span className={classes.monthHeader}>
              {moment(roundInfo?.starts_at).format('MMMM YYYY')}
            </span>
            <span className={classes.preSeasonTitle}>Pre-Season</span>
            <span className={classes.roundStart}>
              Round starts {moment(roundInfo?.starts_at).format('DD MMMM')}
            </span>
          </div>
          {roundCurrentWeek >= 0 ? (
            <div className={classes.backToProgramBtn}>
              <a href={navigateToHeaderLink('dashboard')}>
                <DefaultBtn isRounded={true} padding='0 0.2rem' fontSize='14px'>
                  12 WEEK PROGRAM{' '}
                  <ArrowForwardIos
                    style={{ fontSize: '14px', marginLeft: 4 }}
                  />
                </DefaultBtn>
              </a>
            </div>
          ) : (
            <></>
          )}
          <img src={preseasonProgress} alt='Pre season progress' />
        </div>
      </div>
      <div className={classes.footer}>
        <a
          className={classes.myAccountDiv}
          href={navigateToGoSite('my-account')}
        >
          <SettingsIcon />
          <span>My Account</span>
        </a>
        <div className={classes.myAccountDiv}>
          <TooltipSwitchRoundBtn />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    display: 'flex',
    color: 'grey',
    flexDirection: 'row'
  },
  releaseDatesContainer: {
    paddingTop: '1.1rem',
    minWidth: '14rem',
    top: '-1px'
  },
  divider: {
    width: '80%',
    margin: 'auto'
  },
  footer: {
    width: '100%',
    backgroundColor: '#f0faff',
    display: 'flex',
    justifyContent: 'space-between'
  },
  preSeasonImageContainer: {
    position: 'relative',
    '& img': {
      width: '573px'
    }
  },
  myAccountDiv: {
    width: '10rem',
    height: '3.5rem',
    paddingRight: '2rem',
    ...theme.CSS.center,
    alignItems: 'center',
    color: theme.palette.signup.purple,
    '& svg': {
      paddingRight: '.5rem',
      color: theme.palette.signup.purple
    },
    textDecoration: 'none',
    '&:hover': {
      color: 'black',
      '& svg': {
        color: 'black'
      }
    }
  },
  roundText: {
    color: 'white',
    position: 'absolute',
    left: '22px',
    marginTop: '22px',
    display: 'flex',
    flexDirection: 'column'
  },
  backToProgramBtn: {
    position: 'absolute',
    right: '22px',
    bottom: '22px',
    '& a': {
      textDecoration: 'none'
    }
  },
  monthHeader: {
    fontSize: '12px',
    fontWeight: 400
  },
  preSeasonTitle: {
    fontWeight: 700,
    fontSize: '24px'
  },
  roundStart: {
    fontSize: '18px',
    fontWeight: 400
  },
  [theme.breakpoints.down('md')]: {
    preSeasonImageContainer: {
      '& img': {
        width: '450px'
      }
    }
  }
}))

export default TooltipContentPreSeason
