import React from 'react'
import { makeStyles } from '@material-ui/core'
import backgroundPattern from '../app/assets/img/welcome_steps/BG-ProgramConfirmationPage.svg'
import ContentContainer from '../app/components/ContentContainer'
import WelcomeStepTwoHeader from '../app/modules/welcome_steps/welcome_step_two/WelcomeStepTwoHeader'
import WelcomeStepTwoForm from '../app/modules/welcome_steps/welcome_step_two/WelcomeStepTwoForm'
import { useSelector } from 'react-redux'
import WelcomeRedirector from '../app/modules/welcome_steps/WelcomeRedirector'
import loadMemberProfile from '../app/helpers/LoadMemberProfile'

const WelcomeStepTwo = () => {
  const { stepTwoProgress } = useSelector((state) => state.welcomeStepTwo)
  const classes = useStyles()
  loadMemberProfile()

  return (
    <div className={classes.background}>
      <WelcomeRedirector />
      <ContentContainer>
        <div className={classes.innerContent}>
          <WelcomeStepTwoHeader stepTwoProgress={stepTwoProgress} />
          <WelcomeStepTwoForm stepTwoProgress={stepTwoProgress} />
        </div>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundPattern})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    backgroundAttachment: 'fixed',
    minWidth: '100%',
    minHeight: '100vh'
  },
  innerContent: {
    maxWidth: '73 vw',
    margin: 'auto'
  },
  [theme.breakpoints.down('md')]: {
    innerContent: {
      maxWidth: '80%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    innerContent: {
      maxWidth: '90%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    innerContent: {
      maxWidth: '95%'
    }
  }
}))

export default WelcomeStepTwo
