import React from 'react'
import { makeStyles } from '@material-ui/core'
import TaskContentContainer from '../tasks/TaskContentContainer'

const GuideTechTips = () => {
  const classes = useStyles()
  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <p>
          All 12WBT videos are designed to be watched on your smartphone, tablet, laptop or desktop – catering for
          everyone!
        </p>
        <p>
          If you are planning to connect your computer to your television to view components of your membership, there
          are a few different options, here are a few tips:
        </p>
        <ul>
          <li>
            Connect your Apple device to your Apple TV - Airplay allows you to enable your Apple TV to display whatever
            you have on screen on your device. You may also be able to use an adaptor or HDMI cable to connect.
          </li>
          <li>
            Chromecast - After you press play on your workout via the app or website, please click on the Chromecast
            icon which will appear in the top right corner app and the bottom right corner via the website.
          </li>
          <li>
            Smart TV - all you need to do is ensure that your Smart TV is connected to the internet, either wirelessly
            or with a LAN cable and head to the site via the browser to get started.
          </li>
          <li>
            Mobile, tablet or laptop - most devices will have a HDMI port, this is a great option for connecting the two
            together. You may need to refer to the owner manuals of your devices to check which size HDMI plug you will
            need. Most electrical retail outlets supply the cables required.
          </li>
        </ul>
        <p>
          Always be sure to have the latest software installed on your device to avoid any issues accessing the 12WBT
          site.
        </p>
        <p>12WBT videos are not downloadable, so you will need to be logged into the 12WBT website to play them.</p>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({}))

export default GuideTechTips
