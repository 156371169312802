import React from 'react'
import { makeStyles } from '@material-ui/core'
import TaskContentContainer from '../tasks/TaskContentContainer'
import daneImg from '../../../assets/img/pre_season/guides/dane.jpg'
import louiseImg from '../../../assets/img/pre_season/guides/louise.jpg'
import amandaImg from '../../../assets/img/pre_season/guides/amanda.jpg'
import ericaImg from '../../../assets/img/pre_season/guides/erica.jpg'
import stephImg from '../../../assets/img/pre_season/guides/steph.jpg'
import gImg from '../../../assets/img/pre_season/guides/g.jpg'
import lisaImg from '../../../assets/img/pre_season/guides/lisa.jpeg'
import timImg from '../../../assets/img/pre_season/guides/tim.jpg'

const GuideSupportCrew = () => {
  const classes = useStyles()
  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <p>
          As a member of my 12WBT, you are supported by my expert team of fitness trainers and nutritionists! We are one
          community and family, and as such, you will never feel alone or disconnected. My team will help you with
          everything you need to successfully complete your 12WBT, remove any barriers that pop up along the way and
          make sure that you’re having the best experience possible!
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.daneBack}`}></div>
          <b>Dane Pollock</b>
          <br />
          <em>BPED</em>
          <br />A compulsive list writer, Dane is the perfect person to trial new 12WBT exercise plans and takes great
          delight in giving detailed feedback about improvements or modifications. Whatever treats Mish has in store for
          members, Dane has endured first!
        </p>
        <p>
          After completing a Bachelor in Physical Education, her initial plan of becoming a PE teacher took a turn as
          she started teaching Group Fitness at Les Mills and went on to train as a Personal Trainer. Group Fitness then
          took her travel global as she worked as a trainer and presenter for Les Mills in Australia, the UK and NZ and
          as a Group Fitness Manager across various clubs around the world.
        </p>
        <p>
          She feels in control of her life when her health and fitness are a priority and her goal is to complete a Half
          Ironman. Her perfect night out involves Turkish food and a comedy at the movies.
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.louiseBack}`}></div>
          <b>Louise Mcdonald</b>
          <br />
          <em>Cert III & Cert IV Fitness</em>
          <br />
          Louise has been a Trainer and Wellness Coach for almost 20 years and is one of our experienced Support Crew
          members in Fitness, as our Lean and Strong Captain! Louise has worked extensively with all levels of fitness
          over the years across both Australia and the UK, where she has specialised in competition training with
          athletes for the last decade.
        </p>
        <p>
          Lou loves lifting heavy weights and her goal is to learn the art silks at trapeze school. Seven metres off the
          ground, she’ll be climbing, twisting, dangling and falling from two pieces of fabric. Quite a different buzz
          from her favourite pastime of listening to audio books
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.amandaBack}`}></div>
          <b>Amanda Turbill</b>
          <br />
          <em>APD, MNutr&Diet, BSc (Molecular Genetics)</em>
          <br />A self confessed foodie, nutrition nut and fitness advocate. Amanda gets to live her passions daily as a
          Dietitian with 12WBT. Having been a high level gymnast until her late teens she still tumbles to this day and
          believes to maintain life balance it’s important to find ways to incorporate ‘play’ to daily life . A Bachelor
          of Science (Molecular Genetics) and Masters in Nutrition & Dietetics she has over 20 years working across all
          clinical nutritional specialties with a particular interest in Nutrigenomics, food allergy and food
          intolerance. Amanda is a proud mum and is always keen to help other parents nourish, and stay active together
          with, their own families.
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.ericaBack}`}></div>
          <b>Erica</b>
          <br />
          <em>APD, BNutr&Diet</em>
          <br />
          Erica is a dietitian and trainer on the 12WBT Support Crew. She has completed a degree in Nutrition &
          Dietetics and loves helping people to enjoy, and have fun with, delicious food. With 10 years of experience as
          a Les Mills Group Fitness Instructor, Erica thrives on coaching people to move their bodies, sweat and get
          excited about reaching for big (and little) goals! Erica’s perfect day starts with a bike ride along the coast
          for a couple of hours, followed by brunch and an afternoon spent reading a book on the beach.
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.stephBack}`}></div>
          <b>Steph McDonnell</b>
          <br />
          <em>Site Admin and Tech</em>
          <br />
          Steph is an experienced member of the 12WBT Support Crew, having been a part of the Site Admin & Tech team for
          over 8 years. She loves helping members to navigate the website and troubleshoot any issues, so that they can
          get on with smashing the round! She is passionate about living a full life, enjoying pilates and plenty of
          beach time with her family.
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.gBack}`}></div>
          <b>Georgie Galea</b>
          <br />
          <em>Site Admin</em>
          <br />
          Although she has a background in design, Georgi has always loved words and when she’s not emailing members and
          answering questions, she plays word games or devours books. Georgie firmly believes that fitness first thing
          in the morning supercharges you for the rest of the day and reckons that the only thing between her and a six
          pack is apple pie.
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.lisaBack}`}></div>
          <b>Lisa Donaldson</b>
          <br />
          <em>Accredited Practising Dietitian</em>
          <br />
          Lisa is the Lead Dietitian for 12WBT. With a Masters in Nutrition & Dietetics as well as a Bachelor of
          Education, Lisa is keen to help all 12 Weekers understand how to achieve health for life. Lisa studied Sports
          Dietetics at the Australian Institute of Sport and has a keen interest in gastrointestinal health. A highly
          regarded communicator, Lisa is a spokesperson for the Dietitians Association of Australia, the ‘Dietitian in
          Residence’ at the University of Canberra and a lecturer at the Australian Defence Force Academy. She has also
          been an expert on ABC Television’s Ask the Dr Series. And just so you know, her favourite comfort food is a
          bowl of warm quinoa porridge with cinnamon and banana.
        </p>
        <p>
          <div className={`${classes.imgContainer} ${classes.timBack}`}> </div>
          <b>Tim Pittorino</b>
          <br />
          <em>BHSC</em>
          <br />
          Tim has been working in the Fitness Industry for over 25 years as Personal trainer, holistic health coach as
          well as an Australian strength & conditioning coach. Tim has been (self) described as “dangerously handsome”,
          which compliments his enjoyment of lifting dangerously heavy weights - his strong sense of vanity keeps him
          fit, mobile and lean...kind of!
          <br />
          <br />
          After studying all things physiological for 20 years, Tim now enjoys branching out into the other areas of
          wellness including mental health and emotional resilience. Tim’s lifetime goal is to see just how good the
          human body can feel.
        </p>
        <p>Contact us via email or in the Facebook Member Zone!</p>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    '& p': {
      margin: '2rem 0'
    }
  },
  amandaBack: {
    background: `url(${amandaImg})`
  },
  daneBack: {
    background: `url(${daneImg})`
  },
  louiseBack: {
    background: `url(${louiseImg})`
  },
  stephBack: {
    background: `url(${stephImg})`
  },
  gBack: {
    background: `url(${gImg})`
  },
  lisaBack: {
    background: `url(${lisaImg})`
  },
  timBack: {
    background: `url(${timImg})`
  },
  imgContainer: {
    float: 'left',
    width: '7rem',
    height: '7rem',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginRight: '1rem'
  },
  ericaBack: {
    background: `url(${ericaImg})`,
    backgroundPositionY: '10%',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  }
}))

export default GuideSupportCrew
