import {
  GET_NEXT_AVAILABLE_ROUND_REQUEST,
  GET_NEXT_AVAILABLE_ROUND_SUCCESS,
  GET_NEXT_AVAILABLE_ROUND_FAIL,
  UPDATE_LASSO_REQUEST,
  UPDATE_LASSO_SUCCESS,
  UPDATE_LASSO_FAIL,
  VALIDATE_COUPON_REQUEST,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_FAIL,
  CHECK_MEMBER_REQUEST,
  CHECK_MEMBER_SUCCESS,
  CHECK_MEMBER_FAIL,
  CHECK_FRIEND_MEMBER_REQUEST,
  CHECK_FRIEND_MEMBER_SUCCESS,
  CHECK_FRIEND_MEMBER_FAIL,
  GET_PRICE_POINTS_REQUEST,
  GET_PRICE_POINTS_SUCCESS,
  GET_PRICE_POINTS_FAIL,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  SIGNUP_LOGIN_REQUEST,
  SIGNUP_LOGIN_SUCCESS,
  SIGNUP_LOGIN_FAIL,
  SIGNUP_RESET_ERROR,
  SIGNUP_LOGIN_RESET_ERROR,
  GET_SECURITY_TOKEN_REQUEST,
  GET_SECURITY_TOKEN_SUCCESS,
  GET_SECURITY_TOKEN_FAIL,
  GET_ROUND_INFO_REQUEST,
  GET_ROUND_INFO_SUCCESS,
  GET_ROUND_INFO_FAIL,
  GET_LIFERS_PRICE_POINTS_REQUEST,
  GET_LIFERS_PRICE_POINTS_SUCCESS,
  GET_LIFERS_PRICE_POINTS_FAIL,
  SET_SELECTED_PRICE,
  GET_CURRENT_AND_NEXT_ROUND_DATES_REQUEST,
  GET_CURRENT_AND_NEXT_ROUND_DATES_SUCCESS,
  GET_CURRENT_AND_NEXT_ROUND_DATES_FAIL,
  GET_FRIEND_PRICE_POINTS_REQUEST,
  GET_FRIEND_PRICE_POINTS_SUCCESS,
  GET_FRIEND_PRICE_POINTS_FAIL,
  SIGNUP_WITH_FRIEND_REQUEST,
  SIGNUP_WITH_FRIEND_SUCCESS,
  SIGNUP_WITH_FRIEND_FAIL,
  SIGNUP_RESET_SUCCESS
} from '../constants/Signup.constants'

export const signUpReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case SIGNUP_REQUEST:
    case SIGNUP_WITH_FRIEND_REQUEST: {
      return {
        loading: true,
        error: false,
        successfulSignup: false
      }
    }
    case SIGNUP_SUCCESS:
    case SIGNUP_WITH_FRIEND_SUCCESS: {
      return {
        member: payload,
        error: false,
        successfulSignup: true
      }
    }
    case SIGNUP_RESET_SUCCESS: {
      return {
        ...state,
        successfulSignup: false
      }
    }
    case SIGNUP_RESET_ERROR: {
      return {
        ...state,
        error: false
      }
    }
    case SIGNUP_FAIL:
    case SIGNUP_WITH_FRIEND_FAIL: {
      return {
        loading: false,
        error: payload,
        successfulSignup: false
      }
    }
    default:
      return state
  }
}

export const checkMemberReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case CHECK_MEMBER_REQUEST:
    case CHECK_FRIEND_MEMBER_REQUEST: {
      return { ...state, loading: true }
    }
    case CHECK_MEMBER_SUCCESS: {
      return {
        ...state,
        loading: false,
        member: payload
      }
    }
    case CHECK_FRIEND_MEMBER_SUCCESS: {
      return {
        ...state,
        loading: false,
        friendMember: payload
      }
    }
    case CHECK_MEMBER_FAIL:
    case CHECK_FRIEND_MEMBER_FAIL: {
      return {
        loading: false
      }
    }
    default:
      return state
  }
}

export const lassoReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_LASSO_REQUEST: {
      return {
        loading: true
      }
    }
    case UPDATE_LASSO_SUCCESS: {
      return {
        loading: false,
        lasso: payload
      }
    }
    case UPDATE_LASSO_FAIL: {
      return {
        loading: false
      }
    }
    default:
      return state
  }
}

export const roundReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_NEXT_AVAILABLE_ROUND_REQUEST:
    case GET_ROUND_INFO_REQUEST:
    case GET_CURRENT_AND_NEXT_ROUND_DATES_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_NEXT_AVAILABLE_ROUND_SUCCESS: {
      return {
        ...state,
        round: payload,
        loading: false
      }
    }
    case GET_CURRENT_AND_NEXT_ROUND_DATES_SUCCESS: {
      return {
        ...state,
        currentAndNextRound: payload,
        loading: false
      }
    }
    case GET_ROUND_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        roundInfo: payload
      }
    }
    case GET_ROUND_INFO_FAIL:
    case GET_NEXT_AVAILABLE_ROUND_FAIL:
    case GET_CURRENT_AND_NEXT_ROUND_DATES_FAIL: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
}

export const signUpLoginReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case SIGNUP_LOGIN_REQUEST: {
      return {
        loading: true,
        error: false
      }
    }
    case SIGNUP_LOGIN_SUCCESS: {
      return {
        loading: false,
        error: false,
        memberLoggedIn: payload
      }
    }
    case SIGNUP_LOGIN_RESET_ERROR: {
      return {
        loading: false,
        error: false
      }
    }
    case SIGNUP_LOGIN_FAIL: {
      return {
        loading: false,
        error: true
      }
    }
    default:
      return state
  }
}

export const programsReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PRICE_POINTS_REQUEST:
    case GET_LIFERS_PRICE_POINTS_REQUEST:
    case GET_FRIEND_PRICE_POINTS_REQUEST: {
      return {
        ...state,
        loading: true,
        pricePointFail: false
      }
    }
    case GET_PRICE_POINTS_SUCCESS:
    case GET_LIFERS_PRICE_POINTS_SUCCESS: {
      const programsArray = Object.keys(payload).map((key) => {
        return payload[key]
      })
      return {
        ...state,
        loading: false,
        programs: programsArray
      }
    }
    case GET_FRIEND_PRICE_POINTS_SUCCESS: {
      const programsArray = Object.keys(payload).map((key) => {
        return payload[key]
      })
      return {
        ...state,
        loading: false,
        friendPrograms: programsArray
      }
    }
    case GET_PRICE_POINTS_FAIL:
    case GET_LIFERS_PRICE_POINTS_FAIL:
    case GET_FRIEND_PRICE_POINTS_FAIL: {
      return {
        ...state,
        loading: false,
        programs: undefined,
        pricePointFail: true
      }
    }
    case SET_SELECTED_PRICE: {
      return {
        ...state,
        selectedPrice: payload?.priceInDollars,
        isWeekly: payload?.isWeekly,
        GATotalCostInCents: payload?.GATotalCostInCents,
        isDiscounted: payload?.isDiscounted
      }
    }
    default:
      return state
  }
}

export const securityTokenReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_SECURITY_TOKEN_REQUEST: {
      return { loading: true }
    }
    case GET_SECURITY_TOKEN_SUCCESS: {
      return { loading: false, token: payload?.token }
    }
    case GET_SECURITY_TOKEN_FAIL: {
      return {
        loading: false,
        fail: true
      }
    }
    default:
      return state
  }
}

export const couponReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case VALIDATE_COUPON_REQUEST: {
      return {
        loading: true
      }
    }
    case VALIDATE_COUPON_SUCCESS: {
      return {
        loading: false,
        couponApplied: payload?.couponCode,
        couponSuccess: true
      }
    }
    case VALIDATE_COUPON_FAIL: {
      return {
        loading: false
      }
    }
    default:
      return state
  }
}
