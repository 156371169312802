export const LOW_INTENSITY = 'Low Intensity'
export const MEDIUM = 'Medium'
export const ADVANCED = 'Advanced'

const PUSH_UPS_AGAINST_WALL = {
  label: 'Push Ups (Against Wall)',
  difficulty: LOW_INTENSITY,
  inputs: [{ value: 'pushUpsAgainstWall', metric: 'rep' }]
}

const PUSH_UPS_KNEES = {
  label: 'Push Ups (Knees)',
  difficulty: MEDIUM,
  inputs: [{ value: 'pushUpsKnees', metric: 'rep' }]
}

const PUSH_UPS_TOES = {
  label: 'Push Ups (Toes)',
  difficulty: ADVANCED,
  inputs: [{ value: 'pushUpsToes', metric: 'rep' }]
}

const PLANK_ELEVATED = {
  label: 'Plank (Elevated)',
  difficulty: LOW_INTENSITY,
  inputs: [
    { value: 'plankElevatedMin', metric: 'min' },
    { value: 'plankElevatedSec', metric: 'sec' }
  ]
}

const PLANK_KNEES = {
  label: 'Plank (Knees)',
  difficulty: MEDIUM,
  inputs: [
    { value: 'plankKneesMin', metric: 'min' },
    { value: 'plankKneesSec', metric: 'sec' }
  ]
}

const PLANK_TOES = {
  label: 'Plank (Toes)',
  difficulty: ADVANCED,
  inputs: [
    { value: 'plankToesMin', metric: 'min' },
    { value: 'plankToesSec', metric: 'sec' }
  ]
}

const THREE_KM_CYCLE = {
  label: '3km Cycle Time Trial',
  difficulty: LOW_INTENSITY,
  inputs: [
    { value: 'threeKmCycleTimeTrialMin', metric: 'min' },
    { value: 'threeKmCycleTimeTrialSec', metric: 'sec' }
  ]
}

const ONE_KM_TRIAL = {
  label: '1km Time Trial',
  difficulty: MEDIUM,
  inputs: [
    { value: 'oneKmTimeTrialMin', metric: 'min' },
    { value: 'oneKmTimeTrialSec', metric: 'sec' }
  ]
}

const TWELVE_MINUTE_RUN = {
  label: '12 Minute Run Test',
  difficulty: ADVANCED,
  inputs: [{ value: 'twelveMinuteRunTestMeters', metric: 'm' }]
}

const SIT_AND_REACH = {
  label: 'Sit & Reach',
  inputs: [{ value: 'sitAndReach', metric: 'cm' }]
}

const WALL_SIT = {
  label: 'Wall Sit',
  inputs: [
    { value: 'wallSitMin', metric: 'min' },
    {
      value: 'wallSitSec',
      metric: 'sec'
    }
  ]
}

export const upperBodyStrengthTests = {
  type: 'upper_body',
  category: 'Upper Body Strength:',
  tests: [PUSH_UPS_AGAINST_WALL, PUSH_UPS_KNEES, PUSH_UPS_TOES],
  defaultTest: PUSH_UPS_TOES
}

export const coreStrengthTests = {
  type: 'core',
  category: 'Core Strength:',
  tests: [PLANK_ELEVATED, PLANK_KNEES, PLANK_TOES],
  defaultTest: PLANK_TOES
}

export const aerobicCapacityTests = {
  type: 'aerobic',
  category: 'Aerobic Capacity:',
  tests: [THREE_KM_CYCLE, ONE_KM_TRIAL, TWELVE_MINUTE_RUN],
  defaultTest: TWELVE_MINUTE_RUN
}

export const flexibilityTests = {
  category: 'Flexibility:',
  tests: [SIT_AND_REACH],
  defaultTest: SIT_AND_REACH
}

export const lowerBodyStrengthTests = {
  category: 'Lower Body Strength:',
  tests: [WALL_SIT],
  defaultTest: WALL_SIT
}
