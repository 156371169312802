import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ChooseProgramCard from './ChooseProgramCard'
import { programCardData } from './CardData'

const ChooseProgramType = ({ selectedProgramType, setSelectedProgramType }) => {
  const classes = useStyles()
  const PROGRAM_CARD_DATA = programCardData()
  return (
    <div className={classes.programTypeOptions}>
      <ChooseProgramCard
        name={PROGRAM_CARD_DATA.ROUND.NAME}
        color={PROGRAM_CARD_DATA.ROUND.COLOR}
        bestForHeading={PROGRAM_CARD_DATA.ROUND.BEST_FOR_HEADING}
        heading={PROGRAM_CARD_DATA.ROUND.HEADING}
        benefitsArray={PROGRAM_CARD_DATA.ROUND.BENEFITS_ARRAY}
        icon={PROGRAM_CARD_DATA.ROUND.ICON}
        activeCard={selectedProgramType}
        setActiveCard={setSelectedProgramType}
        dataTestId='singleProgram'
      />
      <ChooseProgramCard
        name={PROGRAM_CARD_DATA.LIFESTYLE.NAME}
        color={PROGRAM_CARD_DATA.LIFESTYLE.COLOR}
        heading={PROGRAM_CARD_DATA.LIFESTYLE.HEADING}
        bestForHeading={PROGRAM_CARD_DATA.LIFESTYLE.BEST_FOR_HEADING}
        benefitsArray={PROGRAM_CARD_DATA.LIFESTYLE.BENEFITS_ARRAY}
        icon={PROGRAM_CARD_DATA.LIFESTYLE.ICON}
        activeCard={selectedProgramType}
        setActiveCard={setSelectedProgramType}
        dataTestId='lifestyleProgram'
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  programTypeOptions: {
    ...theme.CSS.center,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    margin: '1.5rem 0'
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  [theme.breakpoints.down('xs')]: {
    programTypeOptions: {
      display: 'flex',
      flexDirection: 'column'
    }
  }
}))

ChooseProgramType.propTypes = {
  selectedProgramType: PropTypes.string,
  setSelectedProgramType: PropTypes.func.isRequired
}

export default ChooseProgramType
