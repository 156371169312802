import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import NextTaskLink from '../NextTaskLink'

const MeasureUpThighsArms = ({ nextTaskClick, nextTask }) => {
  return (
    <div>
      <TaskContentSubHeader title='Measure Thighs/Arms' />
      <p>
        <b>Thighs:</b> To measure your right thigh, begin with placing your right foot on a bench or chair. Now find
        your measurement starting point by placing the tip of the measuring tape at the centre of your bent knee, along
        your thigh bone and to the widest point of your thigh. This is your measurement starting point! Record this
        distance and make sure you use the same distance when updating your thigh measurement throughout the 12 weeks.
        Now measure around your thigh, and record that number.
      </p>
      <p>
        <b>Arms:</b> Measure up from four fingers to the crease of your elbow. This is your start point. Look for a
        freckle or something as a marker or you can even use a pen to mark the spot. Wrap the tape measure around your
        bicep and lower your arm by your side, keeping the tape measure firm against your skin. It&apos;s a tricky one
        to do on your own, so if you can get help - even better! Measure your right arm.
      </p>
      <NextTaskLink link='Take before photo' onClick={() => nextTaskClick(nextTask)} />
    </div>
  )
}

MeasureUpThighsArms.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default MeasureUpThighsArms
