import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormHelperText, makeStyles, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  getLifersPricePoints,
  setSelectedPrice,
  validateCoupon
} from '../../../../../redux/actions/Signup.actions'
import SelectPlanBanners from '../SelectPlanBanners'
import LifestyleTabHeader from './LifestyleTabHeader'
import useQuery from '../../../../hooks/UseQuery'
import CouponInput from '../CouponInput'

const SelectPlanLifestyleOnly = ({ formik, submissionAttempt }) => {
  const dispatch = useDispatch()
  const { programs, loading } = useSelector((state) => state.signupPrograms)
  const [activeBanner, setActiveBanner] = useState(null)
  const classes = useStyles()
  const theme = useTheme()
  const { utm_coupon: couponCode } = useQuery(['utm_coupon'])

  const onValidCoupon = () => dispatch(getLifersPricePoints(couponCode))
  const onInvalidCoupon = () => dispatch(getLifersPricePoints())

  // Get price points on component load:
  useEffect(() => {
    if (!loading && !programs) {
      // Check if coupon code exists:
      if (couponCode)
        dispatch(validateCoupon(couponCode, onValidCoupon, onInvalidCoupon))
      else {
        dispatch(getLifersPricePoints())
      }
    }
  }, [couponCode])

  const orderedPrograms = useMemo(() => {
    if (programs)
      return programs.slice().sort((a, b) => a.billingCycles - b.billingCycles)
  }, [programs])

  const selectProgram = (program) => {
    const GATotalCostInCents = program?.coupon
      ? program.coupon.minAmount
      : program.minAmount

    // Calculate values to show on UI and send to Google Tag Manager (GTM):
    const price = program?.coupon
      ? program.coupon.discountPriceInCents
      : program.priceInCents

    const priceInDollars = (price / 100).toFixed(2)
    programSelectHandler(program.handle, {
      productHandle: program.chargifyProductHandle,
      paymentPlan: program._id,
      billingOption: program.billingOption,
      priceInDollars,
      GATotalCostInCents,
      couponCode: program?.coupon?.code
    })
  }

  // Re-select selected program on re-render (couponInput blur):
  useEffect(() => {
    if (orderedPrograms && activeBanner) {
      const selectedProgram = orderedPrograms.find(
        (e) => e.handle === activeBanner
      )
      selectProgram(selectedProgram)
    }
  }, [orderedPrograms, activeBanner])

  // Set active program if no program has been set (first render):
  useEffect(() => {
    if (orderedPrograms && !activeBanner) {
      const lifestyleOption = orderedPrograms && orderedPrograms[0]
      if (!lifestyleOption) return
      selectProgram(lifestyleOption)
    }
  }, [orderedPrograms, activeBanner])

  const handleChange = async (name, value) => {
    await formik.setFieldValue(name, value)
    formik.setFieldTouched(name, true)
  }

  // On program option select, change chosen program for styling & update formik values with relevant program details:
  const programSelectHandler = (value, paymentObj) => {
    const isWeekly = value.includes('recurring')
    const isDiscounted = paymentObj?.couponCode

    setActiveBanner(value)
    // Save selected price in signupPrograms reducer to display price on form submit button:
    dispatch(
      setSelectedPrice(
        paymentObj.priceInDollars,
        paymentObj.GATotalCostInCents,
        isWeekly,
        isDiscounted
      )
    )
    handleChange('productHandle', paymentObj.productHandle)
    handleChange('paymentPlan', paymentObj.paymentPlan)
    handleChange('couponCode', paymentObj?.couponCode)
    handleChange('billingOption', paymentObj.billingOption)
  }

  const error = formik.errors.productHandle && submissionAttempt

  return (
    <div className={classes.fieldsContainer}>
      <LifestyleTabHeader />
      <SelectPlanBanners
        values={orderedPrograms}
        activeBanner={activeBanner}
        bannerSelectHandler={programSelectHandler}
        activeColor={theme.palette.signup.purple}
      />
      {error && (
        <FormHelperText className={classes.error}>
          You must select a payment plan.
        </FormHelperText>
      )}
      <CouponInput formik={formik} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: '-1rem',
    color: '#EF5353'
  }
}))

SelectPlanLifestyleOnly.propTypes = {
  formik: PropTypes.object.isRequired,
  submissionAttempt: PropTypes.bool
}

export default SelectPlanLifestyleOnly
