export const SECTION = {
  HOW: 'How this works',
  WEIGHING_YOURSELF: 'Weighing Yourself',
  MEASURE_CHEST_WAIST_HIPS: 'Measure Chest/Waist/Hips',
  MEASURE_THIGHS_ARMS: 'Measure Thighs/Arms',
  BEFORE_PHOTO: 'Before Photo'
}

export const measureUpData = [
  SECTION.HOW,
  SECTION.WEIGHING_YOURSELF,
  SECTION.MEASURE_CHEST_WAIST_HIPS,
  SECTION.MEASURE_THIGHS_ARMS,
  SECTION.BEFORE_PHOTO
]
