import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import ActionButton from '../../ActionButton'
import { Button, makeStyles } from '@material-ui/core'
import TaskVideo from '../../TaskVideo'
import { useSelector } from 'react-redux'
import { getPreSeasonTask } from '../../../../../helpers/GetPreSeasonTask'
import { MEASURE_UP } from '../../TaskData'
import Modal from '../../../../../components/Modal'
import { Link } from 'react-router-dom'
import MeasureUpPhotoUpload from './MeasureUpPhotoUpload'
import navigateToGoSite from '../../../../../helpers/NavigateToGoSite'

const MeasureUpBeforePhoto = ({ formik, onImageUpload }) => {
  const { loading } = useSelector((state) => state.newPreSeason)
  const [showModal, setShowModal] = useState(false)
  const isComplete = getPreSeasonTask(MEASURE_UP)
  const actionButtonTitle = isComplete ? 'Update' : 'Finish'
  const finishMeasureUpButtonClickHandler = () => {
    formik.submitForm()
  }
  const classes = useStyles()
  return (
    <div>
      <TaskContentSubHeader title='Before Photo' />
      <TaskVideo videoId='2520847619001' needsCenterAdjust={true} />
      <p>
        A &apos;before&apos; and &apos;after&apos; photo is a good way for you to really &apos;see&apos; your
        transformation. Believe me, you will want a record of your transformation!
      </p>
      <p>To take your &apos;before&apos; photo:</p>
      <ul>
        <li>Girls - wear form fitting leggings and gym shirt or singlet.</li>
        <li>Boys - wear form fitting shorts and a singlet</li>
        <li>
          <span
            className={classes.photoTips}
            onClick={() => {
              setShowModal(true)
            }}
          >
            Show more photo tips
          </span>
        </li>
      </ul>
      <MeasureUpPhotoUpload onImageUpload={onImageUpload} />
      <div className={classes.buttonContainer}>
        <ActionButton title={actionButtonTitle} onButtonClick={finishMeasureUpButtonClickHandler} loading={loading} />
      </div>
      <Modal
        show={showModal}
        onCancel={() => {
          setShowModal(false)
        }}
      >
        <div className={classes.modalContainer}>
          <div className={classes.modalHeader}>Photo Tips</div>
          <div className={classes.modalBody}>
            <p>
              These tips will help you to take the best before and after pics and ensure your entry into the
              competition. Remember that other members cannot see your before and after photos.
            </p>
            <h2 className={classes.subHeader}>Clothing</h2>
            <ul>
              <li>Your photo needs to include a current copy of a major magazine or newspaper publication.</li>
              <li>Wear the same type of clothing in the “before” and “after” photos.</li>
              <li>Male: Wear form-fitting shorts and gym shirt/singlet top.</li>
              <li>Female: Wear form-fitting leggings and gym shirt/singlet top.</li>
            </ul>
            <h2 className={classes.subHeader}>Photos</h2>
            <ul>
              <li>Only digital photos can be accepted.</li>
              <li>Take your photos against a plain background.</li>
              <li>Please include your whole body, from head to toe.</li>
              <li>
                Try to take up most of the photo. If you are a speck in the distance it’s harder to see your results.
              </li>
              <li>
                Please stand with your arms at your sides in the photos holding the magazine just to the side of your
                body.
              </li>
              <li>
                No other members will be able to see your photos when you post them on the{' '}
                <a href={navigateToGoSite('my-stats')}>My Stats</a> page or the{' '}
                <Link to='/pre-season/tasks/fitness-test-and-measure-up'>Measure Up</Link>&nbsp; Task page.
              </li>
              <li>
                If you would like to post your photos in the Member Zone as well, that’s fine as you might want others
                to witness your transformation.
              </li>
              <li>Please don’t upload photos larger than 2Mb.</li>
              <li>
                Please make sure that the photos you upload are ‘portrait’ rather than ‘landscape’ – that is, that
                they’re taller than they are wide.
              </li>
            </ul>
          </div>
          <div className={classes.modalFooter}>
            <Button className={classes.closeButton} onClick={() => setShowModal(false)}>
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    width: '900px',
    height: '506px',
    margin: 'auto',
    marginLeft: '-65px'
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '35rem'
  },
  modalBody: {
    padding: '1rem'
  },
  modalHeader: {
    ...theme.CSS.center,
    fontSize: '1.3rem',
    borderBottom: `1px solid ${theme.palette.greys.light}`,
    padding: '.5rem'
  },
  modalFooter: {
    width: '100%',
    height: '4rem',
    backgroundColor: theme.palette.greys.light,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    borderTop: `1px solid ${theme.palette.greys.medium}`
  },
  closeButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    backgroundColor: '#e6f2fd',
    marginRight: '1rem',
    '&:hover': {
      backgroundColor: '#7FD9F8'
    }
  },
  subHeader: {
    margin: '0'
  },
  buttonContainer: {
    marginTop: '2rem'
  },
  photoTips: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    '&:hover': {
      color: theme.palette.signup.purple,
      cursor: 'pointer'
    }
  }
}))

MeasureUpBeforePhoto.propTypes = {
  formik: PropTypes.object.isRequired,
  onImageUpload: PropTypes.func.isRequired
}

export default MeasureUpBeforePhoto
