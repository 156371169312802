import React from 'react'
import PropTypes from 'prop-types'
import WelcomeStepsFooter from '../WelcomeStepsFooter'

const WelcomeStepOneSubmit = ({ formik, setSubmissionAttempt, loading }) => {
  const submitHandler = () => {
    setSubmissionAttempt(true)

    // Dispatch profile update here: https://api.dev.cloud.12wbt.com/v1/members/490504/profile
    formik.submitForm()
  }
  return <WelcomeStepsFooter onSubmit={submitHandler} buttonTitle='Continue' loading={loading} />
}

WelcomeStepOneSubmit.propTypes = {
  formik: PropTypes.object.isRequired,
  setSubmissionAttempt: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default WelcomeStepOneSubmit
