import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getProfile } from '../../redux/actions/Member.actions'

const loadMemberProfile = () => {
  const dispatch = useDispatch()
  const memberSummary = useSelector((state) => state.memberSummary.memberSummary)
  const { profile, loading } = useSelector((state) => state.profile)

  useEffect(() => {
    if (memberSummary && !profile && !loading) dispatch(getProfile(memberSummary.id))
  }, [memberSummary])
}

export default loadMemberProfile
