import React from 'react'
import { makeStyles } from '@material-ui/core'

import mainLogo from '../../assets/img/logos/12wbtlogo.png'

import { useSelector } from 'react-redux'

const SignUpErrorModal = () => {
  const { error } = useSelector((state) => state.signup)
  const classes = useStyles()

  const displayError = () => {
    if (error instanceof Array) {
      return error?.map((el, index) => {
        return <div key={index}>{el}</div>
      })
    } else if (error?.message) {
      return <div>{error.message}</div>
    }
    return <div>{error}</div>
  }

  return (
    <div className={classes.container}>
      <img src={mainLogo} alt='12WBT Logo' className={classes.logo} />
      <span>Something went wrong during the signup process. Please contact support@12wbtsupport.com</span>
      <br />
      <span>{error && displayError()}</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.CSS.center,
    padding: '2rem',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center'
  },
  logo: {
    width: '12rem',
    margin: '1rem auto'
  }
}))

export default SignUpErrorModal
