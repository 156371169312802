import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Snackbar, Slide } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

function SlideTransition(props) {
  return <Slide {...props} direction='up' />
}

const Alert = () => {
  const alerts = useSelector((state) => state.alert)
  return (
    <Fragment>
      {alerts !== null &&
        alerts.length > 0 &&
        alerts.map((alert) => (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={true}
            key={alert.id}
            TransitionComponent={SlideTransition}
          >
            <MuiAlert elevation={6} variant='filled' severity={alert.alertType}>
              {alert.msg}
            </MuiAlert>
          </Snackbar>
        ))}
    </Fragment>
  )
}

export default Alert
