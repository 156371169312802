import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import TabItem from '../../fields/TabItem'
import { PLAN_TAB_TYPES } from '../../SignUpData'

const LifestyleTabHeader = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <TabItem
        value={PLAN_TAB_TYPES.LIFESTYLE.value}
        title={PLAN_TAB_TYPES.LIFESTYLE.name}
        activeColor={theme.palette.signup.primary}
        activeTab={PLAN_TAB_TYPES.LIFESTYLE.value}
        oneTab={true}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
    marginTop: '1rem',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.greys.mediumLight}`
  }
}))

export default LifestyleTabHeader
