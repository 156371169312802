import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

function applyOpacity(color) {
  const opacity = 0.2
  return color.substring(0, color.length - 1) + `, ${opacity})`
}

const HealthyEatingBanner = ({ title, description, color, backgroundColor }) => {
  const classes = useStyles({ color, backgroundColor })
  return (
    <div className={classes.container}>
      <span className={classes.bannerTitle}>{title}</span>
      <span className={classes.bannerDescription}>{description}</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.CSS.center,
    flexDirection: 'column',
    color: (props) => props.color,
    width: '100%',
    padding: '0rem 2rem',
    paddingBottom: '1rem',
    backgroundColor: (props) =>
      props.backgroundColor ? applyOpacity(props.backgroundColor) : applyOpacity(props.color),
    margin: '2rem 0',
    textAlign: 'center'
  },
  bannerTitle: {
    fontWeight: 900,
    fontSize: '1.3rem',
    margin: '1rem auto'
  },
  bannerDescription: {
    textAlign: 'center',
    fontWeight: 500
  }
}))

HealthyEatingBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string
}

export default HealthyEatingBanner
