import React, { useState } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'

import Modal from '../../../components/Modal'
import BrightCovePlayer from '../../../components/BrightCovePlayer'
import preSeasonHeaderImage from '../../../assets/img/pre_season/PreSeasonHeaderImage.jpg'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'

import { useSelector } from 'react-redux'

const PreSeasonWelcomeBanner = () => {
  const [showModal, setShowModal] = useState(false)
  const { roundInfo } = useSelector((state) => state.round)
  const { isReturnMember, member } = useSelector((state) => state.member)
  const { currentSubscription } = useSelector((state) => state.currentSubscription)

  let welcomeVideoId = currentSubscription?.lifers ? '6261652382001' : '6262360250001'
  if (isReturnMember) {
    welcomeVideoId = currentSubscription?.lifers ? '6261652907001' : '6262360420001'
  }

  const classes = useStyles({ preSeasonHeaderImage })
  return (
    <>
      <div className={classes.background}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={6}>
            <div className={classes.messageContainer}>
              <span className={classes.date}>{roundInfo?.name}</span>
              {currentSubscription?.lifers ? (
                <>
                  <h2 className={classes.subHeader}>Welcome to Lifestyle {member?.first_name}</h2>
                  <span className={classes.description}>
                    We are so excited you’re here! We are officially in Pre-Season, which means it’s time to get
                    organised and get you prepared for your transformation. Pre-Season is all about setting yourself up
                    for success.
                    <br></br>
                    <br></br>
                    Twelve months from now I want you to look in the mirror and love what you see. Your first 12 week
                    challenge for the year is about to begin and I can’t wait to see what you achieve!
                  </span>
                </>
              ) : isReturnMember ? (
                <>
                  <h2 className={classes.subHeader}>Welcome back {member?.first_name} </h2>
                  <span className={classes.description}>
                    We are so excited you’re here!
                    <br></br>
                    <br></br>
                    We are officially in Pre-Season, which means it’s time to get organised and get you prepared for
                    your transformation. As you know it’s all about setting yourself up for success.
                    <br></br>
                    <br></br>
                    It’s time to make your health a priority and transform into the best version of yourself!
                  </span>
                </>
              ) : (
                <>
                  <h1>Hi {member?.first_name},</h1>
                  <h2 className={classes.subHeader}>Welcome to the team!</h2>
                  <span className={classes.description}>
                    We are so excited you are here! We are officially in Pre-Season, which means it’s time to get
                    organised and get you prepared for your transformation. It’s all about setting yourself up for
                    success.
                    <br></br>
                    <br></br>
                    Its time to make your health a priority and TRANSFORM into the best version of yourself!
                  </span>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.headerImageContainer}>
              <div className={classes.triangleOverlay}></div>
              <Button className={classes.videoButton} onClick={() => setShowModal(true)}>
                <PlayCircleFilledWhiteIcon />
                <span>Welcome from Mish</span>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Modal
        show={showModal}
        onCancel={() => {
          setShowModal(false)
        }}
      >
        <div className={classes.modalVideoContainer}>
          <BrightCovePlayer videoId={welcomeVideoId} />
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: '95%',
    ...theme.CSS.center,
    overflow: 'hidden',
    margin: 'auto'
  },
  background: {
    backgroundColor: 'white'
  },
  messageContainer: {
    margin: '1rem 0 1rem 0rem',
    marginTop: '2rem',
    '& h1': {
      color: theme.palette.signup.purple,
      fontSize: '60px',
      fontWeight: 800,
      margin: '2rem 0'
    }
  },
  date: {
    color: theme.palette.greys.main,
    fontWeight: 'bold'
  },
  headerImageContainer: {
    position: 'relative',
    backgroundImage: (props) => `url(${props.preSeasonHeaderImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '400px',
    ...theme.CSS.center
  },
  subHeader: {
    fontSize: '36px',
    margin: '1.5rem auto'
  },
  triangleOverlay: {
    position: 'absolute',
    borderTop: '450px solid white',
    borderRight: '100px solid transparent',
    width: 0,
    height: 0,
    left: 0
  },
  description: {
    fontSize: '20px'
  },
  videoButton: {
    backgroundColor: '#5164E9',
    width: '231px',
    height: '50px',
    ...theme.CSS.center,
    textTransform: 'none',
    border: '1px solid white',
    '& span': {
      color: 'white',
      marginLeft: '.5rem'
    },
    '&:hover': {
      backgroundColor: 'white',
      borderColor: '#5164E9',
      '& span': {
        color: '#5164E9'
      }
    }
  },
  modalVideoContainer: {
    width: '53.3rem',
    height: '30rem',
    padding: '1rem',
    margin: 'auto'
  },
  [theme.breakpoints.up(1400)]: {
    gridContainer: {
      maxWidth: '90%',
      margin: '0 auto'
    },
    headerImageContainer: {
      maxHeight: '410px'
    }
  },
  [theme.breakpoints.down('lg')]: {
    modalVideoContainer: {
      width: '35.5rem',
      height: '20rem',
      padding: '1rem',
      margin: 'auto'
    }
  },
  [theme.breakpoints.down('md')]: {
    messageContainer: {
      margin: '1rem 1rem 1rem 0',
      '& h1': {
        fontSize: '2.5rem'
      }
    },
    subHeader: {
      fontSize: '27px'
    },
    description: {
      fontSize: '16px',
      lineHeight: '24px'
    },
    headerImageContainer: {
      marginTop: '1rem',
      height: '350px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    modalVideoContainer: {
      width: '26.6rem',
      height: '15rem'
    },
    subHeader: {
      margin: '.5rem auto'
    }
  },
  [theme.breakpoints.down('xs')]: {
    messageContainer: {
      margin: 0,
      padding: '1rem',
      '& h1': {
        margin: '1rem 0',
        fontSize: '1.8rem'
      }
    },
    modalVideoContainer: {
      width: '21.3rem',
      height: '12rem'
    },
    gridContainer: {
      padding: '0'
    },
    triangleOverlay: {
      display: 'none'
    },
    subHeader: {
      fontSize: '24px'
    },
    headerImageContainer: {
      height: '250px'
    }
  }
}))

export default PreSeasonWelcomeBanner
