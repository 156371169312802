import {
  PROGRESS_STEP_TWO,
  REGRESS_STEP_TWO,
  GET_RECOMMENDED_PROGRAMS_REQUEST,
  GET_RECOMMENDED_PROGRAMS_SUCCESS,
  GET_RECOMMENDED_PROGRAMS_FAIL
} from '../constants/WelcomeSteps.constants'

export const stepTwoReducer = (state = { stepTwoProgress: 1, recommendedPrograms: [] }, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_RECOMMENDED_PROGRAMS_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_RECOMMENDED_PROGRAMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        recommendedPrograms: payload
      }
    }
    case GET_RECOMMENDED_PROGRAMS_FAIL: {
      return {
        ...state,
        loading: false,
        recommendedPrograms: []
      }
    }
    case PROGRESS_STEP_TWO: {
      const newStepTwoProgress = state.stepTwoProgress + 1
      return {
        ...state,
        stepTwoProgress: newStepTwoProgress
      }
    }
    case REGRESS_STEP_TWO: {
      let newStepTwoProgress = state.stepTwoProgress - 1
      if (newStepTwoProgress < 1) newStepTwoProgress = 1
      return {
        ...state,
        stepTwoProgress: newStepTwoProgress
      }
    }
    default:
      return state
  }
}

export default stepTwoReducer
