import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core'
import SignupTopRightBanner from '../../assets/img/signup/SignupTopRightBanner.png'
// import SignupHeaderBackground from '../../assets/img/signup/SignupHeaderBackground.jpg'
// import BellaAndMish from '../../assets/img/signup/BellaAndMish.png'
import MishHeroImageMob from '../../assets/img/signup/MishHeroMob.png'
import MishHeroImage from '../../assets/img/signup/MishHeroImage.png'
// import MishHeroImage2 from '../../assets/img/signup/MishHero2.png'
// import MishHeroImage5 from '../../assets/img/signup/MishHero5.png'
import NewMishSignupHeaderImg from '../../assets/img/signup/MIchelle Bridges_12WBT_Purchase Page_Desktop.png'
import NewMishSignupMobHeaderImg from '../../assets/img/signup/Michelle Bridges_12WBT_Purchase Page.png'
import { useSelector } from 'react-redux'

const SignUpHeader = () => {
  const { couponApplied, couponSuccess } = useSelector((state) => state.coupon)
  const classes = useStyles()
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    // Initial check
    setIsMobile(mediaQuery.matches);

    // Cleanup event listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <div>
      <div className={classes.notificationBar}>
        Pre-Season Is Now&nbsp;<a href="https://12wbt.com/community/why-pre-season-works/" style={{color: '#043B6C'}}>Open</a>
      </div>
      <img src={isMobile ? NewMishSignupMobHeaderImg : NewMishSignupHeaderImg} style={{width: '100%'}} />
      {couponSuccess && couponApplied === '10OFF' && (
        <img
          className={classes.topRightBanner}
          src={SignupTopRightBanner}
          alt='10% off Banner'
        />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  notificationBar: {
    backgroundColor: '#f5f5f5',
    minHeight: '53px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#4F4F4F',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '22px',
    fontWeight: '400',
    lineHeight: '1.5em'
  },
  container: {
    backgroundImage: `url(${MishHeroImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '681px',
    position: 'relative',
    // overflow: 'hidden',
    animation: 'fadeInAnimation ease 3s',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    ...theme.CSS.center
  },
  bellaAndMishImg: {
    margin: 'auto',
    height: '675px',
    position: 'absolute',
    bottom: '-10rem'
  },
  MishHeroImage: {
    margin: 'auto',
    height: '675px',
    position: 'absolute',
    // bottom: '-10rem'
    width: '100%'
  },
  topRightBanner: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '320px'
  },
  motivationContainer: {
    ...theme.CSS.center,
    padding: '.5rem 1rem',
    color: 'black',
    bottom: '2rem',
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: '1.4rem',
    display: 'none',
    marginBottom: '-150px'
  },
  motivationTextLg: {
    // color: 'white',
    // fontSize: '3.2rem',
    // marginLeft: '800px',
    // marginTop: '-75px',
    zIndex: '999',
    fontFamily: 'HandWritten',
    fontSize: '210px',
    fontWeight: '400',
    lineHeight: '45px',
    letterSpacing: '0%',
    textAlign: 'center',
    backgroundImage: 'linear-gradient(89.22deg, #FEF8B9 39.18%, #E1C97A 58.3%, #C69E3F 69.52%)',
    backgroundClip: 'text',
    color: 'transparent',
    height: '350px',
    marginLeft: '438px',
    marginTop: '-123px'
  },
  firstTextLine: {
    marginTop: '68px'
  },
  secondTextLine: {
    marginTop: '100px',
    marginLeft: '361px'
  },
  bannerMsg: {
    fontWeight: '400',
    fontFamily: 'Poppins',
    fontSize: '36px',
    color: 'rgba(41, 37, 37, 1)',
    width: '445px',
    marginTop: '158px',
    display: 'inline-block',
    marginLeft: '372px',
    lineHeight: '45px'
  },
  rectangleBar: {
    backgroundColor: 'rgb(255 255 255 / 50%)',
    display: 'inline-block',
    height: '50px'
  },
  msgText: {
    position: 'relative',
    top: '-20px'
  },
  small: {
    display: 'none',
    marginTop: '148px'
  },
  large: {
    display: 'block'
  },
  joinDiv: {
    fontFamily: 'HandWritten',
    fontSize: '130px',
    // lineHeight: '45px',
    letterSpacing: '0%',
    textAlign: 'center',
    backgroundImage: 'linear-gradient(89.22deg, #FEF8B9 24.18%, #E1C97A 58.3%, #C69E3F 69.52%)',
    backgroundClip: 'text',
    color: 'transparent',
    fontWeight: '400'
  },
  motivationContainerText: {
    backgroundColor: 'rgb(255 255 255 / 50%)',
    display: 'inline-block',
    height: '50px',
    fontFamily: 'Poppins',
    fontSize: '25px',
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: '30px'
  },
  [theme.breakpoints.down(1341)]: {
    bannerMsg: {
      // marginLeft: '60px'
    }
  },
  [theme.breakpoints.down(1251)]: {
    motivationTextLg: {
      fontSize: '200px'
    },
    bannerMsg: {
      width: '425px',
      fontSize: '33px',
      marginLeft: '355px'
    }
  },
  [theme.breakpoints.down(1223)]: {
    motivationTextLg: {
      fontSize: '190px'
    },
    bannerMsg: {
      fontSize: '31px',
      width: '410px'
    }
  },
  [theme.breakpoints.down(1203)]: {
    motivationTextLg: {
      fontSize: '180px'
    },
    bannerMsg: {
      fontSize: '29px',
      width: '390px',
      marginLeft: '319px'
    }
  },
  [theme.breakpoints.down(1189)]: {
    motivationTextLg: {
      fontSize: '170px'
    }
  },
  [theme.breakpoints.down(1165)]: {
    // container: {
    //   backgroundImage: `url(${MishHeroImage2})`
    // },
    motivationTextLg: {
      fontSize: '141px',
      marginLeft: '565px',
      lineHeight: '0px'
    },
    bannerMsg: {
      fontSize: '21px',
      width: '250px',
      marginLeft: '293px',
      marginTop: '240px'
    },
    secondTextLine: {
      marginLeft: '261px'
    }
  },
  [theme.breakpoints.down(1128)]: {
    motivationTextLg: {
      fontSize: '131px'
    }
  },
  [theme.breakpoints.down(1110)]: {
    motivationTextLg: {
      fontSize: '125px',
      marginLeft: '545px'
    },
    bannerMsg: {
      width: '230px',
      marginLeft: '267px',
      fontSize: '19px'
    }
  },
  [theme.breakpoints.down(1065)]: {
    // container: {
    //   backgroundImage: `url(${MishHeroImage5})`
    // },
    motivationTextLg: {
      display: 'none'
    },
    motivationContainer: {
      display: 'block',
      marginBottom: '-30px'
    }
  },
  [theme.breakpoints.down(950)]: {

  },
  [theme.breakpoints.down(767)]: {

  },
  [theme.breakpoints.down(635)]: {
    motivationTextLg: {
      display: 'none'
    },
    motivationContainer: {
      display: 'block'
    },
    small: {
      display: 'block'
    },
    large: {
      display: 'none'
    }
  },
  [theme.breakpoints.down(606)]: {
    motivationContainerText: {
      height: '60px'
    }
  },
  [theme.breakpoints.down('md')]: {
    container: {
      // height: '777px'
    },
    topRightBanner: {
      width: '200px'
    },
    // motivationTextLg: {
    //   fontSize: '2rem',
    //   marginLeft: '430px'
    // },
    bellaAndMishImg: {
      height: '550px'
    },
    MishHeroImage: {
      height: '550px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    notificationBar: {
      fontSize: '18px'
    },
    // container: {
    //   height: '300px'
    // },
    motivationTextLg: {
      display: 'none'
    },
    motivationContainer: {
      display: 'block'
    },
    topRightBanner: {
      width: '150px'
    },
    bellaAndMishImg: {
      height: '470px'
    },
    MishHeroImage: {
      height: '470px'
    },
    small: {
      display: 'block'
    },
    large: {
      display: 'none'
    }
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      backgroundImage: `url(${MishHeroImageMob})`,
      height: '460px !important'
    },
    // container: {
    //   height: '250px'
    // },
    topRightBanner: {
      width: '170px'
    },
    bellaAndMishImg: {
      height: '400px'
    },
    MishHeroImage: {
      height: '400px'
    }
  },
  [theme.breakpoints.down(434)]: {
    joinDiv: {
      width: '405px'
    }
  }
}))

export default SignUpHeader
