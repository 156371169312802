export const NUT_OUT_YOUR_NUTRITION = 'nut-out-your-nutrition'
export const ORGANISE_AND_DIARISE = 'organise-and-diarise'
export const INTRODUCE_YOURSELF = 'introduce-yourself'
export const SET_YOURSELF_UP_FOR_SUCCESS = 'set-yourself-up-for-success'
export const FITNESS_TEST_AND_MEASURE_UP = 'fitness-test-and-measure-up'
export const FITNESS_TEST = 'Fitness Test'
export const MEASURE_UP = 'Measure Up'

export const HEADER_TABS = {
  LEARN: 'Learn',
  DO_THE_TASK: 'Do the task'
}

export const tasks = {
  [NUT_OUT_YOUR_NUTRITION]: {
    header: 'Nut Out Your Nutrition',
    intro:
      'Nutrition is everything when it comes to our health! In this task you’ll learn how to get your kitchen prepped, clear out your pantry and fridge, as well as how to shop smart at the supermarket.',
    videoId: '',
    moreTasks: [ORGANISE_AND_DIARISE, INTRODUCE_YOURSELF, SET_YOURSELF_UP_FOR_SUCCESS, FITNESS_TEST_AND_MEASURE_UP]
  },
  [ORGANISE_AND_DIARISE]: {
    header: 'Organise And Diarise',
    intro:
      'Diarising and planning is the best way to guarantee you’ll get your workouts, meals and shopping done. In this task we’ll teach you how to plan smart.',
    videoId: '',
    moreTasks: [NUT_OUT_YOUR_NUTRITION, INTRODUCE_YOURSELF, SET_YOURSELF_UP_FOR_SUCCESS, FITNESS_TEST_AND_MEASURE_UP]
  },
  [INTRODUCE_YOURSELF]: {
    header: 'Introduce Yourself',
    intro: `It's time to get friendly and personable with your fellow 12WBTers and my Support Crew. In this task we’ll show you how to connect and introduce yourself to the 12WBT family, as well as to share your goals with your 12WBT and loved ones.`,
    videoId: '',
    moreTasks: [NUT_OUT_YOUR_NUTRITION, ORGANISE_AND_DIARISE, SET_YOURSELF_UP_FOR_SUCCESS, FITNESS_TEST_AND_MEASURE_UP]
  },
  [SET_YOURSELF_UP_FOR_SUCCESS]: {
    header: 'Set Yourself Up For Success',
    intro: `In order to strive to achieve something, you need to set a series of goals that will lead you to your ultimate goal. Let’s get real, find your why and set your SMART goals.`,
    videoId: '',
    moreTasks: [NUT_OUT_YOUR_NUTRITION, ORGANISE_AND_DIARISE, INTRODUCE_YOURSELF, FITNESS_TEST_AND_MEASURE_UP]
  },
  [FITNESS_TEST_AND_MEASURE_UP]: {
    header: 'Fitness Test & Measure Up',
    intro: `If there’s only one task you do, make sure it’s this one! In order to know how far you've come, you have to know where you started... that is why this task is SO important.`,
    videoId: '',
    moreTasks: [NUT_OUT_YOUR_NUTRITION, ORGANISE_AND_DIARISE, INTRODUCE_YOURSELF, SET_YOURSELF_UP_FOR_SUCCESS]
  }
}
