import React, { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import TaskContentContainer from '../../tasks/TaskContentContainer'
import RadioQuestion from './RadioQuestion'
import SnackSwapQuestion from './SnackSwapQuestion'
import { quizData, QUESTION_TYPE } from './NutritionTruthData'
import { getFeedback } from './SnackSwapResultsData'

const GuideNutritionTruthsQuiz = () => {
  const [questionNumber, setQuestionNumber] = useState(1)
  const [caloriesSaved, setCaloriesSaved] = useState(0)
  const [successfulAnswersCounter, setSuccessfulAnswersCounter] = useState(0)
  const classes = useStyles()
  const currentQuestion = quizData[questionNumber]

  const nextQuestionClickHandler = (result, snackSwapCaloriesResult) => {
    // Save if answer was correct:
    if (result) setSuccessfulAnswersCounter(successfulAnswersCounter + 1)

    // Save difference in calories between unhealthy snack and chosen snack:
    if (snackSwapCaloriesResult) {
      setCaloriesSaved(caloriesSaved + snackSwapCaloriesResult)
    }

    // Go to next question:
    setQuestionNumber(questionNumber + 1)
  }

  const doAgainClickHandler = () => {
    setQuestionNumber(1)
    setCaloriesSaved(0)
    setSuccessfulAnswersCounter(0)
  }

  return (
    <TaskContentContainer>
      <div className={classes.container}>
        {currentQuestion ? (
          currentQuestion.questionType === QUESTION_TYPE.RADIO ? (
            <RadioQuestion
              question={currentQuestion.question}
              number={questionNumber}
              image={currentQuestion.image}
              questionAnswer={currentQuestion.questionAnswer}
              answerText={currentQuestion.answerText}
              onNextQuestionClick={nextQuestionClickHandler}
              answerArray={currentQuestion?.answerArray}
            />
          ) : (
            <SnackSwapQuestion
              number={questionNumber}
              unhealthySnack={currentQuestion.unhealthySnack}
              answerArray={currentQuestion.answerArray}
              onNextQuestionClick={nextQuestionClickHandler}
              questionAnswer={currentQuestion?.questionAnswer?.title}
            />
          )
        ) : (
          <div className={classes.resultsContainer}>
            <span className={classes.saved}>Making smart snack choices just saved you</span>
            <div className={classes.snackResult}>
              <div className={classes.scoreDiv}>
                <span>Average</span>
                <span className={classes.caloriesSaved}>{caloriesSaved}</span>
                <span>calories</span>
              </div>
              <div className={classes.feedbackDiv}>
                <span>{getFeedback(caloriesSaved)}</span>
                <div className={classes.innerAnswerDiv}>
                  <span>You answered {successfulAnswersCounter} out of 12 answers correctly!</span>
                  <Button className={classes.doAgainButton} onClick={doAgainClickHandler}>
                    Take the quiz again
                  </Button>
                </div>
              </div>
            </div>
            <span>
              Did you learn some hard truths? Get involved with your team-mates in our{' '}
              <a href={process.env.REACT_APP_12WBT_FACEBOOK_MEMBER_ZONE}>12WBT Member Zone</a> to discuss SNACKS! Head
              there now and get chatting and remember, the member zone is all about sharing and support so get
              commenting!
            </span>
          </div>
        )}
      </div>
    </TaskContentContainer>
  )
}
function applyOpacity(color) {
  const opacity = 0.2
  return color.substring(0, color.length - 1) + `, ${opacity})`
}

const useStyles = makeStyles((theme) => ({
  resultsContainer: {
    margin: '4rem 0',
    marginBottom: '8rem'
  },
  snackResult: {
    display: 'flex',
    margin: '1rem 0'
  },
  feedbackDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '1rem',
    paddingBottom: 0
  },
  saved: {
    fontSize: '1.2rem',
    margin: '1rem 0'
  },
  scoreDiv: {
    ...theme.CSS.center,
    flexDirection: 'column',
    padding: '1.5rem',
    width: '10rem',
    backgroundColor: applyOpacity(theme.palette.rgb.guide.green),
    color: theme.palette.rgb.guide.green
  },
  caloriesSaved: {
    fontSize: '3rem',
    fontWeight: 'bold',
    margin: '.5rem 0'
  },
  doAgainButton: {
    marginTop: '1rem',
    color: 'white',
    borderRadius: '6px',
    width: '12rem',
    textTransform: 'none',
    backgroundColor: '#5164E9',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    '&:hover': {
      border: `1px solid #5164E9`,
      color: '#5164E9'
    }
  },
  innerAnswerDiv: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 0'
  },
  [theme.breakpoints.down('sm')]: {
    snackResult: {
      flexDirection: 'column',
      ...theme.CSS.center
    },
    feedbackDiv: {
      ...theme.CSS.center,
      textAlign: 'center'
    },
    doAgainButton: {
      margin: 'auto',
      marginTop: '1rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    resultsContainer: {
      margin: 0,
      marginBottom: '1rem'
    }
  }
}))

export default GuideNutritionTruthsQuiz
