import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import TaskVideo from '../../TaskVideo'
import TaskContentContainer from '../../TaskContentContainer'
import NextTaskLink from '../NextTaskLink'

const FitnessTestFlexibility = ({ nextTaskClick, nextTask }) => {
  return (
    <TaskContentContainer>
      <TaskContentSubHeader title='Flexibility' />
      <TaskVideo videoId='3082265608001' needsCenterAdjust={true} />
      <p>
        What does this measure?
        <br />
        To measure your flexibility, you will be doing the Sit & Reach test. This test is a measurement of the
        flexibility of your hamstrings and lower back. You will need to find a place where you can sit on the ground and
        push your feet up against a step to measure this.
      </p>
      <p>
        Prep Yourself
        <br />
        Balance a ruler (you may want to have someone hold it or tape it down) so that the 10cm mark is right on the
        edge of the step (i.e. the ruler is sticking out over the step). Straighten your legs, locking out your knees so
        that your feet are up against the step and take a deep breath. Place your two index fingers on top of each other
        and as you exhale, slide your hands forwards running your fingers as far along the ruler as you can.
      </p>
      <p>Ready, Set…Go!</p>
      <p>
        Record how many cm +/- you were able to reach. If you couldn&apos;t touch your toes, you would be in the minus
        (e.g. -5cm means your fingertips reached 5cm before your toes), if you reached beyond, you would be in the plus
        (e.g. +5cm means your fingertips reached 5cm beyond your toes).
      </p>
      <p>You can attempt this three times and take your best result (don&apos;t get up in between).</p>
      <NextTaskLink link='Lower Body Strength' onClick={() => nextTaskClick(nextTask)} />
    </TaskContentContainer>
  )
}

FitnessTestFlexibility.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default FitnessTestFlexibility
