import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import TaskContentContainer from '../tasks/TaskContentContainer'

const GuideHow12WBTWorks = () => {
  const classes = useStyles()

  const downloadWeeklyPlannerClickHandler = () => {
    window.open(
      'https://resources.12wbt.com/hubfs/PDF%20Files%20(Program%20specific%20comms)/Typical_12WBT_Week_2022.pdf'
    )
  }

  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <p>
          <b>Welcome to my 12WBT!</b>
        </p>
        <p>
          As a member of my team, you are supported by my team of experts and
          your fellow 12WBT members. We give you week by week access to
          motivational and educational content, over 1200 healthy recipes and
          practical tools and advice to help you achieve your goals during
          Pre-Season and the following 12 weeks.
        </p>
        <span>During Pre-Season (4 weeks) you will receive:</span>
        <ul>
          <li>
            Pre-Season Tasks to lay the foundations for a successful 12 weeks to
            follow.
          </li>
          <li>
            Activities to get you into the groove of regular participation and
            routine.
          </li>
          <li>
            Access to the entire 12WBT Recipe and Exercise Index, Workout Videos
            and Express Workouts so you can start testing out some of our
            delicious meals and exciting exercises.
          </li>
          <li>
            Access via email and the 12WBT Facebook Member Zone to an amazing
            team of dietitians, and health and fitness experts, plus become part
            of the incredibly supportive 12WBT member community.
          </li>
          <li>
            Weekly emails to help guide you through your Pre-Season tasks and
            activities
          </li>
          <li>
            Access the entire program via our app available on iPhone and
            Android.
          </li>
        </ul>
        <p>
          During the 12 weeks following the Pre-Season period, you will receive:
        </p>
        <Button
          onClick={downloadWeeklyPlannerClickHandler}
          className={classes.downloadButton}
        >
          Download Your 12WBT Weekly Planner
        </Button>
        <ul>
          <li>
            Weekly Meal Plans and Shopping Lists, plus continued access to the
            12WBT Recipe Index of over 1200+ recipes.
          </li>
          <li>
            Weekly Exercise Plans, plus continued access to the 12WBT Exercise
            Index AND exclusive 12WBT Workout Videos.
          </li>
          <li>Weekly Mindset Videos to help guide you through your journey.</li>
          <li>
            My Tracker and My Stats pages so you can track your food, exercise,
            mindset and measurements, to stay accountable and responsible for
            your progress.
          </li>
          <li>
            Access to the 12WBT Support Crew and 12WBT member community via the
            12WBT Member Zone.
          </li>
          <li>
            Bi-weekly emails on a Thursday and Sunday with tips, advice and
            direct access to your upcoming Meal and Exercise Plans, as well as
            Mindset Videos.
          </li>
          <li>
            Access the entire program via our app available on iPhone and
            Android.
          </li>
        </ul>
        <p>
          Your 12WBT Round finishes with 4 weeks of graduation that includes the
          option to download the following:
        </p>
        <ul>
          <li>
            A week’s worth of bonus plans containing Exercise and Meal Plans,
            Recipes and Shopping Lists.
          </li>
          <li>A Graduation Certificate.</li>
          <li>A Report Card.</li>
        </ul>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#2563EB',
    borderRadius: '6px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    '&:hover': {
      border: `1px solid #2563EB`,
      color: '#2563EB'
    }
  }
}))

export default GuideHow12WBTWorks
