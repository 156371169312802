import React from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import PrintButton from './PrintButton'

const TaskLearnButtonRow = ({ doTaskButtonClick }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Button className={classes.doTaskButton} onClick={() => doTaskButtonClick()}>
        Do the task
      </Button>
      <div className={classes.printButton}>
        <PrintButton />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: '50px'
  },
  doTaskButton: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#2563EB',
    borderRadius: '6px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    width: '138px',
    height: '50px',
    '&:hover': {
      border: `1px solid #2563EB`,
      color: '#2563EB'
    }
  },
  printButton: {
    textTransform: 'none',
    color: '#1D4ED8',
    backgroundColor: '#EFF6FF',
    borderRadius: '6px',
    width: '138px',
    height: '50px',
    marginLeft: '16px'
  }
}))

TaskLearnButtonRow.propTypes = {
  doTaskButtonClick: PropTypes.func.isRequired
}

export default TaskLearnButtonRow
