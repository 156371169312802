import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../components/ContentContainer'

const TaskContentContainer = ({ children }) => {
  const classes = useStyles()
  return (
    <ContentContainer maxPixels={812}>
      <div className={classes.textContainer}>{children}</div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  textContainer: {
    fontSize: '16px',
    lineHeight: '25px',
    backgroundColor: 'white',
    padding: '1rem',
    '& ol': {
      padding: '1rem',
      paddingTop: 0
    },
    '& ul': {
      padding: '1rem',
      paddingTop: 0
    }
  },
  [theme.breakpoints.down('md')]: {
    textContainer: {
      padding: 0,
      margin: 0
    }
  },
  [theme.breakpoints.down('sm')]: {
    textContainer: {
      margin: '0rem .5rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    textContainer: {
      margin: '.5rem',
      marginTop: '2rem',
      fontSize: '1rem',
      padding: '.2rem'
    }
  }
}))

TaskContentContainer.propTypes = {
  children: PropTypes.oneOfType([(PropTypes.array, PropTypes.object)])
}

export default TaskContentContainer
