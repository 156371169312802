import React from 'react'
import ContentContainer from '../../../components/ContentContainer'
import ActivityContentContainer from './ActivityContentContainer'
import { makeStyles } from '@material-ui/core'
import ActivityHeaderSpacer from './ActivityHeaderSpacer'

const AcitivityMakingMoreMeTime = () => {
  const classes = useStyles()
  return (
    <div className={classes.background}>
      <ActivityHeaderSpacer />
      <ContentContainer maxPixels={732}>
        <ActivityContentContainer>
          <p>
            Once upon a time the weight loss formula was simplified to &quot;Eat Less, Move More&quot;. After around 40
            years of escalating obesity levels, failed fad diets and a major shift in the way we lead our lives this
            catchphrase falls short on so many levels.
          </p>
          <p>
            So this Activity is all about making more time to appreciate your body... and while inserting incidental
            exercise into your day is usually a good thing, sometimes what you need is the exact opposite!
          </p>
          <p>
            If you want to be lean and healthy for the rest of your life then IT’S UP TO YOU to continually self-assess,
            self-nuture and self-manage your own energy levels. No one is going to hand you this essential &apos;Me
            time&apos;, it&apos;s up to you to make time for it each and every week, ideally each and every day.
          </p>
          <p>
            Chances are you&apos;ve been slammed with doing lots of seemingly urgent jobs for your: Partner, kids,
            parents, boss, staff and let&apos;s not forget that &quot;one&quot; favour for a random acquaintance.
          </p>
          <p>
            So this weeks activity is to self-assess what you actually need the most to function at your very best, it
            may be:
          </p>
          <ul>
            <li>A walk in the park</li>
            <li>A nap</li>
            <li>Read a book for 30 minutes, during the day!</li>
            <li>A guided meditation</li>
            <li>Turning your phone off for a whole hour</li>
            <li>Another nap</li>
          </ul>
          <p>So this week make it happen, chances are it will be the highlight of your week.</p>
        </ActivityContentContainer>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    width: '100%',
    height: '100%',
    paddingBottom: '20px'
  },
  [theme.breakpoints.down('sm')]: {
    background: {
      backgroundColor: theme.palette.greys.light
    }
  }
}))

export default AcitivityMakingMoreMeTime
