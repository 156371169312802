import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import ActionButton from '../ActionButton'

const ShoppingSmartCalculatorResult = ({ result, edit }) => {
  const classes = useStyles()
  return (
    <div className={classes.resultContainer}>
      <div className={classes.headerRow}>
        <span className={classes.header}>Total Weekly Food Spend</span>
        <ActionButton title='Edit' onButtonClick={edit} />
      </div>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12} sm={2}>
          <div className={classes.calculatedResult}>${result}</div>
        </Grid>
        <Grid item xs={12} sm={10}>
          <span>
            The cost per meal of many 12WBT recipes can be as low as $3 per serve! Your food bill may increase for the
            first few weeks if your cupboard is bare and you don’t have common condiments such as olive oil. However you
            will quickly stock up on these one-off items and then your food bill will become more economical.
          </span>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  resultContainer: {
    margin: '2rem 0'
  },
  grid: {
    margin: '1rem 0'
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  calculatedResult: {
    backgroundColor: '#f0faff',
    border: '1px solid #bde9ff',
    ...theme.CSS.center,
    fontSize: '1.6rem',
    fontWeight: 800,
    width: '100%',
    height: '100%'
  }
}))

ShoppingSmartCalculatorResult.propTypes = {
  edit: PropTypes.func.isRequired,
  result: PropTypes.number.isRequired
}

export default ShoppingSmartCalculatorResult
