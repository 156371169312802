import React from 'react'
import { makeStyles } from '@material-ui/core'
import mainLogo from '../../../assets/img/logos/logo-white@2x.png'
import lifersLogo from '../../../assets/img/logos/lifestyle-logo.png'
import WelcomeStepTwoProgressIcon from './WelcomeStepTwoProgressIcon'
import { ReactComponent as MealPlan } from '../../../assets/img/icons/pre_season/MealPlan.svg'
import { ReactComponent as ExercisePlan } from '../../../assets/img/icons/pre_season/ExercisePlan.svg'
import CheckIcon from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const icons = (stepTwoProgress) => {
  return [
    { icon: ExercisePlan, active: true },
    { icon: MealPlan, active: stepTwoProgress > 2 },
    { icon: CheckIcon, active: stepTwoProgress > 4 }
  ]
}

const WelcomeStepTwoHeader = ({ stepTwoProgress }) => {
  const classes = useStyles()
  const title = stepTwoProgress <= 2 ? 'Pick your fitness program' : 'Your nutrition preference'
  const { currentSubscription } = useSelector((state) => state.currentSubscription)

  return (
    <div className={classes.header}>
      <img src={currentSubscription?.lifers ? lifersLogo : mainLogo} alt='white 12wbt logo' className={classes.logo} />
      <div className={classes.progressStatusBar}>
        {icons(stepTwoProgress).map((obj, i) => {
          return <WelcomeStepTwoProgressIcon key={i} icon={<obj.icon />} active={obj.active} />
        })}
        <div className={classes.divider}></div>
      </div>
      <span className={classes.title}>{title}</span>
      <span>Don&apos;t worry about picking the perfect plan! You always have the option to change it.</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    color: 'white',
    textAlign: 'center'
  },
  logo: {
    maxWidth: '12rem',
    margin: '3rem auto'
  },
  progressStatusBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '17rem',
    position: 'relative',
    alignItems: 'center',
    margin: 'auto'
  },
  divider: {
    position: 'absolute',
    width: '17rem',
    height: '3px',
    backgroundColor: 'white',
    zIndex: 2
  },
  title: {
    fontSize: '2rem',
    margin: '1rem auto'
  }
}))

WelcomeStepTwoHeader.propTypes = {
  stepTwoProgress: PropTypes.number.isRequired
}

export default WelcomeStepTwoHeader
