import React from 'react'
import PropTypes from 'prop-types'
import WelcomeStepsFooter from '../WelcomeStepsFooter'
import { useDispatch, useSelector } from 'react-redux'
import { progressStepTwo } from '../../../../redux/actions/WelcomeSteps.actions'

const WelcomeStepTwoContinue = ({ formik, loading }) => {
  const dispatch = useDispatch()
  const { stepTwoProgress } = useSelector((state) => state.welcomeStepTwo)

  const submitHandler = () => {
    // If not final part of step two, simply progress to next section:
    if (stepTwoProgress < 3) dispatch(progressStepTwo())
    else {
      // Final section of welcome step two, submit form:
      formik.submitForm()
    }
  }
  return <WelcomeStepsFooter onSubmit={submitHandler} buttonTitle={stepTwoProgress < 3 ? 'Continue' : 'Get started now!'} loading={loading} />
}

WelcomeStepTwoContinue.propTypes = {
  formik: PropTypes.object.isRequired,
  loading: PropTypes.bool
}

export default WelcomeStepTwoContinue
