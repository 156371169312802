import React, { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { typicalWeek } from './WelcomeCompleteData'
import WelcomeCompleteFeature from './WelcomeCompleteFeature'
import WelcomeStepsFooter from '../WelcomeStepsFooter'
import ContentContainer from '../../../components/ContentContainer'

import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

const WelcomeCompleteProgramInfo = () => {
  const { isReturnMember } = useSelector((state) => state.member)
  const [redirect, setRedirect] = useState(false)
  const classes = useStyles()

  // Navigate to pre-season here:
  const getStartedNowClickHandler = () => {
    setRedirect(true)
  }

  if (redirect) return <Redirect to='/pre-season' />

  return (
    <ContentContainer>
      <div className={classes.container}>
        {!isReturnMember && (
          <div>
            <div className={classes.textContainer}>
              <span className={classes.title}>12 Week Program</span>
              <span className={classes.description}>
                Each week you will receive your Exercise and Meal Plans, Shopping Lists and Mindset Videos. Everything
                you need for your transformation. Only 32 days to go!
              </span>
            </div>
            <div className={classes.programCard}>
              <div className={classes.header}>
                <span>TYPICAL 12WBT WEEK</span>
              </div>
              <div className={classes.grid}>
                <Grid container spacing={3}>
                  {typicalWeek.map((feature, i) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={i}>
                        <WelcomeCompleteFeature
                          day={feature.day}
                          color={feature.color}
                          title={feature.title}
                          description={feature.description}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </div>
            </div>
          </div>
        )}
        <WelcomeStepsFooter buttonTitle='Get started now!' onSubmit={getStartedNowClickHandler} />
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    color: 'white'
  },
  grid: {
    margin: '1.5rem'
  },
  textContainer: {
    maxWidth: '50ch%',
    display: 'flex',
    flexDirection: 'column',
    margin: '1.2rem auto'
  },
  title: {
    fontSize: '1.7rem',
    fontWeight: 'bold'
  },
  programCard: {
    backgroundColor: 'white',
    borderRadius: '15px',
    margin: '0 0 1rem 0'
  },
  header: {
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '15px',
    backgroundColor: theme.palette.signup.secondary,
    padding: '.5rem',
    fontWeight: 'bold'
  },
  description: {
    margin: '.5rem auto'
  },
  [theme.breakpoints.down('md')]: {
    textContainer: {
      maxWidth: '70%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    textContainer: {
      maxWidth: '90%'
    }
  }
}))

export default WelcomeCompleteProgramInfo
