import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import TaskContentContainer from '../../TaskContentContainer'
import NextTaskLink from '../NextTaskLink'
import navigateToGoSite from '../../../../../helpers/NavigateToGoSite'

const FitnessTestHowThisWorks = ({ nextTaskClick, nextTask }) => {
  return (
    <TaskContentContainer>
      <TaskContentSubHeader title='How this works' />
      <ol>
        <li>
          You will need to complete all of the Fitness Test components listed on the left. Make sure you opt for an
          outdoor session to SMASH the Time Trial test!
        </li>
        <li>
          Enter your results in the form below and you will be provided with your{' '}
          <a href={navigateToGoSite('help/981-what-is-the-12wbt-fitness-score')}>Fitness Score</a> - a number indicating
          your fitness level based on the results from all your tests.
        </li>
        <li>
          Your <a href={navigateToGoSite('help/981-what-is-the-12wbt-fitness-score')}>Fitness Score</a> will determine
          which program is best suited to your fitness level and is a great tool to measure your progress throughout the
          Round.
        </li>
      </ol>
      <TaskContentSubHeader title={`What you'll need to do the test:`} />
      <ul>
        <li>Stopwatch or timer</li>
        <li>30cm ruler</li>
        <li>A towel or mat</li>
        <li>1km marked course. Try to make it flat.</li>
        <li>A wall you can lean against</li>
        <li>A step you can sit in front of</li>
      </ul>
      <NextTaskLink link='Warm up before Fitness Test' onClick={() => nextTaskClick(nextTask)} />
    </TaskContentContainer>
  )
}

FitnessTestHowThisWorks.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default FitnessTestHowThisWorks
