import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../../Modal'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import DoubleArrow from '@material-ui/icons/DoubleArrow'

const ToolTipSwitchRoundModal = ({ isModalOpen, setIsModalOpen }) => {
  const classes = useStyles()
  const subscriptions = useSelector(
    (state) => state.subscriptions?.subscriptions
  )
  const currentSubscription = useSelector(
    (state) => state.currentSubscription?.currentSubscription
  )

  const navigateToGoSiteSwitchingRound = (roundId) => {
    return `${process.env.REACT_APP_GO_LOGIN}?t=${localStorage.getItem(
      'auth_token'
    )}&roundId=${roundId}&redirectUrl=dashboard`
  }

  const isSelectedRound = (subscription) => {
    return currentSubscription.round_id === subscription.round_id
  }

  return isModalOpen ? (
    <Modal
      show={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false)
      }}
    >
      <div className={classes.titleRow}>
        <p className={classes.switchRoundTitle}>Select Round</p>
        <a
          className={classes.closeIconBtn}
          onClick={() => {
            setIsModalOpen(false)
          }}
          type='button'
          aria-hidden='true'
        >
          ×
        </a>
      </div>
      <ul className={classes.switchRoundList}>
        {subscriptions.map((subscription, index) => {
          return (
            <li key={index}>
              <a
                href={
                  isSelectedRound(subscription)
                    ? '#'
                    : navigateToGoSiteSwitchingRound(subscription.round_id)
                }
                className={
                  index === 0
                    ? classes.topBoxBtn
                    : index === subscription.length - 1
                    ? classes.bottomBoxBtn
                    : classes.midBoxBtn
                }
                style={{
                  color: isSelectedRound(subscription) ? 'black' : '#4f5fed',
                  cursor: isSelectedRound(subscription) ? 'default' : 'pointer'
                }}
              >
                {isSelectedRound(subscription) ? (
                  <DoubleArrow
                    style={{
                      fontSize: 15
                    }}
                  />
                ) : (
                  <></>
                )}
                {subscription.round_name} (AU)
              </a>
            </li>
          )
        })}
      </ul>
    </Modal>
  ) : (
    <></>
  )
}

ToolTipSwitchRoundModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func
}

const useStyles = makeStyles((theme) => ({
  titleRow: {
    textAlign: 'center',
    paddingRight: '30px',
    paddingLeft: '30px',
    borderBottom: '1px solid #eee'
  },
  switchRoundTitle: {
    fontWeight: 400,
    fontSize: '20.25px',
    lineHeight: '29.7px'
  },
  closeIconBtn: {
    padding: '10px',
    marginRight: '-25px',
    marginTop: '-60px',
    float: 'right',
    fontSize: '20px',
    fontWeight: 700,
    color: 'black',
    textShadow: '0 1px 0 #fff',
    opacity: '.2',
    '&:hover': {
      color: 'black',
      textDecoration: 'none',
      cursor: 'pointer',
      opacity: '.4'
    }
  },
  switchRoundList: {
    listStyle: 'none',
    padding: '0 30px',
    maxHeight: '500px',
    overflow: 'scroll'
  },
  topBox: {
    marginBottom: '-1px',
    float: 'none',
    textDecoration: 'none'
  },
  topBoxBtn: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    display: 'flex',
    padding: '8px 10px',
    border: '1px solid #ddd',
    cursor: 'pointer',
    color: '#4f5fed',
    fontSize: '15px',
    fontWeight: 700,
    textDecoration: 'none',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#f0faff',
      borderColor: '#ddd',
      zIndex: 2
    }
  },
  midBoxBtn: {
    display: 'flex',
    padding: '8px 10px',
    borderLeft: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    borderRight: '1px solid #ddd',
    cursor: 'pointer',
    color: '#4f5fed',
    fontSize: '15px',
    fontWeight: 700,
    textDecoration: 'none',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#f0faff',
      borderColor: '#ddd',
      zIndex: 2
    }
  },
  bottomBoxBtn: {
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    display: 'flex',
    padding: '8px 10px',
    borderLeft: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    borderRight: '1px solid #ddd',
    cursor: 'pointer',
    color: '#4f5fed',
    fontSize: '15px',
    fontWeight: 700,
    textDecoration: 'none',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#f0faff',
      borderColor: '#ddd',
      zIndex: 2
    }
  }
}))

export default ToolTipSwitchRoundModal
