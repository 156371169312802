import { useEffect } from 'react'
import PropTypes from 'prop-types'

const useEffectWithTimeout = ({ timeout, condition, dependencies, successMethod, failMethod }) => {
  useEffect(() => {
    const myVar = setTimeout(() => {
      if (condition) {
        if (successMethod) successMethod()
        else if (failMethod) failMethod()
      }
    }, timeout)
    return function cleanup() {
      clearTimeout(myVar)
    }
  }, dependencies)
}

useEffectWithTimeout.PropTypes = {
  timeout: PropTypes.number.isRequired,
  condition: PropTypes.bool,
  successMethod: PropTypes.func,
  failMethod: PropTypes.func,
  dependencies: PropTypes.array.isRequired
}

export default useEffectWithTimeout
