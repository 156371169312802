import React, { useEffect, useState } from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import loadCurrentSubscriptionAndRound from '../app/helpers/LoadCurrentSubscriptionAndRound'
import backgroundPattern from '../app/assets/img/welcome_steps/BG-ProgramConfirmationPage.svg'
import { Redirect } from 'react-router-dom'

const Welcome = () => {
  const [redirect, setRedirect] = useState(null)
  const { progress } = useSelector((state) => state.preseason)
  const classes = useStyles()

  loadCurrentSubscriptionAndRound()

  // Redirect to correct welcome step:
  useEffect(() => {
    if (progress) {
      if (progress.welcome_steps_2) {
        setRedirect('/pre-season')
      } else if (progress.welcome_steps_1) {
        setRedirect('/welcome/step-2')
      } else {
        setRedirect('/welcome/step-1')
      }
    }
  }, [progress])

  if (redirect) return <Redirect to={redirect} />

  return (
    <div className={classes.background}>
      <CircularProgress size={70} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundPattern})`,
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'right',
    backgroundAttachment: 'fixed',
    minWidth: '100%',
    minHeight: '100vh',
    ...theme.CSS.center
  }
}))

export default Welcome
