import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const NextTaskLink = ({ link, onClick }) => {
  const classes = useStyles()
  return (
    <p className={classes.linkContainer}>
      Next:&nbsp;
      <span className={classes.link} onClick={onClick}>
        {link}
      </span>
    </p>
  )
}

const useStyles = makeStyles((theme) => ({
  linkContainer: {},
  link: {
    textDecoration: 'underline',
    color: 'blue',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

NextTaskLink.propTypes = {
  link: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default NextTaskLink
