import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Grid, makeStyles } from '@material-ui/core'
import SnackSwapAnswerOption from './SnackSwapAnswerOption'

const SnackSwapQuestion = ({ number, unhealthySnack, answerArray, onNextQuestionClick, questionAnswer }) => {
  const classes = useStyles()
  const [answer, setAnswer] = useState(null)
  const [answerCalories, setAnswerCalories] = useState(null)
  const [answerSubmitted, setAnswerSubmitted] = useState(false)

  const snackOptionClickHandler = (value, calories) => {
    setAnswerCalories(calories)
    setAnswer(value)
  }

  const submitAnswerClickHandler = () => {
    if (answerSubmitted || answer === null) {
      return
    }
    setAnswerSubmitted(true)
  }

  const nextQuestionClickHandler = () => {
    setAnswer(null)
    setAnswerSubmitted(false)
    setAnswerCalories(null)
    const caloriesDiff = unhealthySnack?.calories - answerCalories
    if (answer !== null) onNextQuestionClick(answer === questionAnswer, caloriesDiff)
  }

  return (
    <div className={classes.container}>
      <div className={classes.questionArea}>
        <p>
          <b>{`Q${number}.`}</b>&nbsp;Making smart snack choices can help you avoid unnecessary calories. Pick the
          healthiest snack to see how many calories you can save.
        </p>
        <div className={classes.unhealthySnackRow}>
          <img src={unhealthySnack.image} alt='Unhealthy snack' className={classes.unhealthySnackImg} />
          <div className={classes.unhealthySnackInfo}>
            <span>
              <b>{unhealthySnack.title}</b>
            </span>
            <span>{unhealthySnack.serving}</span>
            <span>
              <b>{unhealthySnack.calories} Calories</b>
            </span>
          </div>
        </div>
        <div className={classes.dividerArea}>
          <div className={classes.dividerText}>
            <span>Swap for a healthier option</span>
          </div>
          <Divider className={classes.divider} />
        </div>
      </div>
      <Grid container spacing={3}>
        {answerArray.map((ans, i) => {
          return (
            <SnackSwapAnswerOption
              key={i}
              title={ans.title}
              img={ans.image}
              calories={ans.calories}
              serving={ans.serving}
              onClick={snackOptionClickHandler}
              currentAnswer={answer}
              answerSubmitted={answerSubmitted}
              benefits={ans?.benefits}
              nextQuestionClick={nextQuestionClickHandler}
            />
          )
        })}
      </Grid>
      <div className={classes.buttonRow}>
        <Button
          className={classes.button}
          onClick={answerSubmitted ? nextQuestionClickHandler : submitAnswerClickHandler}
        >
          {answerSubmitted ? 'Next' : 'Continue'}
        </Button>
        <span>{number} of 12</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  questionArea: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem'
  },
  unhealthySnackRow: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  unhealthySnackImg: {
    width: '10rem',
    height: '10rem'
  },
  unhealthySnackInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1rem'
  },
  dividerArea: {
    margin: '1rem 0',
    position: 'relative',
    width: '100%',
    ...theme.CSS.center,
    '& span': {
      backgroundColor: 'white'
    }
  },
  dividerText: {
    backgroundColor: 'white',
    width: '15rem',
    height: '100%',
    ...theme.CSS.center,
    zIndex: 50
  },
  divider: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'black',
    height: '1px'
  },
  button: {
    width: '18rem',
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#2563EB',
    borderRadius: '6px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    '&:hover': {
      border: `1px solid #2563EB`,
      color: '#2563EB'
    },
    margin: '2rem 0'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  [theme.breakpoints.down('xs')]: {
    button: {
      marginTop: '2rem',
      width: '12rem'
    }
  }
}))

SnackSwapQuestion.propTypes = {
  number: PropTypes.number.isRequired,
  answerArray: PropTypes.array.isRequired,
  unhealthySnack: PropTypes.object.isRequired,
  onNextQuestionClick: PropTypes.func.isRequired,
  questionAnswer: PropTypes.string.isRequired
}

export default SnackSwapQuestion
