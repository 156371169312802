import React from 'react'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../components/ContentContainer'
import ActivityHeaderSpacer from './ActivityHeaderSpacer'
import ActivityContentContainer from './ActivityContentContainer'
import navigateToGoSite from '../../../helpers/NavigateToGoSite'

const ActivityBuildYourInnerCoreStrength = () => {
  const classes = useStyles()
  return (
    <div className={classes.background}>
      <ActivityHeaderSpacer />
      <ContentContainer maxPixels={732}>
        <ActivityContentContainer>
          <span>
            A structure is only as strong as its foundations, so building your deep core and pelvic floor muscles is
            essential to:
          </span>
          <ol>
            <li>Building Strength - Strength makes EVERY activity in your life easier.</li>
            <li>Improving in all sports and lifestyle activities.</li>
            <li>Preventing Back Pain, and let&apos;s not forget…</li>
            <li>Weight Loss - A Strong core = Walking, running and everything else longer and better.</li>
          </ol>
          <p>
            When your core...your entire core can engage before your bigger muscle groups switch on you
            <b>&nbsp;will move with more efficiency and minimise the risk of injuring your back</b>, and I&apos;m not
            just talking about doing a personal best on a deadlift, I&apos;m talking about the much more demanding
            activities such as putting on a toddler&apos;s seat belt or bringing in all the shopping at once.
          </p>
          <p>
            So here&apos;s your initial core routine that can be done everyday for the rest of your life! But start at 3
            times/week and build from there.
          </p>
          <p>Try 1 minute on each of the following exercises, in the following order.</p>
          <p>
            <a href={navigateToGoSite('exercises/324')}>Engage Pelvic Floor - Lying</a> <br />
            This is the easiest way to engage your deep pelvic floor muscles that often gets ignored and can lead to
            incontinence in later life.
          </p>
          <p>
            <a href={navigateToGoSite('exercises/309')}>Lying Lower Abs (Bent Leg)</a> <br />
            This exercise engages your deep, lower Transverse Abdominis that is the secret to a flat stomach and
            pain-free lower back. It is a subtle contraction so you won’t feel a ‘burn’, but rest assured it is a real
            back-saver.
          </p>
          <p>
            <a href={navigateToGoSite('exercises/130')}>Plank - Alternating</a> <br />
            Firstly engage the same muscles you switched on in the previous 2 exercises, then get into the plank
            position. Start on your knees and if you can hold perfect form try transitioning up onto your toes. If your
            shoulders hunch or your hips begin to dip then go back down onto your knees. Alternate between the knees and
            toes until you can hold perfect form on your toes for the entire minute.
          </p>
          <p>
            <a href={navigateToGoSite('exercises/131')}>Side Plank - Knees</a> <br />
            To have your entire core strong and stable you have to work it from all angles. The Side Plank will recruit
            your oblique muscles which will help lateral stability, which means your back will be more supported and
            have less chance of stiffening up. Once you can do the entire minute on your knees, try some time up on your
            toes.
          </p>
          <p>
            <a href={navigateToGoSite('exercises/1107')}>Glute Activation - Laying on Floor</a> <br />
            As the title suggests this exercise is very effective at engaging your glute or butt muscles. Just like all
            the other exercises in this routine it isn’t about pushing as hard as you possibly can, instead it is
            working intelligently and intune with your body so that whatever activity you do for the rest of the day
            will be done with less stress on your joints. <br />
            Share your progress by writing a&nbsp;
            <a href={process.env.REACT_APP_12WBT_FACEBOOK_MEMBER_ZONE}>Member Zone post</a>, or posting through
            Instagram. Tag your post: <b>#12WBTCore</b>
          </p>
        </ActivityContentContainer>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  background: {
    width: '100%',
    height: '100%',
    paddingBottom: '20px'
  },
  [theme.breakpoints.down('sm')]: {
    background: {
      backgroundColor: theme.palette.greys.light
    }
  }
}))

export default ActivityBuildYourInnerCoreStrength
