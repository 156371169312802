import React from 'react'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../components/ContentContainer'
import ActivityContentContainer from './ActivityContentContainer'
import ActivityHeaderSpacer from './ActivityHeaderSpacer'

const ActivityBetterThanYesterday = () => {
  const classes = useStyles()
  return (
    <div className={classes.background}>
      <ActivityHeaderSpacer />
      <ContentContainer maxPixels={732}>
        <ActivityContentContainer>
          <p>
            A major problem with any exercise or weight loss program is that people expect to get instant results, or at
            least results that reflect their effort, this is seldom the case. So instead of starting with the idea of
            conquering Mount Everest on day 1, try starting with this simple formula…drum roll please…
          </p>
          <p>&apos;Better than Yesterday&apos;</p>
          <p>
            This mindset can be used for exercise, nutrition and any aspect of your life you’d like to improve, here are
            some examples.
          </p>
          <table className={classes.table} cellSpacing={0}>
            <tbody>
              <tr className={classes.tableHeader}>
                <td className={classes.tableData}>
                  <span>Yesterday</span>
                </td>
                <td className={classes.tableData}>
                  <span>Today</span>
                </td>
              </tr>
              <tr>
                <td className={classes.tableData}>Drank 1 glass of water all day</td>
                <td className={classes.tableData}>Drink 1.5 glasses of water</td>
              </tr>
              <tr>
                <td className={classes.tableData}>Didn&apos;t leave the house all day</td>
                <td className={classes.tableData}>Walk to your letterbox</td>
              </tr>
              <tr>
                <td className={classes.tableData}>Ordered Uber Eats or Take-away everday</td>
                <td className={classes.tableData}>Prepare 2 homemade meals this week</td>
              </tr>
            </tbody>
          </table>
          <p>
            The above examples may not appear to be enough to elicit serious weight loss, but rest assured they are
            slowly steering your ship in a different, better, more positive direction, and when you change the direction
            of your ship by just 1 degree, over time you will end up in a completely different place.
          </p>
          <p>
            To add to this change of direction, once you make the first slight shift you will start to feel better and
            naturally keep building on it, not because it’s on your vision board, but because your body is happiest when
            you are moving well and eating clean.
          </p>
          <p>
            In short, don&apos;t rush your eating or exercise changes, just start the snowball and make whatever you
            choose to be slightly better than yesterday.
          </p>
        </ActivityContentContainer>
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  table: {
    tableLayout: 'fixed',
    width: '100%',
    border: '1px solid white'
  },
  tableData: {
    backgroundColor: '#CC5FED',
    color: 'white',
    borderColor: '1px solid white',
    border: '1px solid white',
    padding: '.7rem'
  },
  tableHeader: {
    textAlign: 'center',
    '& span': {
      fontWeight: '700!important'
    }
  },
  background: {
    width: '100%',
    height: '100%',
    paddingBottom: '20px'
  },
  [theme.breakpoints.down('sm')]: {
    background: {
      backgroundColor: theme.palette.greys.light
    }
  }
}))

export default ActivityBetterThanYesterday
