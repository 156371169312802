import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'

const SnackSwapAnswerOption = ({
  onClick,
  currentAnswer,
  benefits,
  title,
  serving,
  calories,
  img,
  answerSubmitted
}) => {
  const classes = useStyles({ img })
  const answerClickHandler = () => {
    if (answerSubmitted) {
      return
    }
    onClick(title, calories)
  }
  let overlayClass = classes.overlay
  let cardBodyClass = title === currentAnswer ? `${classes.cardBody} ${classes.cardBodySelected}` : classes.cardBody
  if (benefits && answerSubmitted) {
    cardBodyClass = `${classes.cardBody} ${classes.cardBodySuccess}`
    overlayClass = `${classes.overlay} ${classes.overlaySuccess}`
  }
  return (
    <Grid item xs={12} sm={4} onClick={answerClickHandler} className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.header}>
          {answerSubmitted && (
            <div className={overlayClass}>
              {benefits && (
                <span>
                  <b>Best option</b>
                </span>
              )}
              <span>
                <b>Av. {calories} Calories</b>
              </span>
              {benefits && <span>{benefits}</span>}
            </div>
          )}
        </div>
        <div className={cardBodyClass}>
          <span className={classes.title}>{title}</span>
          <span className={classes.serving}>{serving}</span>
        </div>
      </div>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  header: {
    backgroundImage: (props) => `url(${props.img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '15rem',
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    minHeight: '5rem',
    padding: '.5rem',
    textAlign: 'center',
    color: 'white',
    bottom: 0,
    ...theme.CSS.center,
    flexDirection: 'column',
    backgroundColor: applyOpacity(theme.palette.rgb.signup.purple, 0.4)
  },
  overlaySuccess: {
    backgroundColor: applyOpacity(theme.palette.rgb.guide.green, 0.7)
  },
  cardBody: {
    backgroundColor: theme.palette.greys.light,
    width: '100%',
    height: '7rem',
    padding: '.5rem',
    display: 'flex',
    flexDirection: 'column'
  },
  cardBodySelected: {
    backgroundColor: theme.palette.signup.purple
  },
  cardBodySuccess: {
    backgroundColor: applyOpacity(theme.palette.rgb.guide.green, 0.5)
  },
  title: {
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('xs')]: {
    cardContainer: {
      width: '60%',
      margin: 'auto'
    },
    cardBody: {
      height: '5rem'
    },
    header: {
      height: '10rem'
    }
  },
  [theme.breakpoints.down(430)]: {
    cardContainer: {
      width: '80%'
    }
  }
}))

function applyOpacity(color, opacity) {
  return color.substring(0, color.length - 1) + `, ${opacity})`
}

SnackSwapAnswerOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentAnswer: PropTypes.string,
  title: PropTypes.string.isRequired,
  serving: PropTypes.string.isRequired,
  calories: PropTypes.number.isRequired,
  benefits: PropTypes.string,
  img: PropTypes.string.isRequired,
  answerSubmitted: PropTypes.bool.isRequired
}

export default SnackSwapAnswerOption
