import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import CameraAltIcon from '@material-ui/icons/CameraAlt'

const WelcomeStepOneImageUpload = ({ onImageUpload }) => {
  const [filePreview, setFilePreview] = useState()
  const inputRef = useRef()
  const classes = useStyles({ filePreview })

  const imageUploadClickHandler = () => {
    inputRef.current.click()
  }
  const imageChangeHandler = (e) => {
    if (!e.target.files[0]) return

    const reader = new FileReader()
    reader.onloadend = () => {
      // Save filePreview in component local state:
      setFilePreview(reader.result)

      // Set file in parent state 'WelcomeStepOneForm':
      onImageUpload(e.target.files[0])
    }
    reader.readAsDataURL(e.target.files[0])
  }

  return (
    <div className={classes.imageUploader} onClick={imageUploadClickHandler}>
      {!filePreview && (
        <>
          <CameraAltIcon />
          <span>Add Photo</span>
        </>
      )}
      <input type='file' ref={inputRef} onChange={imageChangeHandler} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  imageUploader: {
    backgroundColor: theme.palette.greys.light,
    borderRadius: '50%',
    backgroundImage: (props) => (props.filePreview ? `url(${props.filePreview})` : undefined),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'none',
    width: '7rem',
    height: '7rem',
    ...theme.CSS.center,
    flexDirection: 'column',
    color: theme.palette.greys.medium,
    '&:hover': {
      cursor: 'pointer'
    },
    '& input': {
      display: 'none'
    },
    marginBottom: '1rem',
    margin: '0 auto'
  },
  imagePreview: {
    width: '100%',
    height: '100%'
  }
}))

WelcomeStepOneImageUpload.propTypes = {
  onImageUpload: PropTypes.func.isRequired
}

export default WelcomeStepOneImageUpload
