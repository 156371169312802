import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../../components/ContentContainer'
import TaskSectionHeader from '../TaskSectionHeader'
import PantryTable from './PantryTable'
import ClearOutTable from './ClearOutTable'
import ShoppingSmartCalculator from './ShoppingSmartCalculator'
import PrintButton from '../PrintButton'
import ActionButton from '../ActionButton'
import TaskContentContainer from '../TaskContentContainer'
import TaskCompletedBanner from '../TaskCompletedBanner'
import { tasks, NUT_OUT_YOUR_NUTRITION } from '../TaskData'
import { useDispatch, useSelector } from 'react-redux'
import { getPreSeasonTask } from '../../../../helpers/GetPreSeasonTask'
import { submitPreSeasonTask, deletePreSeasonTask } from '../../../../../redux/actions/PreSeason.actions'
import navigateToGoSite from '../../../../helpers/NavigateToGoSite'

const NutOutYourNutritionDoTask = () => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.newPreSeason)
  const { currentSubscriptionId } = useSelector((state) => state.member)
  const classes = useStyles()

  const taskTitle = tasks[NUT_OUT_YOUR_NUTRITION].header
  const task = getPreSeasonTask(taskTitle)
  const isComplete = task
  const actionButtonTitle = isComplete ? 'Undo' : 'Finish'

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [isComplete])

  const actionButtonClickHandler = () => {
    if (!isComplete) dispatch(submitPreSeasonTask(currentSubscriptionId, { title: taskTitle }))
    else dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  const onUndoClickHandler = () => {
    if (!loading) dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  return (
    <ContentContainer maxWidth='xl'>
      <div className={classes.container}>
        <TaskContentContainer>
          {isComplete && <TaskCompletedBanner onUndoClick={onUndoClickHandler} />}
          <TaskSectionHeader title='The Clear Out' />
          <p>
            Bring your rubbish bin OR collection box into the middle of the room, open the cupboards, take a big deep
            breath if you have to, and be ruthless!
          </p>
          <ClearOutTable />
          <p>
            Now you have cleared out the pantry and fridge it&apos;s time to prep yourself for the round by re-stocking
            with 12WBT Pantry essentials, arming yourself with kitchen measuring tools and honing in on your nutrition
            knowledge to shop smart.
          </p>
          <TaskSectionHeader title='Re-stock & Kitchen Tools' />
          <p>
            You will be getting your Week 1 Shopping List soon, but why not get ahead with the below shelf staple pantry
            essentials which appear regularly on our Regular Meal Plans.
          </p>
          <PantryTable />
          <TaskSectionHeader title='Kitchen Measuring Tools' />
          <p>
            Portion distortion is the most common cause of weight loss plateaus and increasing weight whilst on a
            ‘weight loss’ diet. Measuring your ingredients accurately and regularly not only ensures appropriate
            nutrition BUT it also trains your eyes to more accurately estimate measures and portions when you are out of
            the home.{' '}
          </p>
          <p>
            Kitchen scales, measuring cups and measuring spoons can all be purchased cheaply from supermarkets and
            homeware stores and are referred to in all our recipes.
          </p>
          <TaskSectionHeader title='Shopping Smart' />
          <p>
            Buying your own groceries and preparing your own healthy meals is definitely more affordable than buying
            takeaway and eating out all the time.
          </p>
          <p>Don’t believe me? Head here for a quick comparison:</p>
          <ShoppingSmartCalculator />
          <h2>Budget Tips</h2>
          <ol className={classes.budgetTipsList}>
            <li>
              Know your diary and plan your week accordingly. Do you need to prepare a quick meal or will you be eating
              out and don’t need to prepare anything at all?
            </li>
            <li>Make a freezer, fridge and pantry inventory. Utilise these ingredients when choosing recipes.</li>
            <li>Learn how to customise your Meal Plan to swap in recipes which use up leftover ingredients.</li>
            <li>Don&apos;t shop whilst hungry.</li>
            <li>Take your shopping list and stick to it.</li>
            <li>
              Buy only what you need. Consider using the Deli for meat and ricotta in ACTUAL quantities. This is true
              for salad items.
            </li>
            <li>
              Be wary of buying in bulk unless you know you will use products and they will either freeze or keep.
            </li>
            <li>
              Be careful of pre-portioned snack foods, they are up to 10x more expensive than buying and portioning
              yourself.
            </li>
            <li>Consider growing your own herbs (or fruit & vegetables).</li>
            <li>When out and about take your own lunch, snacks and water. </li>
          </ol>
          <p>
            So how do you choose the ‘better’ product? Label reading needn’t be so scary. In fact nutrition label
            mastery will have you armed and ready next time you hit the supermarket.
          </p>
          <p>
            What to look for on a{' '}
            <a href={navigateToGoSite('help/680-understanding-product-labels')}>product’s nutrition label:</a>
          </p>
          <div className={classes.nutritionList}>
            <ul>
              <li>10g or less total fat per 100g food</li>
              <li>3g or less saturated fat per 100g food</li>
              <li>10g or less sugar per 100g food</li>
              <li>4g fibre or more per 100g food</li>
              <li>120mg or less = low sodium</li>
              <li>Lowest sodium count possible</li>
              <li>Lowest kilojoule/calorie count possible (1kcal = 4.2kJ)</li>
            </ul>
          </div>
          <p>
            Did you know that you can send your Meal Plans directly from your weekly shopping list through to Woolworths
            online shopping? Home Shopping is a valuable tool to many of our members.
          </p>
          <p>
            <a href={navigateToGoSite('help/2043-how-can-i-create-a-woolworths-account')}>Click here</a> to know more
            about how to set up a Woolworths online account
          </p>
          <p>
            <b>
              <em>You can buy your 12WBT Pantry Essentials through Woolworths online shopping!</em>
            </b>
          </p>
          <div className={classes.buttonRow}>
            <div className={classes.finish}>
              <ActionButton title={actionButtonTitle} onButtonClick={actionButtonClickHandler} loading={loading} />
            </div>
            <PrintButton />
          </div>
        </TaskContentContainer>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '60px auto'
  },
  budgetTipsList: {
    padding: '1rem',
    '& li': {
      marginBottom: '1rem'
    }
  },
  buttonRow: {
    display: 'flex'
  },
  nutritionList: {
    width: '100%',
    border: '1px solid black',
    '& ul': {
      paddingLeft: '2rem'
    }
  },
  finish: {
    marginRight: '16px'
  }
}))

export default NutOutYourNutritionDoTask
