import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import FitnessTestRadio from './FitnessTestRadio'

const FitnessTestModal = ({ formik, testData, setNewTest, closeModal, currentTest }) => {
  const [activeTest, setActiveTest] = useState(currentTest)
  const classes = useStyles()

  const radioClickHandler = (test) => {
    setActiveTest(test)
  }

  // Clear other fields for later calculation of fitness result:
  const clearRelatedTestInputs = () => {
    const otherTests = testData.tests.filter((test) => test.label !== activeTest?.label)
    for (let i = 0; i < otherTests.length; i++) {
      clearFormikFields(otherTests[i].inputs)
    }
  }

  const clearFormikFields = (inputs) => {
    inputs.forEach((input) => {
      formik.setFieldValue(input.value, '', false)
    })
  }

  const saveButtonClickHandler = () => {
    clearRelatedTestInputs()
    setNewTest(activeTest)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>{testData.category}</div>
      <div className={classes.body}>
        <span>
          The harder the exercise the higher your potential fitness score. Select an exercise that best suits your
          fitness level.
        </span>
        {testData.tests.map((test, i) => {
          return <FitnessTestRadio test={test} key={i} setActive={radioClickHandler} activeTest={activeTest} />
        })}
      </div>
      <div className={classes.footer}>
        <Button onClick={saveButtonClickHandler} className={classes.saveButton}>
          Save
        </Button>
        <Button className={classes.cancelButton} onClick={() => closeModal()}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    height: '3rem',
    borderBottom: `1px solid ${theme.palette.greys.light}`,
    width: '100%',
    ...theme.CSS.center,
    fontSize: '1.3rem'
  },
  body: {
    padding: '1rem'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    textTransform: 'none',
    backgroundColor: theme.palette.signup.purple,
    color: 'white',
    height: '2rem',
    marginTop: '.5rem'
  },
  cancelButton: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.transparent,
    color: theme.palette.primary.dark,
    height: '2rem',
    margin: '.5rem 1rem'
  }
}))

FitnessTestModal.propTypes = {
  testData: PropTypes.oneOfType([(PropTypes.array, PropTypes.object)]),
  setNewTest: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  currentTest: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired
}

export default FitnessTestModal
