import React from 'react'
import TaskContentContainer from '../tasks/TaskContentContainer'
import { makeStyles } from '@material-ui/core'

import nutritionGuideScreenshot1 from '../../../assets/img/pre_season/guides/nutritionGuideScreenshot1.png'
import nutritionGuideScreenshot2 from '../../../assets/img/pre_season/guides/nutritionGuideScreenshot2.png'
import nutritionGuideFilters from '../../../assets/img/pre_season/guides/nutritionGuideFilterHeadings.png'

import mobileNav from '../../../assets/img/pre_season/guides/mobileNav.png'
import menu2 from '../../../assets/img/pre_season/guides/menu2.png'
import week3progress from '../../../assets/img/pre_season/guides/week3progress.png'
import lovearecipe from '../../../assets/img/pre_season/guides/lovearecipe.png'
import step4viewrecipes from '../../../assets/img/pre_season/guides/step4viewrecipes.png'

import navigateToGoSite from '../../../helpers/NavigateToGoSite'

const GuideNavigateNutrition = () => {
  const classes = useStyles()
  return (
    <TaskContentContainer>
      <div className={classes.container}>
        <p>
          To nail your nutrition, you must master your Meal Plan, so it helps to know all the tools on the site that are
          available. We are here to give you a quick ‘walk through’ technical tour for all things ‘nutrition’ related on
          the site.
          <br />
          Keep this page handy.
        </p>
        <p>
          <b>Using a Desktop or Laptop?</b>
        </p>
        <p>
          <b>Food & Nutrition </b>can be found in the top navigation bar:
        </p>
        <img src={nutritionGuideScreenshot1} alt='nutrition guide screenshot 1' />
        <p>If you hover over the TAB you will find links to all things Nutrition within the online website. </p>
        <p>
          Here is a quick outline of what is available to Round and Lifestyle Members under this TAB followed by a quick
          explanation of each:
        </p>
        <span>
          <b>12WBT Round Members:</b>
        </span>
        <ol>
          <li>My Meal Plan</li>
          <li>Recipe Index</li>
          <li>Calorie Lookup Tool</li>
          <li>Nutrition Videos</li>
        </ol>
        <span>
          <b>Lifestyle Members (in addition to above):</b>
        </span>
        <ul>
          <li>Nutrition Hacks</li>
          <li>Ready-made Meal Plans</li>
        </ul>
        <span>
          <b>MY MEAL PLAN</b>
        </span>
        <p>
          As Mish always says. Nutrition is 80% of the journey. We recommend choosing an option that best suits your
          lifestyle needs and taste preferences. You can customise them further if you wish (which we will explain
          later).
        </p>
        <p>The Meal Plan that appears under the Food & Nutrition TAB is the one you will have selected on sign-up.</p>
        <br />
        <span>
          Here is an explanation of your <em>Meal Plan Options:</em>
        </span>
        <br />
        <p>
          <b>Classic:</b> This beloved Meal Plan is the original 12WBT Meal Plan and it includes 5 different breakfast
          recipes, 4 different lunch recipes and 6 different dinner recipes for plenty of variety!
        </p>
        <p>
          <b>Time Saver:</b> The Time Saver Meal Plan is perfect for those who have busy schedules OR for those who
          prefer to spend less time in the kitchen cooking. There is less variety than the Classic Meal Plan, with more
          use of leftovers, but rest easy knowing you are still provided with nutritiously balanced, delicious meals.
        </p>
        <p>
          <b>Low Carb:</b> This is our Meal Plan which uses recipes that include carbs only as they appear in nature.
          With as little processing as possible. This Is the perfect choice for those needing to reduce their
          carbohydrate without sacrificing taste.
        </p>
        <p>
          <b>Budget:</b> A terrific option for those looking for lower cost meals and some bulk cooking options. We
          include cheaper cuts of meat as well as some delicious vegetarian meals to reduce your weekly shop. Just
          remember that any meal bulk cooked should be consumed within 3 days or put in the freezer for food safety
        </p>
        <p>
          You can choose to receive any of the above Meal Plans in a{' '}
          <b>
            <em>Vegetarian</em>
          </b>{' '}
          or{' '}
          <b>
            <em>Regular</em>
          </b>{' '}
          (meat containing) format AND whether you are{' '}
          <b>
            <em>Cooking for One</em>
          </b>{' '}
          or{' '}
          <b>
            <em>Cooking for Two.</em>
          </b>
        </p>
        <p>
          IMPORTANT
          <br />
          To change your Meal Plan choices head to your profile picture in the top navigator toolbar and click on each
          of the following &gt; <em>My Account</em> &gt; <em>Exercise & Meals</em> &gt; choose your{' '}
          <em>calorie selection</em> &gt; Slide to the right the <em>Meal Plan</em>&nbsp; options you prefer AND select
          whether you want <em>Vegetarian</em> AND whether you are <em>Cooking for One</em> &gt; scroll down and click
          SAVE.
        </p>
        <p>
          Click <a href={navigateToGoSite('help/topic/25-meal-plans-recipes')}>here</a> to find out more about your Meal
          Plan layout including Treat Meals, Customising Meal Plans and Snacks OR head to our Help section which is in
          the top navigation bar (Seventh Tab across).
        </p>
        <p>
          <b>RECIPE INDEX</b>
          <br />
          Our Recipe Index contains well over 1200 recipes. You can head into this TAB at any time to look for recipes
          OR It will open up when you use the customisation feature on your weekly Meal Plan.
        </p>
        <p>You can filter to find the recipe index in a few different ways:</p>
        <ol>
          <li>
            <p>Click on one of the TABS in the Recipe index toolbar</p>
            <img src={nutritionGuideScreenshot2} alt='Recipe Index Tab Location' />
          </li>
          <li>Type in a specific recipe or ingredient next to the search icon in the toolbar.</li>
          <li>
            <p>
              Use our Advanced Filters on the left of your Recipe Index screen, which have the following search
              headings:
            </p>
            <img src={nutritionGuideFilters} alt='advanced filter headings' />
          </li>
          <li>
            <p>
              Change the way you <em>view</em> your recipes (in addition to using any of the above filters) by selecting
              the ‘Sort by’ tab in the upper right of the page. This option is fabulous when you want to know the newest
              recipes OR the most popular recipes selected.
            </p>
            <img src={step4viewrecipes} alt='recipe examples' />
          </li>
        </ol>
        <p>
          Love a recipe? Why not add it to the Favourites TAB! Simply click on the heart symbol (so that it becomes
          purple) and you will find it listed under the Favourites tab in the Recipe Index. Doing this makes finding it
          again easy.
        </p>
        <img src={lovearecipe} alt='More recipe examples' />
        <span>It is important to know the following about the Recipe Index:</span>
        <ul>
          <li>
            The main meal recipes within your own index are ALL adjusted for the calorie level you have chosen. This
            means that they are suitable in calories to be swapped into your personal meal plan.
          </li>
          <li>
            Snack recipes are all ~150 calories each, for inspiration. Feel free to choose your own snacks though. Click
            <a href={navigateToGoSite('help/448-snacks')}>here</a> for more on snacks.
          </li>
          <li>
            Dessert, snack and festive recipes don’t appear on the Meal Plans. Feel free to use them on your Treat days
            (more <a href={navigateToGoSite('help/648-what-is-a-treat-meal')}>here</a>) OR for a special event.{' '}
          </li>
          <li>
            Cooking for more than two people? Head to the advanced filter and under the Other heading click on ‘Feed the
            Family’. These can be customised weekly into your meal plans OR click{' '}
            <a href={navigateToGoSite('help/468-cooking-for-others')}>here</a> for tips on cooking for others.
          </li>
        </ul>
        <p>
          <b>CALORIE LOOK-UP TOOL</b>
        </p>
        <p>
          Our Calorie look-up tool is here to help you identify the calories and nutrition of certain foods.
          Specifically, useful when you are tracking your daily food intake.{' '}
        </p>
        <p>
          It is drawn from a scientific database of ‘generic’ ingredients called Nuttab. What this means is that you
          need to enter search terms in a specific way and they need to be ‘generic’ not a ‘brand’. An example of this
          is to find out how many calories are in coffee you would type ‘coffee’ rather than ‘Gloria Jeans cappuccino’
          into the search bar. Please be aware you need to change the ‘weight’ of the ingredient to the amount you are
          looking for OR the calories reported to you will be for 100 grams of that ingredient.
        </p>
        <p>
          <b>NUTRITION VIDEOS</b>
        </p>
        <p>We currently have over 80 videos found in this link discussing all things nutrition.</p>
        <p>There are videos on customising your meal plan, cooking skills, Tips for eating out and lots more. </p>
        <p>
          You can arrive at the page and scroll through the videos OR simply use the Sort feature in the upper right of
          the page which allows you to view them either{' '}
          <b>
            <em>alphabetically</em>
          </b>{' '}
          or by{' '}
          <b>
            <em>recently added.</em>
          </b>
        </p>
        <p>
          <b>Exclusive Lifestyle Access:</b>
          <br /> The next two links will only appear for the Lifestyle Members.
        </p>
        <p>
          <b>NUTRITION HACKS</b>
        </p>
        <p>
          This exclusive area contains nearly 40 informative articles addressing all sorts of topics including topics
          like Fodmap Myths Uncovered, Eating well on a Budget, Busy Week Survival Guide and so many more.
        </p>
        <p>
          <b>Ready Made Meal Plans</b>
        </p>
        <p>
          Again, another exclusive link containing over 30 ready-made meal weekly plans for those wanting to change
          things up a little OR for those in between Rounds.
        </p>
        <p>Plan options include Freezable Dinners, Family Friendly Dinners, Hunger Minimiser Meal Plan and more. </p>
        <p>There are a few more important things under different TABS that we want to show you before you go. </p>
        <p>
          Head to the top navigator toolbar and go to the weekly tab which is 1 space across. In the Drop-down menu you
          will see the following:
        </p>
        <img src={week3progress} alt='Week three progress' />
        <p>
          The first option is <a href={navigateToGoSite('week/3/meal-plans')}>My Meal Plan</a>. We have spoken about how
          to function your Meal Plan above, however this link is simply another way to arrive at your weekly Meal Plan.
        </p>
        <p>
          <b>Using the mobile site?</b>
        </p>
        <p>
          The mobile site works just like the desktop version, the biggest difference is the position of your navigation
          bar. You will find everything mentioned above by clicking on the navigation button (three white lines) in the
          left corner of your screen.
        </p>
        <img src={mobileNav} alt='Mobile Navigation' className={classes.mobileNav} />
        <p>
          <b>Using the 12WBT App?</b>
        </p>
        <p>
          Navigating your Nutrition is simple! Here&apos;s how...
          <br />
          Head to the Nutrition icon in the bottom navigation bar, this will open the Food & Nutrition menu which
          includes:
        </p>
        <ul>
          <li>Your Meal Plan</li>
          <li>Shopping List</li>
          <li>Recipe Index</li>
          <li>Nutrition Hacks - Lifestyle members only</li>
          <li>Ready Made Plans - Lifestyle members only</li>
        </ul>
        <img src={menu2} alt='side-drawer menu' className={classes.mobileNav} />
        <p>
          To make any changes to your Meal Plan preferences including your preferred calorie level head to ‘More…’ in
          the bottom navigation bar. Select ‘My Account’ from the menu, under ‘Program’ tap your current calorie level
          and you will see the list of options.
        </p>
        <p>Under ‘Recipe Preferences’ you can select your meal plan settings.</p>
        <p>
          You can also change these preferences when viewing your weekly Meal Plan, by tapping the options at the top of
          your screen. Be sure to save your changes when prompted so you can access your plans on other devices and the
          changes flow through to your Shopping List.
        </p>
      </div>
    </TaskContentContainer>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    '& img': {
      maxWidth: '100%'
    }
  },
  mobileNav: {
    width: '12rem'
  }
}))

export default GuideNavigateNutrition
