import React from 'react'
import { makeStyles } from '@material-ui/core'
import DefaultClearBtn from '../../../../../../../components/buttons/DefaultClearBtn'
import { useSelector } from 'react-redux'

const FitnessScorePanel = () => {
  const classes = useStyles()
  const actualScore = useSelector((state) => state.fitnessTest.fitnessTestScoreResult?.score)
  const exercisePlanName = useSelector((state) => state.currentSubscription.currentSubscription.exercise_plan_name)

  return (
    <div className={classes.fitnessScorePanel}>
      <p className={classes.title}>Fitness Score</p>
      <p className={classes.score}>{actualScore}</p>
      {exercisePlanName === 'Pregnancy' ? (
        <p className={classes.text}>
          Your Fitness Score is an indicator of your overall fitness level. The highest possible score is 100 (think
          elite athlete!). Use it as a guide to set monthly achievable goals and maintain your recommendation. Train
          safely, with integrity and have fun!
        </p>
      ) : (
        <p className={classes.text}>
          Your fitness score is an indicator of your overall fitness level. The highest possible score is 100 (think
          elite athlete!). Use it as a guide to not only track your improvements but to set monthly achievable goals.
          Train with integrity, intensity (and fun!) and watch your score improve.
        </p>
      )}
      <DefaultClearBtn
        title='Learn More'
        href={`${process.env.REACT_APP_GO_SITE}help/1422829-what-is-the-12wbt-fitness-score`}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  fitnessScorePanel: {
    borderRadius: 4,
    border: '1px solid #b8b8b8',
    padding: '1rem',
    textAlign: 'center'
  },
  title: {
    color: theme.palette.signup.purple,
    fontSize: 22,
    fontWeight: '500',
    padding: 0,
    margin: 0
  },
  score: {
    color: theme.palette.signup.purple,
    fontSize: '4rem',
    margin: '1.5rem 0 2rem 0',
    fontWeight: '500'
  },
  text: {
    fontSize: 16
  }
}))

export default FitnessScorePanel
