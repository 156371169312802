import axiosWrapper from '../axiosInstance'
import { setAuthToken } from '../../app/helpers/AuthTokenHelper'
import {
  LOGIN_WITH_TOKEN_REQUEST,
  LOGIN_WITH_TOKEN_SUCCESS,
  LOGIN_WITH_TOKEN_FAIL,
  MEMBER_LOGIN_REQUEST,
  MEMBER_LOGIN_SUCCESS,
  MEMBER_LOGIN_FAIL,
  MEMBER_LOAD_REQUEST,
  MEMBER_LOAD_SUCCESS,
  MEMBER_LOAD_FAIL,
  GET_MEMBER_SUMMARY_REQUEST,
  GET_MEMBER_SUMMARY_SUCCESS,
  GET_MEMBER_SUMMARY_FAIL,
  GET_CURRENT_SUBSCRIPTION_REQUEST,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_CURRENT_SUBSCRIPTION_FAIL,
  GET_LAST_SUBSCRIPTION_REQUEST,
  GET_LAST_SUBSCRIPTION_SUCCESS,
  GET_LAST_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_PHOTO_REQUEST,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAIL,
  UPDATE_PRE_SEASON_PROGRESS_REQUEST,
  UPDATE_PRE_SEASON_PROGRESS_SUCCESS,
  UPDATE_PRE_SEASON_PROGRESS_FAIL,
  GET_PRE_SEASON_PROGRESS_REQUEST,
  GET_PRE_SEASON_PROGRESS_SUCCESS,
  GET_PRE_SEASON_PROGRESS_FAIL,
  SET_WELCOME_SUBSCRIPTION_ID,
  SET_IS_RETURN_MEMBER,
  SET_CURRENT_ROUND_ID,
  SET_BUILD_A_NEW_PLAN,
  GET_ROUND_MEASUREMENTS_REQUEST,
  GET_ROUND_MEASUREMENTS_SUCCESS,
  GET_ROUND_MEASUREMENTS_FAIL,
  SUBMIT_ROUND_MEASUREMENTS_REQUEST,
  SUBMIT_ROUND_MEASUREMENTS_SUCCESS,
  SUBMIT_ROUND_MEASUREMENTS_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPLOAD_BEFORE_PHOTO_REQUEST,
  UPLOAD_BEFORE_PHOTO_SUCCESS,
  UPLOAD_BEFORE_PHOTO_FAIL,
  CLEAR_UPDATE_SUBSCRIPTION_SUCCESS,
  MEMBER_LOGOUT_REQUEST,
  SET_AUTH_ROUTE_ATTEMPT,
  CLEAR_AUTH_ROUTE_ATTEMPT,
  CLEAR_SUBMIT_ROUND_MEASUREMENTS_SUCCESS,
  GET_ALL_SUBSCRIPTIONS_REQUEST,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  GET_ALL_SUBSCRIPTIONS_FAIL,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  MEMBER_LOGOUT_FAIL,
  MEMBER_LOGOUT_SUCCESS
} from '../constants/Member.constants'

export const loginWithToken = (token) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_WITH_TOKEN_REQUEST })
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}auth/login`,
      { token: token }
    )
    setAuthToken(res.data.auth_token)
    dispatch({ type: LOGIN_WITH_TOKEN_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: LOGIN_WITH_TOKEN_FAIL })
  }
}

export const login = (values) => async (dispatch) => {
  try {
    dispatch({ type: MEMBER_LOGIN_REQUEST })
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}auth/login`,
      values
    )
    setAuthToken(res.data.token)
    dispatch({ type: MEMBER_LOGIN_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: MEMBER_LOGIN_FAIL })
  }
}

export const logout = () => async (dispatch) => {
  try {
    dispatch({ type: MEMBER_LOGOUT_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}auth/logout`
    )
    dispatch({ type: MEMBER_LOGOUT_SUCCESS, payload: res })
  } catch (err) {
    dispatch({ type: MEMBER_LOGOUT_FAIL })
  }
}

export const loadMember = () => async (dispatch) => {
  try {
    dispatch({ type: MEMBER_LOAD_REQUEST })
    if (localStorage.token) {
      setAuthToken(localStorage.token)
    }
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}auth/refresh`
    )
    dispatch({ type: MEMBER_LOAD_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: MEMBER_LOAD_FAIL })
  }
}

export const getMemberSummary = () => async (dispatch) => {
  try {
    dispatch({ type: GET_MEMBER_SUMMARY_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/summary`
    )
    dispatch({ type: GET_MEMBER_SUMMARY_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_MEMBER_SUMMARY_FAIL })
  }
}

export const getAllSubscriptions = (memberId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SUBSCRIPTIONS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}/subscriptions`
    )
    dispatch({ type: GET_ALL_SUBSCRIPTIONS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_ALL_SUBSCRIPTIONS_FAIL })
  }
}

export const getLastSubscription = (memberId, subscriptionId) => async (
  dispatch
) => {
  try {
    dispatch({ type: GET_LAST_SUBSCRIPTION_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}/subscriptions/${subscriptionId}`
    )
    dispatch({ type: GET_LAST_SUBSCRIPTION_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_LAST_SUBSCRIPTION_FAIL })
  }
}

export const getCurrentSubscription = (memberId, subscriptionId) => async (
  dispatch
) => {
  try {
    dispatch({ type: GET_CURRENT_SUBSCRIPTION_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}/subscriptions/${subscriptionId}`
    )
    dispatch({ type: GET_CURRENT_SUBSCRIPTION_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_CURRENT_SUBSCRIPTION_FAIL })
  }
}

export const updateSubscription = (memberId, subscriptionId, values) => async (
  dispatch
) => {
  try {
    dispatch({ type: UPDATE_SUBSCRIPTION_REQUEST })
    await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}/subscriptions/${subscriptionId}`,
      values
    )
    dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS })
  } catch (err) {
    dispatch({ type: UPDATE_SUBSCRIPTION_FAIL })
  }
}

export const updateProfile = (memberId, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST })
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}/profile`,
      values
    )
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: UPDATE_PROFILE_FAIL })
  }
}

export const updateMember = (memberId, values) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MEMBER_REQUEST })
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}`,
      values
    )
    dispatch({ type: UPDATE_MEMBER_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: UPDATE_MEMBER_FAIL })
  }
}

// Calling this will trigger an update for welcome steps progress to progress from step1 to step2:
export const setBuildANewPlan = () => async (dispatch) => {
  dispatch({ type: SET_BUILD_A_NEW_PLAN })
}

export const updateProfilePhoto = (memberId, file) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_PHOTO_REQUEST })
    const data = new FormData()
    data.append('member_profile[avatar]', file, file.name)
    await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}/profile`,
      data,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      }
    )
    dispatch({ type: UPDATE_PROFILE_PHOTO_SUCCESS })
  } catch (err) {
    dispatch({ type: UPDATE_PROFILE_PHOTO_FAIL })
  }
}

export const updatePreSeasonProgress = (subscriptionId, values) => async (
  dispatch
) => {
  try {
    dispatch({ type: UPDATE_PRE_SEASON_PROGRESS_REQUEST })
    await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST_OLD}v1/subscriptions/${subscriptionId}/preseason-progress`,
      values
    )
    dispatch({ type: UPDATE_PRE_SEASON_PROGRESS_SUCCESS })
    dispatch(getPreSeasonProgress(subscriptionId))
  } catch (err) {
    dispatch({ type: UPDATE_PRE_SEASON_PROGRESS_FAIL })
  }
}

export const getPreSeasonProgress = (subscriptionId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRE_SEASON_PROGRESS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/subscriptions/${subscriptionId}/preseason-progress`
    )
    dispatch({ type: GET_PRE_SEASON_PROGRESS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_PRE_SEASON_PROGRESS_FAIL })
  }
}

export const getRoundMeasurements = (roundId) => async (dispatch) => {
  try {
    dispatch({ type: GET_ROUND_MEASUREMENTS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/rounds/${roundId}/measurements`
    )
    dispatch({ type: GET_ROUND_MEASUREMENTS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_ROUND_MEASUREMENTS_FAIL })
  }
}

export const submitRoundMeasurements = (
  roundId,
  measurementId,
  values
) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_ROUND_MEASUREMENTS_REQUEST })
    // TODO: Fix Endpoint not needing to be called twice to get results
    await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST_OLD}v1/rounds/${roundId}/measurements/${measurementId}`,
      values
    )
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST_OLD}v1/rounds/${roundId}/measurements/${measurementId}`,
      values
    )
    dispatch({ type: SUBMIT_ROUND_MEASUREMENTS_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: SUBMIT_ROUND_MEASUREMENTS_FAIL })
  }
}

export const getProfile = (memberId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROFILE_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST_OLD}v1/members/${memberId}/profile`
    )
    dispatch({ type: GET_PROFILE_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_PROFILE_FAIL })
  }
}

export const setCurrentSubscriptionId = (id) => async (dispatch) => {
  dispatch({ type: SET_WELCOME_SUBSCRIPTION_ID, payload: id })
}

export const setCurrentRoundId = (id) => async (dispatch) => {
  dispatch({ type: SET_CURRENT_ROUND_ID, payload: id })
}

export const setIsReturnMember = () => (dispatch) => {
  dispatch({ type: SET_IS_RETURN_MEMBER })
}

export const clearUpdateSubscriptionSuccess = () => (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_SUBSCRIPTION_SUCCESS })
}

export const uploadBeforePhoto = (memberId, subscriptionId, file) => async (
  dispatch
) => {
  try {
    dispatch({ type: UPLOAD_BEFORE_PHOTO_REQUEST })
    const data = new FormData()
    data.append('subscription[before_photo]', file, file.name)
    await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}v1/members/${memberId}/subscriptions/${subscriptionId}`,
      data,
      {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        }
      }
    )
    dispatch({ type: UPLOAD_BEFORE_PHOTO_SUCCESS })
  } catch (err) {
    dispatch({ type: UPLOAD_BEFORE_PHOTO_FAIL })
  }
}

// Used to save the protected route that failed authentication:
export const setAuthRouteAttempt = (url) => (dispatch) => {
  dispatch({ type: SET_AUTH_ROUTE_ATTEMPT, payload: url })
}

// To clear saved failed protected route on successful login:
export const clearAuthRouteAttempt = () => (dispatch) => {
  dispatch({ type: CLEAR_AUTH_ROUTE_ATTEMPT })
}

export const clearSubmitRoundMeasurmentsSuccess = () => (dispatch) => {
  dispatch({ type: CLEAR_SUBMIT_ROUND_MEASUREMENTS_SUCCESS })
}
