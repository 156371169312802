import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import ContentContainer from '../../../../components/ContentContainer'
import TaskLearnButtonRow from '../TaskLearnButtonRow'
import PropTypes from 'prop-types'
import TaskContentContainer from '../TaskContentContainer'
import TaskVideo from '../TaskVideo'

const NutOutYourNutritionLearn = ({ doTaskButtonClick }) => {
  const classes = useStyles()
  return (
    <ContentContainer maxWidth='xl'>
      <div className={classes.container}>
        <Box m='96px' />
        <TaskVideo videoId='2960075675001' />
        <TaskContentContainer>
          <p>
            Nutrition is everything when it comes to our health! You really are what you eat, both physically AND
            mentally. So, if you&apos;re looking to optimise your nutrition, then consistency with healthy food habits
            is key.
          </p>
          <p>
            Creating <b>new</b> food habits can be a little more tricky if you are confronted with all your favourite
            treat food temptations every time you enter your kitchen.
          </p>
          <p>
            In this task you&apos;ll get the opportunity to get stuck into creating a kitchen which serves you. One
            which is stocked with whole foods and healthy snacks and which not only provides you with all the nutrients
            you need for vitality and good health BUT one that makes creating long term habits easier.
          </p>
          <p>
            <b>
              <em>
                If you haven&apos;t already, make sure you stop reading this and watch the video first before getting
                stuck into our kitchen makeover!
              </em>
            </b>
          </p>
          <span className={classes.goalsHeader}>Our goals today are to:</span>
          <ul>
            <li>
              Replace all the refined foods in your kitchen with fresh whole foods. If you&apos;re feeling a bit
              hesitant to throw food away, take a peek at <a href='https://www.givenow.com.au/'>GiveNow</a> for local
              charities to donate it to OR consider donating the cash value of the food thrown away to{' '}
              <a href='https://www.foodbank.org.au/'>Foodbank Australia.</a>
            </li>
            <li>Make a shopping list of measuring tools and pantry essentials to take with you to your next shop.</li>
            <li>Learn about minimising your shopping costs and reading nutrition labels.</li>
            <li>
              Have an enrollment conversation with everyone else in the house. You’ve already committed your goals to
              them (or will be in the coming weeks). Explain that you will be eating differently now and a lot of the
              treat food that was allowed in the house before will be gone for good. Remember kids learn best from
              imitation. By making positive changes to the way you eat, you will be setting your kids up for a healthier
              future too.
            </li>
          </ul>
          <TaskLearnButtonRow doTaskButtonClick={doTaskButtonClick} />
        </TaskContentContainer>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  goalsHeader: {
    fontSize: '24px',
    fontWeight: 800
  },
  container: {
    '& li': {
      marginBottom: '1rem'
    }
  }
}))

NutOutYourNutritionLearn.propTypes = {
  doTaskButtonClick: PropTypes.func.isRequired
}

export default NutOutYourNutritionLearn
