import React from 'react'
import ReactDOM from 'react-dom'
import { makeStyles } from '@material-ui/core'

const Backdrop = ({ onClick, children }) => {
  const classes = useStyles()
  return ReactDOM.createPortal(
    <div className={classes.backdrop} onClick={onClick}>
      <div className={classes.innerContent} id='react-testid-backdrop'>
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('backdrop-hook')
  )
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'fixed',
    width: '100%',
    height: '110vh',
    background: 'rgba(0,0,0,0.74)',
    zIndex: 100,
    ...theme.CSS.center,
    top: '-50px' // To cover header of page
  },
  innerContent: {
    position: 'relative',
    zIndex: 101,
    display: 'fixed',
    width: 'min-content'
  }
}))

export default Backdrop
