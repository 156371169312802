import React from 'react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core'

const ContentContainer = ({ children, maxWidth, maxPixels, maxWidthPercentage }) => {
  const classes = useStyles({ maxPixels, maxWidthPercentage })
  return (
    <Container maxWidth={maxWidth} classes={{ root: classes.root }}>
      {children}
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.up('lg')]: {
    root: {
      maxWidth: (props) => (props.maxPixels ? `${props.maxPixels}px` : `${props.maxWidthPercentage}%`)
    }
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      padding: 0
    }
  }
}))

ContentContainer.defaultProps = {
  maxWidth: 'lg'
}

ContentContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  maxWidth: PropTypes.string,
  maxPixels: PropTypes.number,
  maxWidthPercentage: PropTypes.number
}

export default ContentContainer
