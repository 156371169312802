import React, { useEffect, useRef } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import TextAreaInput from '../../../../components/fields/TextAreaInput'
import { useFormik } from 'formik'
import ActionButton from '../ActionButton'
import TaskInfoBanner from '../TaskInfoBanner'
import TaskContentSubHeader from '../TaskContentSubHeader'
import ExcusesTable from './ExcusesTable'
import { overallGoalOptions } from './GoalSettingFormData'
import SelectInput from '../../../../components/fields/SelectInput'
import { tasks, SET_YOURSELF_UP_FOR_SUCCESS } from '../TaskData'
import { useDispatch, useSelector } from 'react-redux'
import { getPreSeasonTask } from '../../../../helpers/GetPreSeasonTask'
import { updatePreSeasonTask, submitPreSeasonTask } from '../../../../../redux/actions/PreSeason.actions'

function checkTaskValue(taskValue, task) {
  return task && task.answer[taskValue] ? task.answer[taskValue] : ''
}

const GoalSettingForm = () => {
  const dispatch = useDispatch()
  const scrollRef = useRef(null)
  const { loading } = useSelector((state) => state.newPreSeason)
  const { currentSubscriptionId } = useSelector((state) => state.member)

  const taskTitle = tasks[SET_YOURSELF_UP_FOR_SUCCESS].header
  const task = getPreSeasonTask(taskTitle)
  const isComplete = task
  const actionButtonTitle = isComplete ? 'Update' : 'Finish'
  const classes = useStyles()

  const formik = useFormik({
    initialValues: {
      internalExcuses: checkTaskValue('internalExcuses', task),
      internalExcusesSolutions: checkTaskValue('internalExcusesSolutions', task),
      externalExcusesWithMyControl: checkTaskValue('externalExcusesWithMyControl', task),
      externalExcusesWithMyControlSolutions: checkTaskValue('externalExcusesWithMyControlSolutions', task),
      externalExcusesOutsideMyControl: checkTaskValue('externalExcusesOutsideMyControl', task),
      externalExcusesOutsideMyControlSolutions: checkTaskValue('externalExcusesOutsideMyControlSolutions', task),
      whatsYourWhy: checkTaskValue('whatsYourWhy', task),
      whatsYourOverallGoal: checkTaskValue('whatsYourOverallGoal', task),
      week4Goals: checkTaskValue('week4Goals', task),
      week4GoalsHowWillIGetThere: checkTaskValue('week4GoalsHowWillIGetThere', task),
      week4GoalsReward: checkTaskValue('week4GoalsReward', task),
      week8Goals: checkTaskValue('week8Goals', task),
      week8GoalsHowWillIGetThere: checkTaskValue('week8GoalsHowWillIGetThere', task),
      week8GoalsReward: checkTaskValue('week8GoalsReward', task),
      week12Goals: checkTaskValue('week12Goals', task),
      week12GoalsHowWillIGetThere: checkTaskValue('week12GoalsHowWillIGetThere', task),
      week12GoalsReward: checkTaskValue('week12GoalsReward', task),
      sixMonthsGoals: checkTaskValue('sixMonthsGoals', task),
      sixMonthsGoalsHowWillIGetThere: checkTaskValue('sixMonthsGoalsHowWillIGetThere', task),
      sixMonthsGoalsReward: checkTaskValue('sixMonthsGoalsReward', task),
      twelveMonthsGoals: checkTaskValue('twelveMonthsGoals', task),
      twelveMonthsGoalsHowWillIGetThere: checkTaskValue('twelveMonthsGoalsHowWillIGetThere', task),
      twelveMonthsGoalsReward: checkTaskValue('twelveMonthsGoalsReward', task)
    },
    onSubmit: (values) => {
      if (!isComplete) dispatch(submitPreSeasonTask(currentSubscriptionId, { title: taskTitle, answer: values }))
      else dispatch(updatePreSeasonTask(currentSubscriptionId, { title: taskTitle, answer: values, _id: task._id }))
    }
  })

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [isComplete])

  const actionButtonClickHandler = () => {
    formik.submitForm()
    window.scrollTo({ top: 0, behaviour: 'smooth' })
  }

  const defaultDropdownGoal = () => {
    const previouslySubmittedValue = checkTaskValue('whatsYourOverallGoal', task)
    if (previouslySubmittedValue) {
      const { value } = overallGoalOptions.find((g) => g.value === previouslySubmittedValue)
      return value
    }
    return ''
  }

  return (
    <>
      <ExcusesTable formik={formik} />
      <span className={classes.header}>Part 2: My Goals and rewards</span>
      <TaskContentSubHeader title='How this works' />
      <ol>
        <li>
          Spend some time exploring your why and what is your overall goal. Your overall goal will always help you in
          choosing the best Fitness Program for you to follow.
        </li>
        <li>
          Take time to think about your goals. Plan what you want to achieve at every milestone point within a round-
          Week 4, Week 8 and Week 12. Make sure that you’re using the S.M.A.R.T approach for goal setting.
        </li>
        <li>
          Outline what actions you might take to help you get there. e.g. get up early to exercise, aim for 4 x workouts
          per week or do a weekend cook-up.
        </li>
        <li>
          Create a reward system for each goal achieved (non-food related). These can help you in keeping focused, on
          track and there’s nothing better than being rewarded for all your hard work and efforts.
        </li>
        <li ref={scrollRef}>
          Setting goals that extend beyond a round, 6 months or 12 months, ensure that you keep prioritising your health
          as part of a long term approach. This is also ideal if you are one of our Lifestyle members completing
          multiple rounds across the course of a year.
        </li>
      </ol>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.singleTextAreaRow}>
            <span className={classes.subHeader}>What&apos;s your why?</span>
            <TextAreaInput formik={formik} name='whatsYourWhy' />
          </Grid>
          <Grid item xs={12} className={classes.singleTextAreaRow}>
            <span className={classes.subHeader}>What&apos;s your overall goal? (Drop down box options)</span>
            <SelectInput
              name='whatsYourOverallGoal'
              options={overallGoalOptions}
              formik={formik}
              defaultValue={defaultDropdownGoal()}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.headerRow}>
              <span className={classes.header}>Short term goals</span>
            </div>
            <span className={classes.subHeader}>Round goals:</span>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week4Goals' label='Week 4' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week4GoalsHowWillIGetThere' label='How will I get there?' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week4GoalsReward' label='Reward:' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week8Goals' label='Week 8' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week8GoalsHowWillIGetThere' label='How will I get there?' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week8GoalsReward' label='Reward:' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week12Goals' label='Week 12' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week12GoalsHowWillIGetThere' label='How will I get there?' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='week12GoalsReward' label='Reward:' />
          </Grid>
          <Grid item xs={12} className={classes.headerRow}>
            <span className={classes.header}>Medium term goals</span>&nbsp;
            <span className={classes.headerOptional}>(optional)</span>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='sixMonthsGoals' label='6 month goals' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='sixMonthsGoalsHowWillIGetThere' label='How will I get there?' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='sixMonthsGoalsReward' label='Reward:' />
          </Grid>
          <Grid item xs={12} className={classes.headerRow}>
            <span className={classes.header}>Long term goals</span>&nbsp;
            <span className={classes.headerOptional}>(optional)</span>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='twelveMonthsGoals' label='12 month goals:' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='twelveMonthsGoalsHowWillIGetThere' label='How will I get there?' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextAreaInput formik={formik} name='twelveMonthsGoalsReward' label='Reward:' />
          </Grid>
        </Grid>
      </div>
      <div className={classes.formSubmitSection}>
        <TaskInfoBanner message='The information you provide here is for your personal reference only and will not be posted publicly' />
        <div className={classes.buttonContainer}>
          <ActionButton title={actionButtonTitle} onButtonClick={actionButtonClickHandler} loading={loading} />
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: theme.palette.greys.light,
    padding: '1.5rem 1rem',
    marginTop: '1rem'
  },
  subHeader: {
    margin: '0rem 0',
    fontWeight: 900
  },
  headerRow: {
    width: '100%',
    textAlign: 'start',
    margin: '.5rem 0'
  },
  header: {
    margin: 'auto',
    fontSize: '1.4rem',
    fontWeight: 800
  },
  formSubmitSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5rem'
  },
  buttonContainer: {
    marginTop: '1rem'
  },
  [theme.breakpoints.down('md')]: {
    header: {
      fontSize: '1.3rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    formSubmitSection: {
      margin: '1rem 0',
      ...theme.CSS.center
    },
    header: {
      fontSize: '1.2rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      fontSize: '1.15rem'
    }
  }
}))

export default GoalSettingForm
