import React, { useState } from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import ToolTipSwitchRoundModal from './ToolTipSwitchRoundModal'

const TooltipSwitchRoundBtn = () => {
  const subscriptions = useSelector((state) => state.subscriptions?.subscriptions)
  const [isSwitchRoundDropDownOpen, setIsSwitchRoundDropDownOpen] = useState(false)
  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentSubscription = useSelector((state) => state.currentSubscription?.currentSubscription)

  const getSelectedRoundName = () => {
    if (subscriptions && currentSubscription) {
      return subscriptions.find((subscription) => subscription.round_id === currentSubscription.round_id).round_name
    }
  }

  const onOpenSwitchRoundModal = (isModalOpen) => {
    if (!isModalOpen) setIsSwitchRoundDropDownOpen(false)
    setIsModalOpen(isModalOpen)
  }

  const navigateToGoSiteSwitchingRound = (roundId) => {
    return `${process.env.REACT_APP_GO_LOGIN}?t=${localStorage.getItem(
      'auth_token'
    )}&roundId=${roundId}&redirectUrl=dashboard`
  }

  return subscriptions ? (
    <div onClick={() => setIsSwitchRoundDropDownOpen(!isSwitchRoundDropDownOpen)} className={classes.switchRoundLink}>
      Round: {getSelectedRoundName()} (AU)
      <KeyboardArrowDown />
      {isSwitchRoundDropDownOpen ? (
        <div className={classes.switchDropDownWindow}>
          <a
            href={navigateToGoSiteSwitchingRound(subscriptions[0].round_id)}
            className={classes.switchDropDownWindowLink}
          >
            {subscriptions[0].round_name}
          </a>
          {subscriptions[1] && (
            <a
              href={navigateToGoSiteSwitchingRound(subscriptions[1].round_id)}
              className={classes.switchDropDownWindowLink}
            >
              {subscriptions[1].round_name}
            </a>
          )}
          {subscriptions[2] && (
            <a
              href={navigateToGoSiteSwitchingRound(subscriptions[2].round_id)}
              className={classes.switchDropDownWindowLink}
            >
              {subscriptions[2].round_name}
            </a>
          )}
          {subscriptions.length > 3 ? (
            <div className={classes.switchDropDownWindowLink}>
              <a onClick={() => setIsModalOpen(true)}>All rounds...</a>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <ToolTipSwitchRoundModal isModalOpen={isModalOpen} setIsModalOpen={onOpenSwitchRoundModal} />
    </div>
  ) : (
    <></>
  )
}

const useStyles = makeStyles((theme) => ({
  switchDropDownWindow: {
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,.2);',
    top: '1.5rem',
    boxShadow: '0 5px 10px rgb(0 0 0 / 20%)'
  },
  switchDropDownWindowLink: {
    padding: '0.3rem 1rem',
    width: '100%',
    display: 'block',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.signup.purple
    }
  },
  switchRoundLink: {
    minWidth: '12rem',
    color: theme.palette.signup.purple,
    fontWeight: 'bold',
    margin: '.4rem 0',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '&:hover': {
      color: 'black',
      cursor: 'pointer'
    },
    a: {
      textDecoration: 'none',
      color: theme.palette.signup.purple
    }
  }
}))

export default TooltipSwitchRoundBtn
