export const snackSwapResults = [
  {
    threshold: 2100,
    feedback: "That's the equivalent of running for over 5 hours or doing about 890 burpees!"
  },
  {
    threshold: 2000,
    feedback: "That's the equivalent of running for over 5 hours or doing about 870 burpees!"
  },
  {
    threshold: 1900,
    feedback: "That's the equivalent of running for over 5 hours or doing about 830 burpees!"
  },
  {
    threshold: 1800,
    feedback: "That's the equivalent of running for over 4 hours or doing about 780 burpees!"
  },
  {
    threshold: 1700,
    feedback: "That's the equivalent of running for over 4 hours or doing about 740 burpees!"
  },
  {
    threshold: 1600,
    feedback: "That's the equivalent of running for over 4 hours or doing about 700 burpees!"
  },
  {
    threshold: 1500,
    feedback: "That's the equivalent of running for over 3 hours or doing about 660 burpees!"
  },
  {
    threshold: 0,
    feedback: "That's the equivalent of running for over 3 hours or doing about 595 burpees!"
  }
]

export function getFeedback(caloriesSaved) {
  for (let i = 0; i < snackSwapResults.length - 1; i++) {
    if (caloriesSaved < snackSwapResults[i].threshold && caloriesSaved > snackSwapResults[i + 1].threshold)
      return snackSwapResults[i + 1].feedback
  }
  return "That's the equivalent of running for over 5 hours or doing about 890 burpees!"
}
