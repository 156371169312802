import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from './header/Header'
import Footer from './footer/Footer'
import { makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { getRoundInfo } from '../../redux/actions/Signup.actions'

import loadCurrentSubscriptionAndRound from '../helpers/LoadCurrentSubscriptionAndRound'
import loadMemberProfile from '../helpers/LoadMemberProfile'

const PageContainer = ({ children }) => {
  const dispatch = useDispatch()
  const currentRoundId = useSelector((state) => state.member.currentRoundId)
  const roundInfo = useSelector((state) => state.round.roundInfo)
  const loading = useSelector((state) => state.round.loading)
  const classes = useStyles()
  const location = useLocation()

  // Setup current round + subscription id's in redux: (Eventually this will be removed as currentRound + currentSubscription will come from memberSummary):
  loadCurrentSubscriptionAndRound()

  // Load member profile:
  loadMemberProfile()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location.pathname])

  // Get round start:
  useEffect(() => {
    if (currentRoundId && !loading && !roundInfo) dispatch(getRoundInfo(currentRoundId))
  }, [currentRoundId])

  return (
    <div className={classes.pageContainer}>
      <Header />
      <div className={classes.childWrapper}>{children}</div>
      <div className={classes.footerContainer}>
        <Footer />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    minHeight: '100vh'
  },
  childWrapper: {
    marginTop: '50px'
  },
  footerContainer: {}
}))

PageContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default PageContainer
