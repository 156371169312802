import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'

const ActionButton = ({ onButtonClick, title, loading }) => {
  const classes = useStyles()
  return (
    <Button className={classes.doTaskButton} onClick={() => onButtonClick()} disabled={loading}>
      {loading ? <CircularProgress size={30} /> : title}
    </Button>
  )
}

const useStyles = makeStyles((theme) => ({
  doTaskButton: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#2563EB',
    borderRadius: '6px',
    width: '138px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    '&:hover': {
      border: `1px solid #2563EB`,
      color: '#2563EB'
    }
  }
}))

ActionButton.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool
}

export default ActionButton
