import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import DefaultClearBtn from '../../../components/buttons/DefaultClearBtn'

const TaskContentSubHeader = ({ title, btnFunction, btnTitle }) => {
  const classes = useStyles()
  return (
    <>
      <h4 className={classes.title}>
        {title} {btnFunction && <DefaultClearBtn title={btnTitle} onClick={btnFunction} />}
      </h4>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    margin: '1rem 0'
  },
  [theme.breakpoints.down('md')]: {
    title: {
      fontSize: '1.2rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '1.1rem'
    }
  }
}))

TaskContentSubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  btnFunction: PropTypes.func,
  btnTitle: PropTypes.string
}

export default TaskContentSubHeader
