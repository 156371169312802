import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import navigateToGoSite from '../../../helpers/NavigateToGoSite'

const PreSeasonLinkCard = ({ title, image, link, externalTo12WBT }) => {
  const classes = useStyles({ image })
  const linkToRender = !externalTo12WBT ? navigateToGoSite(link) : link
  return (
    <a href={linkToRender} className={classes.linkContainer}>
      <div className={classes.overlay}>
        <span className={classes.title}>{title}</span>
      </div>
    </a>
  )
}

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    width: '226px',
    height: '252px',
    backgroundImage: (props) => `url(${props.image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    position: 'relative',
    overflow: 'hidden',
    margin: '0 10px',
    '& :hover': {
      cursor: 'pointer'
    }
  },
  title: {
    fontWeight: 700,
    color: 'white',
    position: 'absolute',
    fontSize: '24px',
    lineHeight: '32px',
    maxWidth: '60%'
  },
  overlay: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0, 0.25)',
    ...theme.CSS.center
  },
  [theme.breakpoints.down(1300)]: {
    linkContainer: {
      width: '180px',
      height: '200px'
    }
  },
  [theme.breakpoints.down(1060)]: {
    linkContainer: {
      width: '160px',
      height: '180px'
    }
  },
  [theme.breakpoints.down('md')]: {
    linkContainer: {
      width: '684px',
      height: '300px',
      marginBottom: '20px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    linkContainer: {
      width: '550px',
      height: '200px',
      marginBottom: '20px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    linkContainer: {
      width: '90vw',
      height: '150px'
    },
    title: {
      maxWidth: '100%'
    }
  }
}))

PreSeasonLinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  externalTo12WBT: PropTypes.bool
}

export default PreSeasonLinkCard
