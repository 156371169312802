import React from 'react'
import PropTypes from 'prop-types'

import { Grid, makeStyles } from '@material-ui/core'
import MeasureUpInput from './MeasureUpInput'
import MeasureUpInputCircle from './MeasureUpInputCircle'

import measureUpFemale from '../../../../../assets/img/pre_season/measureUpFemale.png'
import measureUpMale from '../../../../../assets/img/pre_season/measureUpMale.png'

const MeasureUpChart = ({ formik }) => {
  const classes = useStyles()
  const isFemale = true
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <div className={classes.row}>
                <span className={classes.inputLabel}>Height</span>
                <MeasureUpInputCircle color='#00B5F2' />
              </div>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='height' />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <span className={classes.inputLabel}>Weight</span>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='weight' unitOfMeasurement='kg' />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <div className={classes.row}>
                <span className={classes.inputLabel}>Chest</span>
                <MeasureUpInputCircle color='#10B70F' />
              </div>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='chest' />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <div className={classes.row}>
                <span className={classes.inputLabel}>Waist</span>
                <MeasureUpInputCircle color='#F98D21' />
              </div>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='waist' />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <div className={classes.row}>
                <span className={classes.inputLabel}>Hip</span>
                <MeasureUpInputCircle color='#EB3A3A' />
              </div>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='hip' />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <div className={classes.row}>
                <span className={classes.inputLabel}>Arm</span>
                <MeasureUpInputCircle color='#F17BB6' />
              </div>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='arm' />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <div className={classes.row}>
                <span className={classes.inputLabel}>Leg</span>
                <MeasureUpInputCircle color='#8E38A7' />
              </div>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='leg' />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputRow}>
              <div className={classes.row}>
                <span className={classes.inputLabel}>Distance from knee</span>
                <MeasureUpInputCircle color='#000' />
              </div>
              <div className={classes.inputContainer}>
                <MeasureUpInput formik={formik} name='distanceFromKnee' />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={classes.imageDiv}>
            <img src={isFemale ? measureUpFemale : measureUpMale} alt='Body Chart' />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  inputLabel: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '19px',
    marginRight: '.5rem',
    margin: '1rem .5rem',
    marginLeft: 0,
    maxWidth: '6rem'
  },
  imageDiv: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    '& img': {
      width: '100%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '0 .5rem'
    },
    inputLabel: {
      fontSize: '16px',
      maxWidth: '4rem'
    },
    inputContainer: {
      maxWidth: '10rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      maxWidth: '80%',
      margin: 'auto'
    },
    inputContainer: {
      maxWidth: '12rem'
    }
  },
  [theme.breakpoints.down(400)]: {
    container: {
      maxWidth: '95%'
    }
  }
}))

MeasureUpChart.propTypes = {
  formik: PropTypes.object.isRequired
}

export default MeasureUpChart
