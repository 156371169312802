import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'

import ContentContainer from '../../../../components/ContentContainer'
import YourWhySection from './YourWhySection'
import TaskLearnButtonRow from '../TaskLearnButtonRow'
import TaskContentSubHeader from '../TaskContentSubHeader'
import TaskContentContainer from '../TaskContentContainer'
import TaskVideo from '../TaskVideo'
import { useSelector } from 'react-redux'

const SetYourselfUpForSuccessLearn = ({ doTaskButtonClick }) => {
  const { currentSubscription } = useSelector((state) => state.currentSubscription)
  const classes = useStyles()
  return (
    <ContentContainer maxWidth='xl'>
      <Box m='96px' />
      <TaskVideo videoId={currentSubscription?.lifers ? '6098584295001' : '2715157032001'} />
      <TaskContentContainer>
        <p>
          This task combines 2 important elements on your 12WBT journey. You&apos;ll be taking full responsibility for
          yourself and how you think as well as setting your goals and how you’re going to achieve them.
        </p>
        <span className={classes.header}>Step 1 - Identifying your Excuses</span>
        <TaskContentSubHeader title="Here's the Reality" />
        <ul className={classes.spacedList}>
          <li>I cannot want this for you. I cannot get you your results. Only YOU can want this for yourself</li>
          <li>
            If you want to transform yourself, you&apos;ve got to let go of the excuses that are holding you back and
            make way for a breakthrough.
          </li>
          <li>Don&apos;t rely on motivation- consistency is key!</li>
        </ul>
        <TaskContentSubHeader title='Three types of excuses:' />
        <ul className={classes.spacedList}>
          <li>
            <b>Internal Excuses:</b> The self-talk that goes on between the Jekyll and Hyde in your head. For example:
            ‘I’m too tired’, ‘I’m not motivated enough’ or ‘I might fail’
          </li>
          <li>
            <b>External Excuses within your control:</b> These are excuses prompted by external factors but which you
            still have some control over. For example: ‘I’m too busy’, ‘It’s too hot/cold’ or ‘I can’t afford it’.
          </li>
          <li>
            <b>External Excuses outside of your control:</b> These are external factors that you have absolutely no
            control over, real emergencies. For example: a sick child, family or work crisis. Unfortunately, these
            things are all a part of life and we have to accept that they will happen.
          </li>
        </ul>
        <span className={classes.header}>Step 2 - Setting your Goals</span>
      </TaskContentContainer>
      <Box m='15px' />
      <TaskVideo videoId='5667659327001' />
      <TaskContentContainer>
        <p>Before setting your goal, you need to find your why.</p>
        <TaskContentSubHeader title="What's your Why?" />
        <p>When we are thinking about our ‘why’, this is our purpose, cause or belief that drives every one of us.</p>
        <YourWhySection />
        <p className={classes.header}>Goal Setting:</p>
        <TaskContentSubHeader title='Your Goals should be S.M.A.R.T' />
        <ol className={classes.smartList}>
          <li>
            <p>
              <b>Specific:</b> What exactly is your goal? To feel healthy is not specific. To lose 2kg in a month is
              specific.
            </p>
          </li>
          <li>
            <p>
              <b>Measurable:</b> Make sure you can measure your improvements along the way. You might have body shape
              measurements like weight, cms loss or dropping a dress size or performance measurements like running 4kms
              without stopping.
            </p>
          </li>
          <li>
            <p>
              <b>Achievable:</b> Is it actually possible to achieve your goal? In your heart, you need to believe that
              you can actually get there.
            </p>
          </li>
          <li>
            <p>
              <b>Realistic:</b> This is one notch higher than achievable. It might be possible for you to run a marathon
              (with the right amount of training) but is it realistic given your lifestyle and other demands on your
              time.
            </p>
          </li>
          <li>
            <p>
              <b>Time-based:</b> You need to set your goals against a timeframe.
            </p>
          </li>
        </ol>
        <TaskLearnButtonRow doTaskButtonClick={doTaskButtonClick} />
      </TaskContentContainer>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1.4rem',
    fontWeight: 'bold'
  },
  spacedList: {
    '& li': {
      marginBottom: '1rem'
    }
  },
  smartList: {
    '& li::marker': {
      fontWeight: 'bold'
    }
  },
  [theme.breakpoints.down('md')]: {
    header: {
      fontSize: '1.3rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    header: {
      fontSize: '1.2rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    header: {
      fontSize: '1.15rem'
    }
  }
}))

SetYourselfUpForSuccessLearn.propTypes = {
  doTaskButtonClick: PropTypes.func.isRequired
}

export default SetYourselfUpForSuccessLearn
