import React from 'react'
import { makeStyles } from '@material-ui/core'
import HeaderTooltipList from './HeaderTooltipList'
import { COMMUNITY_LINKS } from './HeaderDataDesktop'
import { ReactComponent as MyFeed } from '../../assets/img/icons/pre_season/MyFeed.svg'
import navigateToGoSite from '../../helpers/NavigateToGoSite'

const TooltipContentCommunity = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <HeaderTooltipList list={COMMUNITY_LINKS} />
      <a href={navigateToGoSite('forums/my-feed')} className={classes.feedContainer}>
        <MyFeed />
        <span>My Feed</span>
      </a>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '20rem',
    '& > div': {
      width: '50%'
    }
  },
  feedContainer: {
    width: '50%',
    borderRadius: '6px',
    borderLeft: `1px solid ${theme.palette.greys.light}`,
    ...theme.CSS.center,
    flexDirection: 'column',
    padding: '.7rem 1rem',
    textDecoration: 'none',
    color: theme.palette.signup.purple,
    '&:hover': {
      color: 'black',
      '& svg': {
        fill: 'black'
      }
    },
    '& span': {
      paddingTop: '.3rem'
    },
    '& svg': {
      width: '4rem',
      height: '4rem',
      fill: theme.palette.signup.purple
    },
    backgroundColor: `#f0faff`
  }
}))

export default TooltipContentCommunity
