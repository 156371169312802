import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextInput from '../fields/TextInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPricePoints,
  validateCoupon,
  getLifersPricePoints
} from '../../../../redux/actions/Signup.actions'

const CouponInput = ({ formik }) => {
  const { loading, couponApplied, couponSuccess } = useSelector(
    (state) => state.coupon
  )
  const dispatch = useDispatch()
  const isLifestyle = window.location.pathname.includes('signup/lifestyle')
  const [isValid, setIsValid] = useState(couponSuccess)

  const handleChange = async (name, value) => {
    await formik.setFieldValue(name, value)
    formik.setFieldTouched(name, true)
  }

  useEffect(() => {
    setIsValid(couponSuccess || false)
  }, [couponSuccess, setIsValid])

  const onValidCoupon = (code) => {
    setIsValid(true)
    if (isLifestyle) {
      dispatch(getLifersPricePoints(code))
    } else {
      dispatch(getPricePoints(code))
    }
  }

  const onInvalidCoupon = () => {
    handleChange('couponCode', null)
    setIsValid(false)
  }

  // Validate coupon on
  const updateFieldsFocussedHandler = (name, value) => {
    if (!value && !loading) {
      const code = formik.values.couponCode
      const paymentPlanId = formik.values.paymentPlan
      dispatch(
        validateCoupon(
          code,
          paymentPlanId,
          function () {
            return onValidCoupon(code)
          },
          onInvalidCoupon
        )
      )
    } else {
      setIsValid(false)
    }
  }

  if (!formik.values?.paymentPlan) return <></>
  return (
    <TextInput
      name='couponCode'
      label='Coupon (optional)'
      placeholder={couponApplied}
      formik={formik}
      loading={loading}
      updateFocus={updateFieldsFocussedHandler}
      isSuccess={isValid}
      upperCase={true}
      isDisabled={isValid}
    />
  )
}

CouponInput.propTypes = {
  formik: PropTypes.object.isRequired
}

export default CouponInput
