import React, { useRef, useState } from 'react'
import { Hidden, makeStyles } from '@material-ui/core'
import { navigationLinks } from './HeaderDataDesktop'
import Link from '../../components/header/Link'
import logo from '../../assets/img/logos/logo-white-simple-small@2x.png'
import defaultProfilePhoto from '../../assets/img/general/profile-default.png'
import SideDrawer from './SideDrawer'
import Backdrop from '../Backdrop'
import HeaderTooltip from './HeaderTooltip'
import TooltipContentAccount from './TooltipContentAccount'
import HeaderHamburger from './HeaderHamburger'
import { useSelector } from 'react-redux'
import navigateToGoSiteWithRound from '../../helpers/NavigateToGoSiteWithRound'
import HeaderMobileWeekDropDown from './HeaderMobileWeekDropDown/HeaderMobileWeekDropDown'


const Header = () => {
  const profileRef = useRef(null)
  const [showSideDrawer, setShowSideDrawer] = useState(false)
  const { profile } = useSelector((state) => state.profile)
  const currentRoundId = useSelector((state) => state.member.currentRoundId)
  let profileImage = profile?.avatar_url?.thumb || defaultProfilePhoto
  if (profile?.avatar_url?.thumb === '/images/layout/bg-member-photos-default.png') profileImage = defaultProfilePhoto

  const classes = useStyles({ profileImage: profileImage })

  const navLinks = (isSideDrawer) => {
    return navigationLinks.map((link, i) => {
      return (
        <div key={i} className={classes.linkContainer}>
          <Link
            title={link.title}
            link={link.link}
            key={i}
            withRadius={link.withRadius}
            content={link.content}
            isSideDrawer={isSideDrawer}
          />
        </div>
      )
    })
  }

  return (
    <div>
      {showSideDrawer && <Backdrop onClick={() => setShowSideDrawer(false)} />}
      <div className={classes.navHeader}>
        <Hidden mdUp>
          <HeaderHamburger setSideDrawer={() => setShowSideDrawer(!showSideDrawer)} />
        </Hidden>
        <a href={navigateToGoSiteWithRound('dashboard', currentRoundId)}>
          <div className={classes.logoDiv}>
            <img src={logo} alt='12WBT Logo' />
          </div>
        </a>
        <Hidden smDown>{navLinks()}</Hidden>
        <Hidden mdUp><HeaderMobileWeekDropDown /></Hidden>
        <div className={classes.profileContainer} ref={profileRef}>
          <div className={classes.profileDiv}>
            <div className={classes.spacer}></div>
            <HeaderTooltip withRadius={true} targetEl={profileRef} withArrowOffset={false}>
              <TooltipContentAccount />
            </HeaderTooltip>
          </div>
        </div>
      </div>
      <SideDrawer show={showSideDrawer}></SideDrawer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  navHeader: {
    height: '50px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 999
  },
  logoDiv: {
    width: '120px',
    height: '50px',
    backgroundColor: theme.palette.primary.dark,
    ...theme.CSS.center,
    '& img': {
      width: '5rem'
    }
  },
  spacer: {
    height: '1.89rem'
  },
  profileContainer: {
    ...theme.CSS.center,
    flexDirection: 'column',
    cursor: 'pointer',
    '& > div > div': {
      visibility: 'hidden'
    },
    '&:hover': {
      '& > div > div': {
        visibility: 'visible'
      }
    },
    marginLeft: '1rem'
  },
  profileDiv: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    overflow: 'hidden',
    border: '1px solid grey',
    // marginLeft: '6rem',
    backgroundImage: (props) => `url(${props.profileImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  user: {
    backgroundImage: (props) => `url(${props.profileImage}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  linkContainer: {
    cursor: 'pointer',
    '& > div > div': {
      visibility: 'hidden'
    },
    '&:hover': {
      '& > div > div': {
        visibility: 'visible'
      }
    }
  },
  [theme.breakpoints.down('lg')]: {
    profileContainer: {
      marginLeft: '2rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    navHeader: {
      justifyContent: 'flex-start'
    },
    profileContainer: {
      right: '30px',
      position: 'absolute',
      '& > div > div': {
        visibility: (props) => (props.showProfileTooltip ? 'visible!important' : 'hidden')
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    profileDiv: {
      right: '10px'
    }
  }
}))

export default Header
