import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { ReactComponent as LoudSpeakerIcon } from '../../../assets/img/icons/pre_season/LoudSpeaker.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getRoundInfo } from '../../../../redux/actions/Signup.actions'
import moment, { now } from 'moment'

const RoundStartsBanner = () => {
  const dispatch = useDispatch()
  const { currentRoundId } = useSelector((state) => state.member)
  const { roundInfo } = useSelector((state) => state.round)
  const classes = useStyles()

  // Get round start:
  useEffect(() => {
    if (currentRoundId) dispatch(getRoundInfo(currentRoundId))
  }, [currentRoundId])

  const duration = moment.duration(moment(roundInfo?.starts_at).diff(now()))
  const days = Math.floor(duration.asDays())
  const hours = Math.floor(duration.asHours() - days * 24)
  const message =
    days < 0
      ? 'Your 12 Week Program has kicked off!'
      : `Round starts ${moment(roundInfo?.starts_at).format('DD MMMM')}: `
  const timeToStart = days < 0 ? null : `${days} days and ${hours} hours to go!`
  return (
    <div className={classes.container}>
      <LoudSpeakerIcon />
      <div className={classes.textContainer}>
        <span className={classes.bold}>{message}</span>
        <span>{timeToStart}</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: '#5164E9',
    width: '100%',
    height: '64px',
    ...theme.CSS.center,
    '& span': {
      color: 'white',
      fontWeight: 400,
      fontSize: '16px'
    },
    marginBottom: theme.paddings.md
  },
  textContainer: {
    display: 'flex'
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      justifyContent: 'flex-start',
      marginBottom: 0,
      '& svg': {
        margin: '0 1rem'
      }
    },
    textContainer: {
      flexDirection: 'column'
    },
    bold: {
      fontWeight: 'bold'
    }
  }
}))

export default RoundStartsBanner
