import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, makeStyles } from '@material-ui/core'
import { getPreSeasonActivity } from '../../../helpers/GetPreSeasonActivity'
import trophy from '../../../assets/img/pre_season/badge-trophy@2x.png'
import CheckIcon from '@material-ui/icons/Check'

import { useDispatch, useSelector } from 'react-redux'
import {
  submitPreSeasonActivity,
  updatePreSeasonActivity,
  getPreSeasonActivityCounter
} from '../../../../redux/actions/PreSeason.actions'

const ActivityHeaderActions = ({ header }) => {
  const { loading } = useSelector((state) => state.newPreSeason)
  const preSeasonActivityCounterLoading = useSelector((state) => state.newPreSeasonActivity?.loading)
  const { currentRoundId, currentSubscriptionId } = useSelector((state) => state.member)
  const dispatch = useDispatch()
  const classes = useStyles()
  const activity = getPreSeasonActivity(header)
  const inProgress = activity

  useEffect(() => {
    if (currentRoundId && !preSeasonActivityCounterLoading) {
      dispatch(getPreSeasonActivityCounter(currentRoundId))
    }
  }, [activity, currentRoundId])

  const countMeInClickHandler = () => {
    if (inProgress) dispatch(updatePreSeasonActivity(currentSubscriptionId, { _id: activity._id, title: header }))
    else dispatch(submitPreSeasonActivity(currentSubscriptionId, { title: header }))
  }

  if (!activity) {
    return (
      <div className={classes.center}>
        <Button className={classes.countMeInButton} onClick={countMeInClickHandler} disabled={loading}>
          Count Me In!
        </Button>
      </div>
    )
  } else if (activity.status === 'done') {
    return (
      <div className={classes.center}>
        <div className={classes.successDiv}>
          <img src={trophy} alt='trophy' className={classes.trophy} />
          <div className={classes.successRow}>
            <CheckIcon />
            <span className={classes.status}>Conquered!</span>
          </div>
        </div>
      </div>
    )
  } else if (activity.status === 'in progress') {
    return (
      <div className={classes.center}>
        <Button
          className={`${classes.countMeInButton} ${classes.inProgressButton}`}
          onClick={countMeInClickHandler}
          disabled={loading}
        >
          Finish
        </Button>
        <div className={classes.inProgressRow}>
          <CheckIcon />
          <span className={classes.status}>In progress</span>
        </div>
      </div>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  countMeInButton: {
    backgroundColor: '#CC5FED',
    color: 'white',
    width: '200px',
    height: '50px',
    borderRadius: '6px',
    '&:hover': {
      color: '#CC5FED',
      border: '1px solid #CC5FED'
    },
    '&:disabled': {
      color: 'white'
    }
  },
  inProgressButton: {
    height: '40px',
    marginBottom: '.5rem'
  },
  center: {
    ...theme.CSS.center,
    flexDirection: 'column',
    position: 'relative',
    height: '100%'
  },
  successDiv: {
    ...theme.CSS.center,
    flexDirection: 'column',
    height: '100%'
  },
  successRow: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    color: '#01c461',
    '& svg': {
      color: '#01c461'
    }
  },
  inProgressRow: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    color: theme.palette.greys.main,
    '& svg': {
      color: theme.palette.greys.main
    }
  },
  trophy: {
    width: '4rem',
    marginBottom: '.3rem'
  },
  [theme.breakpoints.down('sm')]: {
    countMeInButton: {
      width: '150px',
      height: '40px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    countMeInButton: {
      marginTop: '10px',
      width: '200px'
    },
    trophy: {
      width: '3rem'
    },
    status: {
      fontSize: '16px'
    }
  }
}))

ActivityHeaderActions.propTypes = {
  header: PropTypes.string.isRequired
}

export default ActivityHeaderActions
