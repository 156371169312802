import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import SectionTabs from '../SectionTabs'

import { useFormik } from 'formik'

import MeasureUpHowThisWorks from './MeasureUpHowThisWorks'
import MeasureUpWeighingYourself from './MeasureUpWeighingYourself'
import MeasureUpChestWaistHip from './MeasureUpChestWaistHip'
import MeasureUpThighsArms from './MeasureUpThighsArms'
import MeasureUpBeforePhoto from './MeasureUpBeforePhoto'
import MeasureUpChart from './MeasureUpChart'

import TaskContentContainer from '../../TaskContentContainer'
import TaskCompletedBanner from '../../TaskCompletedBanner'
import { getPreSeasonTask } from '../../../../../helpers/GetPreSeasonTask'
import { MEASURE_UP } from '../../TaskData'
import { useDispatch, useSelector } from 'react-redux'
import {
  deletePreSeasonTask,
  updatePreSeasonTask,
  submitPreSeasonTask
} from '../../../../../../redux/actions/PreSeason.actions'
import { SECTION, measureUpData } from './MeasureUpData'
import {
  submitRoundMeasurements,
  updateProfile,
  uploadBeforePhoto,
  getRoundMeasurements
} from '../../../../../../redux/actions/Member.actions'
import ActionButton from '../../ActionButton'

function checkTaskValue(taskValue, task) {
  return task && task?.answer[taskValue] ? task.answer[taskValue] : 0
}

const MeasureUp = () => {
  const [activeSection, setActiveSection] = useState(SECTION.HOW)
  const [measurement, setMeasurement] = useState(null)
  const [fileUploaded, setFileUploaded] = useState(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.newPreSeason)
  const { currentRoundId, currentSubscriptionId, member } = useSelector((state) => state.member)
  const { loading: measurementsLoading, measurements } = useSelector((state) => state.measurements)
  const { profile } = useSelector((state) => state.profile)

  useEffect(() => {
    if (measurements) {
      setMeasurement(measurements[0])
    }
  }, [measurements])

  useEffect(() => {
    if (!measurementsLoading && !measurements && currentRoundId) {
      dispatch(getRoundMeasurements(currentRoundId))
    }
  }, [currentRoundId, measurements, loading])

  const task = getPreSeasonTask(MEASURE_UP)
  const isComplete = task

  const formik = useFormik({
    initialValues: {
      height: checkTaskValue('height', task),
      weight: checkTaskValue('weight', task),
      chest: checkTaskValue('chest', task),
      waist: checkTaskValue('waist', task),
      hip: checkTaskValue('hip', task),
      arm: checkTaskValue('arm', task),
      leg: checkTaskValue('leg', task),
      distanceFromKnee: checkTaskValue('distanceFromKnee', task)
    },
    onSubmit: (values) => {
      if (!measurement) {
        console.log('Measure up submission failed')
        return
      }
      if (isComplete)
        dispatch(
          updatePreSeasonTask(currentSubscriptionId, { title: MEASURE_UP, answer: { ...values }, _id: task._id })
        )
      else dispatch(submitPreSeasonTask(currentSubscriptionId, { title: MEASURE_UP, answer: { ...values } }))

      // Update Member Profile stats with old endpoints:
      // PUT request should have all values, assign initial from GET request:
      const roundMeasurements = {
        measurements: { ...measurement }
      }

      // Update new values from form:
      roundMeasurements.measurements.widest = values.hip
      roundMeasurements.measurements.waist = values.waist
      roundMeasurements.measurements.right_thigh = values.leg
      roundMeasurements.measurements.right_arm = values.arm
      roundMeasurements.measurements.chest = values.chest
      roundMeasurements.measurements.weight = values.weight
      roundMeasurements.measurements.distance_from_knee = values.distanceFromKnee
      dispatch(submitRoundMeasurements(currentRoundId, measurement.id, roundMeasurements))

      // Height is set directly on profile, not in measurements endpoint.
      // Update Profile (height is set directly on profile, not in measurements endpoint):
      const profileValues = { ...profile }
      profileValues.height = values.height
      profileValues.height_in_cm = values.height
      profileValues.height_in_feet = parseFloat((values.height / 30.48).toFixed(2))
      profileValues.height_in_inches = parseFloat((values.height / 2.54).toFixed(2))
      dispatch(updateProfile(member.id, profileValues))

      // Upload Before Photo:
      if (fileUploaded) {
        dispatch(uploadBeforePhoto(member.id, currentSubscriptionId, fileUploaded))
      }
      window.scrollTo({ top: 100, behavior: 'smooth' })
    }
  })

  // When task loads (if not null), assign values to formik:
  useEffect(() => {
    if (task) {
      const keys = Object.keys(task?.answer)
      if (keys) {
        keys.forEach((key) => {
          formik.setFieldValue(key, checkTaskValue(key, task))
        })
      }
    }
  }, [task])

  const nextTaskLinkClickHandler = (value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveSection(value)
  }

  // Set file in state:
  const fileUploadHandler = (file) => {
    setFileUploaded(file)
  }

  const SectionContent = () => {
    switch (activeSection) {
      case SECTION.HOW: {
        return <MeasureUpHowThisWorks nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.WEIGHING_YOURSELF} />
      }
      case SECTION.WEIGHING_YOURSELF: {
        return (
          <MeasureUpWeighingYourself
            nextTaskClick={nextTaskLinkClickHandler}
            nextTask={SECTION.MEASURE_CHEST_WAIST_HIPS}
          />
        )
      }
      case SECTION.MEASURE_CHEST_WAIST_HIPS: {
        return (
          <MeasureUpChestWaistHip nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.MEASURE_THIGHS_ARMS} />
        )
      }
      case SECTION.MEASURE_THIGHS_ARMS: {
        return <MeasureUpThighsArms nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.BEFORE_PHOTO} />
      }
      case SECTION.BEFORE_PHOTO: {
        return <MeasureUpBeforePhoto formik={formik} onImageUpload={fileUploadHandler} />
      }
      default:
        return null
    }
  }

  const tabChangeHandler = (value) => {
    setActiveSection(value)
  }

  const undoTaskClickHandler = () => {
    if (!loading) dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  const actionButtonTitle = isComplete ? 'Update' : 'Finish'

  const finishMeasureUpButtonClickHandler = () => {
    formik.submitForm()
  }

  return (
    <div className={classes.container}>
      {isComplete && <TaskCompletedBanner onUndoClick={undoTaskClickHandler} />}
      <SectionTabs tabsArray={measureUpData} activeTab={activeSection} setTabActive={tabChangeHandler} />
      <TaskContentContainer>{SectionContent()}</TaskContentContainer>
      <div style={activeSection === SECTION.BEFORE_PHOTO ? { visibility: 'hidden' } : {}}>
        <MeasureUpChart formik={formik} />
        <div className={classes.buttonContainer}>
          <ActionButton title={actionButtonTitle} onButtonClick={finishMeasureUpButtonClickHandler} loading={loading} />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '20px'
  },
  buttonContainer: {
    marginTop: '2rem'
  }
}))

export default MeasureUp
