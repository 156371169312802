import React, { useRef } from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles } from '@material-ui/core'
import HeaderTooltip from '../HeaderTooltip'
import { useSelector } from 'react-redux'
import WeekDropDownCircleButton from './components/WeekDropDownCircleButton'

const HeaderMobileWeekDropDown = () => {
  const classes = useStyles()
  const weekDropDownRef = useRef(null)
  const subscriptions = useSelector(
    (state) => state.subscriptions?.subscriptions
  )
  const currentSubscription = useSelector(
    (state) => state.currentSubscription?.currentSubscription
  )

  const roundCurrentWeek = useSelector(
    (state) => state.round?.roundInfo?.current_week
  )

  const isCurrentRound = (subscription) => {
    return currentSubscription?.round_id === subscription?.round_id
  }

  const navigateToGoSiteThroughRoundId = (roundId) => {
    return `${process.env.REACT_APP_GO_LOGIN}?t=${localStorage.getItem(
      'auth_token'
    )}&roundId=${roundId}&redirectUrl=dashboard`
  }

  const getWeekType = (weekNumber) => {
    const weekDay = new Date().getDay()

    if (roundCurrentWeek + 1 === weekNumber && weekDay > 3) {
      return 'newWeek'
    }
    return 'week'
  }

  const isDisabled = (weekNumber) => {
    const weekDay = new Date().getDay()

    return (
      roundCurrentWeek < weekNumber &&
      !(roundCurrentWeek + 1 === weekNumber && weekDay > 3)
    )
  }

  return roundCurrentWeek ? (
    <div className={classes.weekDropDown} ref={weekDropDownRef}>
      {roundCurrentWeek < 1 ? 'Pre-Season' : `Week ${roundCurrentWeek}`}
      <KeyboardArrowDown />
      <div className={classes.menu}>
        <HeaderTooltip
          withRadius={true}
          targetEl={weekDropDownRef}
          withArrowOffset={false}
        >
          {subscriptions &&
            subscriptions?.map((subscription, index) => (
              <div key={index}>
                <a
                  href={
                    isCurrentRound(subscription)
                      ? '#'
                      : navigateToGoSiteThroughRoundId(subscription.round_id)
                  }
                  className={
                    isCurrentRound(subscription)
                      ? classes.roundSelectedRow
                      : classes.roundRow
                  }
                >
                  {subscription.round_name}
                </a>
                {isCurrentRound(subscription) && (
                  <div className={classes.currentSubsInfo}>
                    <div className={classes.currentSubsInfoRow}>
                      <WeekDropDownCircleButton
                        type='preSeason'
                        roundId={subscription.round_id}
                      />
                    </div>
                    <div className={classes.currentSubsInfoRow}>
                      <WeekDropDownCircleButton
                        weekNumber={1}
                        type={getWeekType(1)}
                        isCurrentWeek={roundCurrentWeek === 1}
                        isDisabled={isDisabled(1)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={2}
                        type={getWeekType(2)}
                        isCurrentWeek={roundCurrentWeek === 2}
                        isDisabled={isDisabled(2)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={3}
                        type={getWeekType(3)}
                        isCurrentWeek={roundCurrentWeek === 3}
                        isDisabled={isDisabled(3)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={4}
                        type={getWeekType(4)}
                        isCurrentWeek={roundCurrentWeek === 4}
                        isDisabled={isDisabled(4)}
                        roundId={subscription.round_id}
                      />
                    </div>
                    <div className={classes.currentSubsInfoRow}>
                      <WeekDropDownCircleButton
                        weekNumber={5}
                        type={getWeekType(5)}
                        isCurrentWeek={roundCurrentWeek === 5}
                        isDisabled={isDisabled(5)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={6}
                        type={getWeekType(6)}
                        isCurrentWeek={roundCurrentWeek === 6}
                        isDisabled={isDisabled(6)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={7}
                        type={getWeekType(7)}
                        isCurrentWeek={roundCurrentWeek === 7}
                        isDisabled={isDisabled(7)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={8}
                        type={getWeekType(8)}
                        isCurrentWeek={roundCurrentWeek === 8}
                        isDisabled={isDisabled(8)}
                        roundId={subscription.round_id}
                      />
                    </div>
                    <div className={classes.currentSubsInfoRow}>
                      <WeekDropDownCircleButton
                        weekNumber={9}
                        type={getWeekType(9)}
                        isCurrentWeek={roundCurrentWeek === 9}
                        isDisabled={isDisabled(9)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={10}
                        type={getWeekType(10)}
                        isCurrentWeek={roundCurrentWeek === 10}
                        isDisabled={isDisabled(10)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={11}
                        type={getWeekType(11)}
                        isCurrentWeek={roundCurrentWeek === 11}
                        isDisabled={isDisabled(11)}
                        roundId={subscription.round_id}
                      />
                      <WeekDropDownCircleButton
                        weekNumber={12}
                        type={getWeekType(12)}
                        isCurrentWeek={roundCurrentWeek === 12}
                        isDisabled={isDisabled(12)}
                        roundId={subscription.round_id}
                      />
                    </div>
                    <div className={classes.currentSubsInfoRow}>
                      <WeekDropDownCircleButton
                        type='iMadeIt'
                        isDisabled={true}
                        roundId={subscription.round_id}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
        </HeaderTooltip>
      </div>
    </div>
  ) : (
    <></>
  )
}

const useStyles = makeStyles((theme) => ({
  weekDropDown: {
    fontWeight: 'bold',
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    right: 80,
    position: 'absolute',
    zIndex: 999,
    cursor: 'pointer',
    '& > div': {
      visibility: 'hidden'
    },
    '&:hover': {
      '& > div': {
        visibility: 'visible'
      }
    }
  },
  menu: {
    right: 50,
    position: 'absolute'
  },
  roundRow: {
    padding: '8px 20px',
    display: 'block',
    color: theme.palette.signup.purple,
    background: '#f0faff',
    fontWeight: '700',
    textDecoration: 'none',
    textAlign: 'center',
    borderBottom: '1px solid #ddd'
  },
  roundSelectedRow: {
    padding: '8px 20px',
    display: 'block',
    color: '#000',
    background: '#fff',
    fontWeight: '700',
    textDecoration: 'none',
    textAlign: 'center',
    borderBottom: '1px solid #ddd'
  },
  currentSubsInfo: {
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'column'
  },
  currentSubsInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}))

export default HeaderMobileWeekDropDown
