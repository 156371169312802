import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles, TextField } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'

const TextInput = ({
  formik,
  label,
  name,
  placeholder,
  onBlurHandler,
  onFocusHandler,
  isSuccess,
  withLock,
  loading,
  updateFocus,
  type,
  isDisabled,
  upperCase,
  autoFocus,
  autoCompleteHandler
}) => {
  const [isInSuccessState, setIsInSuccessState] = useState(true)
  const classes = useStyles({ label, isInSuccessState })
  const blurHandler = (e) => {
    if (onBlurHandler) onBlurHandler()
    formik.handleBlur(e)
    if (updateFocus) updateFocus(name, false)
  }

  useEffect(() => {
    if (isSuccess) {
      setIsInSuccessState(true)
    } else {
      setIsInSuccessState(false)
    }
  }, [isSuccess, blurHandler])

  const focusHandler = () => {
    if (onFocusHandler) onFocusHandler()
    if (updateFocus) updateFocus(name, true)
  }

  const onChangeHandler = (e) => {
    const newValue = upperCase ? e.target.value.toUpperCase() : e.target.value

    // This detects chrome auto-fill for email on signup page, we have to call an endpoint to check for return members before allowing signup:
    if (
      formik.values[name] === '' &&
      newValue.length > 3 &&
      autoCompleteHandler
    ) {
      autoCompleteHandler(newValue)
    }
    formik.handleChange({ event: e, target: { name: name, value: newValue } })
  }

  return (
    <div className={classes.container}>
      <span className={classes.label}>{label}</span>
      <div className={classes.textInput}>
        {withLock && (
          <div className={classes.lock}>
            <LockIcon />
          </div>
        )}
        <TextField
          fullWidth
          placeholder={placeholder}
          id={name}
          disabled={isDisabled}
          autoFocus={autoFocus}
          name={name}
          onFocus={focusHandler}
          onBlur={(e) => blurHandler(e)}
          variant='outlined'
          type={type}
          value={formik.values[name]}
          onChange={onChangeHandler}
          error={formik.errors[name] && formik.touched[name]}
          helperText={formik.touched[name] && formik.errors[name]}
          InputProps={{
            classes: {
              notchedOutline: classes.notchedOutline,
              input: classes.input
            }
          }}
        />
        {loading && (
          <div className={classes.spinner}>
            <CircularProgress size={25} />
          </div>
        )}
      </div>
      {isInSuccessState && !loading && (
        <span className={classes.successMessage} id={`${name}-success-message`}>
          Successfully applied coupon
        </span>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontSize: '1rem',
    margin: '.5rem 0',
    color: 'grey',
    textAlign: 'left',
    '& span': {
      margin: '.2rem 0!important',
      marginTop: (props) => !props.label && '1.2rem'
    },
    // remove X
    '& input[type="number"]::-webkit-clear-button': {
      display: 'none'
    },

    // remove inside of arrows button
    '& input[type="number"]::-webkit-inner-spin-button': {
      display: 'none'
    },

    // remove outsideof arrows button
    '& input[type="number"]::-webkit-outside-spin-button': {
      display: 'none'
    }
  },
  '& .MuiFormHelperText-root': {
    color: 'red!important'
  },
  input: {
    '&::placeholder': {
      color: 'black'
    }
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: ({ isInSuccessState }) =>
      isInSuccessState ? theme.palette.signup.green : theme.palette.grey[400]
  },
  textInput: {
    display: 'flex',
    position: 'relative',
    borderRadius: theme.borderRadius.sm,
    '& .MuiOutlinedInput-input': {
      padding: '9px',
      backgroundColor: 'white',
      borderRadius: theme.borderRadius.sm
    }
  },
  spinner: {
    position: 'absolute',
    right: '10px',
    height: '100%',
    ...theme.CSS.center
  },
  successMessage: {
    color: theme.palette.signup.green,
    fontSize: '.9rem',
    fontWeight: 'bold'
  },
  label: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#374151'
  },
  lock: {
    width: '3.5rem',
    height: '3.5rem',
    backgroundColor: theme.palette.grey[400],
    marginRight: '-3px',
    zIndex: 10,
    ...theme.CSS.center,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    '& svg': {
      color: 'white',
      width: '2rem',
      height: '2rem'
    }
  }
}))

TextInput.defaultProps = {
  placeholder: '',
  withLock: false,
  type: 'text',
  isDisabled: false,
  autoFocus: false
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  formik: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  withLock: PropTypes.bool,
  loading: PropTypes.bool,
  updateFocus: PropTypes.func,
  isDisabled: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.object
  ]),
  isSuccess: PropTypes.bool,
  upperCase: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoCompleteHandler: PropTypes.func
}

export default TextInput
