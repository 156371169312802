import {
  GET_NEW_PRE_SEASON_DATA_REQUEST,
  GET_NEW_PRE_SEASON_DATA_SUCCESS,
  GET_NEW_PRE_SEASON_DATA_FAIL,
  SUBMIT_PRE_SEASON_TASK_REQUEST,
  SUBMIT_PRE_SEASON_TASK_SUCCESS,
  SUBMIT_PRE_SEASON_TASK_FAIL,
  DELETE_PRE_SEASON_TASK_REQUEST,
  DELETE_PRE_SEASON_TASK_SUCCESS,
  DELETE_PRE_SEASON_TASK_FAIL,
  UPDATE_PRE_SEASON_TASK_REQUEST,
  UPDATE_PRE_SEASON_TASK_SUCCESS,
  UPDATE_PRE_SEASON_TASK_FAIL,
  SUBMIT_PRE_SEASON_ACTIVITY_REQUEST,
  SUBMIT_PRE_SEASON_ACTIVITY_SUCCESS,
  SUBMIT_PRE_SEASON_ACTIVITY_FAIL,
  UPDATE_PRE_SEASON_ACTIVITY_REQUEST,
  UPDATE_PRE_SEASON_ACTIVITY_SUCCESS,
  UPDATE_PRE_SEASON_ACTIVITY_FAIL,
  DELETE_PRE_SEASON_ACTIVITY_SUCCESS,
  DELETE_PRE_SEASON_ACTIVITY_FAIL,
  GET_PRE_SEASON_ACTIVITY_COUNTER_FAIL,
  GET_PRE_SEASON_ACTIVITY_COUNTER_REQUEST,
  GET_PRE_SEASON_ACTIVITY_COUNTER_SUCCESS,
  GET_FITNESS_SCORE_IMPROVEMENTS_REQUEST,
  GET_FITNESS_SCORE_IMPROVEMENTS_SUCCESS,
  GET_FITNESS_SCORE_IMPROVEMENTS_FAIL,
  GET_FITNESS_SCORE_RESULT_REQUEST,
  GET_FITNESS_SCORE_RESULT_SUCCESS,
  GET_FITNESS_SCORE_RESULT_FAIL,
  UPDATE_DIFFICULTY_TYPE_REQUEST,
  UPDATE_DIFFICULTY_TYPE_SUCCESS,
  UPDATE_DIFFICULTY_TYPE_FAIL
} from '../constants/PreSeason.constants'
import axiosWrapper from '../axiosInstance'

export const getNewPreSeasonData = (subscriptionId) => async (dispatch) => {
  try {
    dispatch({ type: GET_NEW_PRE_SEASON_DATA_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}members/subscriptions/${subscriptionId}`
    )
    dispatch({ type: GET_NEW_PRE_SEASON_DATA_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: GET_NEW_PRE_SEASON_DATA_FAIL })
  }
}

export const submitPreSeasonTask = (subscriptionId, values) => async (
  dispatch
) => {
  try {
    dispatch({ type: SUBMIT_PRE_SEASON_TASK_REQUEST })
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}members/subscriptions/${subscriptionId}/pre-season/task`,
      values
    )
    dispatch({ type: SUBMIT_PRE_SEASON_TASK_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: SUBMIT_PRE_SEASON_TASK_FAIL })
  }
}

export const deletePreSeasonTask = (subscriptionId, taskId) => async (
  dispatch
) => {
  try {
    dispatch({ type: DELETE_PRE_SEASON_TASK_REQUEST })
    const res = await axiosWrapper.delete(
      `${process.env.REACT_APP_API_HOST}members/subscriptions/${subscriptionId}/pre-season/task/${taskId}`
    )
    dispatch({ type: DELETE_PRE_SEASON_TASK_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: DELETE_PRE_SEASON_TASK_FAIL })
  }
}

export const updatePreSeasonTask = (subscriptionId, values) => async (
  dispatch
) => {
  try {
    dispatch({ type: UPDATE_PRE_SEASON_TASK_REQUEST })
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}members/subscriptions/${subscriptionId}/pre-season/task`,
      values
    )
    dispatch({ type: UPDATE_PRE_SEASON_TASK_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: UPDATE_PRE_SEASON_TASK_FAIL })
  }
}

export const submitPreSeasonActivity = (subscriptionId, values) => async (
  dispatch
) => {
  try {
    dispatch({ type: SUBMIT_PRE_SEASON_ACTIVITY_REQUEST })
    const res = await axiosWrapper.post(
      `${process.env.REACT_APP_API_HOST}members/subscriptions/${subscriptionId}/pre-season/activity`,
      { ...values, status: 'in progress' }
    )
    dispatch({ type: SUBMIT_PRE_SEASON_ACTIVITY_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: SUBMIT_PRE_SEASON_ACTIVITY_FAIL })
  }
}

export const updatePreSeasonActivity = (subscriptionId, values) => async (
  dispatch
) => {
  try {
    dispatch({ type: UPDATE_PRE_SEASON_ACTIVITY_REQUEST })
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}members/subscriptions/${subscriptionId}/pre-season/activity`,
      { ...values, status: 'done' }
    )
    dispatch({ type: UPDATE_PRE_SEASON_ACTIVITY_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: UPDATE_PRE_SEASON_ACTIVITY_FAIL })
  }
}

export const deletePreSeasonActivity = (subscriptionId, activityId) => async (
  dispatch
) => {
  try {
    dispatch({ type: DELETE_PRE_SEASON_TASK_REQUEST })
    const res = await axiosWrapper.delete(
      `${process.env.REACT_APP_API_HOST}members/subscriptions/${subscriptionId}/pre-season/activity/${activityId}`
    )
    dispatch({ type: DELETE_PRE_SEASON_ACTIVITY_SUCCESS, payload: res.data })
  } catch (err) {
    dispatch({ type: DELETE_PRE_SEASON_ACTIVITY_FAIL })
  }
}

export const getPreSeasonActivityCounter = (roundId) => async (dispatch) => {
  try {
    dispatch({ type: GET_PRE_SEASON_ACTIVITY_COUNTER_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}rounds/${roundId}/pre-season/participants`
    )
    dispatch({
      type: GET_PRE_SEASON_ACTIVITY_COUNTER_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    dispatch({ type: GET_PRE_SEASON_ACTIVITY_COUNTER_FAIL })
  }
}

export const getFitnessScoreImprovements = (
  roundId,
  memberId,
  weekNumber
) => async (dispatch) => {
  try {
    dispatch({ type: GET_FITNESS_SCORE_IMPROVEMENTS_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}v2/rounds/${roundId}/members/${memberId}/fitness_scores/improvements?week=${weekNumber}`
    )
    dispatch({
      type: GET_FITNESS_SCORE_IMPROVEMENTS_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    dispatch({ type: GET_FITNESS_SCORE_IMPROVEMENTS_FAIL })
  }
}

export const getFitnessScoreResult = (roundId, memberId, week) => async (
  dispatch
) => {
  try {
    dispatch({ type: GET_FITNESS_SCORE_RESULT_REQUEST })
    const res = await axiosWrapper.get(
      `${process.env.REACT_APP_API_HOST}v2/rounds/${roundId}/members/${memberId}/fitness_scores`
    )
    dispatch({
      type: GET_FITNESS_SCORE_RESULT_SUCCESS,
      payload: res.data.find(
        (fitnessScoreResult) => fitnessScoreResult.week_number === week
      )
    })
  } catch (err) {
    dispatch({ type: GET_FITNESS_SCORE_RESULT_FAIL })
  }
}

export const updateDifficultyType = (subscriptionId, week, data) => async (
  dispatch
) => {
  try {
    dispatch({ type: UPDATE_DIFFICULTY_TYPE_REQUEST })
    const res = await axiosWrapper.put(
      `${process.env.REACT_APP_API_HOST}v2/fitness-test-exercise-options/upper_body?subscription_id=${subscriptionId}&week=${week}`,
      data
    )
    dispatch({
      type: UPDATE_DIFFICULTY_TYPE_SUCCESS,
      payload: res.data
    })
  } catch (err) {
    dispatch({ type: UPDATE_DIFFICULTY_TYPE_FAIL })
  }
}
