import React from 'react'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const DefaultBtn = ({
  children,
  onClick,
  loading,
  isBlokes,
  isRounded,
  padding,
  fontSize
}) => {
  const classes = useStyles({ fontSize, padding })

  return (
    <div>
      <Button
        style={{
          textTransform: 'none',
          borderRadius: isRounded ? 30 : 0,
          padding: padding
        }}
        className={isBlokes ? classes.defaultBlokesBtn : classes.defaultBtn}
        variant='contained'
        onClick={onClick}
        disabled={loading}
      >
        {loading ? <CircularProgress size={25} /> : children}
      </Button>
    </div>
  )
}

DefaultBtn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isBlokes: PropTypes.bool,
  isRounded: PropTypes.bool,
  padding: PropTypes.string,
  fontSize: PropTypes.string
}

const useStyles = makeStyles((theme) => ({
  defaultBtn: {
    backgroundColor: theme.palette.signup.purple,
    '& .MuiButton-label': {
      fontWeight: 900,
      padding: (props) => props.padding || '0 40px',
      color: '#fff',
      fontSize: (props) => props.fontSize || '17.5px'
    }
  },
  defaultBlokesBtn: {
    backgroundColor: theme.palette.blokesSignup.secondary,
    '& .MuiButton-label': {
      fontWeight: 900,
      padding: (props) => props.padding || '0 40px',
      color: '#fff',
      fontSize: (props) => props.fontSize || '17.5px'
    }
  }
}))

export default DefaultBtn
