import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'

import CheckIcon from '@material-ui/icons/Check'

const TaskCompletedBanner = ({ onUndoClick }) => {
  const [loading, setLoading] = useState(false)
  const undoClickHandler = () => {
    setLoading(true)
    onUndoClick()
  }
  const classes = useStyles()
  return (
    <div className={classes.bannerContainer}>
      <div className={classes.completeContainer}>
        <CheckIcon />
        <span className={classes.mainText}>Task complete. Well done!</span>
      </div>
      <div className={classes.undoButton} onClick={undoClickHandler}>
        {loading ? (
          <CircularProgress className={classes.headerSpinner} size={20} />
        ) : (
          <span className={classes.undo}>Undo</span>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '.5rem 0',
    backgroundColor: '#F0FFF0',
    border: `1px solid #1AC461`,
    borderRadius: '6px',
    margin: '1rem auto',
    marginBottom: '2rem'
  },
  completeContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: '#1AC461',
      marginRight: '6px'
    },
    padding: '0 .8rem'
  },
  mainText: {
    color: '#1AC461',
    fontWeight: 800,
    fontSize: '1.1rem'
  },
  undoButton: {
    width: '6rem',
    height: '100%',
    '&:hover': {
      cursor: 'pointer'
    },
    textAlign: 'right',
    padding: '0 1rem'
  },
  undo: {
    color: theme.palette.signup.purple,
    fontWeight: 800,
    fontSize: '1.1rem'
  },
  headerSpinner: {
    color: '#1AC461'
  },
  [theme.breakpoints.down('md')]: {
    bannerContainer: {
      marginBottom: '1rem'
    }
  },
  [theme.breakpoints.down('xs')]: {
    bannerContainer: {
      width: '90%',
      flexDirection: 'column',
      padding: 0
    },
    undoButton: {
      textAlign: 'center'
    },
    mainText: {
      fontSize: '.9rem'
    }
  }
}))

TaskCompletedBanner.propTypes = {
  onUndoClick: PropTypes.func.isRequired
}

export default TaskCompletedBanner
