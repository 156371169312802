import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import CalculatorInput from './CalculatorInput'
import ActionButton from '../ActionButton'

const ShoppingSmartCalculatorForm = ({ formik }) => {
  const classes = useStyles()
  return (
    <div>
      <Grid container>
        <Grid xs={12} item sm={5}>
          <div className={classes.headerRow}>
            <span className={`${classes.header} ${classes.sectionHeader}`}>Eating In</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          <div className={classes.headerRow}>
            <span className={`${classes.headerAppend} ${classes.sectionHeader}`}> Weekly Spend</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridHeader}>
          <div className={classes.headerRow}>
            <span className={classes.header}>Groceries</span>
            <span className={classes.headerAppend}> (excluding alcohol)</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          <CalculatorInput formik={formik} name='groceries' />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridHeader}>
          <span className={classes.header}>Alcohol</span>
        </Grid>
        <Grid item xs={12} sm={7}>
          <CalculatorInput formik={formik} name='homeAlcohol' />
        </Grid>
        <Grid xs={12} item sm={5}>
          <div className={classes.headerRow}>
            <span className={`${classes.header} ${classes.sectionHeader}`}>Dining Out</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          <div className={classes.headerRow}>
            <span className={`${classes.headerAppend} ${classes.sectionHeader}`}> Weekly Spend</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridHeader}>
          <span className={classes.header}>Breakfast</span>
        </Grid>
        <Grid item xs={12} sm={7}>
          <CalculatorInput formik={formik} name='breakfast' />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridHeader}>
          <span className={classes.header}>Lunch</span>
        </Grid>
        <Grid item xs={12} sm={7}>
          <CalculatorInput formik={formik} name='lunch' />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridHeader}>
          <span className={classes.header}>Dinner</span>
        </Grid>
        <Grid item xs={12} sm={7}>
          <CalculatorInput formik={formik} name='dinner' />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridHeader}>
          <div className={classes.headerRow}>
            <span className={classes.header}>Snacks</span>
            <span className={classes.headerAppend}> (Coffee, chips etc)</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={7}>
          <CalculatorInput formik={formik} name='snacks' />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.gridHeader}>
          <span className={classes.header}>Alcohol</span>
        </Grid>
        <Grid item xs={12} sm={7}>
          <CalculatorInput formik={formik} name='outAlcohol' />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.buttonContainer}>
        <ActionButton
          title='Calculate'
          onButtonClick={() => {
            formik.submitForm()
          }}
        />
      </div>
      <ol className={classes.list}>
        <li>To keep things simple, use whole dollars (ignore the cents).</li>
        <li>Compare this figure once you are half way through the 12WBT and see how much money you are saving.</li>
      </ol>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  gridHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem'
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: 500,
    margin: '1rem 0'
  },
  headerAppend: {
    fontSize: '1.1rem',
    fontWeight: 500,
    color: theme.palette.greys.main
  },
  sectionHeader: {
    fontSize: '1.5rem',
    margin: '2rem 0'
  },
  divider: {
    backgroundColor: 'grey',
    margin: '1rem'
  },
  buttonContainer: {
    ...theme.CSS.center
  },
  [theme.breakpoints.down('sm')]: {
    header: {
      margin: 0
    }
  }
}))

ShoppingSmartCalculatorForm.propTypes = {
  formik: PropTypes.object.isRequired
}

export default ShoppingSmartCalculatorForm
