import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ReactPlayerLoader from '@brightcove/react-player-loader'

const BrightCovePlayer = ({ videoId }) => {
  const classes = useStyles()
  return (
    <ReactPlayerLoader
      attrs={{ className: classes.video }}
      accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}
      playerId={process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID}
      videoId={videoId}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  video: {
    height: '100%',
    width: '100%',
    '& .video-js': {
      backgroundColor: 'white',
      width: '100%',
      minHeight: '100%'
    },
    '& .vjs-dock-text': {
      display: 'none'
    },
    '& vjs-poster': {
      backgroundColor: 'white'
    }
  }
}))

BrightCovePlayer.propTypes = {
  videoId: PropTypes.string.isRequired
}

export default BrightCovePlayer
