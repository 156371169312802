import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'

import ContentContainer from '../../../../components/ContentContainer'
import TaskTabItem from '../TaskTabItem'
import MeasureUp from './measure_up/MeasureUp'
import FitnessTest from './fitness_test/FitnessTest'

const FITNESS_TABS = {
  FITNESS_TEST: 'Fitness Test',
  MEASURE_UP: 'Measure Up'
}
const FitnessTestAndMeasureUpDoTask = () => {
  const [activeTab, setActiveTab] = useState(FITNESS_TABS.FITNESS_TEST)

  const classes = useStyles()
  return (
    <div>
      <div className={classes.tabSelector}>
        <TaskTabItem value={FITNESS_TABS.FITNESS_TEST} activeTab={activeTab} setActiveTab={setActiveTab} />
        <TaskTabItem value={FITNESS_TABS.MEASURE_UP} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <ContentContainer maxPixels={812}>
        {activeTab === FITNESS_TABS.MEASURE_UP ? <MeasureUp /> : <FitnessTest />}
      </ContentContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  tabSelector: {
    height: '60px',
    width: '768px',
    backgroundColor: 'white',
    margin: '5rem auto',
    marginBottom: '3rem',
    display: 'flex',
    borderRadius: '5px',
    filter: `drop-shadow(0 0 0.1rem ${theme.palette.greys.main})`
  },
  [theme.breakpoints.down('sm')]: {
    tabSelector: {
      width: '550px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    tabSelector: {
      width: '90%'
    }
  }
}))

export default FitnessTestAndMeasureUpDoTask
