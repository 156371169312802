import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../../components/ContentContainer'

import ActionButton from '../ActionButton'
import TaskContentContainer from '../TaskContentContainer'
import TaskCompletedBanner from '../TaskCompletedBanner'
import { tasks, ORGANISE_AND_DIARISE } from '../TaskData'
import { useDispatch, useSelector } from 'react-redux'
import { getPreSeasonTask } from '../../../../helpers/GetPreSeasonTask'
import { submitPreSeasonTask, deletePreSeasonTask } from '../../../../../redux/actions/PreSeason.actions'

const OrganiseAndDiariseDoTask = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { loading } = useSelector((state) => state.newPreSeason)
  const { currentSubscriptionId } = useSelector((state) => state.member)

  const taskTitle = tasks[ORGANISE_AND_DIARISE].header
  const task = getPreSeasonTask(taskTitle)
  const isComplete = task
  const actionButtonTitle = isComplete ? 'Undo' : 'Finish'

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [isComplete])

  const actionButtonClickHandler = () => {
    if (!isComplete) dispatch(submitPreSeasonTask(currentSubscriptionId, { title: taskTitle }))
    else dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  const onUndoClickHandler = () => {
    if (!loading) dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  return (
    <ContentContainer maxWidth='xl'>
      <div className={classes.container}>
        <TaskContentContainer>
          {isComplete && <TaskCompletedBanner onUndoClick={onUndoClickHandler} />}
          <ol>
            <li>
              Grab your diary and identify any ‘red flag’ events that you may encounter over the course of a round.
              Include school holidays, functions and commitments that may impact on your routine so you can prepare for
              these. Ideally plan for the 12 week round or at least a month in advance and update as you go. Keep in
              mind that times like these are all a part of life and at 12WBT, we&apos;ll teach you the skills to work
              around them while enjoying balance and moderation.
            </li>
            <li>Diarise all your workouts- where you&apos;ll be doing them and what you&apos;ll be doing.</li>
            <li>
              Plan your shopping days- your big shop, perhaps a trip to the Farmers Market, online shopping if that
              works for you or any top-up shops. Set aside time to do any cook ups making extra portions to freeze for
              later, make any lunches the night before or pre-cut veggies for snacks or as a part of future meal prep.
            </li>
            <li>
              Work out your Mini Milestone events for week 4, 8, and your Major Milestone for week 12. These are an
              ESSENTIAL part of your journey.
            </li>
          </ol>
          <ActionButton title={actionButtonTitle} onButtonClick={actionButtonClickHandler} loading={loading} />
        </TaskContentContainer>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '60px auto',
    '& ol': {
      '& li': {
        marginBottom: '2rem'
      }
    },
    '& ol > li::marker': {
      fontWeight: 'bold'
    }
  },
  [theme.breakpoints.down('md')]: {
    container: {
      '& ol': {
        '& li': {
          marginBottom: '1rem'
        }
      }
    }
  }
}))

export default OrganiseAndDiariseDoTask
