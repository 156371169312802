import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles, Grid } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useSelector } from 'react-redux'
import { EXERCISE_TYPES_CONST } from './const/ExerciseTypesConst'
import InfoIcon from '@material-ui/icons/Info'

const ImprovingYourFitnessScore = () => {
  const classes = useStyles()
  const actualScore = useSelector((state) => state.fitnessTest.fitnessTestScoreResult?.score)
  const targetScore = useSelector((state) => state.fitnessTest.fitnessTestImprovements)
  const measurements = useSelector((state) => state.measurements?.measurements[0])

  const [rows, setRows] = useState([
    {
      id: 1,
      exerciseType: 'Upper Body Strength'
    },
    {
      id: 2,
      exerciseType: 'Core Strength'
    },
    {
      id: 3,
      exerciseType: 'Aerobic Capacity'
    },
    {
      id: 4,
      exerciseType: 'Flexibility'
    },
    {
      id: 5,
      exerciseType: 'Lower Body Strength'
    }
  ])

  const BAR_HEIGHT_CONST = 3

  const convertSecondsToMinutes = (totalSeconds) => {
    if (totalSeconds && !totalSeconds.isNaN) {
      const minutes = Math.floor(totalSeconds / 60)
      const seconds = totalSeconds % 60

      let minutesString = minutes.toString()
      let secondsString = seconds.toString()

      if (minutes < 10) minutesString = `0${minutes}`
      if (seconds < 10) secondsString = `0${seconds}`

      return `${minutesString}:${secondsString}`
    }
    return '-'
  }

  useEffect(() => {
    if (!targetScore || !measurements) return

    const whatToChange = targetScore.change
    const newRows = []
    EXERCISE_TYPES_CONST.forEach((exerciseType) => {
      if (whatToChange[exerciseType.exerciseVariable] || whatToChange[exerciseType.exerciseVariable] === null) {
        const row = rows.find((row) => {
          return row.exerciseType === exerciseType.exerciseType
        })
        const targetValue = whatToChange[exerciseType.exerciseVariable]
        const actualValue = measurements[exerciseType.exerciseVariable]

        row.startWeekExercise = exerciseType.exerciseString
        row.startWeekValueString = exerciseType.isTimeValue ? convertSecondsToMinutes(actualValue) : actualValue
        row.startWeekValueNumber = actualValue

        row.weekFourExercise = exerciseType.exerciseString
        row.weekFourValueString =
          targetValue &&
          (exerciseType.isTimeValue ? convertSecondsToMinutes(targetValue + actualValue) : targetValue + actualValue)
        row.weekFourValueNumber = targetValue

        row.unit = exerciseType.unit
        newRows.push(row)
      }
    })
    setRows(newRows)
  }, [measurements, targetScore, actualScore])

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {}
    }
  }))(TableRow)

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#f2f2f2',
      color: '#000000',
      fontWeight: 'bold'
    },
    body: {
      fontSize: 14
    }
  }))(TableCell)

  return (
    <div>
      <p className={classes.title}>Improving your Fitness Score</p>
      <p className={classes.text}>
        Are you up for a little challenge? I’ve set you a new target to aim for over the next four weeks. Every
        improvement you make represents a stronger, fitter, healthier version of you. Be proud of what you have achieved
        and what you WILL achieve!
      </p>
      <Grid container direction='row' justify='center' alignItems='flex-end'>
        <Grid container item xs={6} direction='row' justify='center' alignItems='center'></Grid>
        <Grid container item xs={6} direction='row' justify='center' alignItems='center'>
          <p className={classes.targetBarBoosYourFitnessText}>
            Boost your fitness score with just a little more effort!
          </p>
        </Grid>
      </Grid>
      <Grid container direction='row' justify='center' alignItems='flex-end'>
        <Grid container item xs={4} direction='row' justify='center' alignItems='center'></Grid>
        <Grid container item xs={4} alignItems='center' direction='column'>
          <p className={classes.scoreBarNumber}>{actualScore || 0}</p>
          <div style={{ height: 50 + (actualScore || 0 * BAR_HEIGHT_CONST) }} className={classes.startWeekBar}></div>
        </Grid>
        <Grid container item xs={4} alignItems='center' direction='column' spacing={3}>
          <div className={classes.targetBarLine}></div>
          <p className={classes.targetBarText}>Target</p>
          <p className={classes.scoreBarNumber}>{targetScore.score || 0}</p>
          <div
            style={{ height: 50 + (targetScore.score || 0 * BAR_HEIGHT_CONST) }}
            className={classes.weekFourBar}
          ></div>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='improving your fitness score table'>
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align='center'>Start Week</StyledTableCell>
              <StyledTableCell align='center'>Week 4</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell className={classes.boldTextCol}>{row.exerciseType}</StyledTableCell>
                <StyledTableCell align='center'>
                  {row.startWeekExercise}
                  <br />
                  {row.startWeekValueString}
                </StyledTableCell>
                <StyledTableCell align='center' className={classes.weekFourCol}>
                  {row.weekFourValueString ? (
                    row.startWeekValueNumber > row.startWeekValueNumber + row.weekFourValueNumber ? (
                      'Maximum achieved'
                    ) : (
                      <>
                        {row.weekFourExercise}
                        <br />
                        {row.weekFourValueString}
                        <br />
                        {`${row.weekFourValueNumber}${row.unit} more`}
                      </>
                    )
                  ) : (
                    <InfoIcon />
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <p className={classes.scoreBasedText}>* Score based on partial entry of fitness test</p>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: '1.5rem'
  },
  text: {
    fontSize: 16
  },
  boldTextCol: {
    fontWeight: '900'
  },
  weekFourCol: {
    color: theme.palette.signup.purple
  },
  startWeekBar: {
    backgroundColor: theme.palette.signup.purple,
    width: '30%'
  },
  weekFourBar: {
    border: `1px ${theme.palette.signup.purple} dotted`,
    borderBottom: 'none',
    width: '30%',
    marginBottom: 12
  },
  scoreBarNumber: {
    fontSize: 33,
    color: theme.palette.signup.purple,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '.5rem 0 1rem 0'
  },
  targetBarText: {
    fontSize: 18,
    color: theme.palette.signup.purple,
    fontWeight: 'bold',
    margin: 0
  },
  targetBarLine: {
    height: 30,
    border: '2px solid #4f5fec'
  },
  targetBarBoosYourFitnessText: {
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  scoreBasedText: {
    color: '#929292'
  }
}))

export default ImprovingYourFitnessScore
