import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Check from '@material-ui/icons/Check'
import DirectionsRun from '@material-ui/icons/DirectionsRun'
import Flag from '@material-ui/icons/Flag'
import EmojiEvents from '@material-ui/icons/EmojiEvents'
import navigateToGoSiteWithRound from '../../../../helpers/NavigateToGoSiteWithRound'

/// Available types: week, newWeek, preSeason, iMadeIt
const WeekDropDownCircleButton = ({
  type,
  weekNumber,
  isCurrentWeek,
  isDisabled,
  roundId
}) => {
  const classes = useStyles({ isDisabled, type, isCurrentWeek })

  const getText = () => {
    switch (type) {
      case 'preSeason':
        return 'PRE-SEASON'
      case 'iMadeIt':
        return 'I MADE IT!'
      default:
        return weekNumber
    }
  }

  const getBtnColClass = () => {
    switch (type) {
      case 'preSeason':
        return classes.largeBtnCol
      case 'iMadeIt':
        return classes.largeBtnCol
      default:
        return classes.checkBtnCol
    }
  }

  const getChild = () => {
    switch (type) {
      case 'preSeason':
        return <Flag className={classes.icon} />
      case 'iMadeIt':
        return <EmojiEvents className={classes.icon} />
      case 'disabled':
        return <></>
      case 'newWeek':
        return <p className={classes.newText}>NEW</p>
      default:
        return <Check className={classes.icon} />
    }
  }

  const getCircleType = () => {
    switch (type) {
      case 'preSeason': {
        if (isDisabled) return classes.disableCircleWithIcon
        return classes.enableCircle
      }

      case 'iMadeIt': {
        if (isDisabled) return classes.disableCircleWithIcon
        return classes.enableCircle
      }
      default: {
        if (isDisabled) return classes.disableWeekCircle
        if (isCurrentWeek || type === 'newWeek') return classes.clearCircle
        return classes.enableCircle
      }
    }
  }

  const getUrl = () => {
    switch (type) {
      case 'week': {
        if (isDisabled) return '#'
        return navigateToGoSiteWithRound(
          `week/${weekNumber}`,
          roundId,
          weekNumber
        )
      }
      case 'new': {
        return navigateToGoSiteWithRound(
          `week/${weekNumber}`,
          roundId,
          weekNumber
        )
      }
      case 'preSeason': {
        return '/pre-season'
      }
      case 'iMadeIt': {
        if (isDisabled) return '#'
        return navigateToGoSiteWithRound('graduation', roundId)
      }
    }
  }

  return (
    <div className={getBtnColClass()}>
      <a href={getUrl()} className={classes.link}>
        <div className={getCircleType()}>
          {isCurrentWeek && <DirectionsRun className={classes.currentWeek} />}
          {getChild()}
        </div>
      </a>
      <p className={classes.text}>{getText()}</p>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  checkBtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80
  },
  checkBtnCol: {
    marginTop: ({ type }) => (type === 'preSeason' ? 0 : 30),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    width: 32
  },
  largeBtnCol: {
    marginTop: ({ type }) => (type === 'preSeason' ? 0 : 30),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    width: '100%'
  },
  enableCircle: {
    fontWeight: '700',
    fontSize: '10px',
    width: 32,
    height: 32,
    lineHeight: 32,
    background: theme.palette.signup.purple,
    color: '#fff',
    textAlign: 'center',
    borderRadius: 16,
    position: 'relative',
    boxShadow: '2px 2px 6px rgb(0 0 0 / 15%)'
  },
  clearCircle: {
    width: 31,
    height: 31,
    background: '#fff',
    border: ({ isCurrentWeek }) =>
      `2px solid ${isCurrentWeek ? theme.palette.signup.purple : '#5ab391'}`,
    borderRadius: 16,
    boxShadow: '2px 2px 6px rgb(0 0 0 / 15%)',
    position: 'relative'
  },
  disableCircleWithIcon: {
    fontWeight: '700',
    fontSize: '10px',
    width: 32,
    height: 32,
    lineHeight: 32,
    background: '#aeddf4',
    color: '#fff',
    textAlign: 'center',
    borderRadius: 16,
    position: 'relative'
  },
  disableWeekCircle: {
    width: 16,
    height: 16,
    background: '#aeddf4',
    marginTop: 9,
    borderRadius: 8
  },
  icon: {
    position: 'absolute',
    top: 4,
    left: 4,
    color: 'white',
    width: 24,
    height: 24
  },
  preSeasonIcon: {
    position: 'absolute',
    top: 4,
    left: 4,
    color: 'white',
    width: 24,
    height: 24
  },
  currentWeek: {
    position: 'absolute',
    top: -30,
    left: 4,
    color: theme.palette.signup.purple,
    width: 28,
    height: 28
  },
  text: {
    color: (props) =>
      props.isDisabled
        ? '#aeddf4'
        : props.type === 'newWeek'
        ? '#5ab391'
        : theme.palette.signup.purple
  },
  newText: {
    color: '#5ab391',
    fontSize: 10,
    marginLeft: 2,
    marginTop: 7
  },
  link: {
    textDecoration: 'none'
  }
}))

WeekDropDownCircleButton.propTypes = {
  type: PropTypes.string,
  weekNumber: PropTypes.number,
  isCurrentWeek: PropTypes.bool,
  isDisabled: PropTypes.bool,
  roundId: PropTypes.number
}

export default WeekDropDownCircleButton
