import React from 'react'
import { makeStyles } from '@material-ui/core'

const pantryItems = [
  'Canned beans and legumes (red kidney, chickpeas and lentils)',
  'Canned tomatoes',
  'Canned tuna/salmon in spring water',
  'Passata (tomato pasta sauce)',
  'Dried pasta (wholemeal pasta shapes and lasagne sheets)',
  'Rice (basmati, brown or long grain - I recommend brown)',
  'Quinoa',
  'Rolled oats',
  'Reduced salt soy sauce',
  'Dried herbs (mixed herbs, paprika, cinnamon and cumin)',
  'Flour (plain and self-raising)',
  'Olive oil (extra virgin - I recommend spray or pump olive oil)',
  'Pepper grinder full of black pepper',
  'Stock (cartons or cubes – look for a low sodium/no added salt option)',
  'Vinegar for dressing (balsamic and red wine)'
]

const PantryTable = () => {
  const classes = useStyles()
  const PantryItem = (item) => {
    return (
      <div className={classes.item}>
        <span className={classes.check}>&#10003;</span>
        <span>{item.item}</span>
      </div>
    )
  }
  return (
    <div className={classes.pantryTable}>
      <div className={classes.pantryHeader}>PANTRY ESSENTIALS:</div>
      {pantryItems.map((item, i) => {
        return <PantryItem item={item} key={i} />
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  pantryTable: {
    width: '100%',
    border: `1px solid ${theme.palette.greys.dark}`
  },
  pantryHeader: {
    textAlign: 'center',
    backgroundColor: theme.palette.greys.light,
    fontWeight: 500,
    fontSize: '1.2rem',
    padding: '8px'
  },
  check: {
    margin: '1rem'
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export default PantryTable
