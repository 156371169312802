import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { ReactComponent as PrinterIcon } from '../../../assets/img/icons/pre_season/Printer.svg'

const PrintButton = () => {
  const classes = useStyles()
  const printButtonClickHandler = () => {
    window.print()
  }
  return (
    <Button className={classes.printButton} onClick={printButtonClickHandler}>
      <PrinterIcon />
      <span className={classes.title}>Print</span>
    </Button>
  )
}

const useStyles = makeStyles((theme) => ({
  printButton: {
    textTransform: 'none',
    color: '#3B82F6',
    backgroundColor: '#EFF6FF',
    borderRadius: '6px',
    width: '138px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    '& svg': {
      color: '#3B82F6'
    }
  },
  title: {
    marginLeft: '5px'
  }
}))

export default PrintButton
