import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import PreSeasonWelcomeBanner from '../app/modules/pre_season/home/PreSeasonWelcomeBanner'
import PreSeasonTaskCards from '../app/modules/pre_season/home/PreSeasonTaskCards'
import PreSeasonActivities from '../app/modules/pre_season/home/PreSeasonActivities'
import PreSeasonEarlyAccessCards from '../app/modules/pre_season/home/PreSeasonEarlyAccessCards'
import PreSeasonGuide from '../app/modules/pre_season/home/PreSeasonGuide'
import RoundStartsBanner from '../app/modules/pre_season/home/RoundStartsBanner'
import PageContainer from '../app/components/PageContainer'

import loadNewPreSeasonData from '../app/helpers/LoadNewPreSeasonData'
import { useSelector } from 'react-redux'

const PreSeason = () => {
  const classes = useStyles()
  const [redirect, setRedirect] = useState(false)
  const { loading, progress } = useSelector((state) => state.preseason)

  // Get task & activity info:
  loadNewPreSeasonData()

  // Redirect user to welcome steps if they havnt been completed:
  useEffect(() => {
    if (!loading && progress) {
      if (!progress?.welcome_steps_1 || !progress?.welcome_steps_2) {
        setRedirect(true)
      }
    }
  }, [loading, progress])

  if (redirect) return <Redirect to='/welcome' />

  return (
    <PageContainer>
      <div className={classes.container}>
        <RoundStartsBanner />
        <PreSeasonWelcomeBanner />
        <PreSeasonTaskCards />
        <PreSeasonActivities />
        <PreSeasonEarlyAccessCards />
        <PreSeasonGuide />
      </div>
    </PageContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F9FAFB',
    height: '100%',
    width: '100%',
    overflow: 'hidden'
  }
}))

export default PreSeason
