import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormHelperText,
  makeStyles,
  // Typography
} from '@material-ui/core'

const TermsAgreement = ({
  termsAgreed,
  setTermsAgreed,
  submissionAttempt,
  termsLink
}) => {
  const classes = useStyles()
  const error = submissionAttempt && !termsAgreed
  return (
    <div className={classes.container}>
      <label className={classes.checkBoxRow}>
        <Checkbox
          data-testid='termsCheckbox'
          checked={termsAgreed}
          className={classes.root}
          inputProps={{ 'aria-label': 'Checkbox A' }}
          icon={<span className={classes.icon} />}
          checkedIcon={
            <span className={`${classes.icon} ${classes.checkedIcon}`} />
          }
          color='primary'
          onChange={() => {
            setTermsAgreed(!termsAgreed)
          }}
        />
        <span className={classes.terms}>
          I have read and agree to the&nbsp;
          <a href={termsLink} className={classes.termsAndConditions}>
            <b>terms and conditions</b>
          </a>
        </span>
      </label>
      {error && (
        <FormHelperText className={classes.error}>
          You must agree to terms and conditions
        </FormHelperText>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1rem 0'
  },
  checkBoxRow: {
    display: 'flex',
    alignItems: 'center',
    '& :hover': {
      cursor: 'pointer'
    }
  },
  error: {
    color: '#EF5353'
  },
  root: {
    padding: '5px',
    left: '-5px'
  },
  terms: {
    fontSize: '14px'
  },
  termsAndConditions: {
    color: theme.palette.signup.newButton,
    textDecoration: 'none',
    fontWeight: 'bold',
    '& :hover': {
      color: 'blue'
    }
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: theme.palette.signup.newButton,
    backgroundImage: `linear-gradient(180deg,${theme.palette.rgb.signup.newButton},${theme.palette.rgb.signup.newButton})`,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.signup.primary
    }
  }
}))

TermsAgreement.propTypes = {
  termsAgreed: PropTypes.bool.isRequired,
  setTermsAgreed: PropTypes.func.isRequired,
  submissionAttempt: PropTypes.bool.isRequired,
  termsLink: PropTypes.string.isRequired
}

export default TermsAgreement
