import React, { useState } from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import SignUpFAQItem from './SignUpFAQItem'
import faqData from './FAQData'

const SignUpFAQDrawer = ({ show, closeHandler }) => {
  const classes = useStyles()
  const [activeItem, setActiveItem] = useState(null)

  const activeItemHandler = (value) => {
    if (activeItem === value) setActiveItem(null)
    else setActiveItem(value)
  }

  return (
    <Drawer anchor='right' open={show} onClose={closeHandler} className={classes.drawerContainer}>
      <div className={classes.drawer}>
        <h2 className={classes.faqHeader}>FAQ</h2>
        <div className={classes.faqItems}>
          {faqData.map((obj, index) => {
            return (
              <SignUpFAQItem
                header={obj.header}
                content={obj.content}
                key={index}
                lastElement={index === faqData.length - 1}
                collapsed={activeItem !== index}
                setActiveItem={activeItemHandler}
                index={index}
              />
            )
          })}
        </div>
        <div className={classes.closeButton} onClick={closeHandler}>
          <ChevronRightIcon fontSize={'large'} />
        </div>
      </div>
    </Drawer>
  )
}

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '100%'
  },
  drawer: {
    minHeight: '100%',
    ...theme.CSS.center,
    minWidth: '20rem',
    maxWidth: '20rem',
    flexDirection: 'column',
    position: 'relative'
  },
  faqHeader: {
    color: theme.palette.signup.darkBlue
  },
  closeButton: {
    position: 'absolute',
    left: '-.2rem',
    top: '5%',
    width: '2rem',
    height: '4rem',
    border: '2px solid white',
    borderTopRightRadius: '15px',
    borderBottomRightRadius: '15px',
    zIndex: 100,
    backgroundColor: theme.palette.signup.signupFaq,
    '&:hover': {
      cursor: 'pointer'
    },
    color: 'white',
    ...theme.CSS.center
  },
  faqItems: {
    minHeight: '70%'
  },
  [theme.breakpoints.down('xs')]: {
    drawer: {
      maxWidth: '18rem',
      minWidth: '18rem'
    }
  },
  [theme.breakpoints.down(400)]: {
    drawer: {
      maxWidth: '14rem',
      minWidth: '14rem'
    }
  }
}))

SignUpFAQDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired
}

export default SignUpFAQDrawer
