export const EXERCISE_TYPES_CONST = [
  {
    exerciseVariable: 'push_ups',
    exerciseString: 'Push Ups (Toes)',
    exerciseType: 'Upper Body Strength',
    exerciseTypeVariable: 'upper_body',
    exerciseTypeDifficulty: 'Advanced',
    isTimeValue: false,
    unit: ' reps'
  },
  {
    exerciseVariable: 'push_ups_wall',
    exerciseString: 'Push Ups (Against Wall)',
    exerciseType: 'Upper Body Strength',
    exerciseTypeVariable: 'upper_body',
    exerciseTypeDifficulty: 'Low Intensity',
    isTimeValue: false,
    unit: ' reps'
  },
  {
    exerciseVariable: 'knee_push_ups',
    exerciseString: 'Push Ups (Knees)',
    exerciseType: 'Upper Body Strength',
    exerciseTypeVariable: 'upper_body',
    exerciseTypeDifficulty: 'Medium',
    isTimeValue: false,
    unit: ' reps'
  },
  {
    exerciseVariable: 'sit_and_reach',
    exerciseString: 'Sit & Reach',
    exerciseType: 'Flexibility',
    isTimeValue: false,
    unit: 'cm'
  },
  {
    exerciseVariable: 'plank_toes',
    exerciseString: 'Plank (Toes)',
    exerciseType: 'Core Strength',
    exerciseTypeVariable: 'core',
    exerciseTypeDifficulty: 'Advanced',
    isTimeValue: true,
    unit: 's'
  },
  {
    exerciseVariable: 'elevated_plank',
    exerciseString: 'Plank (Elevated)',
    exerciseType: 'Core Strength',
    exerciseTypeVariable: 'core',
    exerciseTypeDifficulty: 'Low Intensity',
    isTimeValue: true,
    unit: 's'
  },
  {
    exerciseVariable: 'ab_stage',
    exerciseString: 'Plank (Knees)',
    exerciseType: 'Core Strength',
    exerciseTypeVariable: 'core',
    exerciseTypeDifficulty: 'Medium',
    isTimeValue: true,
    unit: 's'
  },
  {
    exerciseVariable: 'wall_sit',
    exerciseString: 'Wall Sit',
    exerciseType: 'Lower Body Strength',
    isTimeValue: true,
    unit: 's'
  },
  {
    exerciseVariable: 'time_trial',
    exerciseString: '1km Time Trial',
    exerciseType: 'Aerobic Capacity',
    exerciseTypeVariable: 'aerobic',
    exerciseTypeDifficulty: 'Medium',
    isTimeValue: true,
    unit: 's'
  },
  {
    exerciseVariable: 'three_km_cycle',
    exerciseString: '3km Cycle Time Trial',
    exerciseType: 'Aerobic Capacity',
    exerciseTypeVariable: 'aerobic',
    exerciseTypeDifficulty: 'Low Intensity',
    isTimeValue: true,
    unit: 's'
  },
  {
    exerciseVariable: 'twelve_minute_run_test',
    exerciseString: '12 Minute Run Test',
    exerciseType: 'Aerobic Capacity',
    exerciseTypeVariable: 'aerobic',
    exerciseTypeDifficulty: 'Advanced',
    isTimeValue: false,
    unit: 'm'
  }
]
