import * as yup from 'yup'

const welcomeStepOneValidationSchema = () => {
  return yup.object({
    screen_name: yup.string().required('Enter a valid screen name'),
    gender: yup.string().required('Please select a gender'),
    birthday: yup.string().required('Enter a valid date of birth'),
    street_address: yup.string().required('Enter a street address'),
    suburb: yup.string().required('Enter a valid suburb'),
    postcode: yup.string().required('Enter a valid postcode'),
    state: yup.string(),
    country: yup.string().required('Enter a valid country'),
    phone_number: yup.string().required('Enter a valid mobile number'),
    day: yup.string().required(),
    month: yup.string().required(),
    year: yup.string().required()
  })
}

// Emails with '+' are invalid within our ecosystem (Admin FE errors occur):
// function isValidEmail(value) {
//   if (value.includes('+') && process.env.REACT_APP_ENVIRONMENT === 'production') return false
//   return true
// }

export default welcomeStepOneValidationSchema
