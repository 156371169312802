import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ReactDOM from 'react-dom'
import Slide from '@material-ui/core/Slide'
import { sideDrawerLinks } from './HeaderDataSideDrawer'
import { useSelector } from 'react-redux'
import navigateToGoSite from '../../helpers/NavigateToGoSite'

const SideDrawer = ({ show, children }) => {
  const currentSubscription = useSelector(
    (state) => state.currentSubscription?.currentSubscription
  )
  const classes = useStyles()
  const docBody = document.getElementsByTagName('BODY')[0]
  const roundCurrentWeek = useSelector(
    (state) => state.round?.roundInfo?.current_week
  )
  docBody.style.overflow = show ? 'hidden' : 'auto'
  const content = (
    <Slide direction='right' in={show} mountOnEnter unmountOnExit>
      <aside className={classes.sideDrawer}>
        <div className={classes.scrollable}>
          {sideDrawerLinks(roundCurrentWeek).map((navList, i) => {
            if (navList.shouldShow) {
              return (
                <div key={i}>
                  <div className={classes.sideDrawerHeader}>
                    {navList.header}
                  </div>
                  {navList.links.map((item, i) => {
                    let linkClass = classes.sideDrawerBodyLink
                    // Modify link color & check if lifestyle member:
                    if (item.lifestyleOnly) {
                      if (!currentSubscription?.lifers) return null
                      linkClass = `${classes.sideDrawerBodyLink} ${classes.lifestyleLink}`
                    }
                    return (
                      <a
                        href={navigateToGoSite(item.link)}
                        key={i}
                        className={linkClass}
                      >
                        {item?.icon}
                        {item.title}
                      </a>
                    )
                  })}
                </div>
              )
            }
            return <></>
          })}
        </div>
      </aside>
    </Slide>
  )
  return ReactDOM.createPortal(content, document.getElementById('drawer-hook'))
}

const useStyles = makeStyles((theme) => ({
  sideDrawer: {
    position: 'fixed',
    left: 0,
    top: '50px',
    zIndex: 101,
    width: '300px',
    background: 'white',
    boxShadow: '0 2px 8px rgba(0,0,0,0.26)',
    height: '100vh',
    minHeight: '100vh',
    backgroundColor: '#3F4446',
    overflow: 'auto'
  },
  scrollable: {
    height: '800px'
  },
  sideDrawerHeader: {
    backgroundColor: '#565D61',
    color: 'white',
    fontWeight: 600,
    padding: '.5rem',
    fontSize: '.9rem'
  },
  sideDrawerBodyLink: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#3F4446',
    color: 'white',
    borderTop: '1px solid #565D61',
    padding: '.5rem',
    fontSize: '.8rem',
    textDecoration: 'none',
    '& a': {
      color: 'white',
      textDecoration: 'none'
    },
    '&:hover': {
      backgroundColor: '#565D61'
    },
    '& svg': {
      width: '20px',
      marginRight: '.5rem'
    }
  },
  lifestyleLink: {
    color: '#39ced9'
  },
  [theme.breakpoints.down('xs')]: {
    sideDrawer: {
      width: '220px'
    }
  }
}))

SideDrawer.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default SideDrawer
