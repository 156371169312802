import React from 'react'
import { FormHelperText, makeStyles } from '@material-ui/core'
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from 'prop-types'

const Recaptcha = ({
    captchaRef,
    setCaptchaToken,
    captchaError
}) => {
    const classes = useStyles()
    return <>
    <ReCAPTCHA 
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
        ref={captchaRef} 
        onChange={setCaptchaToken}
      />
      {captchaError && (
        <FormHelperText className={classes.error}>
          You must check above captcha
        </FormHelperText>
      )}
    </>
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: '#EF5353'
  },
}));

Recaptcha.propTypes = {
  captchaRef: PropTypes.object.isRequired,
  setCaptchaToken: PropTypes.func.isRequired,
  captchaError: PropTypes.bool.isRequired,
}

export default Recaptcha;