import React from 'react'
import { ReactComponent as Bullseye } from '../../../../assets/img/icons/signup/bullseye.svg'
import { ReactComponent as Diamond } from '../../../../assets/img/icons/signup/diamond.svg'

import { PLAN_TAB_TYPES } from '../../SignUpData'
import { useTheme } from '@material-ui/core'

export const programCardData = () => {
  const theme = useTheme()
  return {
    ROUND: {
      NAME: PLAN_TAB_TYPES.ROUND.value,
      HEADING: '12 weeks',
      BEST_FOR_HEADING: 'New Members',
      ICON: <Bullseye />,
      COLOR: theme.palette.signup.round,
      BENEFITS_ARRAY: [
        'Tailored programs',
        'Weekly meal plans',
        'Flexible workout options',
        'Weekly mindset sessions'
      ]
    },
    LIFESTYLE: {
      NAME: PLAN_TAB_TYPES.LIFESTYLE.value,
      HEADING: 'Lifestyle',
      BEST_FOR_HEADING: 'Unlimited Access',
      ICON: <Diamond />,
      COLOR: theme.palette.signup.primary,
      BENEFITS_ARRAY: [
        '6 or 12 month membership',
        'Keep your health on track all year round',
        'Access to all programs + exclusive content'
      ]
    }
  }
}
