import * as yup from 'yup'

const signUpFormValidationSchema = () => {
  return yup.object({
    email: yup
      .string()
      .email('Insert a valid email')
      .required('Your email is required')
      .test(
        'emailCheck',
        'Invalid email, your email must not include special characters',
        (value) =>
          value !== undefined && isValidEmail(value) && value.length > 1
      ),
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string().required('Please enter your last name'),
    password: yup
      .string()
      .required('No password provided.')
      .min(6, 'Your password must be 6 characters minimum')
      .test(
        'partnerCheckPass',
        'Entered passwords must match',
        function (value) {
          const passwordReTypeValue = this.resolve(yup.ref('passwordReType'))
          return (
            value === passwordReTypeValue ||
            passwordReTypeValue?.length < 6 ||
            !passwordReTypeValue
          )
        }
      ),
    passwordReType: yup
      .string()
      .required('Please re-type your password')
      .min(6, 'Your password must be 6 characters minimum')
      .test(
        'partnerCheckPass',
        'Entered passwords must match',
        function (value) {
          const passwordValue = this.resolve(yup.ref('password'))
          return value === passwordValue || passwordValue?.length < 6
        }
      ),
    paymentMethod: yup.string().required('Please select a program'),
    productHandle: yup.string().required('Please select a program'),
    billingOption: yup.string().required('Please select a program'),
    paymentPlan: yup.string().required('Please select a program')
  })
}

const signUpFriendFormValidationSchema = () => {
  return yup.object({
    friendEmail: yup
      .string()
      .email('Insert a valid email')
      .required('Your email is required')
      .test(
        'emailCheck',
        'Invalid email, your email must not include special characters',
        (value) =>
          value !== undefined && isValidEmail(value) && value.length > 1
      )
      .test(
        'partnerEmailCheck',
        'Entered emails cannot match',
        function (value) {
          const emailValue = this.resolve(yup.ref('email'))
          return value !== emailValue
        }
      ),
    friendFirstName: yup.string().required('Please enter your first name'),
    friendLastName: yup.string().required('Please enter your last name')
  })
}

export const combinedValidationSchemas = () => {
  return signUpFormValidationSchema().concat(signUpFriendFormValidationSchema())
}

// Emails with '+' are invalid within our ecosystem (Admin FE errors occur):
function isValidEmail(value) {
  if (value.includes('+') && process.env.REACT_APP_ENVIRONMENT === 'production')
    return false
  return true
}

export default signUpFormValidationSchema
