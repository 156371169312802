import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import { ReactComponent as Lock } from '../../../assets/img/icons/signup/Lock.svg'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../../components/Modal'
import {
  resetSignUpError,
  resetSignUpSuccess
} from '../../../../redux/actions/Signup.actions'
import { login } from '../../../../redux/actions/Member.actions'
import SignUpErrorModal from '../SignUpErrorModal'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

const SignUpFormSubmit = ({
  formik,
  chargifyLoading,
  submitClickHandler,
  selectedRound
}) => {
  const {
    loading: programsLoading,
    isWeekly,
    selectedPrice,
    GATotalCostInCents
  } = useSelector((state) => state.signupPrograms)
  const { loading: checkMemberLoading } = useSelector(
    (state) => state.checkMember
  )
  const { loading: couponLoading } = useSelector((state) => state.coupon)
  const { loading, error, member, successfulSignup } = useSelector(
    (state) => state.signup
  )

  const { currentAndNextRound } = useSelector((state) => state.round)
  const memberState = useSelector((state) => state.member)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [dataSent, setDataSent] = useState(false)
  const dispatch = useDispatch()
  const sendDataToGTM = useGTMDispatch()
  const classes = useStyles()

  const investingMessage = isWeekly
    ? `You're investing $${selectedPrice} a week in changes for life.`
    : `You're investing $${selectedPrice} in changes for life.`

  // On successful registration, login member & send data to GA:
  useEffect(() => {
    if (member && formik.values.email && formik.values.password) {
      dispatch(
        login({ email: formik.values.email, password: formik.values.password })
      )

      // Check for 'dataSent' prevents sending data on every re-render:
      if (!dataSent) {
        // Send program & payment type:
        let program = 'single'
        let paymentType = 'recurring'

        if (formik.values.productHandle.includes('lifers')) {
          program = 'lifestyle'
        }

        if (formik.values.productHandle.includes('upfront')) {
          paymentType = 'upfront'
        }

        // Set Round Id:
        const roundId = selectedRound || currentAndNextRound?.nextRoundId

        // IMPORTANT* The price must be sent to google tag manager as an integer (in cents):
        sendDataToGTM({
          event: 'signUpSuccess',
          paymentType,
          program,
          roundId,
          price: GATotalCostInCents
        })
        setDataSent(true)
      }
    }
  }, [member, formik.values])

  // Login member success
  useEffect(() => {
    // A logged in member may still access the signup page, so only redirect if the signup was just successful:
    if (memberState.member && successfulSignup) {
      dispatch(resetSignUpSuccess())
      setRedirect(true)
    }
  }, [memberState?.member, setRedirect])

  // Sign up error modal:
  useEffect(() => {
    if (error) {
      setShowErrorModal(true)
    }
  }, [error])

  const submitHandler = () => {
    if (!loading && !member) {
      submitClickHandler()
    }
  }

  if (redirect) return <Redirect to='/welcome' />

  const signupLoading =
    loading ||
    checkMemberLoading ||
    couponLoading ||
    programsLoading ||
    chargifyLoading

  return (
    <>
      {selectedPrice && (
        <div className={classes.investingMessage}>
          <span id='investingMessage'>{investingMessage}</span>
        </div>
      )}
      <Button
        className={classes.button}
        onClick={submitHandler}
        disabled={signupLoading}
      >
        {signupLoading ? (
          <CircularProgress size={25} className={classes.spinner} />
        ) : (
          <span className={classes.buttonTitle}>
            <Lock className={classes.lock} />
            Place Order
          </span>
        )}
      </Button>
      <Modal
        show={showErrorModal}
        onCancel={() => {
          dispatch(resetSignUpError())
          setShowErrorModal(false)
        }}
      >
        <SignUpErrorModal />
      </Modal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 500,
    width: '100%',
    height: '50px',
    marginTop: '2rem',
    margin: '1rem 0',
    backgroundColor: theme.palette.signup.newButton,
    textTransform: 'none',
    color: 'white',
    borderRadius: theme.borderRadius.sm,
    '& svg': {
      fill: 'white'
    },
    '&:hover': {
      color: theme.palette.signup.newButton,
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.signup.newButton}`,
      '& svg': {
        color: theme.palette.signup.newButton,
        fill: theme.palette.signup.newButton
      }
    },
    '&:disabled': {
      color: theme.palette.greys.light
    }
  },
  buttonTitle: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif'
  },
  spinner: {
    color: 'white'
  },
  lock: {
    marginRight: '1rem'
  },
  investingMessage: {
    width: '100%',
    textAlign: 'center',
    fontSize: '.8rem',
    padding: '0 1rem'
  }
}))

SignUpFormSubmit.propTypes = {
  submitClickHandler: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  selectedRound: PropTypes.number,
  chargifyLoading: PropTypes.bool
}

export default SignUpFormSubmit
