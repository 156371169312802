import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'

const TaskInfoBanner = ({ message }) => {
  const classes = useStyles()
  return (
    <div className={classes.bannerContainer}>
      <div className={classes.iconDiv}>
        <LockIcon />
      </div>
      <span>{message}</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    backgroundColor: '#2563EB',
    width: '100%',
    '& span': {
      color: 'white',
      lineHeight: '21px',
      paddingLeft: '6px',
      fontSize: '1rem'
    },
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '.1rem',
    margin: '1rem auto',
    boxShadow: theme.CSS.cardShadow
  },
  iconDiv: {
    backgroundColor: 'blue',
    width: '3rem',
    height: '2.5rem',
    ...theme.CSS.center,
    borderRadius: '6px',
    '& svg': {
      color: 'white',
      width: '90%'
    },
    margin: '.5rem'
  },
  [theme.breakpoints.down('sm')]: {
    bannerContainer: {
      flexDirection: 'column',
      textAlign: 'center',
      '& span': {
        padding: '.5rem 0',
        fontSize: '.9rem',
        maxWidth: '90%'
      }
    }
  }
}))

TaskInfoBanner.propTypes = {
  message: PropTypes.string.isRequired
}

export default TaskInfoBanner
