import React from 'react'
import navigateToGoSite from '../../helpers/NavigateToGoSite'
import HeaderTooltipList from './HeaderTooltipList'
import TooltipContentCommunity from './TooltipContentCommunity'
import TooltipContentPreSeason from './TooltipContentPreSeason'

const FOOD_AND_NUTRITION_LINKS = [
  { title: 'Recipe Index', link: 'recipes' },
  {
    title: 'Calorie Lookup',
    link: 'calorie-lookup'
  },
  {
    title: 'Nutrition Videos',
    link: 'videos/category/nutrition'
  },
  {
    title: 'Nutrition Hacks',
    link: 'recipes/nutrition-hacks',
    lifestyleOnly: true
  },
  {
    title: 'Ready-made Meal Plans',
    link: 'lifestyle/meal-plans/ready-made',
    lifestyleOnly: true
  }
]

const FITNESS_LINKS = [
  { title: 'Exercise Index', link: 'exercises' },
  { title: 'Fitness Videos', link: 'videos/category/fitness' },
  { title: 'Workout Videos', link: 'videos/category/workout' },
  { title: 'Express Workouts', link: 'exercises/express-workouts' },
  {
    title: 'Workout Hacks',
    link: 'exercises/workout-hacks',
    lifestyleOnly: true
  },
  {
    title: '4 Week Programs',
    link: 'exercises/lifestyle/4-week-programs',
    lifestyleOnly: true
  }
]

export const COMMUNITY_LINKS = [
  { title: 'Member Zone', link: 'forums/member-zone' },
  { title: 'Challenges', link: 'challenges' }
]

const MINDSET_LINKS = [
  { title: 'Mindset Videos', link: 'videos/category/mindset' }
]

export const navigationLinks = [
  {
    title: 'Pre-Season',
    content: <TooltipContentPreSeason />,
    withRadius: false
  },
  {
    title: 'Food & Nutrition',
    content: <HeaderTooltipList list={FOOD_AND_NUTRITION_LINKS} />
  },
  {
    title: 'Fitness',
    content: <HeaderTooltipList list={FITNESS_LINKS} />
  },
  {
    title: 'Mindset',
    content: <HeaderTooltipList list={MINDSET_LINKS} />
  },
  {
    title: 'Community',
    content: <TooltipContentCommunity />
  },
  {
    title: 'Blog',
    link: 'http://www.12wbt.com/blog'
  },
  {
    title: 'Help',
    link: navigateToGoSite('help')
  }
]
