import React from 'react'
import PropTypes from 'prop-types'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core'

const HeaderHamburger = ({ setSideDrawer }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.iconDiv}
      onClick={() => {
        setSideDrawer()
      }}
    >
      <MenuIcon className={classes.icon} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  iconDiv: {
    width: '3rem',
    ...theme.CSS.center,
    backgroundColor: theme.palette.primary.dark,
    height: '100%',
    color: 'white',
    borderRight: `1px solid ${theme.palette.primary.transparent}`,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

HeaderHamburger.propTypes = {
  setSideDrawer: PropTypes.func.isRequired
}

export default HeaderHamburger
