import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../../components/Modal'
import { makeStyles } from '@material-ui/core'

import badgesList from '../../../assets/img/welcome_steps/badges-100@2x.png'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import BrightCovePlayer from '../../../components/BrightCovePlayer'

import { useSelector } from 'react-redux'

const WelcomeBackMessage = ({ welcomeMessage }) => {
  const { memberSummary } = useSelector((state) => state.memberSummary)
  const roundsCompleted = memberSummary?.subscriptions.length - 1
  const classes = useStyles({ roundsCompleted })
  const title = `Welcome back ${memberSummary?.first_name}, you're good to go!`
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div className={classes.welcomeMessageContainer}>
        <div className={classes.badgeContainer}>
          <img src={badgesList} className={classes.badge} alt='Rounds completed 12WBT Badge' />
        </div>
        <span className={classes.welcomeBackTitle}>{title}</span>
        <span className={classes.welcomeBackMessage}>{welcomeMessage}</span>
        <div
          className={classes.videoMessage}
          onClick={() => {
            setShowModal(true)
          }}
        >
          <div className={classes.playButton}>
            <PlayArrowIcon />
          </div>
          <span>Video message from Mish</span>
        </div>
      </div>
      <Modal
        show={showModal}
        onCancel={() => {
          setShowModal(false)
        }}
      >
        <div className={classes.modalVideoContainer}>
          <BrightCovePlayer videoId={'6098586222001'} />
        </div>
      </Modal>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  welcomeMessageContainer: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    color: 'white'
  },
  welcomeBackMessage: {
    lineHeight: '1.3rem',
    maxWidth: '60%',
    margin: '1rem auto'
  },
  welcomeBackTitle: {
    textAlign: 'center',
    fontSize: '1.8rem',
    fontWeight: 600,
    margin: '.5rem auto',
    maxWidth: '30%'
  },
  badgeContainer: {
    margin: '1rem auto',
    overflow: 'hidden',
    width: '100px',
    height: '96px'
  },
  badge: {
    width: '100px',
    height: '3100px',
    marginTop: (props) => `-${193 + (props.roundsCompleted - 1) * 97}px`
  },
  videoMessage: {
    display: 'flex',
    alignItems: 'center',
    margin: '1rem auto',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  playButton: {
    ...theme.CSS.center,
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.transparent,
    marginRight: '1rem'
  },
  modalVideoContainer: {
    width: '53.3rem',
    height: '30rem',
    padding: '1rem',
    margin: 'auto'
  },
  [theme.breakpoints.down('lg')]: {
    modalVideoContainer: {
      width: '35.5rem',
      height: '20rem',
      padding: '1rem',
      margin: 'auto'
    }
  },
  [theme.breakpoints.down('md')]: {
    welcomeBackTitle: {
      maxWidth: '40%'
    }
  },
  [theme.breakpoints.down('sm')]: {
    modalVideoContainer: {
      width: '26.6rem',
      height: '15rem'
    },
    welcomeBackTitle: {
      maxWidth: '50%'
    }
  },
  [theme.breakpoints.down('xs')]: {
    modalVideoContainer: {
      width: '21.3rem',
      height: '12rem'
    },
    welcomeBackTitle: {
      maxWidth: '60%',
      fontSize: '1.5rem'
    },
    welcomeBackMessage: {
      maxWidth: '80%'
    }
  },
  [theme.breakpoints.down(420)]: {
    modalVideoContainer: {
      padding: '.5rem',
      width: '17.8rem',
      height: '10rem'
    },
    welcomeBackTitle: {
      maxWidth: '90%'
    },
    welcomeBackMessage: {
      maxWidth: '90%',
      fontSize: '.8rem'
    }
  }
}))

WelcomeBackMessage.propTypes = {
  welcomeMessage: PropTypes.string.isRequired
}

export default WelcomeBackMessage
