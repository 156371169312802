import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import navigateToGoSiteWithRound from '../../helpers/NavigateToGoSiteWithRound'

const HeaderTooltipList = ({ list }) => {
  const currentSubscription = useSelector((state) => state.currentSubscription?.currentSubscription)
  const currentRoundId = useSelector((state) => state.member.currentRoundId)
  const classes = useStyles()
  return (
    <div className={classes.linkContainer}>
      {list.map((link, i) => {
        let linkClass = classes.link
        // Modify link color & check if lifestyle member:
        if (link.lifestyleOnly) {
          if (!currentSubscription?.lifers) return null
          linkClass = `${classes.link} ${classes.lifestyleLink}`
        }
        return (
          <a href={navigateToGoSiteWithRound(link.link, currentRoundId)} key={i} className={linkClass}>
            {link.title}
          </a>
        )
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '4rem',
    padding: '.5rem 0'
  },
  link: {
    padding: '.7rem 1rem',
    textDecoration: 'none',
    color: theme.palette.signup.purple,
    '&:hover': {
      color: 'black'
    }
  },
  lifestyleLink: {
    color: '#39ced9'
  }
}))

HeaderTooltipList.propTypes = {
  list: PropTypes.array.isRequired
}

export default HeaderTooltipList
