import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const WelcomeStepsFooter = ({ onSubmit, buttonTitle, loading }) => {
  const classes = useStyles()
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])
  return (
    <div className={classes.container}>
      <Button className={classes.button} onClick={onSubmit}>
        {loading ? <CircularProgress size={30} /> : buttonTitle}
      </Button>
      <Divider className={classes.divider} />
      <div className={classes.linksContainer}>
        <span>
          Consult your healthcare professional before beginning any diet or
          fitness regime.
        </span>
        <span>
          Copyright © 2021 12WBT Trading Pty Ltd. |{' '}
          <a href={process.env.REACT_APP_PUBLIC_SITE_TERMS}>Terms </a> | &nbsp;
          <a href={process.env.REACT_APP_PUBLIC_SITE_PRIVACY}>Privacy</a>
        </span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    ...theme.CSS.center,
    flexDirection: 'column',
    fontSize: '.8rem'
  },
  button: {
    backgroundColor: 'white',
    color: theme.palette.signup.purple,
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    borderRadius: '10px',
    width: '12rem',
    margin: '1rem auto',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.signup.primary
    }
  },
  divider: {
    width: '100%',
    marginTop: '2rem',
    backgroundColor: theme.palette.greys.medium
  },
  linksContainer: {
    marginTop: '.5rem',
    ...theme.CSS.center,
    textAlign: 'center',
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.grey[300]
    },
    '& a:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    padding: '1rem',
    color: theme.palette.grey[300],
    '& span': {
      margin: '.3rem 0'
    }
  }
}))

WelcomeStepsFooter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  loading: PropTypes.bool
}

export default WelcomeStepsFooter
