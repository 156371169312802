export const typicalWeek = [
  {
    day: 'MON',
    title: `It's Go Time`,
    description: 'Start your new week like you mean it. Give it 100%!',
    color: '#FFC76D'
  },
  {
    day: 'TUE',
    title: `It's all about MINDSET`,
    description: 'A new video from Mish unlocked at 5pm',
    color: '#F515B4'
  },
  {
    day: 'WED',
    title: 'Checkpoint Day',
    description: 'The ONLY day you should be weighing yourself.',
    color: '#00C461'
  },
  {
    day: 'THU',
    title: `Next week's plans are live!`,
    description: 'Pull your diaries out and get organised.',
    color: '#FF9260'
  },
  {
    day: 'FRI',
    title: `It's Core Day`,
    description: 'For a lot of you. Strengthen and stretch your way to success!',
    color: '#4CA5FF'
  },
  {
    day: 'SAT',
    title: 'Most of you will have more time to train EXTRA hard',
    description: `It's SSS time. Smash it out!`,
    color: '#CC5FED'
  },
  {
    day: 'SUN',
    title: `You've earned a rest day - enjoy!`,
    description: 'Mindset Video unlocked at 5pm.',
    color: '#F5245E'
  }
]

export const typicalWeekBlokes = [
  {
    day: 'MON',
    title: `It's Go Time`,
    description: 'Start your week strong, rip straight in!',
    color: '#FFC76D'
  },
  {
    day: 'TUE',
    title: `It's all about MINDSET`,
    description: 'A Pump Up video will be released at 5 pm',
    color: '#F515B4'
  },
  {
    day: 'WED',
    title: 'Checkpoint Day',
    description: 'Put another workout in the bank',
    color: '#00C461'
  },
  {
    day: 'THU',
    title: `Next week's plans are live!`,
    description: 'Shop, stretch, get to bed early',
    color: '#FF9260'
  },
  {
    day: 'FRI',
    title: `It's Core Day`,
    description: 'Test your cardio fitness with a 3km Bike sprint',
    color: '#4CA5FF'
  },
  {
    day: 'SAT',
    title: 'Most of you will have more time to train EXTRA hard',
    description: `Try 20 mins of your favourite type of cardio`,
    color: '#CC5FED'
  },
  {
    day: 'SUN',
    title: `You've earned a rest day - enjoy!`,
    description: 'Mindset Video unlocked at 5pm.',
    color: '#F5245E'
  }
]

export default typicalWeek
