import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import WelcomeStepTwoSelectFitnessGoal from './WelcomeStepTwoSelectFitnessGoal'
import WelcomeStepTwoContinue from './WelcomeStepTwoContinue'
import { useDispatch, useSelector } from 'react-redux'
import { updateSubscription } from '../../../../redux/actions/Member.actions'
import WelcomeStepTwoSelectProgram from './WelcomeStepTwoSelectProgram'
import { GOALS } from './WelcomeStepTwoData'
import WelcomeStepTwoSelectCalories from './WelcomeStepTwoSelectCalories'
import WelcomeStepTwoSelectMealPlan from './WelcomeStepTwoSelectMealPlan'

const WelcomeStepTwoForm = () => {
  const dispatch = useDispatch()
  const { stepTwoProgress } = useSelector((state) => state.welcomeStepTwo)
  const { loading, currentSubscriptionId } = useSelector((state) => state.member)
  const { memberSummary } = useSelector((state) => state.memberSummary)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [stepTwoProgress])

  const formik = useFormik({
    initialValues: {
      fitnessGoal: GOALS.LOSE_WEIGHT,
      exercise_plan_level: 'Beginner',
      exercise_plan_name: 'Beginner',
      default_nutrition_plan: 'Classic - Cooking for 2',
      recipe_calorie_level: '',
      nutrition_program_id: ''
    },
    onSubmit: (values) => {
      dispatch(updateSubscription(memberSummary.id, currentSubscriptionId, values))
    }
  })
  const renderAppropriateCards = () => {
    switch (stepTwoProgress) {
      case 1: {
        return <WelcomeStepTwoSelectFitnessGoal formik={formik} collapsed={false} />
      }
      case 2: {
        return (
          <>
            <WelcomeStepTwoSelectFitnessGoal formik={formik} collapsed={true} />
            <WelcomeStepTwoSelectProgram formik={formik} collapsed={false} />
          </>
        )
      }
      case 3: {
        return (
          <>
            <WelcomeStepTwoSelectProgram formik={formik} collapsed={true} />
            <WelcomeStepTwoSelectCalories formik={formik} collapsed={false} />
            <WelcomeStepTwoSelectMealPlan formik={formik} collapsed={false} />
          </>
        )
      }
      default:
        return null
    }
  }
  return (
    <div>
      {renderAppropriateCards()}
      <WelcomeStepTwoContinue formik={formik} loading={loading} />
    </div>
  )
}

export default WelcomeStepTwoForm
