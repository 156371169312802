import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import ContentContainer from '../../../../components/ContentContainer'
import TaskLearnButtonRow from '../TaskLearnButtonRow'
import TaskContentContainer from '../TaskContentContainer'
import TaskVideo from '../TaskVideo'

import navigateToGoSite from '../../../../helpers/NavigateToGoSite'

const FitnessTestAndMeasureUpLearn = ({ doTaskButtonClick }) => {
  const classes = useStyles()
  return (
    <ContentContainer maxWidth='xl'>
      <div className={classes.container}>
        <TaskVideo videoId='3086187186001' />
        <TaskContentContainer>
          <p>
            If there&apos;s only 1 task you do, make sure it’s this one! In order to know how far you’ve come, you have
            to know where you started... that is why this task is SO important.
          </p>
          <p>The purpose of this task is to:</p>
          <ol>
            <li>It will make sure you start on the right program that suits your current goals and conditioning.</li>
            <li>
              Measure and track all areas of fitness to see which areas you improve on, and which areas will need more
              attention.
            </li>
            <li>Help you with your personal goal setting.</li>
          </ol>
          <p className={classes.subheader}>Your Fitness Test includes:</p>
          <ul>
            <li>Upper Body Strength</li>
            <li>Core and Lower Body muscular endurance</li>
            <li>Aerobic capacity</li>
            <li>Flexibility</li>
          </ul>
          <p>
            I will be getting you to redo your Fitness Test every 4 weeks and record it in your{' '}
            <a href={navigateToGoSite('my-stats')}>My Stats</a>. This means you&apos;ll have to get used to the testing
            and measuring process! Your My Stats will open Monday in Week 1, ready for your first Weekly Checkpoint!
          </p>
          <p className={classes.subheader}>Your Measure Up includes:</p>
          <ul>
            <li>Weighing yourself</li>
            <li>Body Measurements</li>
            <li>Whipping out your phone and taking a &apos;Selfie&apos;</li>
          </ul>
          <p>
            While most people are still focussed on &quot;Weight loss&quot; solely from the scales, this form of
            measuring becomes less important the more weight you lose.
          </p>
          <p>
            There will soon come a time when the scales don&apos;t change, which is great news, it means your body fat
            levels are now at a low level, in which case the tape measurements are the best way to track body fat
            loses...and then finally when the measurements aren&apos;t changing the &apos;Selfie&apos; will become your
            new set of scales.
          </p>
          <span className={classes.memberZoneHeader}>Tip!</span>
          <div className={classes.memberZoneTips}>
            <span>
              I&apos;ve had members in the past avoid this task, only to regret it at the end - it&apos;s important to
              celebrate your achievements, and to set benchmarks.
            </span>
          </div>
          <TaskLearnButtonRow doTaskButtonClick={doTaskButtonClick} />
        </TaskContentContainer>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '96px'
  },
  memberZoneTips: {
    ...theme.CSS.center,
    borderLeft: `5px solid ${theme.palette.signup.purple}`,
    width: '100%',
    margin: 'auto',
    marginBottom: '2rem',
    height: '3rem',
    marginTop: '10px',
    paddingLeft: '2rem'
  },
  memberZoneHeader: {
    fontSize: '1.3rem',
    fontWeight: 900,
    color: theme.palette.signup.purple
  },
  [theme.breakpoints.down('sm')]: {
    memberZoneTips: {
      height: '5rem',
      paddingLeft: '.1rem ',
      '& span': {
        marginLeft: '1rem'
      }
    }
  },
  [theme.breakpoints.down('xs')]: {
    memberZoneTips: {
      height: '8rem'
    }
  }
}))

FitnessTestAndMeasureUpLearn.propTypes = {
  doTaskButtonClick: PropTypes.func.isRequired
}

export default FitnessTestAndMeasureUpLearn
