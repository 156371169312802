import React from 'react'
import PropTypes from 'prop-types'
import { Grid, makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedPrice } from '../../../../redux/actions/Signup.actions'

const splitText = (text) => {
  return (
    text && text.split('\n').map((string, i) => <span key={i}>{string}</span>)
  )
}

const BannerItem = ({
  value,
  activeBanner,
  setActiveBanner,
  paymentObj,
  title,
  description,
  price,
  minTotalCostInDollars,
  activeColor,
  terms,
  discount,
  paymentDescription,
  billingCycles,
  originalPriceInCents
}) => {
  const classes = useStyles({ activeColor })
  const dispatch = useDispatch()
  const { selectedPrice } = useSelector((state) => state.signupPrograms)

  const currentBannerActive = value === activeBanner
  // If user already selected payment plan before entering coupon code, update price in redux:
  if (currentBannerActive && selectedPrice !== paymentObj.priceInDollars) {
    const isWeekly = value.includes('recurring')
    const isDiscounted = paymentObj?.couponCode
    dispatch(
      setSelectedPrice(
        paymentObj.priceInDollars,
        paymentObj.GATotalCostInCents,
        isWeekly,
        isDiscounted
      )
    )
  }

  const bannerClickHandler = () => {
    setActiveBanner(value, paymentObj)
  }

  const containerClass =
    activeBanner === value
      ? `${classes.container} ${classes.activeContainer}`
      : classes.container

  // Display correct banner description (in multiple rows):
  let bannerDescription = `$${price} ${description}`
  if (paymentDescription) {
    bannerDescription = paymentObj.productHandle.includes('recurring')
      ? `${paymentDescription}\n$${price} ${description}\u002A`
      : `${paymentDescription}\n${description}\u002A`
  }
  bannerDescription += `\nMin total cost $${minTotalCostInDollars}`
  const descriptionSplit = splitText(bannerDescription)
  const termsSplit = splitText(terms)
  const discountPrice =
    discount?.discountPriceInCents &&
    (discount?.discountPriceInCents / 100).toFixed(2)
  const isRecurring = paymentObj.productHandle.includes('recurring')
  const priceText = isRecurring ? `$${price} weekly` : `$${price}`
  const discountText = isRecurring
    ? `$${discountPrice} weekly`
    : `$${discountPrice}`

  return (
    <Grid container className={containerClass} onClick={bannerClickHandler}>
      <Grid item className={classes.bannerContent} xs={8} sm={8}>
        <span className={classes.title}>{title}</span>
        <div className={classes.description}>{descriptionSplit}</div>
      </Grid>
      {price && (
        <Grid item xs={4} sm={4} className={classes.priceGridItem}>
          {discount ? (
            <div className={classes.slashedDiv}>
              <div>
                <span className={classes.slashedPrice}>
                  ${(originalPriceInCents / 100).toFixed(2)}
                </span>
              </div>
              <span className={classes.price}>{discountText}</span>
            </div>
          ) : (
            <span className={classes.price}>{priceText}</span>
          )}
        </Grid>
      )}
      {terms && activeBanner === value && (
        <span className={classes.terms}>{termsSplit}</span>
      )}
      {discount?.termsLink && activeBanner === value && (
        <span className={classes.termsLink}>
          See Terms and Conditions&nbsp;
          <a
            style={{ whiteSpace: 'nowrap' }}
            href={`${process.env.REACT_APP_PUBLIC_SITE}${discount?.termsLink}`}
          >
            here
          </a>
        </span>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    margin: '1.5rem 0',
    borderRadius: '5px',
    border: '1px solid #D1D5DB',
    padding: '16px 12px',
    // backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    textAlign: 'initial',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  activeContainer: {
    border: ({ activeColor }) =>
        `3px solid ${theme.palette.signup.borderColor}`
      // activeColor
      //   ? `3px solid ${activeColor}`
      //   : `3px solid ${theme.palette.signup.borderColor}`
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 900,
    fontSize: '14px',
    marginBottom: '.2rem'
  },
  description: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#6B7280',
    letterSpacing: '0px',
    display: 'flex',
    flexDirection: 'column'
  },
  terms: {
    marginTop: '.5rem',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#6B7280',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500
  },
  termsLink: {
    fontSize: '9px',
    marginTop: '.5rem',
    lineHeight: '12px',
    color: '#6B7280',
    fontWeight: 500
  },
  priceGridItem: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    overflow: 'visible'
  },
  price: {
    fontWeight: 700,
    fontSize: '20px',
    // color: theme.palette.signup.purple
    color: '#000000',
    marginLeft: '27px'
  },
  slashedDiv: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      margin: '.2rem 0'
    }
  },
  slashedPrice: {
    fontSize: '17px',
    fontWeight: 600,
    color: 'black',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      borderBottom: `2px solid red`,
      left: '-5%',
      width: '110%',
      top: '50%'
    }
  },
  saveDiv: {
    padding: '0 .2rem',
    color: 'white',
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1.04rem',
    backgroundColor: theme.palette.signup.lightBlue
  },
  [theme.breakpoints.down(1430)]: {
    price: {
      paddingLeft: '.1rem',
      fontSize: '16px',
      fontWeight: 600
    },
    container: {
      padding: '16px 8px'
    },
    saveDiv: {
      fontSize: '14px'
    },
    slashedPrice: {
      marginLeft: '.2rem',
      fontSize: '15px'
    }
  },
  [theme.breakpoints.down('sm')]: {
    price: {
      fontSize: '14px'
    },
    slashedPrice: {
      fontSize: '13px'
    },
    saveDiv: {
      fontSize: '12px'
    }
  },
  [theme.breakpoints.down('xs')]: {
    price: {
      fontSize: '16px'
    },
    slashedPrice: {
      fontSize: '15px'
    },
    saveDiv: {
      fontSize: '14px'
    }
  }
}))

BannerItem.propTypes = {
  value: PropTypes.string.isRequired,
  activeBanner: PropTypes.string,
  setActiveBanner: PropTypes.func,
  paymentObj: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string,
  activeColor: PropTypes.string,
  terms: PropTypes.string,
  isSlashed: PropTypes.bool,
  minTotalCostInDollars: PropTypes.string,
  paymentDescription: PropTypes.string,
  billingCycles: PropTypes.number,
  discount: PropTypes.object,
  originalPriceInCents: PropTypes.number
}

export default BannerItem
