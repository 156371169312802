import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import ShoppingSmartCalculatorForm from './ShoppingSmartCalculatorForm'
import ShoppingSmartCalculatorResult from './ShoppingSmartCalculatorResult'

const ShoppingSmartCalculator = () => {
  const [calculationDone, setCalculationDone] = useState(false)
  const [result, setResult] = useState(0)
  const formik = useFormik({
    initialValues: {
      groceries: 0,
      homeAlcohol: 0,
      breakfast: 0,
      lunch: 0,
      dinner: 0,
      snacks: 0,
      outAlcohol: 0
    },
    onSubmit: (values) => {
      const array = Object.values(values)
      let accumulator = 0
      array.forEach((value) => {
        if (value === '') value = 0
        accumulator += value
      })
      setResult(accumulator)
      setCalculationDone(true)
    }
  })
  const classes = useStyles()

  if (calculationDone) return <ShoppingSmartCalculatorResult edit={() => setCalculationDone(false)} result={result} />
  return (
    <div className={classes.container}>
      <ShoppingSmartCalculatorForm formik={formik} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '80%',
    margin: 'auto'
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      maxWidth: '90%'
    }
  }
}))

export default ShoppingSmartCalculator
