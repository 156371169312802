import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const TabItem = ({ value, title, activeTab, activeColor }) => {
  const classes = useStyles({ activeColor })
  const tabItemClass =
    value === activeTab
      ? `${classes.tabItem} ${classes.activeTab}`
      : classes.tabItem

  return (
    <div className={tabItemClass}>
      <span className={classes.tabItemMainSpan}>
        <span className={classes.tabItemInnerSpan}>{title}</span>
      </span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  tabItem: {
    fontSize: '70px',
    ...theme.CSS.center,
    width: '100%',
    height: '85px',
    position: 'relative',
    fontFamily: 'Handwritten'
  },
  activeTab: {
    fontWeight: 'bold'
  },
  activeBorder: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '8px',
    backgroundColor: (props) => props.activeColor
  },
  tabItemMainSpan: {
    display: 'inline-block',
    height: '30px',
    width: '385px',
    backgroundColor: '#D5EBFF'
  },
  tabItemInnerSpan: {
    position: 'relative',
    top: '-37px'
  },
  [theme.breakpoints.down('sm')]: {
    tabItem: {
      fontSize: '45px'
    },
    tabItemMainSpan: {
      height: '23px',
      width: '335px'
    },
    tabItemInnerSpan: {
      top: '-22px'
    }
  }
}))

TabItem.propTypes = {
  value: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  activeColor: PropTypes.string,
  oneTab: PropTypes.bool
}

export default TabItem
