import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const FormHeader = forwardRef(
  ({ defaultStepNumber, roundDate, adjustableStep, title }, ref) => {
    const classes = useStyles()
    const stepNumber =
      adjustableStep && roundDate ? defaultStepNumber + 1 : defaultStepNumber
    return (
      <span className={classes.formHeader} ref={ref}>
          <b>Step {stepNumber}</b>. {title}
      </span>
    )
  }
)

const useStyles = makeStyles((theme) => ({
  formHeader: {
    // fontWeight: 700,
    // fontSize: '17px'
  }
}))

FormHeader.propTypes = {
  defaultStepNumber: PropTypes.number.isRequired,
  roundDate: PropTypes.any,
  adjustableStep: PropTypes.bool,
  title: PropTypes.string.isRequired
}

FormHeader.defaultProps = {
  adjustableStep: false
}

FormHeader.displayName = 'FormHeader'

export default FormHeader
