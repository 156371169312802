import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Hidden, makeStyles } from '@material-ui/core'
import HeaderTooltip from './HeaderTooltip'

const Link = ({ title, link, content, withRadius, isSideDrawer }) => {
  const classes = useStyles({ isSideDrawer })
  const targetRef = useRef(null)
  if (link)
    return (
      <a href={link} className={classes.link}>
        {title}
      </a>
    )
  return (
    <div className={classes.link} ref={targetRef}>
      {title}
      <Hidden xsDown>
        <HeaderTooltip withRadius={withRadius} targetEl={targetRef}>
          {content}
        </HeaderTooltip>
      </Hidden>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  link: {
    margin: '18px 15px',
    color: theme.palette.signup.purple,
    fontWeight: 700,
    fontSize: (props) => (props.isSideDrawer ? '18px' : '14px'),
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark
    }
  },
  [theme.breakpoints.down('md')]: {
    link: {
      fontSize: (props) => (props.isSideDrawer ? '15px' : '12px'),
      margin: '18px 10px'
    }
  }
}))

Link.defaultProps = {
  isSideDrawer: false
}

Link.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isSideDrawer: PropTypes.bool,
  withRadius: PropTypes.bool
}

export default Link
