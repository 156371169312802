import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import SectionTabs from '../SectionTabs'

import FitnessTestHowThisWorks from './FitnessTestHowThisWorks'
import FitnessTestCoreStrength from './FitnessTestCoreStrength'
import FitnessTestFlexibility from './FitnessTestFlexibility'
import FitnessTestAerobicCapacity from './FitnessTestAerobicCapacity'
import FitnessTestLowerBodyStrength from './FitnessTestLowerBodyStrength'
import FitnessTestUpperBodyStrength from './FitnessTestUpperBodyStrength'
import FitnessTestWarmUp from './FitnessTestWarmUp'
import FitnessTestResults from './FitnessTestResults/FitnessTestResults'
import TaskCompletedBanner from '../../TaskCompletedBanner'

import { getPreSeasonTask } from '../../../../../helpers/GetPreSeasonTask'
import { FITNESS_TEST } from '../../TaskData'
import { useDispatch, useSelector } from 'react-redux'
import { deletePreSeasonTask } from '../../../../../../redux/actions/PreSeason.actions'

const SECTION = {
  HOW: 'How this works',
  WARMUP: 'Warm Up',
  UPPER_BODY_STRENGTH: 'Upper Body Strength',
  CORE_STRENGTH: 'Core Strength',
  AEROBIC_CAPACITY: 'Aerobic Capacity',
  FLEXIBILITY: 'Flexibility',
  LOWER_BODY_STRENGTH: 'Lower Body Strength'
}

const fitnessTestData = [
  SECTION.HOW,
  SECTION.WARMUP,
  SECTION.UPPER_BODY_STRENGTH,
  SECTION.CORE_STRENGTH,
  SECTION.AEROBIC_CAPACITY,
  SECTION.FLEXIBILITY,
  SECTION.LOWER_BODY_STRENGTH
]

const FitnessTest = () => {
  const [activeSection, setActiveSection] = useState(SECTION.HOW)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { currentSubscriptionId } = useSelector((state) => state.member)
  const { loading } = useSelector((state) => state.newPreSeason)

  const task = getPreSeasonTask(FITNESS_TEST)
  const isComplete = task

  useEffect(() => {
    // window.scrollTo({ top: 0, behaviour: 'smooth' })
  }, [isComplete])

  const nextTaskLinkClickHandler = (value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setActiveSection(value)
  }

  const SectionContent = () => {
    switch (activeSection) {
      case SECTION.HOW: {
        return <FitnessTestHowThisWorks nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.WARMUP} />
      }
      case SECTION.WARMUP: {
        return <FitnessTestWarmUp nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.UPPER_BODY_STRENGTH} />
      }
      case SECTION.UPPER_BODY_STRENGTH: {
        return (
          <FitnessTestUpperBodyStrength nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.CORE_STRENGTH} />
        )
      }
      case SECTION.CORE_STRENGTH: {
        return <FitnessTestCoreStrength nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.AEROBIC_CAPACITY} />
      }
      case SECTION.AEROBIC_CAPACITY: {
        return <FitnessTestAerobicCapacity nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.FLEXIBILITY} />
      }
      case SECTION.FLEXIBILITY: {
        return (
          <FitnessTestFlexibility nextTaskClick={nextTaskLinkClickHandler} nextTask={SECTION.LOWER_BODY_STRENGTH} />
        )
      }
      case SECTION.LOWER_BODY_STRENGTH: {
        return <FitnessTestLowerBodyStrength />
      }
      default:
        return null
    }
  }

  const tabChangeHandler = (value) => {
    setActiveSection(value)
  }

  const undoTaskClickHandler = () => {
    if (!loading) dispatch(deletePreSeasonTask(currentSubscriptionId, task._id))
  }

  return (
    <div className={classes.container}>
      {isComplete && <TaskCompletedBanner onUndoClick={undoTaskClickHandler} />}
      <SectionTabs tabsArray={fitnessTestData} activeTab={activeSection} setTabActive={tabChangeHandler} />
      {SectionContent()}
      <FitnessTestResults />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '20px'
  }
}))
export default FitnessTest
