import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { taskCardsData } from './PreSeasonData'
import TaskCard from './TaskCard'
import PreSeasonHeader from './PreSeasonHeader'
import ContentContainer from '../../../components/ContentContainer'
import { useSelector } from 'react-redux'
import moment from 'moment'

const PreSeasonTaskCards = () => {
  const { preseason } = useSelector((state) => state.newPreSeason)
  const { roundInfo } = useSelector((state) => state.round)
  const classes = useStyles()
  return (
    <ContentContainer maxPixels={1400}>
      <div className={classes.container}>
        <PreSeasonHeader
          title='Tasks'
          description='Member’s who nail their Pre-Season Tasks are our biggest success stories. Like I said these tasks are IMPORTANT! There are 5 Pre-Season Tasks to complete, 4 are unlocked and ready for you to do now. The final task `Fitness Test & Measure Up` will be unlocked in the last week of Pre-Season (Week 4), just before the Round kicks off.'
        />
        <Grid container spacing={3} className={classes.taskGridContainer}>
          {taskCardsData.map((task, i) => {
            let cardLocked = false
            let unlockDate = null
            let gridSize = 4
            if (i === 0 || i === 1) gridSize = 6
            let taskDone = false

            taskDone = preseason?.preSeason?.tasks.find((t) => t.title === task.title)

            if (task.title === 'Fitness Test & Measure Up') {
              const fitnessTestDone = preseason?.preSeason?.tasks.find((t) => t.title === 'Fitness Test')
              const measureUpDone = preseason?.preSeason?.tasks.find((t) => t.title === 'Measure Up')
              if (fitnessTestDone && measureUpDone) taskDone = true
              const duration = moment.duration(moment(roundInfo?.starts_at).diff(moment()))
              const days = Math.floor(duration.asDays())
              if (days >= 7) {
                cardLocked = true
              }
              unlockDate = moment(roundInfo?.starts_at).subtract(7, 'days').format('DD MMMM')
            }
            return (
              <Grid item xs={12} sm={6} md={6} lg={gridSize} key={i}>
                <TaskCard
                  bannerImage={task.bannerImage}
                  title={task.title}
                  link={task.link}
                  description={task.description}
                  done={taskDone}
                  required={task.required}
                  locked={cardLocked}
                  unlockDate={unlockDate}
                />
              </Grid>
            )
          })}
        </Grid>
      </div>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.paddings.md
  },
  taskHeader: {
    ...theme.CSS.center,
    flexDirection: 'column',
    textAlign: 'center',
    '& h3': {
      fontWeight: 700,
      fontSize: '36px',
      margin: '1rem auto'
    }
  },
  taskGridContainer: {
    marginTop: theme.paddings.md
  },
  taskDescription: {
    maxWidth: '683px',
    fontSize: '20px',
    lineHeight: '28px'
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      margin: '1rem'
    }
  }
}))

export default PreSeasonTaskCards
