import React from 'react'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import mealPlanIcon from '../../assets/img/icons/nav/MealPlan.svg'
import myFeedIcon from '../../assets/img/icons/nav/MyFeed.svg'
import exercisePlanIcon from '../../assets/img/icons/nav/ExercisePlan.svg'
import mindsetIcon from '../../assets/img/icons/nav/Mindset.svg'
import myStatsIcon from '../../assets/img/icons/nav/MyStats.svg'
import myTrackerIcon from '../../assets/img/icons/nav/MyTracker.svg'
import weeklyChallengeIcon from '../../assets/img/icons/nav/WeeklyChallenge.svg'

const iconStyle = {
  color: '#fff',
  width: '20px',
  height: '20px',
  marginRight: '5px'
}

export const sideDrawerLinks = (roundCurrentWeek) => [
  {
    header: 'PRE-SEASON',
    shouldShow: roundCurrentWeek < 1,
    links: [{ title: 'My Stats', link: 'my-stats', icon: <EqualizerIcon /> }],
    isPreSeason: true
  },
  {
    header: `WEEK ${roundCurrentWeek}`,
    shouldShow: roundCurrentWeek >= 1,
    links: [
      {
        title: 'My Meal Plan',
        link: `week/${roundCurrentWeek}/meal-plans`,
        icon: <img src={mealPlanIcon} style={iconStyle} />
      },
      {
        title: 'My Shopping List',
        link: `week/${roundCurrentWeek}/meal-plans/shopping-list`,
        icon: <img src={myFeedIcon} style={iconStyle} />
      },
      {
        title: 'My Exercise Plan',
        link: `week/${roundCurrentWeek}/exercise-plan`,
        icon: <img src={exercisePlanIcon} style={iconStyle} />
      },
      {
        title: 'Mindset Video',
        link: `week/${roundCurrentWeek}`,
        icon: <img src={mindsetIcon} style={iconStyle} />
      },
      {
        title: 'My Stats',
        link: 'my-stats',
        icon: <img src={myStatsIcon} style={iconStyle} />
      },
      {
        title: 'My tracker',
        link: `week/${roundCurrentWeek}/my-tracker`,
        icon: <img src={myTrackerIcon} style={iconStyle} />
      },
      {
        title: 'Weekly Challenge',
        link: `week/${roundCurrentWeek}/challenge`,
        icon: <img src={weeklyChallengeIcon} style={iconStyle} />
      }
    ]
  },
  {
    header: 'FOOD & NUTRITION',
    shouldShow: true,
    links: [
      { title: 'Recipe Index', link: 'recipes' },
      { title: 'Calorie Lookup', link: 'calorie-lookup' },
      { title: 'Nutrition Videos', link: 'video/category/nutrition' },
      {
        title: 'Nutrition Hacks',
        link: 'recipes/nutrition-hacks',
        lifestyleOnly: true
      },
      {
        title: 'Ready-made Meal Plans',
        link: 'lifestyle/meal-plans/ready-made',
        lifestyleOnly: true
      }
    ]
  },
  {
    header: 'FITNESS',
    shouldShow: true,
    links: [
      { title: 'Exercise Index', link: 'exercises' },
      { title: 'Fitness Videos', link: 'videos/category/fitness' },
      { title: 'Workout Videos', link: 'videos/category/workout' },
      { title: 'Express Workouts', link: 'exercises/express-workouts' },
      {
        title: 'Workout Hacks',
        link: 'exercises/workout-hacks',
        lifestyleOnly: true
      },
      {
        title: '4 Week Programs',
        link: 'exercises/lifestyle/4-week-programs',
        lifestyleOnly: true
      }
    ]
  },
  {
    header: 'MINDSET',
    shouldShow: true,
    links: [{ title: 'Mindset Videos', link: 'videos/category/mindset' }]
  },
  {
    header: 'COMMUNITY',
    shouldShow: true,
    links: [
      { title: 'Member Zone', link: 'forums/member-zone' },
      { title: 'Challenges', link: 'challenges' },
      { title: 'Blog', link: 'https://www.12wbt.com/blog' },
      { title: 'My Feed', link: 'forums/my-feed' },
      { title: 'Help', link: 'help' }
    ]
  }
]
