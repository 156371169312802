import React, { useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getRoundInfo } from '../../../../redux/actions/Signup.actions'

const WelcomeCompletePreSeasonBanner = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { currentRoundId } = useSelector((state) => state.member)
  const { roundInfo } = useSelector((state) => state.round)

  // Get next available round start dates:
  useEffect(() => {
    if (currentRoundId) {
      dispatch(getRoundInfo(currentRoundId))
    }
  }, [currentRoundId])

  const roundText = `${moment(roundInfo?.starts_at).format('MMMM DD, YYYY')}, ${moment(roundInfo?.starts_at).fromNow(
    true
  )} to go!`

  return (
    <div className={classes.container}>
      <span className={classes.title}>Pre-Season is open!</span>
      <span>We all start together and finish together.</span>
      <Grid container className={classes.progressLineContainer}>
        <Grid item xs={4} className={classes.gridItem}>
          <div className={classes.greenCircle}></div>
          <div className={classes.greenLine}></div>
          <div className={classes.preSeasonPosition}>
            <div className={classes.speechBubble}>
              <div className={classes.preSeasonSpeechBubble}>
                <span>You are in</span>
              </div>
              <div className={classes.downArrow}></div>
            </div>
          </div>
          <span className={classes.preSeasonText}>Pre-Season</span>
        </Grid>
        <Grid item xs={8} className={classes.gridItem}>
          <div className={classes.blueCircle}></div>
          <div className={classes.blueLine}></div>
          <div className={classes.blueCircle}></div>
          <span className={classes.start}>Start</span>
          <span className={classes.programText}>12 Week Program</span>
        </Grid>
      </Grid>
      <div className={classes.bottomText}>
        <span className={classes.bold}>{roundText}</span>
        <span>Get prepped! Work through your Pre-Season Tasks to hit the ground running.</span>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: 'white',
    margin: '2rem 0',
    padding: '2rem 0',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '1.8rem',
    margin: '.5rem'
  },
  bottomText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center'
  },
  bold: {
    fontWeight: 'bold',
    margin: '.5rem'
  },
  progressLineContainer: {
    maxWidth: '80%',
    width: '80%',
    margin: '3rem auto',
    display: 'flex',
    flexDirection: 'row'
  },
  greenCircle: {
    backgroundColor: theme.palette.signup.green,
    width: '10px',
    height: '10px',
    borderRadius: '50%'
  },
  greenLine: {
    height: '1px',
    backgroundColor: theme.palette.signup.green,
    marginLeft: '-2px',
    width: '102%'
  },
  blueCircle: {
    backgroundColor: theme.palette.signup.primary,
    width: '10px',
    height: '10px',
    borderRadius: '50%'
  },
  blueLine: {
    height: '1px',
    width: '102%',
    backgroundColor: theme.palette.signup.primary
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center',
    fontSize: '.8rem',
    '& span': {
      top: '12px'
    }
  },
  preSeasonPosition: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '4.5rem'
  },
  preSeasonText: {
    fontWeight: 'bold',
    position: 'absolute'
  },
  preSeasonSpeechBubble: {
    borderRadius: '6px',
    backgroundColor: theme.palette.signup.green,
    color: 'white',
    padding: '3px',
    fontSize: '.8rem',
    fontWeight: 'bold',
    width: '6rem'
  },
  speechBubble: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  downArrow: {
    width: 0,
    height: 0,
    borderLeft: '7px solid transparent',
    borderRight: '7px solid transparent',
    borderTop: `7px solid ${theme.palette.signup.green}`,
    marginLeft: '2rem auto'
  },
  start: {
    position: 'absolute',
    left: '-13px',
    color: theme.palette.greys.dark
  },
  programText: {
    position: 'absolute',
    color: theme.palette.greys.dark
  },
  [theme.breakpoints.down('xs')]: {
    gridItem: {
      fontSize: '.7rem'
    },
    programText: {
      top: '25px!important'
    },
    preSeasonText: {
      top: '25px!important'
    }
  }
}))

export default WelcomeCompletePreSeasonBanner
