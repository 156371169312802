import React from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import mainLogo from '../../../assets/img/logos/logo-white@2x.png'
import lifersLogo from '../../../assets/img/logos/lifestyle-logo.png'
import { useSelector } from 'react-redux'

const WelcomeStepOneHeader = () => {
  const classes = useStyles()
  const { currentSubscription } = useSelector((state) => state.currentSubscription)
  return (
    <div className={classes.header}>
      <img src={currentSubscription?.lifers ? lifersLogo : mainLogo} alt='white 12wbt logo' className={classes.logo} />
      <Divider className={classes.divider} />
      <span className={classes.title}>A bit about yourself</span>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto'
  },
  logo: {
    maxWidth: '10rem',
    margin: '2rem auto'
  },
  divider: {
    backgroundColor: theme.palette.greys.medium
  },
  title: {
    fontSize: '1.8rem',
    margin: '2rem auto',
    color: 'white'
  },
  [theme.breakpoints.down('xs')]: {
    title: {
      marginBottom: '3rem'
    }
  }
}))

export default WelcomeStepOneHeader
