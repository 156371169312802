import React from 'react'
import PropTypes from 'prop-types'

import ContentContainer from '../../../../components/ContentContainer'

import TaskLearnButtonRow from '../TaskLearnButtonRow'
import TaskContentContainer from '../TaskContentContainer'
import TaskVideo from '../TaskVideo'
import { Box } from '@material-ui/core'

const IntroduceYourselfLearn = ({ doTaskButtonClick }) => {
  return (
    <>
      <Box m='96px' />
      <TaskVideo videoId='5667848192001' />
      <ContentContainer maxPixels={812}>
        <TaskContentContainer>
          <p>
            Welcome to my family! The 12WBT is a team event, which means we can lean on each other and ask for support
            when we need it. At 12WBT we are family!
          </p>
          <p>
            As a family we ALL want to get to know you a little better - myself, my team and the other members of the
            12WBT community and there are many different ways you can connect.
          </p>
          <p>
            Why not check out our private&nbsp;
            <a href={process.env.REACT_APP_12WBT_FACEBOOK_MEMBER_ZONE}>Facebook Member Zone</a> page, Member Zone,&nbsp;
            <a href={process.env.REACT_APP_FACEBOOK_PAGE}>Facebook</a>&nbsp;and&nbsp;
            <a href={process.env.REACT_APP_INSTAGRAM_PAGE}>Instagram</a> channel for all of my tips, tricks, recipes and
            Member shoutouts - you might even make a new friend or 10!
          </p>
          <p>
            To help you take that first step, we developed this Pre-Season task - Introduce Yourself and Your
            Intentions. The purpose of this task is to complete your 12WBT profile, then head over to the Member Zone
            and introduce yourself, as well as informing your teammates and Support Crew (and me!) what you want to
            achieve in 12 weeks time. If we make it a requirement and everyone has to do it, you’re not alone and we’re
            all in this together.
          </p>
          <p>
            We would also love you to make a commitment to your goals and say it out loud to the people who mean
            something to you - your family, friends and colleagues. For example, put your commitment as your Facebook
            status, tweet it out to your friends and family, or call a friend and let them know about your 12WBT. Put it
            out there into the universe!
          </p>
          <p>
            This way you&apos;re not only holding yourself accountable to your goals, but you&apos;re also acknowledging
            that this is a true commitment and you&apos;re confident in your ability to achieve them. Are you a man or a
            woman of your word? Are you willing to give me your word that you will do the work and commit to your
            future?
          </p>
          <p>
            <b>NOW</b> is the time to;
          </p>
          <ul>
            <li>Stand up and openly say, &quot;I am going to do this!&quot;</li>
            <li>Make a commitment with a loved one.</li>
          </ul>
          <TaskLearnButtonRow doTaskButtonClick={doTaskButtonClick} />
        </TaskContentContainer>
      </ContentContainer>
    </>
  )
}

IntroduceYourselfLearn.propTypes = {
  doTaskButtonClick: PropTypes.func.isRequired
}

export default IntroduceYourselfLearn
