import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SignUpHeader from '../app/modules/signup/SignUpHeader'
import Footer from '../app/components/footer/Footer'
import SignUpForm from '../app/modules/signup/form/SignUpForm'
import { useDispatch, useSelector } from 'react-redux'
import SignUpFAQButton from '../app/modules/signup/faq/SignUpFAQButton'
import PromotionalBanner from '../app/modules/signup/PromotionalBanner'
// import MobileBadgeRow from '../app/modules/signup/MobileBadgeRow'
import Reclaim25Terms from '../app/modules/signup/info_section/Reclaim25Terms'
import {
  getCurrentAndNextRoundDates,
  getSecurityToken
} from '../redux/actions/Signup.actions'

const SignUp = ({ lifestyle }) => {
    const dispatch = useDispatch();
    const { currentAndNextRound, loading } = useSelector((state) => state.round);
    const { loading: tokenLoading, token } = useSelector(
        (state) => state.signupSecurityToken
    );

    // Get security token for signup form:
    useEffect(() => {
        if (!tokenLoading && !token) {
            dispatch(getSecurityToken());
        }
    }, [dispatch, token, tokenLoading]);


  // Get next available rounds so signupform knows to render two round options:
  useEffect(() => {
    if (!loading && !currentAndNextRound)
      dispatch(getCurrentAndNextRoundDates())
  }, [currentAndNextRound])

  return (
    <div style={{backgroundColor: '#fcf8f8'}}>
      <SignUpHeader />
      <SignUpFAQButton />
      <PromotionalBanner />
      <SignUpForm lifestyle={lifestyle} />
      <Reclaim25Terms />
      <Footer />
    </div>
  )
}

SignUp.defaultProps = {
  lifestyle: false
}

SignUp.propTypes = {
  lifestyle: PropTypes.bool
}

export default SignUp
