import {
  MEMBER_LOGIN_REQUEST,
  MEMBER_LOGIN_SUCCESS,
  MEMBER_LOGIN_FAIL,
  MEMBER_LOAD_REQUEST,
  MEMBER_LOAD_SUCCESS,
  MEMBER_LOAD_FAIL,
  GET_CURRENT_SUBSCRIPTION_REQUEST,
  GET_CURRENT_SUBSCRIPTION_SUCCESS,
  GET_CURRENT_SUBSCRIPTION_FAIL,
  GET_ALL_SUBSCRIPTIONS_REQUEST,
  GET_ALL_SUBSCRIPTIONS_SUCCESS,
  GET_ALL_SUBSCRIPTIONS_FAIL,
  GET_LAST_SUBSCRIPTION_REQUEST,
  GET_LAST_SUBSCRIPTION_SUCCESS,
  GET_LAST_SUBSCRIPTION_FAIL,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAIL,
  GET_MEMBER_SUMMARY_REQUEST,
  GET_MEMBER_SUMMARY_SUCCESS,
  GET_MEMBER_SUMMARY_FAIL,
  UPDATE_PROFILE_PHOTO_REQUEST,
  UPDATE_PROFILE_PHOTO_SUCCESS,
  UPDATE_PROFILE_PHOTO_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PRE_SEASON_PROGRESS_REQUEST,
  UPDATE_PRE_SEASON_PROGRESS_SUCCESS,
  UPDATE_PRE_SEASON_PROGRESS_FAIL,
  GET_PRE_SEASON_PROGRESS_REQUEST,
  GET_PRE_SEASON_PROGRESS_SUCCESS,
  GET_PRE_SEASON_PROGRESS_FAIL,
  SET_WELCOME_SUBSCRIPTION_ID,
  SET_IS_RETURN_MEMBER,
  SET_CURRENT_ROUND_ID,
  SET_BUILD_A_NEW_PLAN,
  GET_ROUND_MEASUREMENTS_REQUEST,
  GET_ROUND_MEASUREMENTS_SUCCESS,
  GET_ROUND_MEASUREMENTS_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  CLEAR_UPDATE_SUBSCRIPTION_SUCCESS,
  SET_AUTH_ROUTE_ATTEMPT,
  CLEAR_AUTH_ROUTE_ATTEMPT,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  MEMBER_LOGOUT_SUCCESS,
  MEMBER_LOGOUT_FAIL,
  SUBMIT_ROUND_MEASUREMENTS_REQUEST,
  SUBMIT_ROUND_MEASUREMENTS_SUCCESS,
  SUBMIT_ROUND_MEASUREMENTS_FAIL,
  CLEAR_SUBMIT_ROUND_MEASUREMENTS_SUCCESS
} from '../constants/Member.constants'

export const memberReducer = (state = { isAuthenticated: false, isReturnMember: false }, action) => {
  const { type, payload } = action
  switch (type) {
    case MEMBER_LOGIN_REQUEST:
    case MEMBER_LOAD_REQUEST:
    case GET_LAST_SUBSCRIPTION_REQUEST:
    case UPDATE_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case MEMBER_LOGIN_SUCCESS: {
      localStorage.setItem('token', payload.token)
      localStorage.setItem('auth_token', payload.auth_token)
      return {
        ...state,
        isAuthenticated: true,
        ...payload,
        loading: false,
        memberLoginError: false
      }
    }
    case MEMBER_LOGOUT_SUCCESS:
    case MEMBER_LOGOUT_FAIL: {
      return state
    }
    case MEMBER_LOAD_SUCCESS:
      localStorage.setItem('token', payload.token)
      return {
        ...state,
        isAuthenticated: true,
        ...payload,
        loading: false,
        memberLoginError: false
      }
    case GET_LAST_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastSubscription: payload
      }
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        updateSubscriptionSuccess: true
      }
    }
    case CLEAR_UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        updateSubscriptionSuccess: false
      }
    }
    case SET_WELCOME_SUBSCRIPTION_ID: {
      return {
        ...state,
        currentSubscriptionId: payload
      }
    }
    case SET_IS_RETURN_MEMBER: {
      return {
        ...state,
        isReturnMember: true
      }
    }
    case SET_CURRENT_ROUND_ID: {
      return {
        ...state,
        currentRoundId: payload
      }
    }
    case MEMBER_LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
        memberLoginError: true
      }
    }
    case MEMBER_LOAD_FAIL:
    case GET_LAST_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false
      }
    }
    case UPDATE_SUBSCRIPTION_FAIL: {
      return {
        ...state,
        loading: false,
        updateSubscriptionSuccess: false
      }
    }
    default:
      return state
  }
}

export const memberSummaryReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_MEMBER_SUMMARY_REQUEST: {
      return {
        loading: true
      }
    }
    case GET_MEMBER_SUMMARY_SUCCESS: {
      return {
        memberSummary: payload,
        loading: false
      }
    }
    case GET_MEMBER_SUMMARY_FAIL: {
      return {
        loading: true
      }
    }
    default:
      return state
  }
}

export const currentSubscriptionReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_CURRENT_SUBSCRIPTION_REQUEST: {
      return {
        loading: true
      }
    }
    case GET_CURRENT_SUBSCRIPTION_SUCCESS: {
      return {
        loading: false,
        currentSubscription: payload
      }
    }
    case GET_CURRENT_SUBSCRIPTION_FAIL: {
      return {
        loading: false
      }
    }
    default:
      return state
  }
}

export const getAllSubscriptionsReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ALL_SUBSCRIPTIONS_REQUEST: {
      return {
        loading: true
      }
    }
    case GET_ALL_SUBSCRIPTIONS_SUCCESS: {
      return {
        loading: false,
        subscriptions: payload
      }
    }
    case GET_ALL_SUBSCRIPTIONS_FAIL: {
      return {
        loading: false
      }
    }
    default:
      return state
  }
}

export const profileReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PROFILE_REQUEST:
    case UPDATE_PROFILE_PHOTO_REQUEST:
    case UPDATE_MEMBER_REQUEST:
    case UPDATE_PROFILE_REQUEST: {
      return {
        loading: true
      }
    }
    case UPDATE_MEMBER_SUCCESS: {
      return { loading: false }
    }
    case GET_PROFILE_SUCCESS: {
      return {
        loading: false,
        profile: payload
      }
    }
    case UPDATE_PROFILE_SUCCESS: {
      return {
        updateProfileSuccess: true,
        loading: false
      }
    }
    case SET_BUILD_A_NEW_PLAN: {
      return {
        ...state,
        buildANewPlan: true
      }
    }
    case UPDATE_PROFILE_PHOTO_SUCCESS: {
      return {
        photo: payload,
        loading: false
      }
    }
    case UPDATE_PROFILE_PHOTO_FAIL:
    case UPDATE_MEMBER_FAIL:
    case GET_PROFILE_FAIL:
    case UPDATE_PROFILE_FAIL: {
      return {
        loading: false
      }
    }
    default:
      return state
  }
}

export const measurementsReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_ROUND_MEASUREMENTS_REQUEST:
    case SUBMIT_ROUND_MEASUREMENTS_REQUEST: {
      return {
        ...state,
        loading: true,
        success: false
      }
    }
    case GET_ROUND_MEASUREMENTS_SUCCESS: {
      return {
        measurements: payload,
        loading: false
      }
    }
    case SUBMIT_ROUND_MEASUREMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true
      }
    }
    case GET_ROUND_MEASUREMENTS_FAIL:
    case SUBMIT_ROUND_MEASUREMENTS_FAIL: {
      return {
        loading: false
      }
    }
    case CLEAR_SUBMIT_ROUND_MEASUREMENTS_SUCCESS: {
      return { ...state, loading: false, success: undefined }
    }
    default:
      return state
  }
}

export const preSeasonReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_PRE_SEASON_PROGRESS_REQUEST:
    case UPDATE_PRE_SEASON_PROGRESS_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_PRE_SEASON_PROGRESS_SUCCESS: {
      return { ...state, loading: false, progress: payload }
    }
    case UPDATE_PRE_SEASON_PROGRESS_SUCCESS: {
      return state
    }
    case GET_PRE_SEASON_PROGRESS_FAIL:
    case UPDATE_PRE_SEASON_PROGRESS_FAIL: {
      return { ...state, loading: false }
    }
    default:
      return state
  }
}

export const authRouteReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_AUTH_ROUTE_ATTEMPT: {
      return {
        url: payload
      }
    }
    case CLEAR_AUTH_ROUTE_ATTEMPT: {
      return {}
    }
    default:
      return state
  }
}

export default memberReducer
