import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import TaskContentContainer from '../../TaskContentContainer'
import TaskVideo from '../../TaskVideo'
import NextTaskLink from '../NextTaskLink'

const FitnessTestAerobicCapacity = ({ nextTaskClick, nextTask }) => {
  return (
    <TaskContentContainer>
      <TaskContentSubHeader title='Aerobic Capacity' />
      <TaskVideo videoId='3082265607001' needsCenterAdjust={true} />
      <p>
        What does this measure?
        <br />
        This is a measurement of your cardiovascular fitness. The fitter you are, the healthier your heart.
      </p>
      <p>
        Choose your intensity – you have 3 levels to choose from. For a low intensity option and a test that can be
        easier on your knees, perform the 3km (1.86 miles) Cycle Time Trial and record your time in the results table
        below. It is best if you choose a flat course but whatever you do clearly mark your course as you will be
        repeating this and we want perfect accuracy each time.
      </p>
      <p>
        For the medium intensity option, complete the 1km Time Trial. Measure out 1km (0.62 miles) or 1000m (1100
        yards). That&apos;s 2.5 laps around a standard athletics track. I would prefer you not do it on a treadmill
        because there are slight differences running outdoors and I&apos;d rather you set your natural speed than have
        the machine do it for you. Record your time in the results table below.
      </p>
      <p>
        For the most advanced option, perform the 12 Minute Running Test by running as far as you can in 12 minutes then
        record your distance in the results table below. Be accurate! If you can use a smartphone app to help you with
        recording your time and distance, go for it! Here are some examples of helpful apps...
      </p>
      <ol>
        <li>Strava</li>
        <li>Map My Run</li>
        <li>Runkeeper</li>
      </ol>
      <p>
        Make sure you enter your results against the RIGHT test! Click on the highlighted blue test names in the results
        section below to select the intensity option you chose.
      </p>
      <p>Ready, Set…Go!</p>
      <p>Start your stopwatch or hit record on your smartphone and get moving!!!!</p>
      <NextTaskLink link='Flexibility' onClick={() => nextTaskClick(nextTask)} />
    </TaskContentContainer>
  )
}

FitnessTestAerobicCapacity.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default FitnessTestAerobicCapacity
