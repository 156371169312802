import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Grid, makeStyles } from '@material-ui/core'
import RadioAnswerOption from './RadioAnswerOption'

const RadioQuestion = ({ number, question, image, questionAnswer, answerArray, answerText, onNextQuestionClick }) => {
  const [answer, setAnswer] = useState(null)
  const [answerSubmitted, setAnswerSubmitted] = useState(false)
  const classes = useStyles({ image, answerSubmitted })

  const submitQuestionHandler = () => {
    if (answer !== null) {
      setAnswerSubmitted(true)
    }
    // Next button clicked:
    if (answerSubmitted) {
      setAnswer(null)
      setAnswerSubmitted(false)
      const result = answer === questionAnswer
      onNextQuestionClick(result)
    }
  }

  const radioButtonClickHandler = (value) => {
    if (answerSubmitted) return
    setAnswer(value)
  }

  const buttonClass = !answerSubmitted ? classes.button : `${classes.button} ${classes.nextButton}`
  const answerInfoTitleClass =
    questionAnswer === answer
      ? `${classes.infoTitle} ${classes.successInfoTitle}`
      : `${classes.infoTitle} ${classes.failInfoTitle}`
  const questionAreaClassName = !answerSubmitted
    ? classes.questionArea
    : `${classes.questionArea} ${classes.questionAreaanswerSubmitted}`

  return (
    <div className={classes.container}>
      <p>
        <b>{`Q${number}.`}</b>&nbsp;{question}
      </p>
      <Grid container>
        <Grid item xs={12} sm={5} className={classes.back}>
          <div className={classes.img} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <div className={questionAreaClassName}>
            {answerArray ? (
              <>
                {answerArray.map((ans, i) => {
                  return (
                    <RadioAnswerOption
                      key={i}
                      currentAnswer={answer}
                      setAnswer={radioButtonClickHandler}
                      answerSubmitted={answerSubmitted}
                      radioAnswer={ans}
                      currentAnswerCorrect={questionAnswer === ans}
                      correctAnswerChosen={answerSubmitted && answer === ans && answer === questionAnswer}
                    />
                  )
                })}
              </>
            ) : (
              <>
                <RadioAnswerOption
                  currentAnswer={answer}
                  setAnswer={radioButtonClickHandler}
                  answerSubmitted={answerSubmitted}
                  radioAnswer={true}
                  currentAnswerCorrect={questionAnswer === true}
                  correctAnswerChosen={answerSubmitted && answer === true && answer === questionAnswer}
                />
                <RadioAnswerOption
                  currentAnswer={answer}
                  setAnswer={radioButtonClickHandler}
                  answerSubmitted={answerSubmitted}
                  radioAnswer={false}
                  currentAnswerCorrect={questionAnswer === false}
                  correctAnswerChosen={answerSubmitted && answer === questionAnswer && answer === false}
                />
              </>
            )}
            {answerSubmitted && (
              <div className={classes.answerContainer}>
                <span className={answerInfoTitleClass}>{answer === questionAnswer ? 'Correct!' : 'Did you know?'}</span>
                <span className={classes.answerText}>{answerText}</span>
              </div>
            )}
          </div>
          <div className={classes.bottomSection}>
            <Divider className={classes.divider} />
            <div className={classes.buttonRow}>
              <Button className={buttonClass} onClick={submitQuestionHandler}>
                {answerSubmitted ? 'Next' : 'Continue'}
              </Button>
              <span>{number} of 12</span>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '6rem 0'
  },
  questionArea: {
    margin: '1rem',
    display: 'flex',
    flexDirection: 'column'
  },
  questionAreaanswerSubmitted: {
    margin: '.5rem',
    fontSize: '.8rem',
    width: '15rem'
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.greys.light,
    width: '100%',
    margin: '.5rem 0',
    height: '3rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  checkboxRowanswerSubmitted: {
    height: '2.5rem',
    borderRadius: '6px'
  },
  successRadio: {
    '&$checked': {
      color: 'rgb(50,205,50)'
    }
  },
  checked: {},
  failRadio: {
    '&$checked': {
      color: 'red'
    }
  },
  infoTitle: {
    fontWeight: 800,
    fontSize: '1.2rem'
  },
  answerContainer: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column'
  },
  successInfoTitle: {
    color: 'rgb(50,205,50)'
  },
  buttonRow: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  img: {
    margin: '1rem',
    marginRight: 0,
    height: '17rem',
    width: '17rem',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: (props) => `url(${props.image})`
  },
  divider: {
    color: theme.palette.greys.light,
    margin: '.5rem 0'
  },
  back: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    width: '18rem',
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#2563EB',
    borderRadius: '6px',
    height: '50px',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    '&:hover': {
      border: `1px solid #2563EB`,
      color: '#2563EB'
    }
  },
  nextButton: {
    width: '10rem'
  },
  bottomSection: {
    margin: (props) => (props.answerSubmitted ? '.5rem' : '1rem')
  },
  [theme.breakpoints.down('sm')]: {
    img: {
      marginRight: '1rem'
    },
    container: {
      margin: '2rem 0'
    },
    questionArea: {
      margin: 'auto'
    },
    button: {
      width: '11rem'
    },
    questionAreaanswerSubmitted: {
      width: '100%',
      fontSize: '1rem'
    }
  }
}))

RadioQuestion.propTypes = {
  number: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  questionAnswer: PropTypes.oneOfType[(PropTypes.string.isRequired, PropTypes.object.isRequired)],
  image: PropTypes.string.isRequired,
  answerText: PropTypes.string.isRequired,
  answerArray: PropTypes.array,
  onNextQuestionClick: PropTypes.func.isRequired
}

export default RadioQuestion
